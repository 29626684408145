import React from "react";

function FileFailedSVG() {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="12" cy="12" r="10" fill="#FF6961" stroke="#FF6961" />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M7.89645 8.60355C7.70118 8.40829 7.70118 8.09171 7.89645 7.89645C8.09171 7.70118 8.40829 7.70118 8.60355 7.89645L12 11.2929L15.3964 7.89645C15.5917 7.70118 15.9083 7.70118 16.1036 7.89645C16.2988 8.09171 16.2988 8.40829 16.1036 8.60355L12.7071 12L16.1036 15.3964C16.2988 15.5917 16.2988 15.9083 16.1036 16.1036C15.9083 16.2988 15.5917 16.2988 15.3964 16.1036L12 12.7071L8.60355 16.1036C8.40829 16.2988 8.09171 16.2988 7.89645 16.1036C7.70118 15.9083 7.70118 15.5917 7.89645 15.3964L11.2929 12L7.89645 8.60355Z"
        fill="white"
      />
    </svg>
  );
}

export default FileFailedSVG;
