import SubscriberNavbar from "../../../../../components/subscriberNavbar/SubscriberNavbar"
import "./History.css"
import HistoryComponent from "./historyComponent/HistoryComponent"
import { MyContext } from "../../../../../ContextAPIStore/CreateContext"
import { useContext, useEffect, useState } from "react"
import { historyAllGetAPI } from "../../../../../services/servicesAPI"
import { toast } from "react-toastify"
import LoaderComponent from "../../../../../components/loaderComponent/LoaderComponent"
const History = (props)=>{

  const [historyAllData,setHistoryAllData] = useState([])
  const [pagingData,setPagingData] = useState({page:1,limit:25})
  const [totalCount,setTotalCount] = useState()
  const [loader,setLoader] = useState(false)
  


  const historyAllGet = async (payload)=>{
    setLoader(true)

    const response = await historyAllGetAPI(payload);

    if(response.success){
      console.log("response123=======>",response?.data?.data)
      setTimeout(()=>{
        setLoader(false)
        setTotalCount(response?.data?.data?.count)
      setHistoryAllData(response?.data?.data?.result)

      },1500)
      
      
    }else{
      toast.error(response.message)
      setLoader(false)
    }
    
  }


  useEffect(()=>{
    historyAllGet(pagingData);
  },[])

  const handlePagingApiData = async (value)=>{
    console.log("value======>",value)
    if(value === 1){
      if(pagingData.page >= 2){
        let page = pagingData.page - 1
        let limit = pagingData.limit
        let payload = {page:page,limit:limit}
        setPagingData(payload)
        await historyAllGet(payload);
      }

    }else{
      if((totalCount > (10 * pagingData.page)))
      {
        let page = pagingData.page + 1
        let limit = pagingData.limit
        let payload = {page:page,limit:limit}
        setPagingData(payload)
        await historyAllGet(payload);
       }

      }
      
  }


  const constConfigData = useContext(MyContext)
    return <div className="history">
      {
        loader && <LoaderComponent />
      }
        <div style={{ height: "35px" }}></div>
        <div className="history-heading">
        <div>
          <h2 style={{fontWeight:"600",fontSize: "2.4em",
              letterSpacing: "0.8px",}}>{constConfigData?.Contacts}</h2>
        </div>
        {/* <div className="group-heading-right">
          
          <div  className="group-heading-right-2">
            <p>Create Group</p>
          </div>
        </div> */}
      </div>
      <div style={{ height: "30px" }}></div>
        <SubscriberNavbar />
        <div style={{ height: "30px" }}></div>
        {
          historyAllData.length > 0 ?
        
        <div className="history-content" >
          {
            historyAllData.map((item,index)=>{
              return <HistoryComponent key={index} item={item} />
            })
          }
        <div className="history-content-footer">
        <div>
            <p>
            {constConfigData?.Showing} 
            {/* {pagingData.page }  */}
            {" "}
            <span>{(pagingData.limit*pagingData.page)-(pagingData.limit-1)}</span>
            {" "}
            to
            {" "} 
             {/* {pagingData.page *historyAllData.length}  */}
              <span> {(pagingData.page*pagingData.limit)>totalCount?totalCount:(pagingData.page*pagingData.limit)}</span>
             {" "}
             of {" "}
             {" "}

             <span>{totalCount}</span>
             {" "}
             {constConfigData?.results}
            </p>
          </div>
          <div>
            <button disabled={pagingData.length > 1 ? true : false} onClick={()=>{handlePagingApiData(1)}} style={{backgroundColor:pagingData.page > 1 ? "#E5E7EB" : "#F2F3F5",padding:'12px 22px',fontSize:'1.2em',color:pagingData.page > 1?'#404040':"rgb(159,159,159)"}}  >{constConfigData?.Previous}</button>
            <button disabled={pagingData.length > 1 ? true : false} onClick={()=>{handlePagingApiData(2)}} style={{backgroundColor: (totalCount > (10 * pagingData.page)) ? "#E5E7EB" : "#F2F3F5",padding:'12px 22px',fontSize:'1.2em',color:(totalCount > (10 * pagingData.page))?'#404040':"rgb(159,159,159)"}} >{constConfigData?.Next}</button>
          </div>
        </div>
        </div>
         :
         <div className="history-no-data">
            <p>No Contacts history yet.</p>

        </div> 
}
    </div>
}

export default History