import { Formik } from "formik";
import "./MyProfile.css";
import { useContext, useEffect, useState } from "react";
import { useNavigate } from 'react-router-dom'
import { ChangePasswordSchema } from "../../../utils/ValidationSchema";
import { changePasswordAPI } from "../../../services/servicesAPI";
import { toast } from "react-toastify";
import { getUserDetailsFromLocalStorage } from "../../../utils/getToken";
import { MyContext } from "../../../ContextAPIStore/CreateContext";

const MyProfile = () => {
  const navigate = useNavigate()
  const configData = useContext(MyContext)
  const [showPasswordFieldFlag,setShowPasswordFieldFlag] = useState(false)
  const[clickedField,setClickedField]=useState(null)
  const [currPassword,setCurrPassword] = useState("")
  const [newPassword,setNewPassword] = useState("")
  const [confirmPassword,setConfirmPassword] = useState("")
  const [userDetails,setUserDetails] = useState({})


  const handleBorderStyle =(field)=>{
    setClickedField(field === clickedField ? null : field);
  }

  const handleChangePasswordApi = async (values)=>{
    let payloadData = {
                "currentPassword":values?.currPassword,
                "newPassword":values?.newPassword
              }
    console.log("values==========>",payloadData)
    const response = await changePasswordAPI(payloadData)
    console.log("response change password=========>",response)
    if(response.success){
      toast.success(response.message)
    }else{
      toast.error(response.message)
    }
  }

  useEffect(()=>{
    let details = getUserDetailsFromLocalStorage()
    setUserDetails(details)
  },[])


  return (
    <div className="my-profile">
      <div className="my-profile-heading">
        <h1>{configData?.Profile_details}</h1>
      </div>
      <div style={{ height: "20px" }}></div>
      <Formik
      initialValues={{
        currPassword: "",
        newPassword: "",
        confirmPassword: "",
      }}
      validationSchema={ChangePasswordSchema}
      onSubmit={(values)=>{
        // console.log("submit values",values)
        handleChangePasswordApi(values)
      }}
      
      >
        {({handleSubmit, errors, touched,handleChange,values}) => {
          return <div className="my-profile-content">
            <div className="my-profile-content-1">
                <div className="my-profile-content-1-1">
                    <p>{configData?.First_name}</p>
                    <input disabled value={userDetails?.firstName} placeholder=" " type="text" style={{border:`1px solid ${clickedField==='field1' ? 'black':'#EBEDF0'}`}} onClick={()=>handleBorderStyle('field1')}/>
                </div>
                <div className="my-profile-content-1-1">
                    <p>{configData?.Last_name}</p>
                    <input disabled value={userDetails?.lastName} placeholder=" " type="text" style={{border:`1px solid ${clickedField==='field2' ? 'black':'#EBEDF0'}`}} onClick={()=>handleBorderStyle('field2')}/>
                </div>
            </div>
            <div className="my-profile-content-2">
                <div className="my-profile-content-2-1">
                    <p>{configData?.Email}</p>
                    <input disabled value={userDetails?.email} placeholder=" " type="text" style={{border:`1px solid ${clickedField==='field3' ? 'black':'#EBEDF0'}`}} onClick={()=>handleBorderStyle('field3')}  />
                </div>
                
            </div>
            
            <div style={{marginBottom:"5vh"}} className="my-profile-content-2">
                <div style={{position:"relative"}} className="my-profile-content-2-1">
                  {
                    showPasswordFieldFlag ? <p> {configData?.Current_password}</p> :<p>{configData?.Password}</p>
                  }
                   
                    <div style={{display:"flex",alignItems:"center",justifyContent:"space-between"}}>
                    <input id="currPassword"  name="currPassword" value={values.currPassword} onChange={handleChange} placeholder={!showPasswordFieldFlag?"******":""} style={{width:`${showPasswordFieldFlag?"100%":`${(100-19.5)}%`}`}} type="password"  />
                   {
                    !showPasswordFieldFlag && <span onClick={()=>{setShowPasswordFieldFlag(true)}} style={{cursor:"pointer",color:"#404040",fontSize:"14px",backgroundColor:"#E5E7EB",padding:"1.9vh 2%",marginLeft:"1%",borderRadius:"4px" ,fontWeight:'600',letterSpacing:'0.5px'}}>{configData?.Change_password}</span>
                   } 
                   </div>
                   {errors.currPassword && touched.currPassword ? (
                        <p style={{color:"red",position:"absolute",bottom:"-45%",fontSize:"0.8em"}}>{errors.currPassword}</p>
                      ) : (
                        <></>
                      )}
                      
                </div>
                
            </div>

            {
              showPasswordFieldFlag && <>
              <div style={{marginBottom:"5vh"}} className="my-profile-content-2">
                <div style={{position:"relative"}} className="my-profile-content-2-1">
                    <p>{configData?.New_Password}</p>
                    <input id="newPassword" name="newPassword" value={values.newPassword} onChange={handleChange} placeholder=" " type="password"   />
                    {errors.newPassword && touched.newPassword ? (
                        <p style={{color:"red",position:"absolute",bottom:"-45%",fontSize:"0.8em"}}>{errors.newPassword}</p>
                      ) : (
                        <></>
                      )}
                </div>
                
            </div>
            <div style={{marginBottom:"5vh"}} className="my-profile-content-2">
                <div style={{position:"relative"}} className="my-profile-content-2-1">
                    <p>{configData?.Repeat_new_Password}</p>
                    <input id="confirmPassword" name="confirmPassword" value={values.confirmPassword} onChange={handleChange} placeholder="" type="password"   />
                    {errors.confirmPassword && touched.confirmPassword ? (
                        <p style={{color:"red",position:"absolute",bottom:"-45%",fontSize:"0.8em"}}>{errors.confirmPassword}</p>
                      ) : (
                        <></>
                      )}

                </div>
                
            </div>
              </>
            }
            {
              showPasswordFieldFlag && <div className="my-profile-content-footer" >
              <div onClick={()=> {
                // navigate('/constant-config')
                handleSubmit(values)
                }}>
                <p style={{color:'white',fontWeight:'600',fontSize:'1em',letterSpacing:'0.7px'}} >{configData?.Save_Changes}</p>
              </div> 
            </div>

            }
            
            
           
            </div>
        }}
      </Formik>
    </div>
  );
};

export default MyProfile;
// style={{border:`1px solid ${clickedField==='field6' ? 'black':'#EBEDF0'}`}} onClick={()=>handleBorderStyle('field6')}