import "./ProgressBarComponent.css"
const ProgressBarComponent = ({value})=>{
    return <div className="progress-bar-component">
        <div className="progress-bar-component-prog">
        <progress  id="file" value={value} max="100"> 32% </progress>
        </div>
        <div className="progress-bar-component-label">
            <p>{value}%</p>
        </div>
    </div>
}

export default ProgressBarComponent