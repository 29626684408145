import { useState } from "react";
import "./ChangePassword.css";
import { FiEye, FiEyeOff } from "react-icons/fi";
import { ChangePasswordSchema } from "../../utils/ValidationSchema";
import { Formik } from "formik";
import { useNavigate } from "react-router-dom";
const ChangePassword = () => {
  const [showCurrPasswordFlag, setShowCurrPasswordFlag] = useState(false);
  const [showNewPasswordFlag, setShowNewPasswordFlag] = useState(false);
  const [showConfirmPasswordFlag, setShowConfirmPasswordFlag] = useState(false);

  const navigate = useNavigate()



  const handleChangePasswordSubmit = (values) => {
    console.log("click change passwors submit",values);
    navigate("/dashboard")
  };

  return (
    <div className="">
      <div style={{ height: "35px" }}></div>
      <div className="change-password">
        <h1>Change Password</h1>
        <p>Please enter your current password to change your password.</p>

        <Formik
          initialValues={{
            currPassword: "",
            newPassword: "",
            confirmPassword: "",
          }}
          validationSchema={ChangePasswordSchema}
          onSubmit={(values) => {
            // console.log("hello",values);
            handleChangePasswordSubmit(values)

          }}
        >
          {({ handleSubmit, errors, touched,handleChange,values }) => {
            return (
              <div className="change-password-content">
                <div className="change-password-content-1">
                  <div className="change-password-content-row">
                    <label htmlFor="">Current password</label>

                    <div className="change-password-content-row-input">
                      <input id="currPassword" name="currPassword" value={values.currPassword} onChange={handleChange}  placeholder="Current Password" type={showCurrPasswordFlag?"text":"password"} />
                      {showCurrPasswordFlag ? (
                        <div
                          onClick={() => {
                            setShowCurrPasswordFlag(!showCurrPasswordFlag);
                          }}
                          className="change-password-content-row-icon"
                        >
                          <FiEyeOff size={20} />
                        </div>
                      ) : (
                        <div
                          onClick={() => {
                            setShowCurrPasswordFlag(!showCurrPasswordFlag);
                          }}
                          className="change-password-content-row-icon"
                        >
                          <FiEye size={20} />
                        </div>
                      )}

                      {errors.currPassword && touched.currPassword ? (
                        <p style={{color:"red",position:"absolute",bottom:"-20px"}}>{errors.currPassword}</p>
                      ) : (
                        <></>
                      )}
                    </div>
                  </div>

                  <div className="change-password-content-row">
                    <label htmlFor="">Enter new password</label>
                    <div className="change-password-content-row-input">
                      <input id="newPassword" name="newPassword" value={values.newPassword} onChange={handleChange}  placeholder="Current Password" type={showNewPasswordFlag?"text":"password"} />
                      {showNewPasswordFlag ? (
                        <div
                          onClick={() => {
                            setShowNewPasswordFlag(!showNewPasswordFlag);
                          }}
                          className="change-password-content-row-icon"
                        >
                          <FiEyeOff size={20} />
                        </div>
                      ) : (
                        <div
                          onClick={() => {
                            setShowNewPasswordFlag(!showNewPasswordFlag);
                          }}
                          className="change-password-content-row-icon"
                        >
                          <FiEye size={20} />
                        </div>
                      )}
                      {errors.newPassword && touched.newPassword? (
                        <p style={{color:"red",position:"absolute",bottom:"-20px"}}>{errors.newPassword}</p>
                      ) : (
                        <></>
                      )}
                    </div>
                  </div>
                  <div className="change-password-content-row">
                    <label htmlFor="">Confirm new password</label>
                    <div className="change-password-content-row-input">
                      <input id="confirmPassword" name="confirmPassword" value={values.confirmPassword} onChange={handleChange} placeholder="Current Password" type={showConfirmPasswordFlag?"text":"password"} />
                      {showConfirmPasswordFlag ? (
                        <div
                          onClick={() => {
                            setShowConfirmPasswordFlag(
                              !showConfirmPasswordFlag
                            );
                          }}
                          className="change-password-content-row-icon"
                        >
                          <FiEyeOff size={20} />
                        </div>
                      ) : (
                        <div
                          onClick={() => {
                            setShowConfirmPasswordFlag(
                              !showConfirmPasswordFlag
                            );
                          }}
                          className="change-password-content-row-icon"
                        >
                          <FiEye size={20} />
                        </div>
                      )}
                      {errors.confirmPassword && touched.confirmPassword ? (
                        <p style={{color:"red",position:"absolute",bottom:"-20px"}}>{errors.confirmPassword}</p>
                      ) : (
                        <></>
                      )}
                    </div>
                  </div>
                </div>
                <div className="change-password-content-2">
                  <button onClick={()=>{}} style={{ color: "#404040" }}>Cancel</button>
                  <button
                    type="button"
                    style={{ backgroundColor: "#2081E2" }}
                    onClick={() => {
                      handleSubmit(values);
                    }}
                  >
                    Submit
                  </button>
                </div>
              </div>
            );
          }}
        </Formik>
      </div>
    </div>
  );
};

export default ChangePassword;
