let filterData = [
  {
    key: "totalContacts",
    data: {
      name: "All Contacts",
      filters: [
        [
          {
            operator: "yes",
            args: ["fields", "isActive", []]
          }
        ],
      ],
      createdAt: "2024-07-09T09:55:48.119Z",
    }
  },
  {
    key: "EmailExists",
    data: {
      name: "Has Email",
      filters: [
        [
          {
            operator: "isSet",
            args: ["email", [], []],
          },
          {
            operator: "yes",
            args: ["fields", "isActive", []]
          }
        ],
      ],
      createdAt: "2024-07-09T09:55:48.119Z",
    }
  },
  {
    key: "HBCU",
    data: {
      name: "HBCU",
      filters: [
        [
          {
            operator: "isSet",
            args: ["fields", "HBCUShort", []],
          },
          {
            operator: "yes",
            args: ["fields", "isActive", []]
          }
        ],
      ],
      createdAt: "2024-07-09T09:55:48.119Z",
    }
  },
  {
    key: "OpenedEmail",
    data: {
      name: "Opened Email",
      filters: [
        [
          {
            operator: "isGreaterThan",
            args: ["stats", "emailOpened", "1"],
          },
          {
            operator: "yes",
            args: ["fields", "isActive", []]
          }
        ],
      ],
      createdAt: "2024-07-09T09:55:48.119Z",
    }
  },
  {
    key: "LinkedIn",
    data: {
      name: "LinkedIn",
      filters: [
        [
          {
            operator: "isSet",
            args: ["fields", "linkedInURL", []],
          },
          {
            operator: "yes",
            args: ["fields", "isActive", []]
          }
        ],
      ],
      createdAt: "2024-07-09T09:55:48.119Z",
    }
  },
  {
    key: "MailingAddress",
    data: {
      name: "Mailing Address",
      filters: [
        [
          {
            operator: "isSet",
            args: ["fields", "addrIds", []],
          },
          {
            operator: "yes",
            args: ["fields", "isActive", []]
          }
        ],
      ],
      createdAt: "2024-07-09T09:55:48.119Z",
    }
  },
  {
    key: "Phone",
    data: {
      name: "Phone",
      filters: [
        [
          {
            operator: "isSet",
            args: ["fields", "phone", []],
          },
          {
            operator: "yes",
            args: ["fields", "isActive", []]
          }
        ],
      ],
      createdAt: "2024-07-09T09:55:48.119Z",
    }
  },
  {
    key: "NoEmails",
    data: {
      name: "No Email",
      filters: [
        [
          {
            operator: "isNotSet",
            args: ["email", [], []],
          },
          {
            operator: "yes",
            args: ["fields", "isActive", []]
          }
        ],
      ],
      createdAt: "2024-07-09T09:55:48.119Z",
    }
  },
  {
    key: "GoodEmail",
    data: {
      name: "Good Email",
      selectedContact: "goodEmails",
      filters: [
        [
          {
            operator: "yes",
            args: ["fields", "isActive", []]
          }
        ],
      ],
      createdAt: "2024-07-09T09:55:48.119Z",
    }
  },
  {
    key: "SentEmail",
    data: {
      name: "Sent Email",
      filters: [
        [
          {
            operator: "isGreaterThan",
            args: ["stats", "emailSent", "1"],
          },
          {
            operator: "yes",
            args: ["fields", "isActive", []]
          }
        ],
      ],
      createdAt: "2024-07-09T09:55:48.119Z",
    }
  },
  {
    key: "Inactive",
    data: {
      name: "Inactive",
      filters: [
        [
          {
            operator: "no",
            args: ["fields", "isActive", []],
          },
        ],
      ],
      createdAt: "2024-07-09T09:55:48.119Z",
    }
  }
];

module.exports = filterData;
