import CrossSVG from "../../asset/svg/CrossSVG";
import SendSVG from "../../asset/svg/SendSVG";
import "./SearchModal.css";
import { IoSearchOutline, IoSendSharp } from "react-icons/io5";

const SearchModal = (props) => {
  return (
    <div className="searchmodal">
      <div className="searchmodal-content">
        {/* <div className="searchmodal-content-close" onClick={()=>{props.setSearchModallag(false)}}>
                <p>X</p>
            </div> */}
        <div
          className="searchmodal-content-close"
          onClick={() => {
            props.setSearchModallag(false)
            // setDropdownFlag(false);
            // onclose();
          }}
        >
          <div>
            <CrossSVG />
          </div>
        </div>
        <div className="searchmodal-content-content">
            <div style={{width:"100%",alignItems:"center",justifyContent:"flex-start",paddingLeft:"2vw"}} className="searchmodal-content-content">
            <IoSearchOutline size={20} />
          <input
            style={{ border: "none", borderRight: "1px solid #d4d4d4" ,width:"100%"}}
            type="text"
            placeholder="Search contacts by email or name"
          />

            </div>
        
          <div className="searchmodal-content-content-btn">
            {/* <IoSendSharp style={{ marginRight: "3px" }} /> */}
            <SendSVG />
            <p>Enter</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SearchModal;
