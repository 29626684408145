const CrossSVG = () => {
  return (
      <svg
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clip-path="url(#clip0_260_14020)">
          <path
            d="M4.24268 3.75781L12.728 12.2431"
            stroke="white"
            stroke-width="1.5"
            stroke-linecap="round"
          />
          <path
            d="M4.24268 12.2422L12.728 3.75691"
            stroke="white"
            stroke-width="1.5"
            stroke-linecap="round"
          />
        </g>
        <defs>
          <clipPath id="clip0_260_14020">
            <rect width="16" height="16" fill="white" />
          </clipPath>
        </defs>
      </svg>
  );
};
export default CrossSVG;
