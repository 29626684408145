import { useContext, useEffect, useState } from "react";
import "./SettingNavbar.css";
import { useLocation, useNavigate } from "react-router-dom";
import { MyContext } from "../../../ContextAPIStore/CreateContext";
import { NavLink } from "react-router-dom";
const SettingNavbar = () => {
  const constConfigData = useContext(MyContext);
  // const EXPORT_COLUMNS  ="EXPORT_COLUMNS"
  // const MY_PROFILE = "MY_PROFILE"
  // const NOTIFICATIONS ="NOTIFICATIONS"
  // const navigate = useNavigate()
  // const location = useLocation()

  // const [navItemFlag,setNavItemFlag] = useState({key:EXPORT_COLUMNS,value:true})

  // useEffect(()=>{
  //     let obj1 = { key: (location?.state?.fromScreen === EXPORT_COLUMNS || location?.state?.fromScreen === "CONSTANT_CONFIG")?EXPORT_COLUMNS:location?.state?.fromScreen , value: true };
  // setNavItemFlag(obj1);
  // },[])

  return (
    <div className="setting-navbar">
      <ul>
        {/* <li onClick={()=>{handleNavigate("/constant-config",EXPORT_COLUMNS)}} style={{borderBottom:`2px solid ${navItemFlag.key === EXPORT_COLUMNS?"#2081E3":"#E5E7EB"}`}}>
                {constConfigData?.Export_Column}
            </li>
            <li onClick={()=>{handleNavigate("/constant-config/my-profile",MY_PROFILE)}} style={{borderBottom:`2px solid ${navItemFlag.key === MY_PROFILE?"#2081E3":"#E5E7EB"}`}}>{constConfigData?.My_Profile}</li>
            <li onClick={()=>{handleNavigate("/constant-config/notifications",NOTIFICATIONS)}} style={{borderBottom:`2px solid ${navItemFlag.key === NOTIFICATIONS?"#2081E3":"#E5E7EB"}`}}>Notification</li> */}

        <li>
          <NavLink to="/constant-config" end>
            {" "}
            {constConfigData?.Contact_Columns}
          </NavLink>
        </li>
        <li>
          {" "}
          <NavLink to="/constant-config/my-profile" end>
            {constConfigData?.My_Profile}
          </NavLink>{" "}
        </li>
        <li>
          <NavLink to="/constant-config/notifications" end>
            {" "}
            {constConfigData?.Notifications}
          </NavLink>
        </li>
        <li>
          <NavLink to="/constant-config/backup" end>
            {" "}
            {constConfigData?.Backup}s
          </NavLink>
        </li>
      </ul>
    </div>
  );
};

export default SettingNavbar;
