import React from "react";

const IbuttonSVG =()=> {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="20" height="20" rx="10" fill="black" fill-opacity="0.07" />
      <path
        d="M9.15239 15V7.84583H10.8385V15H9.15239ZM10.0001 6.83046C9.73304 6.83046 9.50326 6.74197 9.31075 6.56497C9.11823 6.38488 9.02197 6.16907 9.02197 5.91756C9.02197 5.66294 9.11823 5.44714 9.31075 5.27014C9.50326 5.09005 9.73304 5 10.0001 5C10.2702 5 10.5 5.09005 10.6894 5.27014C10.8819 5.44714 10.9782 5.66294 10.9782 5.91756C10.9782 6.16907 10.8819 6.38488 10.6894 6.56497C10.5 6.74197 10.2702 6.83046 10.0001 6.83046Z"
        fill="#404040"
      />
    </svg>
  );
}

export default IbuttonSVG;
