import * as Yup from 'yup';
 
 export const ChangePasswordSchema = Yup.object().shape({
   currPassword: Yup.string().min(8,"The value must be at least 8 character").required('This Field is required'),
   newPassword: Yup.string().min(8,"The value must be at least 8 character").required('This Field is required'),
   confirmPassword: Yup.string().min(8,"The value must be at least 8 character").oneOf([Yup.ref('newPassword'), null], 'Repeat Password is not match with the new Password')
   .required('This Field is required'),
 });

 export const ResetPasswordSchema = Yup.object().shape({
  newPassword: Yup.string().min(8,"The value must be at least 8 character").required('This Field is required'),
  confirmPassword: Yup.string().min(8,"The value must be at least 8 character").oneOf([Yup.ref('newPassword'), null], 'Repeat Password is not match with the new Password')
  .required('This Field is required'),
});

 export const AddNewAccountsSchema = Yup.object().shape({
  name:Yup.string().min(3,"The value must be at least 3 character").required('This Field is required'),
  userName:Yup.string().min(3,"The value must be at least 3 character").required('This Field is required'),
  url:Yup.string().min(3,"The value must be at least 3 character").required('This Field is required'),
  secretKey:Yup.string().min(3,"The value must be at least 3 character").required('This Field is required'),
  connectionString:Yup.string().min(3,"The value must be at least 3 character").required('This Field is required'),
 })