import { MdKeyboardArrowRight } from "react-icons/md";
import "./AddNewAccounts.css";
import { Formik } from "formik";
import { useNavigate } from "react-router-dom";
import ToggleOnSVG from "../../../asset/svg/ToggleOnSVG";
import { useContext, useEffect, useState } from "react";
import ToggleOffSVG from "../../../asset/svg/ToggleOffSVG";
import { AddNewAccountsSchema } from "../../../utils/ValidationSchema";
import { FiEye, FiEyeOff } from "react-icons/fi";
import { MyContext } from "../../../ContextAPIStore/CreateContext";
import BreadCrumbsComponent from "../../../components/breadCrumbsComponent/BreadCrumbsComponent";
import { emsConnAddPostAPI } from "../../../services/servicesAPI";
import { toast } from "react-toastify";
import { getEMSAPIType } from "../../../services/servicesAPI";

const AddNewAccounts = () => {
  const constConfigData = useContext(MyContext);

  const navigate = useNavigate();
  const [isActiveToggleFlag, setIsActiveToggleFlag] = useState(false);
  const [typeDropdownFlag, setTypeDropdownFlag] = useState(false);
  const [typeDropdownInputValue, setTypeDropdownInputValue] = useState("");
  const [typeFieldError, setTypeFieldError] = useState(false);
  const [showSecretKeyFlag, setShowSecretKeyFlag] = useState(false);
  const [url, setUrl] = useState("");
  const [connectionString, setConnectionString] = useState("");
  const [emsTypeID, setEmsTypeID] = useState("");
  const [emsType, setEMSType] = useState([]);

  console.log(emsTypeID);

  const handleNavigate = (path) => {
    navigate(path);
  };

  const handleSetDropdownValue = (value, id, e) => {
    setUrl(e?.baseURL);
    setConnectionString(e?.connectionString);
    setEmsTypeID(id);
    setTypeDropdownInputValue(value);
    setTypeDropdownFlag(false);
    setTypeFieldError(false);
  };

  const handleSubmitAPI = async (formValue) => {
    console.log("submit Values", formValue);
    let myPayload = {
      label: formValue?.name,
      emsAPITypeId: formValue?.emsAPITypeId,
      username: formValue?.userName,
      url: url,
      secretKey: formValue?.secretKey,
      connectionString: connectionString,
      isActive: formValue?.isActive,
    };
    console.log("modifiedSubmitValue", myPayload);
    let response2 = await emsConnAddPostAPI(myPayload);
    console.log(response2);
    if (response2.success) {
      toast.success(response2.message);
    } else {
      toast.error(response2.message);
    }
    if (response2.success) {
      handleNavigate("/accounts/");
    }
  };

  const getEMSAPITypeFun = async () => {
    const response = await getEMSAPIType();
    console.log("emsType", response?.data?.data?.emsTypes);
    setEMSType(response?.data?.data?.emsTypes);
  };

  useEffect(() => {
    getEMSAPITypeFun();
  }, []);

  return (
    <div className="add-new-account">
      <div style={{ height: "15px" }}></div>

      <div className="add-new-account-route">
        {/* <p onClick={()=>{handleNavigate("/accounts")}} style={{ color: "#404040E5",cursor:"pointer" }}>Accounts</p>
        <MdKeyboardArrowRight style={{ margin: "0px 10px" }} />
        <p style={{ color: "#262626" }}>Add new</p> */}
        <BreadCrumbsComponent
          routeData={[{ key: "Accounts", value: "./" }, { key: "Add new" }]}
        />
      </div>

      <div className="add-new-account-heading">
        <h2 style={{ fontSize: "xx-large" }}>
          {constConfigData?.Add_new_account}
        </h2>
      </div>
      <div style={{ height: "40px" }}></div>

      <Formik
        initialValues={{
          name: "",
          userName: "",
          url: "null",
          secretKey: "",
          connectionString: "null",
        }}
        validationSchema={AddNewAccountsSchema}
        onSubmit={(values) => {
          values.emsAPITypeId = emsTypeID;
          if (isActiveToggleFlag) {
            values.isActive = true;
          } else {
            values.isActive = false;
          }
          handleSubmitAPI(values);
        }}
      >
        {({ handleChange, values, errors, touched, handleSubmit }) => {
          return (
            <div className="add-new-account-content">
              <div className="add-new-account-content-1">
                <div className="add-new-account-content-1-row">
                  <label htmlFor="">{constConfigData?.Name}</label>

                  <div className="add-new-account-content-1-row-input">
                    <input
                      id="name"
                      name="name"
                      value={values.name}
                      onChange={handleChange}
                      placeholder="Enter Name"
                    />

                    {errors.name && touched.name ? (
                      <p
                        style={{
                          color: "red",
                          position: "absolute",
                          bottom: "-20px",
                        }}
                      >
                        {errors.name}
                      </p>
                    ) : (
                      <></>
                    )}
                  </div>
                </div>
                <div className="add-new-account-content-1-row">
                  <label htmlFor="">{constConfigData?.Type}</label>

                  <div
                    style={{ position: "relative" }}
                    className="add-new-account-content-1-row-input"
                  >
                    <input
                      onClick={() => {
                        setTypeDropdownFlag(!typeDropdownFlag);
                      }}
                      id=""
                      name=""
                      value={typeDropdownInputValue}
                      placeholder="Select Type"
                    />
                    {typeDropdownFlag && (
                      <div className="add-new-account-dropdown">
                        {emsType.map((e) => {
                          return (
                            <p
                              onClick={() => {
                                handleSetDropdownValue(e?.name, e?._id, e);
                              }}
                              style={{ padding: "8px", cursor: "pointer" }}
                            >
                              {e?.name}
                            </p>
                          );
                        })}
                      </div>
                    )}
                    {typeFieldError && (
                      <p
                        style={{
                          color: "red",
                          position: "absolute",
                          bottom: "-20px",
                        }}
                      >
                        This Field is required.
                      </p>
                    )}

                    {/* {errors.currPassword && touched.currPassword ? (
                        <p style={{color:"red",position:"absolute",bottom:"-20px"}}>{errors.currPassword}</p>
                      ) : (
                        <></>
                      )} */}
                  </div>
                </div>

                <div className="add-new-account-content-1-row">
                  <label htmlFor="">{constConfigData?.User_Name}</label>

                  <div className="add-new-account-content-1-row-input">
                    <input
                      id="userName"
                      name="userName"
                      value={values.userName}
                      onChange={handleChange}
                      placeholder="Enter Username"
                      autoComplete="off" 
                    />

                    {errors.userName && touched.userName ? (
                      <p
                        style={{
                          color: "red",
                          position: "absolute",
                          bottom: "-20px",
                        }}
                      >
                        {errors.userName}
                      </p>
                    ) : (
                      <></>
                    )}
                  </div>
                </div>
                <div className="add-new-account-content-1-row">
                  <label htmlFor="">{constConfigData?.URL}</label>

                  <div className="add-new-account-content-1-row-input">
                    <div
                      style={{
                        border: "1px solid #D6D6D6",
                        borderRadius: "4px",
                        height: "35px",
                        paddingLeft: "10px",
                        paddingTop: "8px",
                      }}
                      name="url"
                      value={url}
                    >
                      {url}
                    </div>

                    {errors.url && touched.url ? (
                      <p
                        style={{
                          color: "red",
                          position: "absolute",
                          bottom: "-20px",
                        }}
                      >
                        {errors.url}
                      </p>
                    ) : (
                      <></>
                    )}
                  </div>
                </div>
                <div className="add-new-account-content-1-row">
                  <label htmlFor="">{constConfigData?.Secrate_Key}</label>

                  <div className="add-new-account-content-1-row-input">
                    <input
                      type={showSecretKeyFlag ? "text" : "password"}
                      id="secretKey"
                      name="secretKey"
                      value={values.secretKey}
                      onChange={handleChange}
                      placeholder="Enter Secret Key"
                      autoComplete="new-password"
                    />
                    {showSecretKeyFlag ? (
                      <div
                        onClick={() => {
                          setShowSecretKeyFlag(!showSecretKeyFlag);
                        }}
                        className="show-secret-key-icon"
                      >
                        <FiEyeOff size={20} />
                      </div>
                    ) : (
                      <div
                        onClick={() => {
                          setShowSecretKeyFlag(!showSecretKeyFlag);
                        }}
                        className="show-secret-key-icon"
                      >
                        <FiEye size={20} />
                      </div>
                    )}

                    {errors.secretKey && touched.secretKey ? (
                      <p
                        style={{
                          color: "red",
                          position: "absolute",
                          bottom: "-20px",
                        }}
                      >
                        {errors.secretKey}
                      </p>
                    ) : (
                      <></>
                    )}
                  </div>
                </div>
                <div className="add-new-account-content-1-row">
                  <label htmlFor="">{constConfigData?.Connection_String}</label>

                  <div className="add-new-account-content-1-row-input">
                    <input
                      id="connectionString"
                      name="connectionString"
                      value={connectionString}
                      onChange={handleChange}
                      placeholder="Enter Connection String"
                    />

                    {errors.connectionString && touched.connectionString ? (
                      <p
                        style={{
                          color: "red",
                          position: "absolute",
                          bottom: "-20px",
                        }}
                      >
                        {errors.connectionString}
                      </p>
                    ) : (
                      <></>
                    )}
                  </div>
                </div>
                <div className="add-new-account-content-1-row">
                  <label htmlFor="">{constConfigData?.Is_Active}</label>

                  <div
                    style={{
                      display: "flex",
                      justifyContent: "flex-start",
                    }}
                  >
                    <p
                      onClick={() => {
                        setIsActiveToggleFlag(!isActiveToggleFlag);
                      }}
                      style={{ background: "" }}
                    >
                      {isActiveToggleFlag ? <ToggleOnSVG /> : <ToggleOffSVG />}
                    </p>

                    {/* {errors.currPassword && touched.currPassword ? (
                        <p style={{color:"red",position:"absolute",bottom:"-20px"}}>{errors.currPassword}</p>
                      ) : (
                        <></>
                      )} */}
                  </div>
                </div>
              </div>

              <div className="add-new-account-content-2">
                <button
                  onClick={() => {
                    handleNavigate("/accounts/");
                  }}
                  style={{ color: "#404040" }}
                >
                  {constConfigData?.Cancel}
                </button>
                <button
                  type="button"
                  style={{ backgroundColor: "#2081E2" }}
                  onClick={() => {
                    if (!typeDropdownInputValue || !url) {
                      setTypeFieldError(true);
                      return; // Prevent further execution
                    }
                    handleSubmit(values);
                  }}
                >
                  {constConfigData?.Submit}
                </button>
              </div>
            </div>
          );
        }}
      </Formik>
    </div>
  );
};

export default AddNewAccounts;
