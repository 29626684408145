import "./Login.css";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { FiEye, FiEyeOff } from "react-icons/fi";
import img1 from "../../../asset/images/img1.jpg";
import loginBanner from "../../../asset/images/loginBanner.png";
import loginBanner2 from "../../../asset/images/loginBanner2.png";
import MainLogo from "../../../asset/svg/MainLogo";
import { forgotAPI, loginAPI } from "../../../services/servicesAPI";
import { toast } from "react-toastify";
import { useContext } from "react";
import { MyContext } from "../../../ContextAPIStore/CreateContext";
import LoaderComponent from "../../../components/loaderComponent/LoaderComponent";

const Login = () => {
  // const [configData, setConfigData] = useContext(myContext)

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [showPasswordFlag, setShowPasswordFlag] = useState(false);
  const [focus, setFocus] = useState(false);
  const [rememberMeBoolFlag, setRememberMeBoolFlag] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const constConfigData = useContext(MyContext);
  const [loader, setLoader] = useState(false);
  const [forgot, setForgot] = useState(true)

  const handleLogin = async () => {
    if (email && password) {
      let userData = {
        email,
        password,
      };
      setLoader(true);
      let response = await loginAPI(userData);
      if (response.success) {
        let data = JSON.stringify(response.data.data);

        if (rememberMeBoolFlag) {
          // handleSetRememberMe(email,password)
          localStorage.setItem("userDetails", data);
          // localStorage.setItem("token",response?.data?.data?.accessToken)
          sessionStorage.removeItem("userDetails");
        } else {
          sessionStorage.setItem("userDetails", data);
          // sessionStorage.setItem("token",response?.data?.data?.accessToken)
          localStorage.removeItem("userDetails");
          // localStorage.removeItem("userRememberMeDetails")
        }

        toast.success(response.message);

        navigate("/dashboard", {
          // state: { name: "suraj", lName: "singh", fromScreen: "DASHBOARD" },
          state: { fromScreen: "DASHBOARD" },
        });
      } else {
        toast.error(response.message);
      }
      setLoader(false);
    } else {
      toast.error("Email and Password fields are mandatory to filled.");

      // setTimeout(() => {
      //   setError("");
      // }, 2000);
      // setError("Email and Password fields are mandatory to filled.");
    }
  };

  const handleForgotPassword = async () => {
    try {
      if(!email) {
        toast.error("Email fields are mandatory to filled.");
        return;
      }
      setLoader(true);
      const res = await forgotAPI({email});
      if(res?.statusCode === 200) {
        toast.success(res?.message || "Password reset link sent successfully")
        setLoader(false);
      } else {
        setLoader(false);
        toast.error(res?.message || "Something went wrong");
      }
    } catch (error) {
      setLoader(false);
        toast.error("Something went wrong");
    }
  }

  const handleGetRememberMe = () => {
    let rememberMeDetails = JSON.parse(
      localStorage.getItem("userRememberMeDetails")
    );

    if (rememberMeDetails) {
      setRememberMeBoolFlag(true);
      setEmail(rememberMeDetails?.email);
      setPassword(rememberMeDetails?.password);
    } else {
      setRememberMeBoolFlag(false);
    }
  };

  const handleSetRememberMe = (userEmail, userPassword) => {
    let userRememberMeDetails = {
      email: userEmail,
      password: userPassword,
    };
    localStorage.setItem(
      "userRememberMeDetails",
      JSON.stringify(userRememberMeDetails)
    );
  };

  useEffect(() => {
    // handleGetRememberMe()
  }, []);

  return (
    <div className="login">
      {loader && <LoaderComponent />}
      <div className="login-content">
        { forgot ? (
          <>
            <div className="login-content-logo">
              <MainLogo />
            </div>
            <div className="login-content-form">
              <h1 className="h1">{constConfigData?.SignIn}</h1>
              {/* <h3>Don't have an account yet? Sign up</h3> */}
              <div className="login-content-form-content">
                <div className="login-content-form-content-row">
                  <label htmlFor="">{constConfigData?.User_Name}</label>
                  <input
                    value={email}
                    type="text"
                    onChange={(e) => {
                      setEmail(e.target.value);
                    }}
                    placeholder={constConfigData?.Placeholder_User_Name}
                  />
                </div>

                <div className="login-content-form-content-row">
                  <label htmlFor="">{constConfigData?.Password}</label>
                  <div
                    className="login-content-form-content-row-pass"
                    style={{
                      border: `${
                        focus ? "1px solid #2081E2" : "1px solid #dbdbdc"
                      }`,
                    }}
                  >
                    <input
                      onFocusCapture={() => {
                        setFocus(true);
                      }}
                      onBlur={() => {
                        setFocus(false);
                      }}
                      value={password}
                      type={showPasswordFlag ? "text" : "password"}
                      onChange={(e) => {
                        setPassword(e.target.value);
                      }}
                      placeholder={constConfigData?.Placeholder_Password}
                    />
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        setShowPasswordFlag(!showPasswordFlag);
                      }}
                    >
                      <div style={{ fontSize: "1.5em" }}>
                        {showPasswordFlag ? <FiEyeOff /> : <FiEye />}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="login-content-form-content-forgot">
                  <div style={{ display: "flex", width: "100%" }}>
                    <input
                      className="checkbox-input"
                      type="checkbox"
                      checked={rememberMeBoolFlag}
                      onChange={() => {
                        setRememberMeBoolFlag(!rememberMeBoolFlag);
                      }}
                      style={{ marginRight: "10px" }}
                    />
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        background: "",
                        width: "100%",
                      }}
                    >
                      <p style={{ fontSize: "1em", color: "#404040" }}>
                        {constConfigData?.Remember_Me}
                      </p>
                      <p onClick={()=>{setForgot(false)}} style={{ fontSize: "1em", color: "#404040",cursor:"pointer" }}>
                        {"Forgot your password?"}
                      </p>
                    </div>
                  </div>
                  {/* <div>
                <p>Forgot your Password?</p>
              </div> */}
                </div>
                <div className="login-content-form-content-row">
                  {error && (
                    <p style={{ color: "red", textAlign: "center" }}>{error}</p>
                  )}
                </div>
                <div className="login-content-form-content-row">
                  <button
                    onClick={() => {
                      handleLogin();
                    }}
                  >
                    {constConfigData?.SignIn}
                  </button>
                </div>
              </div>
            </div>
          </>
        ) : (
          <>
            <div className="login-content-logo">
              <MainLogo />
            </div>
            <div className="login-content-form">
            <p  onClick={()=>{setForgot(true)}} style={{ fontSize: "1.1em", color: "rgb(150 150 150)",cursor:"pointer", margin:"0",padding:"0" }}>
                        {"<"}{" "}{"Go back"}
                      </p>
              <h1 className="h11" style={{paddingTop:"15px"}} >{constConfigData?.Reset_password}</h1>
              <p style={{ fontSize: "1em", color: "rgb(150 150 150)",cursor:"pointer" }}>
                We'll email you instructions on how to reset your password.
              </p>
              {/* <h3>Don't have an account yet? Sign up</h3> */}
              <div style={{marginTop:"30px"}} className="">
                <div style={{margin:"15px 0px"}} className="login-content-form-content-row">
                  <label htmlFor="">{constConfigData?.User_Name}</label>
                  <input
                    value={email}
                    type="text"
                    onChange={(e) => {
                      setEmail(e.target.value);
                    }}
                    placeholder={constConfigData?.Placeholder_User_Name}
                  />
                </div>
                <div className="login-content-form-content-forgot">
                 
                  {/* <div>
                <p>Forgot your Password?</p>
              </div> */}
                </div>
                <div className="login-content-form-content-row">
                  {error && (
                    <p style={{ color: "red", textAlign: "center" }}>{error}</p>
                  )}
                </div>
                <div className="login-content-form-content-row">
                  <button
                    onClick={() => {
                      handleForgotPassword();
                    }}
                  >
                    <p style={{fontSize:"1.1em"}}>{constConfigData?.Sent_password_link}</p>
                  </button>
                </div>
              </div>
            </div>
          </>
        )}
      </div>
      <div className="login-banner">
        {showModal && (
          <div className="login-modal">
            <div className="login-modal-content">
              <h1>{constConfigData?.Heading_Modal}</h1>
              <div>
                <p>
                  Lorem ipsum dolor sit amet consectetur adipisicing elit. Quis
                  similique, ullam est rerum deserunt odit qui quas quisquam
                  error amet!
                </p>
                <button
                  onClick={() => {
                    setShowModal(false);
                  }}
                >
                  {constConfigData?.Close_Modal}
                </button>
              </div>
            </div>
          </div>
        )}

        <img
          src={loginBanner2}
          alt="LoginBanner_image"
          height="100%"
          width="100%"
        />
      </div>
    </div>
  );
};
export default Login;
