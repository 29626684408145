import { useEffect, useReducer, useState } from "react";
import {
  chooseFilterGETApi,
  getGroupByIdPostAPI,
  groupGetAPI,
  updateGroupPutAPI,
  updateViewNamePatchAPI,
} from "../../services/servicesAPI";
import { GoPlus } from "react-icons/go";
import { SlRefresh } from "react-icons/sl";
import FilterRow from "./filterRow";
import AddSegmentModal from "../../modal/addSegmentModal/AddSegmentModal";
import { createViewPostAPI } from "../../services/servicesAPI";
import { createViewPatchAPIwithID } from "../../services/servicesAPI";
import { filterData } from "../../asset/documentfiles/Documents";
import { useLocation, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { getViewByIdPostAPI } from "../../services/servicesAPI";
import LoaderComponent from "../loaderComponent/LoaderComponent";
import { load } from "@syncfusion/ej2-react-grids";
import { checkArray } from "../../utils/Utils";
import { getFilterOptionsByName } from "../../utils/filterService";

const FilterParent = ({ setChooseAFilterArrayDataPayload, setViewData,viewData, callApiWithFilter, handleActiveList,totalCount,totalEmailIdsCount,totalAddressCount }) => {
  const [filterOptions, setFilterOptions] = useState(null);
  const [filterSelected, setFilterSelected] = useState([]);
  const [saveModalFlag, setSaveModalFlag] = useState(false);
  const [filterBtnFlag, setFilterBtnFlag] = useState(false);
  const [createFilter, setCreateFilter] = useState(false);
  const [filterName, setFilterName] = useState("");
  const [apiFalse, setApiFalse] = useState(true);
  const [loader, setLoader] = useState(false);
  const [groupIde, setGroupIde] = useState("");
  const [ide, setIde] = useState("");
  const [dashKey, setDashKey] = useState("");
  const [payloadForTable, setPayloadForTable] = useState([]);
  const [groupLoader, setGroupLoader] = useState(false);
  const [filterBtnFlag2, setFilterBtnFlg2] = useState(false);
  const [selectedFilterStoreForClearButton,setSelectedFilterStoreForClearButton] = useState([])
  const [data, setData] = useState([
    [
      {
        operator: "",
        args: [],
      },
    ],
  ]);

  const [groups, setGroups] = useState([]);
  const [dataView, setDataView] = useState([]);
  const [dataForMap, setDataForMap] = useState([]);
  let [formatedData, setFormatedData] = useState([]);

  const location = useLocation();
  const id = location?.state?.id;

  const searchParams = new URLSearchParams(location.search);

  // console.log("filterSelected", filterSelected);
  useEffect(() => {
    console.log("PayloadForTable", payloadForTable);
  }, [payloadForTable]);

  useEffect(() => {
    setLoader(true);
    setIde("");
    setGroupIde("");
    if (searchParams.get("type") === "group") {
      const grpIde = searchParams.get("id");
      setGroupIde(grpIde);
    } else if (searchParams.get("type") === "view") {
      const id = searchParams.get("id");
      setIde(id);
    } else if (searchParams.get("type") === "dashboard") {
      setDashKey(searchParams.get("dashKey"));
    }
  }, [location]);

  useEffect(() => {
    if (location.pathname == "/contacts" && location.search == "") {
      setFilterSelected([
        {
          options: filterOptions,
          index: 0,
          rulesSelected: [
            {
              operator: [],
              args: [[], [], []],
              orStatus: "",
            },
          ],
        },
      ]);
    }
  }, [ide, groupIde]);

  const getViewByIDPostFunction = async () => {
    try {
      let res;
      if (ide) {
        setLoader(true);
        res = await getViewByIdPostAPI(ide, payload);
        console.log('view res', res);
        setViewData(res?.data?.data);
        setFilterSelected([])
      } else if (groupIde) {
        setLoader(true);
        res = await getGroupByIdPostAPI(groupIde, payload);
        console.log('group res', res);
        setFilterSelected([])
      } else if (dashKey) {
        res = getFilterOptionsByName(dashKey);
        console.log("res suraj ",res)
        if(res.data.key === 'GoodEmail') {
          handleActiveList(res.data.data.selectedContact);
          setViewData(res?.data?.data);
          setFilterSelected([])
        }
        console.log('filter res', res);
        // setFilterSelected([])
      }
      // console.log("res getViewbyId", res?.data?.view?.filters);
      // if (res?.data?.data?.createdAt) {
      //   setViewData(res?.data?.data);
      // }
      if (res?.data?.data?.filters?.length && res?.data?.data?.filters[0][0]?.args[0]) {
        setViewData(res?.data?.data);
        console.log(res?.data?.data?.filters, 'setChooseAFilterArrayDataPayload')
        setChooseAFilterArrayDataPayload(res?.data?.data?.filters);
        setPayloadForTable(res?.data?.data?.filters);
        setData(res?.data);
        setDataForMap(res?.data?.data?.filters);
        let temp = await formateDataFun(res?.data?.data?.filters);
        console.log("Temp==========", temp);
        setFormatedData(temp);
        setSelectedFilterStoreForClearButton(JSON.parse(JSON.stringify(temp)))
        setTimeout(() => {
          setLoader(false);
        }, 500);
        // console.log("formatedData from API  ", formatedData);
        // console.log("data all view api ", res.data.view.filters);
      } else {
        console.log("else filterOptions",filterOptions)
        setFilterSelected([
          {
            options: filterOptions,
            index: 0,
            rulesSelected: dashKey ? [] : [
              {
                operator: [],
                args: [[], [], []],
                orStatus: "",
              },
            ],
            // rulesSelected: [
            //   {
            //     operator: [],
            //     args: [[], [], []],
            //     orStatus: "",
            //   },
            // ],
          },
        ]);
        setLoader(false);
      }
      // if(dashKey) {
      //   callApiWithFilter();
      // }
    } catch (err) {
      console.log(err);
    }
    setLoader(false);
    if(dashKey) {
      callApiWithFilter();
    }
  };

  useEffect(() => {
    const loadFilterOptions = async () => {
      try {
        const response = await chooseFilterGETApi();
        // console.log("RESPONSE=====>", response.data.data.result);
        setFilterOptions(response.data.data.result);
      } catch (error) {}
    };
    const getGroups = async () => {
      setGroupLoader(true);
      const res = await groupGetAPI(
        { page: 1, limit: 1000 },
        { sortBy: "createdAt", order: "DESC" }
      );
      if (res.success) {
        setGroupLoader(false);
        setGroups(res?.data?.data?.result);
      }
    };
    loadFilterOptions();
    getGroups();
    // getViewByIDPostFunction();
  }, []);

  useEffect(() => {
    if (filterOptions !== null) {
      getViewByIDPostFunction();
    }
  }, [filterOptions]);

  // console.log("FilterOptions===================>", filterOptions);

  // console.log("chooseFilterGroupapi", groups);
  // console.log("formatedDATA_of_firlst_LIne", formatedData);

  const [payload, setPayload] = useState({
    isExpanded: false,
  });

  const createPayloadForView = () => {
    setPayload({
      isExpanded: false,
    });
  };

  useEffect(() => {
    createPayloadForView();
  }, []);

  const formateDataFun = (data) => {
    let operators;

    return data.map((singleData, index) => {
      return {
        index,
        options: filterOptions,
        rulesSelected: singleData.map((value) => {
          const args = [];

          if (value?.args[0] === "groups") {
            args.push([
              {
                key: "groups",
                value: "Groups",
                isOptionOperator: filterOptions.filter((option) => option.key === 'groups')[0]?.isOptionOperator,
                placeholder: filterOptions.filter((option) => option.key === 'groups')[0]?.placeholder,
                options: filterOptions.filter((option) => option.key === 'groups')[0]?.options,
              },
            ]);
            args.push([]);
            args.push([]);
            args.push(value.args[1].map((group) => group));
            operators = filterOptions.filter((option) => option.key === 'groups')[0]?.options;
          } else if (value?.args[0] === "fields") {
            args.push([
              {
                key: "fields",
                value: "Fields",
                isOptionOperator: filterOptions.filter((option) => option.key === 'fields')[0]?.isOptionOperator || false,
                placeholder: filterOptions.filter((option) => option.key === 'fields')[0]?.placeholder || "",
                options: filterOptions.filter((option) => option.key === 'fields')[0]?.options || [],
              },
            ]);
            let res2 = filterOptions.filter((option) => option.key === 'fields')[0]?.options.find((operator) => {
              return value.args[1] == operator.key;
            });
            args.push([
              {
                key: value.args[1],
                value: res2?.value,
                // isOptionOperator: filterOptions[0]?.isOptionOperator,
                // placeholder: filterOptions[0]?.placeholder,
                // options: filterOptions[0]?.options[0]?.options,
                isOptionOperator: res2.isOptionOperator,
                placeholder: res2?.placeholder,
                options: res2.options
              },
            ]);
            args.push([]);
            args.push(value.args[2]);
            // operators = filterOptions[0]?.options[0]?.options;
            operators = res2.options
          } else if (value?.args[0] === "stats") {
            args.push([
              {
                key: "stats",
                value: "Stats",
                isOptionOperator: filterOptions.filter((option) => option.key === 'stats')[0]?.isOptionOperator || false,
                placeholder: filterOptions.filter((option) => option.key === 'stats')[0]?.placeholder || "",
                options: filterOptions.filter((option) => option.key === 'stats')[0]?.options || [],
              },
            ]);
            let res2 = filterOptions.filter((option) => option.key === 'stats')[0]?.options.find((operator) => {
              return value.args[1] == operator.key;
            });
            args.push([
              {
                key: value.args[1],
                value: res2?.value,
                isOptionOperator: filterOptions.filter((option) => option.key === 'stats')[0]?.isOptionOperator || false,
                placeholder: filterOptions.filter((option) => option.key === 'stats')[0]?.placeholder || "",
                options: filterOptions.filter((option) => option.key === 'stats')[0]?.options[0]?.options ||[],
              },
            ]);
            args.push([]);
            args.push(value.args[2]);
            operators = filterOptions.filter((option) => option.key === 'stats')[0]?.options[0]?.options;
          } else if (value?.args[0] === "email") {
            console.log(filterOptions, 'email')
            args.push([
              {
                key: "email",
                value: "Email",
                isOptionOperator: filterOptions.filter((option) => option.key === 'email')[0]?.isOptionOperator || false,
                placeholder: filterOptions.filter((option) => option.key === 'email')[0]?.placeholder || "",
                options: filterOptions.filter((option) => option.key === 'email')[0]?.options || [],
              },
            ]);
            let res2 = filterOptions[3]?.options.find((operator) => {
              return value.args[1] == operator.key;
            });
            args.push([]);
            args.push([]);
            args.push(value.args[2]);
            operators = filterOptions.filter((option) => option.key === 'email')[0]?.options || [];
          }

          const selectedOperator = operators.find((operator) => {
            return operator.key === value?.operator;
          });

          return {
            args,
            operator: [
              {
                key: selectedOperator?.key,
                value: selectedOperator?.value,
                hasInput: selectedOperator?.hasInput,
                hasMultiValue: selectedOperator?.hasMultiValue,
                isOptionOperator: selectedOperator?.isOptionOperator,
              },
            ],
          };
        }),
      };
    });
  };

  // filterData and formatedData used here============================================>
  useEffect(() => {
    if (ide && apiFalse) {
      setFilterSelected(formatedData);
    }
  }, []);

  // useEffect(() => {
  //   const loadFilterOptions = async () => {
  //     try {
  //       const response = await chooseFilterGETApi();
  //       console.log('filter sets ===>>>>>')
  //       setFilterOptions(response.data.data.result);
  //     } catch (error) {}
  //   };
  //   const getGroups = async () => {
  //     const res = await groupGetAPI(
  //       { page: 1, limit: 33 },
  //       { sortBy: "createdAt", order: "DESC" }
  //     );
  //     setGroups(res?.data?.data?.result);
  //   };
  //   loadFilterOptions();
  //   getGroups();
  // }, []);

  /* const createPayload = () => {
    const newData = filterSelected.map((e, index) => {
      const rules = e.rulesSelected.map((val) => ({
        operator: val?.operator[0]?.key || "",
        args: [
          val?.args[0][0]?.key || "",
          val?.args[1][0]?.key || val?.args[3],
          ((val?.args[0][0]?.key == "fields" || val?.args[0][0]?.key == "stats") && val?.args[3]) || "",
        ],
      }));
      return rules;
    });

    setData(newData);
  }; */

  const createPayload = () => {
    const newData = filterSelected.map((e, index) => {
      const rules = e.rulesSelected.map(
        (val) => (
          console.log("Value_Hai ye", val),
          {
            operator: val?.operator[0]?.key || "",
            args: [
              val?.args[0][0]?.key || "",
              val?.args[1][0]?.key || val?.args[3],
              checkArray(val) ? "" : val?.args[3],
            ],
          }
        )
      );
      return rules;
    });
    setData(newData);
  };

  useEffect(() => {
    createPayload();
  }, [filterSelected]);

  const addAndCondition = (index) => {
    let temp = [...filterSelected];
    temp[index].rulesSelected.push({
      operator: [],
      args: [[], [], []],
      orStatus: "",
    });
    setFilterSelected(temp);
  };

  const addOrCondition = (index) => {
    let temp = [...filterSelected];
    temp.push({
      options: filterOptions,
      index: index + 1,
      rulesSelected: [
        {
          operator: [],
          args: [[], [], []],
          orStatus: "",
        },
      ],
    });
    setFilterSelected(temp);
    createPayload();
    setApiFalse(false);
  };

  useEffect(() => {
    if (filterOptions) {
      setFilterSelected([
        {
          options: filterOptions,
          index: 0,
          rulesSelected: dashKey ? [] : [
            {
              operator: [],
              args: [[], [], []],
              orStatus: "",
            },
          ],
          // rulesSelected: [
          //   {
          //     operator: [],
          //     args: [[], [], []],
          //     orStatus: "",
          //   },
          // ],
        },
      ]);
    }
  }, [filterOptions]);
  const onChange = (event) => {
    // console.log(event, "onchange parent");
  };
  const updateRules = (rules, ruleIndex, parentIndex) => {
    // console.log("update rules called", rules);
    let filters = [...filterSelected];
    filters[parentIndex].rulesSelected[ruleIndex] = rules;
    console.log(filters, 'filters 435', filterSelected);
    setFilterSelected(filters);
  };
  const deleteOneRow = (ruleIndex, parentIndex) => {
    if (
      filterSelected[parentIndex]?.rulesSelected.length == 1 &&
      filterSelected.length > 1
    ) {
      var temp = [...filterSelected];
      temp.splice(parentIndex, 1);
      // console.log("temp", temp);
      setFilterSelected(temp);
      // console.log(filterSelected);
    }
    setFilterSelected(temp);
    let res1 = [...filterSelected];
    // console.log("res1", res1);
    if (res1.length == 1 && res1[parentIndex].rulesSelected.length == 1) {
      res1[0].rulesSelected = [];
      res1[0].rulesSelected.push({
        operator: [],
        args: [[], [], []],
        orStatus: "",
      });
      setFilterSelected(res1);
      setFilterBtnFlag(false);
    } else {
      // console.log("ruleIndex", ruleIndex, "parentIndex", parentIndex);
      let filters = [...filterSelected];
      if (filters[parentIndex]?.rulesSelected.length > 1) {
        filters[parentIndex].rulesSelected.splice(ruleIndex, 1);
        setFilterSelected(filters);
      }
    }
  };

  const handleClearFilterData = () => {
    let res = [...filterSelected];
    res.splice(1, res.length - 1);
    res[0].rulesSelected = [];
    res[0].rulesSelected.push({
      operator: [],
      args: [[], [], []],
      orStatus: "",
    });
    setFilterSelected(res);
    setFilterBtnFlag(false);
  };

  const handleSave = () => {
    createPayload();
    setSaveModalFlag(true);
    // console.log("handleSavePayload", data);
    setPayloadForTable(data);
  };
  const handleViewUpdate = async () => {
    createPayload();
    await refreshHandlers();
    // setSaveModalFlag(true);
    // console.log("handleSavePayload", data);
    setPayloadForTable(data);
    createNewFilterAndUpdateExistingViewAPI()
    
  };
  const handleGroupUpdate = ()=>{
    createPayload();
    refreshHandlers();
    // setSaveModalFlag(true);
    // console.log("handleSavePayload", data);
    setPayloadForTable(data);
    createNewFilterAndUpdateExistingGroupAPI()

  }
  

  const createFitlerPatchAPI = async (id) => {
    try {
      const response2 = await createViewPatchAPIwithID(id, { filters: data });
      if (response2.success) {
        toast.success(response2.message);
        setLoader(false);
      } else {
        toast.error(response2.message);
      }
      // console.log("CreateViewPatchApi_Response", response2);
      setSaveModalFlag(false);
    } catch (err) {
      // console.log("CreateViewApi_Error", err);
    }
  };

  const createFilterAPI = async () => {
    let payload = { name: filterName,
      contacts:totalCount,
      emails:totalEmailIdsCount,
      addresses:totalAddressCount
     };
    try {
      setLoader(true);
      const response = await createViewPostAPI(payload);
      let id = response?.data?.data?._id;
      // console.log("CreateViewPostApi",response)
      createFitlerPatchAPI(id);
    } catch (err) {
      // console.log("CreateViewApi_Error", err);
    }
  };
  const createNewFilterAndUpdateExistingViewAPI = async () => {
    console.log("createNewFilterAndUpdateExistingViewAPI function start",filterName)
    let payload = { 
      name: viewData?.name,
      contacts:totalCount,
      emails:totalEmailIdsCount,
      addresses:totalAddressCount
     };
      console.log("payload",payload)
    try {
      setLoader(true);
      const res = await updateViewNamePatchAPI(searchParams.get("id"), payload);
      // const response = await createViewPostAPI(payload);
      // let id = response?.data?.data?._id;
      // console.log("CreateViewPostApi",response)
      createFitlerPatchAPI(searchParams.get("id"));
    } catch (err) {
      // console.log("CreateViewApi_Error", err);
    }
  };
  // const handleUpdateGroupAPI = async (item,renameInputValue)=>{
  //   let response = await updateGroupPutAPI(item._id,{name:renameInputValue})
  //   if(response.success){
  //     toast.success(response.message)
  //     getGroupAPI(pagingData,filterSortingPayloadGroup)
  //   }else{
  //     toast.error(response.message)
  //   }
  // }
  const createNewFilterAndUpdateExistingGroupAPI = async () => {
    console.log("createNewFilterAndUpdateExistingViewAPI function start")
    let payload = { name: viewData?.name,
      contacts:totalCount };
      console.log("payload",payload)
    try {
      setLoader(true);
      let response = await updateGroupPutAPI(searchParams.get("id"),payload)
      // const response = await createViewPostAPI(payload);
      // let id = response?.data?.data?._id;
      // console.log("CreateViewPostApi",response)
      createFitlerPatchAPI(searchParams.get("id"));
    } catch (err) {
      // console.log("CreateViewApi_Error", err);
    }
  };

  useEffect(() => {
    console.log("formatedData", formatedData);
    setFilterSelected(formatedData);
  }, [formatedData]);

  const refreshHandlers = () => {
    createPayload();
    if(dashKey) {
      setChooseAFilterArrayDataPayload(data);
    }
    setChooseAFilterArrayDataPayload(data);
    setPayloadForTable(data);
  };

  // useEffect(() => {}, [filterSelected]);

  // useEffect(() => {
  //   createFilterAPI({"name":filterName});
  // }, [createFilter]);

  useEffect(() => {
    setLoader(false);
  }, [location]);

  return (
    <div
      onClick={() => {
        setApiFalse(false);
      }}
      className="filter-component"
    >
      {loader && <LoaderComponent />}
      {/* {false && } */}
      {filterSelected &&
        filterSelected.map((filterRow, index) => {
          return (
            <div key={index}>
              {filterRow.rulesSelected.map((rules, childIndex) => {
                // console.log("rules parent=========>",rules)
                return (
                  <>
                    <FilterRow
                      groupLoader={groupLoader}
                      index={index}
                      childIndex={childIndex}
                      setFilterName={setFilterName}
                      setCreateFilter={setCreateFilter}
                      setFilterBtnFlag={setFilterBtnFlag}
                      key={childIndex}
                      filterOptions={filterRow.options}
                      rules={rules}
                      onDropdownChange={onChange}
                      groups={groups}
                      choosedData={
                        filterSelected[index]?.rulesSelected[childIndex]?.args
                      }
                      updateRules={(rules) =>
                        updateRules(rules, childIndex, index)
                      }
                      deleteRules={() => deleteOneRow(childIndex, index)}
                      setFilterBtnFlg2={setFilterBtnFlg2}
                    />
                  </>
                );
              })}

              {filterSelected[index]?.rulesSelected[
                filterSelected[index]?.rulesSelected.length - 1
              ]?.args[0].length ? (
                <div>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                      margin: "10px 0px",
                      color: "gray",
                    }}
                  >
                    <div
                      style={{
                        height: "1px",
                        width: "2%",
                        backgroundColor: "#D1D5DB",
                      }}
                    />
                    <p>AND</p>
                    <div
                      style={{
                        height: "1px",
                        width: "93%",
                        backgroundColor: "#D1D5DB",
                        // color:"red"
                      }}
                    />
                  </div>
                  <div
                    style={{
                      background: "rgb(253 253 253)",
                      padding: "0px 10px",
                      color: "gray",
                      border: "1px solid #D1D5DB",
                      borderRadius: "3px",
                      width: "10rem",
                      paddingBottom: "1.5px",
                      cursor: "pointer",
                    }}
                  >
                    <a onClick={() => addAndCondition(index)}>
                      <span style={{ fontSize: "1.3em" }}>+</span> add a
                      condition
                    </a>
                  </div>

                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                      margin: "10px 0px",
                      color: "gray",
                    }}
                  >
                    <div
                      style={{
                        height: "1px",
                        width: "2%",
                        backgroundColor: "#D1D5DB",
                      }}
                    />
                    <p>OR</p>
                    <div
                      style={{
                        height: "1px",
                        width: "93%",
                        backgroundColor: "#D1D5DB",
                        // color:"red"
                      }}
                    />
                  </div>
                  {index == filterSelected.length - 1 && (
                    <div
                      style={{
                        background: "rgb(253 253 253)",
                        padding: "0px 10px",
                        color: "gray",
                        border: "1px solid #D1D5DB",
                        borderRadius: "3px",
                        width: "15rem",
                        paddingBottom: "1.5px",
                        cursor: "pointer",
                      }}
                    >
                      <a onClick={() => addOrCondition(index)}>
                        <span style={{ fontSize: "1.3em" }}>+</span> add another
                        set of condition
                      </a>
                    </div>
                  )}
                </div>
              ) : (
                ""
              )}
            </div>
          );
        })}
      {!location.search ? (
        <div>
          {filterBtnFlag && (
            <div className="allsubscriber-file-bottom-btn">
              {true && (
                <button
                  onClick={() => {
                    refreshHandlers();
                  }}
                  className="refresh"
                  style={{
                    fontWeight: "550",
                    fontSize: "14px",
                    padding: "0.14rem 0.1rem",
                    color: "rgb(93 93 93)",
                    padding: "10px 10px",
                    borderRadius: "4px",
                    backgroundColor: "#E5E7EB",
                    cursor: "pointer",
                  }}
                >
                  {" "}
                  <SlRefresh
                    style={{ paddingRight: "0", marginRight: "4px" }}
                  />{" "}
                  Refresh
                </button>
              )}
              <button
                className="clear"
                style={{ color: "black", backgroundColor: "#E5E7EB" }}
                onClick={() => {
                  handleClearFilterData();
                }}
              >
                <p
                  style={{
                    fontWeight: "550",
                    fontSize: "14px",
                    padding: "0.14rem 0.1rem",
                    color: "rgb(93 93 93)",
                    padding: "0px 10px",
                    borderRadius: "4px",
                    backgroundColor: "#E5E7EB",
                  }}
                >
                  Clear
                </p>
              </button>
              <button
                onClick={() => {
                  handleSave();
                }}
                style={{ color: "white", backgroundColor: "#2081E3" }}
              >
                <p
                  style={{
                    fontWeight: "550",
                    fontSize: "14px",
                    padding: "0.14rem 0.1rem",
                    color: "white",
                    padding: "0px 10px",
                    borderRadius: "4px",
                  }}
                >
                  Save as view
                  {/* {(ide || groupIde ? "Save as" : "Save as view")} */}
                </p>
              </button>
              {saveModalFlag && (
                <AddSegmentModal
                  setFilterName={setFilterName}
                  setCreateFilter={setCreateFilter}
                  setSaveModalFlag={setSaveModalFlag}
                  headingText={"Create view"}
                  createFilterAPI={createFilterAPI}
                />
              )}
            </div>
          )}
        </div>
      ) : (
        <div>
          {filterBtnFlag2 && (
            <div className="allsubscriber-file-bottom-btn">
              {true && (
                <button
                  onClick={() => {
                    refreshHandlers();
                  }}
                  className="refresh"
                  style={{
                    fontWeight: "550",
                    fontSize: "14px",
                    padding: "0.14rem 0.1rem",
                    color: "rgb(93 93 93)",
                    padding: "10px 10px",
                    borderRadius: "4px",
                    backgroundColor: "#E5E7EB",
                    cursor: "pointer",
                  }}
                >
                  {" "}
                  <SlRefresh
                    style={{ paddingRight: "0", marginRight: "4px" }}
                  />{" "}
                  Refresh
                </button>
              )}
              <button
                className="clear"
                style={{ color: "black", backgroundColor: "#E5E7EB" }}
                onClick={() => {
                  if(ide || groupIde || dashKey){
                    setFilterSelected(JSON.parse(JSON.stringify(selectedFilterStoreForClearButton)))
                  }else{
                    console.log("inside else")
                    handleClearFilterData();
                  }
                }}
              >
                <p
                  style={{
                    fontWeight: "550",
                    fontSize: "14px",
                    padding: "0.14rem 0.1rem",
                    color: "rgb(93 93 93)",
                    padding: "0px 10px",
                    borderRadius: "4px",
                    backgroundColor: "#E5E7EB",
                  }}
                >
                  Clear
                </p>
              </button>
              <button
                onClick={() => {
                  if(searchParams.get("type") === "group"){
                    console.log("group========>")
                    handleSave();
                    // handleGroupUpdate()
                  }else if(searchParams.get("type") === "view"){
                    
                    
                    handleViewUpdate()
                    // UpdateViewNamePatchFunction(item?._id, { name: `${name}` });
                  }else{
                    console.log("else========>")
                    handleSave();
                  }
                  // handleSave();
                }}
                style={{ color: "white", backgroundColor: "#2081E3" }}
              >
                <p
                  style={{
                    fontWeight: "550",
                    fontSize: "14px",
                    padding: "0.14rem 0.1rem",
                    color: "white",
                    padding: "0px 10px",
                    borderRadius: "4px",
                  }}
                >
                  {/* Save as new */}
                  {(ide || groupIde ? "Save as" : "Save as view")}
                </p>
              </button>
              {saveModalFlag && (
                <AddSegmentModal
                  setFilterName={setFilterName}
                  setCreateFilter={setCreateFilter}
                  setSaveModalFlag={setSaveModalFlag}
                  headingText={"Create view"}
                  createFilterAPI={createFilterAPI}
                />
              )}
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default FilterParent;
