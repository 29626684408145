import { MdKeyboardArrowRight } from "react-icons/md"
import "./ImportMailChamp.css"
import MatchColumnsNavbarComponent from "../../components/matchColumnsNavbarComponent/MatchColumnsNavbarComponent"
const ImportMailChamp = ()=>{
    return <div className="mailchamp">
    <div className="mailchamp-route">
          <p>Contacts</p>
          <MdKeyboardArrowRight style={{margin:"0px 10px"}} />
          <p>Add new</p>
      </div>
      <div className="mailchamp-heading">
          <h1>Add new contacts</h1>
      </div>
    <MatchColumnsNavbarComponent />
    <div className="mailchamp-content">
        IMport mail champ
    </div>

    </div>
}

export default ImportMailChamp