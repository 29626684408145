import { ColumnDirective, ColumnsDirective, Filter, GridComponent, Inject, Page, Sort } from "@syncfusion/ej2-react-grids";
import { useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";
import { DataManager, UrlAdaptor } from "@syncfusion/ej2-data";
import { getToken } from "../../../utils/getToken";
import LoaderComponent from "../../../components/loaderComponent/LoaderComponent";
import { returnDateFromTimestamp } from "../../../utils/Utils";
import AccountsNavbar from "../accountsNavbar/AccountsNavbar";
import "./AutoActivities.css";
import FilterListSVG from "../../../asset/svg/FilterListSVG";

const AutoActivities = () => {
  const navigate = useNavigate();
  const [SyncFuseData, setSyncFuseData] = useState(null);
  const [loading, setLoading] = useState(true);

  const pageSettings = { pageSize: 25 };
  const sortSettings = { columns: [] };
  const FilterOptions = {
    type: 'CheckBox',
    enableInfiniteScrolling: true
  };

  const handleNavigate = (path, data) => {
    let dataFormatted = {
      label: data?.emsConnLabel,
      type: data?.emsConnType,
      date: data?.createdAt,
      activityName: data?.activityName,
      task: data?.task,
      note: data?.note,
      contactId: data?.contactId,
      status: data?.status,
    };
    navigate(path, { state: { message: dataFormatted } });
  };

  const SyncFun = async () => {
    setLoading(true);
    try {
      const SyncData = new DataManager({
        adaptor: new UrlAdaptor(),
        url: `${process.env.REACT_APP_BASE_URL}/account/getEMSActivity`,
        headers: [
          {
            "Content-Type": "application/json",
            Authorization: "Bearer " + getToken(),
          },
        ],
      });

      setSyncFuseData(SyncData);
    } catch (error) {
      // setLoading(false);
      console.error('Error fetching data:', error);
    } finally {
      setTimeout(() => {
        setLoading(false);
      }, 1500);
      
    }
  };

  useEffect(() => {
    SyncFun();
  }, []);

  return (
    <div className="auto-activities">
      {loading && <LoaderComponent />}
      <div style={{ height: "35px" }}></div>
      <div className="auto-activities-heading">
        <h2 style={{ fontWeight: "600", fontSize: "2.4em", letterSpacing: "0.8px" }}>Accounts</h2>
      </div>
      <div style={{ height: "30px" }}></div>
      <AccountsNavbar />
      <div style={{ height: "35px" }}></div>
      <div className="auto-activities-content">
        <div className="auto-activities-content-heading"><h1>Auto activities</h1></div>
        <div className="auto-activities-content-sync" style={{ overflowX: "scroll" }}>
          <div style={{ minWidth: "100vw" }}>
            <GridComponent
              style={{ border: "none", padding: "0px 10px" }}
              width={"100%"}
              // height={259}
              pageSettings={pageSettings}
              allowPaging={true}
              allowSorting={true}
              sortSettings={sortSettings}
              dataSource={SyncFuseData}
              allowFiltering={true}
              filterSettings={FilterOptions}
              loadingIndicator={{ indicatorType: 'Shimmer' }}
            >
              <ColumnsDirective>
                <ColumnDirective
                  field=''
                  headerText='Label'
                  width={"100px"}
                  textAlign="left"
                  template={(props) => {
                    return <span onClick={() => { handleNavigate("edit-activity", props) }} style={{ color: "#2081E3", cursor: "pointer" }}>{props.emsConnLabel}</span>
                  }}
                />
                <ColumnDirective
                  field='emsConnType'
                  headerText="Type"
                  width={"70px"}
                  textAlign="left"
                  template={(props) => {
                    return <span style={{ cursor: "pointer" }}>{props.emsConnType}</span>
                  }}
                />
                <ColumnDirective
                  field=''
                  headerText="Date"
                  width={"100px"}
                  textAlign="left"
                  template={(props) => {
                    return <span style={{ cursor: "pointer" }}>{returnDateFromTimestamp(props?.createdAt)}</span>
                  }}
                />
                <ColumnDirective
                  field=''
                  headerText="Activity name"
                  width={"100px"}
                  textAlign="left"
                  template={(props) => {
                    return <span style={{ cursor: "pointer" }}>{props?.activityName}</span>
                  }}
                />
                <ColumnDirective
                  field=''
                  headerText="Task"
                  width={"100px"}
                  textAlign="left"
                  template={(props) => {
                    return <span style={{ cursor: "pointer" }}>{props?.task}</span>
                  }}
                />
                <ColumnDirective
                  field=''
                  headerText="Note"
                  width={"100px"}
                  textAlign="left"
                  template={(props) => {
                    return <span style={{ cursor: "pointer" }}>{props?.note}</span>
                  }}
                />
                {/* <ColumnDirective
                  field=''
                  headerText="Contact Id"
                  width={"100px"}
                  textAlign="left"
                  template={(props) => {
                    return <span style={{ cursor: "pointer" }}>{props?.contactId}</span>
                  }}
                /> */}
                {/* <ColumnDirective
                  field=''
                  headerText="Status"
                  width={"100px"}
                  textAlign="left"
                  template={(props) => {
                    return <span style={{ cursor: "pointer" }}>{props?.status}</span>
                  }}
                /> */}
              </ColumnsDirective>
              <Inject services={[Page, Sort, Filter]} />
            </GridComponent>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AutoActivities;
