import { MdKeyboardArrowRight } from "react-icons/md"
import "./EditAccounts.css"
import {useLocation, useNavigate} from "react-router-dom"
// import ToggleOnSVG from "../../asset/svg/ToggleOnSVG"
// import RefreshSVG from "../../asset/svg/RefreshSVG"
import { useCallback, useContext, useEffect, useState } from "react"
import ToggleOnSVG from "../../../asset/svg/ToggleOnSVG"
import ToggleOffSVG from "../../../asset/svg/ToggleOffSVG"
import RefreshSVG from "../../../asset/svg/RefreshSVG"
import { MyContext } from "../../../ContextAPIStore/CreateContext"
import BreadCrumbsComponent from "../../../components/breadCrumbsComponent/BreadCrumbsComponent"
import { testConnectionGetAPI, updateIsActiveEMS } from "../../../services/servicesAPI"
import { toast } from "react-toastify"
import { getAllEMSById } from "../../../services/servicesAPI"

// import ToggleOffSVG from "../../asset/svg/ToggleOffSVG"
const EditAccounts = ()=>{
  const constConfigData = useContext(MyContext)
  const navigate = useNavigate()
  const location = useLocation()
  const { state: additional } = location;
  

  const [isToogleValue,setIsToogleValue] = useState(false)
  const [additionalData, setAdditionalData] = useState([])
  const  [refreshBool, setRefreshBool] = useState(false)

  const handleNavigate = (path)=>{
    navigate(path)
  }

  const getAllEMSByIdFun =async (id) =>{
    let res = await getAllEMSById(id)
    if(res?.success){
      setAdditionalData(res?.data?.data)
    }
  }

  

  useEffect(()=>{
    if(additional?.emsConnId){
      getAllEMSByIdFun(additional?.emsConnId)
    }
  },[])

  const handleToogleValue = ()=>{
    setIsToogleValue(!isToogleValue)
  }

  const handleToggleClick = async (isActive, value) => {
    console.log(isActive, value);
    const res = await updateIsActiveEMS({
      emsConnId: value?.emsConnId,
      isActive: isActive,
    });
    if(res.success){
      toast.success(res.message);
      getAllEMSByIdFun(additional?.emsConnId)
    }else{
      toast.error(res.message);
    }
  };

  const  refBool= ()=> {
    setRefreshBool(false)
  }
  const handleRefresh = () =>{
    testConnectionGetAPIFun()
    setRefreshBool(true)
    setTimeout(refBool,500)
  }

  const testConnectionGetAPIFun = async () =>{
    setRefreshBool(true)
    let res = await testConnectionGetAPI(additional?.emsConnId)
    if(res.success){
      toast.success(res.message)
      setRefreshBool(false)
    }else{
      toast.error(res?.message)
      setRefreshBool(false)
    }
  }

  const backgroundColorClass = (additionalData?.connectionStatus === "Good" || additionalData?.connectionStatus === "GOOD") ? "good-background" : "bad-background";

    return <div className="edit-account">
        <div style={{ height: "15px" }}></div>
      <div className="edit-account-route">
        {/* <p onClick={()=>{handleNavigate("/accounts")}} style={{ color: "#404040E5",cursor:"pointer" }}>Accounts</p>
        <MdKeyboardArrowRight style={{ margin: "0px 10px" }} />
        <p onClick={()=>{handleNavigate("/accounts")}} style={{ color: "#404040E5",cursor:"pointer" }}>Ems connection </p>
        <MdKeyboardArrowRight style={{ margin: "0px 10px" }} />
        <p style={{ color: "#262626" }}>edit </p> */}
        <BreadCrumbsComponent routeData={[{ key:"Accounts",value:"./"},{key:"Ems connection",value:"./"},{key:"First Label",}]}  />
      </div>
      <div style={{ height: "25px" }}></div>
      <div className="edit-account-content">
        <div className="edit-account-content-heading">
          <h1>{constConfigData?.Account_Details}</h1>
        </div>


        <div className="edit-account-content-content">


          <div className="edit-account-content-content-row">
            <div className="edit-account-content-content-row-left">
              <p>Label</p>
            </div>
            <div className="edit-account-content-content-row-right">
              <p>{additionalData?.label}</p>
            </div>
          </div>

          <div className="edit-account-content-content-row">
            <div className="edit-account-content-content-row-left">
              <p>Type</p>
            </div>
            <div className="edit-account-content-content-row-right">
              <p>{additionalData?.type}</p>
            </div>
          </div>

          <div className="edit-account-content-content-row">
            <div className="edit-account-content-content-row-left">
              <p>Username</p>
            </div>
            <div className="edit-account-content-content-row-right">
              <p>{additionalData?.username}</p>
            </div>
          </div>

          <div className="edit-account-content-content-row">
            <div className="edit-account-content-content-row-left">
              <p>Last Activity</p>
            </div>
            <div className="edit-account-content-content-row-right">
              <p>{additionalData?.lastActivity}</p>
            </div>
          </div>

          <div className="edit-account-content-content-row">
            <div className="edit-account-content-content-row-left">
              <p>URL</p>
            </div>
            <div className="edit-account-content-content-row-right">
              <p>{additionalData?.url}</p>
            </div>
          </div>
          <div className="edit-account-content-content-row">
            <div className="edit-account-content-content-row-left">
              <p>Secret Key</p>
            </div>
            <div style={{wordWrap:"break-word",overflowWrap:"break-word"}} className="edit-account-content-content-row-right">
              <p>{additionalData?.secretKey}</p>
            </div>
          </div>
          <div className="edit-account-content-content-row">
            <div className="edit-account-content-content-row-left">
              <p>Connection string</p>
            </div>
            <div className="edit-account-content-content-row-right">
              <p>{additionalData?.connectionString}</p>
            </div>
          </div>

          <div className="edit-account-content-content-row">
            <div className="edit-account-content-content-row-left">
              <p>Is Active</p>
            </div>
            <div onClick={() => {
                      handleToggleClick(!additionalData?.isActive, additional);
                    }} style={{cursor:"pointer",display:"flex",justifyContent:"flex-start"}} className="edit-account-content-content-row-right">
              {
                additionalData?.isActive ? <ToggleOnSVG /> :<ToggleOffSVG />
              }
              
            </div>
          </div>

          <div className="edit-account-content-content-footer">
            <div className="edit-account-content-content-footer-left">
              <p>Connection</p>
            </div>
            <div className="edit-account-content-content-footer-right">
              <div className={backgroundColorClass}>
                <p>{additionalData?.connectionStatus}</p>
              </div>
              <div onClick={()=>{
                handleRefresh()
              }} className="edit-account-content-content-footer-right-2">
                <RefreshSVG />
                <p>{refreshBool ? "Refreshing...":"Test connection"}</p>

              </div>
              
            </div>

          </div>


        </div>


      </div>
    </div>
}

export default EditAccounts