import React from "react";

function ISVG() {

  return (
    <div>
        <svg width="16" height="15" viewBox="0 0 16 15" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M8 0.5C6.61553 0.5 5.26216 0.910543 4.11101 1.67971C2.95987 2.44888 2.06266 3.54213 1.53285 4.82122C1.00303 6.1003 0.86441 7.50776 1.13451 8.86563C1.4046 10.2235 2.07129 11.4708 3.05026 12.4497C4.02922 13.4287 5.2765 14.0954 6.63437 14.3655C7.99224 14.6356 9.3997 14.497 10.6788 13.9672C11.9579 13.4373 13.0511 12.5401 13.8203 11.389C14.5895 10.2378 15 8.88447 15 7.5C15 5.64348 14.2625 3.86301 12.9497 2.55025C11.637 1.2375 9.85652 0.5 8 0.5ZM8 13.3333C6.84628 13.3333 5.71846 12.9912 4.75918 12.3502C3.79989 11.7093 3.05222 10.7982 2.61071 9.73232C2.16919 8.66642 2.05368 7.49353 2.27876 6.36197C2.50384 5.23042 3.05941 4.19102 3.87521 3.37521C4.69102 2.5594 5.73042 2.00383 6.86198 1.77875C7.99353 1.55367 9.16642 1.66919 10.2323 2.1107C11.2982 2.55221 12.2093 3.29989 12.8502 4.25917C13.4912 5.21846 13.8333 6.34627 13.8333 7.5C13.8333 9.0471 13.2188 10.5308 12.1248 11.6248C11.0308 12.7188 9.5471 13.3333 8 13.3333Z" fill="#404040" stroke="#404040" stroke-width="0.5"/>
<path d="M7.99997 6.15859C7.84526 6.15859 7.69689 6.22005 7.58749 6.32944C7.4781 6.43884 7.41664 6.58721 7.41664 6.74192V10.7086C7.41664 10.8633 7.4781 11.0117 7.58749 11.1211C7.69689 11.2305 7.84526 11.2919 7.99997 11.2919C8.15468 11.2919 8.30306 11.2305 8.41245 11.1211C8.52185 11.0117 8.58331 10.8633 8.58331 10.7086V6.74192C8.58331 6.58721 8.52185 6.43884 8.41245 6.32944C8.30306 6.22005 8.15468 6.15859 7.99997 6.15859ZM7.99997 3.76692C7.84206 3.77286 7.6924 3.83904 7.58175 3.95186C7.4711 4.06468 7.40784 4.21559 7.40497 4.37359V4.46692C7.4047 4.54303 7.42027 4.61837 7.45069 4.68813C7.48112 4.75789 7.52574 4.82056 7.58171 4.87213C7.63767 4.92371 7.70377 4.96307 7.77578 4.98771C7.84779 5.01236 7.92414 5.02173 7.99997 5.01526C8.15284 5.00948 8.29788 4.94617 8.40605 4.838C8.51422 4.72983 8.57753 4.58479 8.58331 4.43192V4.29192C8.58376 4.21778 8.56852 4.14439 8.53856 4.07657C8.50861 4.00875 8.46463 3.94804 8.40952 3.89844C8.35441 3.84884 8.28942 3.81148 8.21883 3.78881C8.14824 3.76614 8.07365 3.75868 7.99997 3.76692Z" fill="#404040" stroke="#404040" stroke-width="0.5"/>
</svg>
    </div>
  );
}

export default ISVG;