import React from "react";

const SplitSVG = () => {
  return (
    <svg
      width="16"
      height="17"
      viewBox="0 0 16 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M13.5093 5.96118C13.7632 5.70734 13.7632 5.29578 13.5093 5.04194L11.5093 3.04194C11.2555 2.7881 10.8439 2.7881 10.5901 3.04194C10.3362 3.29578 10.3362 3.70734 10.5901 3.96118L11.4801 4.85117H5.00029C4.64131 4.85117 4.35029 5.14219 4.35029 5.50117C4.35029 5.86016 4.64131 6.15117 5.00029 6.15117L11.4809 6.15117L10.5901 7.04194C10.3362 7.29578 10.3362 7.70734 10.5901 7.96118C10.8439 8.21502 11.2555 8.21502 11.5093 7.96118L13.5093 5.96118ZM5.50933 13.0419C5.76317 13.2958 5.76317 13.7073 5.50933 13.9612C5.25549 14.215 4.84393 14.215 4.59009 13.9612L2.59009 11.9612C2.52777 11.8989 2.48075 11.827 2.44903 11.7504C2.41725 11.6737 2.39971 11.5897 2.39971 11.5016C2.39971 11.4134 2.41725 11.3294 2.44903 11.2528C2.48075 11.1761 2.52777 11.1043 2.59009 11.0419L4.59009 9.04194C4.84393 8.7881 5.25549 8.7881 5.50933 9.04194C5.76317 9.29578 5.76317 9.70734 5.50933 9.96118L4.61895 10.8516H11.0497C11.4087 10.8516 11.6997 11.1426 11.6997 11.5016C11.6997 11.8605 11.4087 12.1516 11.0497 12.1516H4.61895L5.50933 13.0419Z"
        fill="#404040"
      />
    </svg>
  );
};

export default SplitSVG;
