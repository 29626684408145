import { Formik } from "formik";
import ToggleOffSVG from "../../../asset/svg/ToggleOffSVG";
import ToggleOnSVG from "../../../asset/svg/ToggleOnSVG";
import "./ActionEditModal.css";
import { FiEye, FiEyeOff } from "react-icons/fi";
import { useContext, useEffect, useState } from "react";
import { MyContext } from "../../../ContextAPIStore/CreateContext";
import BreadCrumbsComponent from "../../../components/breadCrumbsComponent/BreadCrumbsComponent";
import { AddNewAccountsSchema } from "../../../utils/ValidationSchema";
import { getEMSAPIType, testConnectionGetAPI } from "../../../services/servicesAPI";
import { emsConnUpdate } from "../../../services/servicesAPI";
import { toast } from "react-toastify";
import CrossSVG from "../../../asset/svg/CrossSVG";
import { change } from "@syncfusion/ej2-react-grids";

const ActionEditModal = ({onClose, editVal, SyncFun,setActionEditModalFlag ,setChangeTrack,changeTrack}) => {
  const constConfigData = useContext(MyContext);
  const [showSecretKeyFlag, setShowSecretKeyFlag] = useState(false);
  const [isActiveToggleFlag, setIsActiveToggleFlag] = useState(editVal?.isActive);
  const [typeDropdownFlag, setTypeDropdownFlag] = useState(false);
  const [url, setUrl] = useState(editVal?.url)
  const [typeDropdownInputValue, setTypeDropdownInputValue] = useState(
    editVal?.type
  );
  const [typeFieldError, setTypeFieldError] = useState(false);
  const [emsTypeID, setEmsTypeID] = useState(editVal?.emsAPITypeId);
  const [emsType, setEMSType] = useState([]);

  console.log("editValue", editVal);

  const handleSetDropdownValue = (value, id,e) => {
    setUrl(e?.baseURL)
    setEmsTypeID(id);
    setTypeDropdownInputValue(value);
    setTypeDropdownFlag(false);
    setTypeFieldError(false);
  };

  const handleSubmitAPI = async (formValue) => {
    console.log("submit Values", formValue);
    let myPayload = {
      emsConnId: editVal?._id,
      label: formValue?.name,
      emsAPITypeId: formValue?.emsAPITypeId,
      username: formValue?.userName,
      url: formValue?.url,
      secretKey: formValue?.secretKey,
      connectionString: formValue?.connectionString,
      isActive: formValue?.isActive,
    };
    console.log("modifiedSubmitValue", myPayload);
    let response2 = await emsConnUpdate(myPayload);
    console.log(response2);
    if (response2.success) {
      setChangeTrack(!changeTrack)
      SyncFun();
      toast.success(response2.message);
      onClose();
    } else {
      toast.error(response2.message);
    }
  };

  const getEMSAPITypeFun = async () => {
    const response = await getEMSAPIType();
    console.log("emsType", response?.data?.data?.emsTypes);
    setEMSType(response?.data?.data?.emsTypes);
  };

  useEffect(() => {
    getEMSAPITypeFun();
  }, []);

  

  return (
    <div  className="action-edit-modal">
      <div
        style={{display:"flex",background:"",width:"70%",display:"flex",justifyContent:"end"}}
        onClick={() => {
          setActionEditModalFlag(false)
          // onclose();
        }}
      >
        <div style={{background:"#494949",borderRadius:"50%",padding:"8px",paddingBottom:"5px", margin:"6px"}}>
          <CrossSVG />
        </div>
      </div>
      <div className="action-edit-modal-content">
        <Formik
          initialValues={{
            name: editVal?.label,
            emsAPITypeId: editVal?.label,
            userName: editVal?.username,
            url: editVal?.url,
            secretKey: editVal?.secretKey,
            connectionString: editVal?.connectionString,
            isActive: editVal?.isActive,
          }}
          validationSchema={AddNewAccountsSchema}
          onSubmit={(values) => {
            values.emsAPITypeId = emsTypeID;
            if (isActiveToggleFlag) {
              values.isActive = true;
            } else {
              values.isActive = false;
            }
            handleSubmitAPI(values);
          }}
        >
          {({ handleChange, values, errors, touched, handleSubmit }) => {
            return (
              <div className="add-new-account-content">
                <div>
                  <div className="ems-content-heading">
                    <h1>{constConfigData?.Account_Details}</h1>
                  </div>
                </div>
                <div className="add-new-account-content-1">
                  <div className="add-new-account-content-1-row">
                    <label htmlFor="">{constConfigData?.Name}</label>

                    <div className="add-new-account-content-1-row-input">
                      <input
                        id="name"
                        name="name"
                        value={values.name}
                        onChange={handleChange}
                        placeholder="Enter Name"
                      />

                      {errors.name && touched.name ? (
                        <p
                          style={{
                            color: "red",
                            position: "absolute",
                            bottom: "-20px",
                          }}
                        >
                          {errors.name}
                        </p>
                      ) : (
                        <></>
                      )}
                    </div>
                  </div>
                  <div className="add-new-account-content-1-row">
                    <label htmlFor="">{constConfigData?.Type}</label>

                    <div
                      style={{ position: "relative" }}
                      className="add-new-account-content-1-row-input"
                    >
                      <input
                        onClick={() => {
                          setTypeDropdownFlag(!typeDropdownFlag);
                        }}
                        id=""
                        name=""
                        value={typeDropdownInputValue}
                        placeholder="Select Type"
                      />
                      {typeDropdownFlag && (
                        <div className="add-new-account-dropdown">
                          {emsType.map((e) => {
                            return (
                              <p
                                onClick={() => {
                                  handleSetDropdownValue(e?.name, e?._id,e);
                                }}
                                style={{ padding: "8px", cursor: "pointer" }}
                              >
                                {e?.name}
                              </p>
                            );
                          })}
                        </div>
                      )}
                      {typeFieldError && (
                        <p
                          style={{
                            color: "red",
                            position: "absolute",
                            bottom: "-20px",
                          }}
                        >
                          This Field is required.
                        </p>
                      )}

                      {/* {errors.currPassword && touched.currPassword ? (
                      <p style={{color:"red",position:"absolute",bottom:"-20px"}}>{errors.currPassword}</p>
                    ) : (
                      <></>
                    )} */}
                    </div>
                  </div>

                  <div className="add-new-account-content-1-row">
                    <label htmlFor="">{constConfigData?.User_Name}</label>

                    <div className="add-new-account-content-1-row-input">
                      <input
                        id="userName"
                        name="userName"
                        value={values.userName}
                        onChange={handleChange}
                        placeholder="Enter Username"
                      />

                      {errors.userName && touched.userName ? (
                        <p
                          style={{
                            color: "red",
                            position: "absolute",
                            bottom: "-20px",
                          }}
                        >
                          {errors.userName}
                        </p>
                      ) : (
                        <></>
                      )}
                    </div>
                  </div>
                  <div className="add-new-account-content-1-row">
                    <label htmlFor="">{constConfigData?.URL}</label>

                    <div className="add-new-account-content-1-row-input">
                      <input
                        id="url"
                        name="url"
                        value={url}
                        onChange={handleChange}
                        placeholder="Enter URL"
                      />

                      {errors.url && touched.url ? (
                        <p
                          style={{
                            color: "red",
                            position: "absolute",
                            bottom: "-20px",
                          }}
                        >
                          {errors.url}
                        </p>
                      ) : (
                        <></>
                      )}
                    </div>
                  </div>
                  <div className="add-new-account-content-1-row">
                    <label htmlFor="">{constConfigData?.Secrate_Key}</label>

                    <div className="add-new-account-content-1-row-input">
                      <input
                        type={showSecretKeyFlag ? "text" : "password"}
                        id="secretKey"
                        name="secretKey"
                        value={values.secretKey}
                        onChange={handleChange}
                        placeholder="Enter Secret Key"
                      />
                      {showSecretKeyFlag ? (
                        <div
                          onClick={() => {
                            setShowSecretKeyFlag(!showSecretKeyFlag);
                          }}
                          className="show-secret-key-icon"
                        >
                          <FiEyeOff size={20} />
                        </div>
                      ) : (
                        <div
                          onClick={() => {
                            setShowSecretKeyFlag(!showSecretKeyFlag);
                          }}
                          className="show-secret-key-icon"
                        >
                          <FiEye size={20} />
                        </div>
                      )}

                      {errors.secretKey && touched.secretKey ? (
                        <p
                          style={{
                            color: "red",
                            position: "absolute",
                            bottom: "-20px",
                          }}
                        >
                          {errors.secretKey}
                        </p>
                      ) : (
                        <></>
                      )}
                    </div>
                  </div>
                  <div className="add-new-account-content-1-row">
                    <label htmlFor="">
                      {constConfigData?.Connection_String}
                    </label>

                    <div className="add-new-account-content-1-row-input">
                      <input
                        id="connectionString"
                        name="connectionString"
                        value={values.connectionString}
                        onChange={handleChange}
                        placeholder="Enter Connection String"
                      />

                      {errors.connectionString && touched.connectionString ? (
                        <p
                          style={{
                            color: "red",
                            position: "absolute",
                            bottom: "-20px",
                          }}
                        >
                          {errors.connectionString}
                        </p>
                      ) : (
                        <></>
                      )}
                    </div>
                  </div>
                  <div className="add-new-account-content-1-row">
                    <label htmlFor="">{constConfigData?.Is_Active}</label>

                    <div
                      style={{
                        cursor: "pointer",
                        display: "flex",
                        justifyContent: "flex-start",
                      }}
                      onClick={() => {
                        setIsActiveToggleFlag(!isActiveToggleFlag);
                      }}
                    >
                      {isActiveToggleFlag ? <ToggleOnSVG /> : <ToggleOffSVG />}

                      {/* {errors.currPassword && touched.currPassword ? (
                      <p style={{color:"red",position:"absolute",bottom:"-20px"}}>{errors.currPassword}</p>
                    ) : (
                      <></>
                    )} */}
                    </div>
                  </div>
                </div>

                <div className="add-new-account-content-2">
                  <button
                    onClick={() => {
                      onClose();
                    }}
                    style={{ color: "#404040" }}
                  >
                    {constConfigData?.Cancel}
                  </button>
                  <button
                    type="button"
                    style={{ backgroundColor: "#2081E2" }}
                    onClick={() => {
                      //   handleChangePasswordSubmit();
                      if (typeDropdownInputValue) {
                        handleSubmit(values);
                      } else {
                        setTypeFieldError(true);
                      }
                    }}
                  >
                    {constConfigData?.Submit}
                  </button>
                </div>
              </div>
            );
          }}
        </Formik>
      </div>
    </div>
  );
};

export default ActionEditModal;
