import axios from "axios";

// import { BASE_URL } from "../env";
import { getToken } from "../utils/getToken";
// import constConfig from "../asset/ConstantConfig.json"
// let token = "";
let token = getToken();

const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
  // timeout: 60000, 
  // Set a timeout (in milliseconds) for requests
  headers: {
    "Content-Type": "application/json",
    "ngrok-skip-browser-warning": "69420",
    Authorization: "Bearer " + token,
  },
});

export const axiosInstanceWithoutToken = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
  // timeout: 60000,
  // Set a timeout (in milliseconds) for requests
  headers: {
    "Content-Type": "application/json",
    "ngrok-skip-browser-warning": "69420",
    // Authorization: "Bearer " + token,
  },
});
axiosInstance.interceptors.request.use((config) => {
  return config;
});
axiosInstance.interceptors.response.use((response) => {
  return response;
});

export const convertToApiResponse = (response) => {
  return {
    success: true, // Set to true for successful responses
    message: response.data.message, // You can populate this with a success message if needed
    statusCode: response.data.statusCode,
    data: response.data, // Assign the API response data to the model's data property
  };
};

export const convertToApiError = (error, isCancel) => {
  if (error.response) {
    const { data, status } = error.response;
    const apiErrorResponse = {
      success: false,
      statusCode: status,
      message: isCancel ? 'REQUEST_CANCEL' : data.message,
      data: null, // No data for error responses
    };
    return apiErrorResponse;
  } else {
    // Handle other errors (e.g., network errors)
    const apiErrorResponse = {
      success: false,
      statusCode: 500,
      message: isCancel ? 'REQUEST_CANCEL' : "Please check your network connection/Server Issued.", // You can customize the error message here
      data: null, // No data for error responses
    };

    return apiErrorResponse;
  }
};

export const loginAPI = async (userData) => {
  try {
    const response = await axiosInstance.post("/user/login", userData);
    var res = convertToApiResponse(response);

    return res;
  } catch (error) {
    let err = convertToApiError(error);

    return err;
  }
};

export const forgotAPI = async (req) => {
  try {
    const response = await axiosInstance.post("/user/forgotPassword", req);
    var res = convertToApiResponse(response);

    return res;
  } catch (error) {
    let err = convertToApiError(error);

    return err;
  }
};

export const resetPasswordAPI = async (req, token) => {
  try {
    const response = await axiosInstance.post("/user/reset-password", req, {
      headers: {
        Authorization: "Bearer " + token,
      }
    });
    var res = convertToApiResponse(response);

    return res;
  } catch (error) {
    let err = convertToApiError(error);

    return err;
  }
}

// export const uploadFileToPresigned = async (file) => {
//   const res = await axios.put(
//     "https://mds-stage-bucket.s3.ap-south-1.amazonaws.com/test.csv?Content-Type=text%2Fcsv&X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIAQ3EGSQ5SAEMSR2VC%2F20240410%2Fap-south-1%2Fs3%2Faws4_request&X-Amz-Date=20240410T044206Z&X-Amz-Expires=3600&X-Amz-Signature=b84bdcb1a38a469256db78aea9aee9f800abbadea7bf72e09e9abb0cb42d4348&X-Amz-SignedHeaders=host",
//     file,
//     {
//       headers: {
//         "Content-Type": file.type,
//       },
//       onUploadProgress: (progressEvent) => {
//         const percentCompleted = Math.round(
//           (progressEvent.loaded * 100) / progressEvent.total
//         );
//       },
//     }
//   );
// };

export const logoutAPI = async (userDataToken) => {
  try {
    const response = await axiosInstance.post(
      "/user/logout",
      {},
      {
        headers: {
          Authorization: "Bearer " + getToken(),
        },
      }
    );
    var res = convertToApiResponse(response);
    return res;
  } catch (error) {
    let err = convertToApiError(error);

    return err;
  }
};
export const changePasswordAPI = async (payload) => {
  try {
    const response = await axiosInstance.post("/user/updatePassword", payload, {
      headers: {
        Authorization: "Bearer " + getToken(),
      },
    });
    var res = convertToApiResponse(response);
    return res;
  } catch (error) {
    let err = convertToApiError(error);

    return err;
  }
};

export const groupGetAPI = async (payload, filterPayload) => {
  try {
    const response = await axiosInstance.post(
      `/group/getAllGroups?page=${payload?.page}&limit=${payload.limit}`,
      filterPayload,
      {
        headers: {
          Authorization: "Bearer " + getToken(),
        },
      }
    );
    var res = convertToApiResponse(response);
    return res;
  } catch (error) {
    let err = convertToApiError(error);
    return err;
  }
};

export const createGroupPostAPI = async (payload) => {
  try {
    const response = await axiosInstance.post("/group", payload, {
      headers: {
        Authorization: "Bearer " + getToken(),
      },
    });
    var res = convertToApiResponse(response);
    return res;
  } catch (error) {
    let err = convertToApiError(error);
    return err;
  }
};

export const updateGroupPutAPI = async (groupId, payload) => {
  try {
    const response = await axiosInstance.put(`/group/${groupId}`, payload, {
      headers: {
        Authorization: "Bearer " + getToken(),
      },
    });
    var res = convertToApiResponse(response);
    return res;
  } catch (error) {
    let err = convertToApiError(error);
    return err;
  }
};

export const deleteGroupByIDDeleteAPI = async (payload) => {
  try {
    const response = await axiosInstance.post(
      `/group/deleteGroup`,
      { groupId: payload },
      {
        headers: {
          Authorization: "Bearer " + getToken(),
        },
      }
    );
    var res = convertToApiResponse(response);
    return res;
  } catch (error) {
    let err = convertToApiError(error);
    return err;
  }
};

export const chooseFilterGETApi = async () => {
  try {
    const response = await axiosInstance.get("/query/filters", {
      headers: {
        Authorization: "Bearer " + getToken(),
      },
    });
    var res = convertToApiResponse(response);
    return res;
  } catch (error) {
    let err = convertToApiError(error);
    return err;
  }
};

export const constantConfigGETApi = async () => {
  try {
    const response = await axios.get("/ConstantConfig.json");
    var res = convertToApiResponse(response);
    return res;
  } catch (error) {
    let err = convertToApiError(error);
    return err;
  }
};

export const getPresignedUrlApi = async (payload) => {
  try {
    const response = await axiosInstance.post(
      "/contact/getPresignedURL",
      payload,
      {
        headers: {
          Authorization: "Bearer " + getToken(),
        },
      }
    );
    var res = convertToApiResponse(response);
    return res;
  } catch (error) {
    let err = convertToApiError(error);
    return err;
  }
};
// onUploadProgress: (progressEvent) => {
//   const percentCompleted = Math.round(
//     (progressEvent.loaded * 100) / progressEvent.total
//   );
// },

export const uploadCsvFilePutAPI = async (file, url, setLoaderProgressBar) => {
  try {
    const response = await axiosInstanceWithoutToken.put(url, file, {
      onUploadProgress: (progressEvent) => {
        const percentCompleted = Math.round(
          (progressEvent.loaded * 100) / progressEvent.total
        );
        setLoaderProgressBar(percentCompleted);

        // progressBarUploadMD(progressEvent)
      },
    });
    var res = convertToApiResponse(response);
    return res;
  } catch (error) {
    let err = convertToApiError(error);
    return err;
  }
};

export const importContactsAPI = async (fileId) => {
  let token =  getToken()
  console.log("AccessToken",getToken())
  console.log("fileId",fileId)
  try {
    const response = await axiosInstance.post(
      `/contact/importContacts/${fileId}`,{},
      {
        headers: {
          Authorization: "Bearer " + token,
        },
      }
    );
    var res = convertToApiResponse(response);
    return res;
  } catch (error) {
    let err = convertToApiError(error);
    return err;
  }
};

export const getFileDetailsAPI = async (fileId) => {
  try {
    const response = await axiosInstance.get(
      `/contact/getFileDetails/${fileId}`,
      {
        headers: {
          Authorization: "Bearer " + getToken(),
        },
      }
    );
    var res = convertToApiResponse(response);
    return res;
  } catch (error) {
    let err = convertToApiError(error);
    return err;
  }
};

export const historyAllGetAPI = async (payload) => {
  try {
    const response = await axiosInstance.get(
      `/contact/getFiles?page=${payload?.page}&limit=${payload?.limit}`,
      {
        headers: {
          Authorization: "Bearer " + getToken(),
        },
      }
    );
    var res = convertToApiResponse(response);
    return res;
  } catch (error) {
    let err = convertToApiError(error);
    return err;
  }
};

export const exportColumnGetAPI = async () => {
  try {
    const response = await axiosInstance.post(
      "/contact/getExportColumns?$skip=0&$top=10",{},
      {
        headers: {
          Authorization: "Bearer " + getToken(),
        },
      }
    );
    var res = convertToApiResponse(response);
    return res;
  } catch (error) {
    let err = convertToApiError(error);
    return err;
  }
};
export const getContactProfileBYIdAPI = async (payload) => {
  try {
    const response = await axiosInstance.get(
      `/contact/getContactById/${payload}`,
      {
        headers: {
          Authorization: "Bearer " + getToken(),
        },
      }
    );
    var res = convertToApiResponse(response);
    return res;
  } catch (error) {
    let err = convertToApiError(error);
    return err;
  }
};
export const notificationGetAPI = async (payload) => {
  
  try {
    const response = await axiosInstance.get(`/notification?$top=${payload.limit}&$skip=${payload.skip}`, {
      headers: {
        Authorization: "Bearer " + getToken(),
      },
    });
    var res = convertToApiResponse(response);
    return res;
  } catch (error) {
    let err = convertToApiError(error);
    return err;
  }
};

let cancelToken;
export const getAllContactsPostAPI = async (pagingData, payload,query) => {
  try {
    if (cancelToken) {
      cancelToken.cancel('REQUEST_CANCEL');
    }
    cancelToken = axios.CancelToken.source();

    const response = await axiosInstance.post(
      `contact/getContacts?$skip=${pagingData?.skip}&$top=${pagingData?.limit}&textSearch=&show=${query}`,
      payload,
      {
        headers: {
          Authorization: "Bearer " + getToken()
        },
        cancelToken: cancelToken.token
      }
    );
    var res = convertToApiResponse(response);
    return res;
  } catch (error) {
    let err;
    if (axios.isCancel(error)) {
      err = convertToApiError(error, true)
    } else {
      err = convertToApiError(error)
    }
    return err;
  }
};

export const getExportColumnsAPI = async () => {
  try {
    const response = await axiosInstance.post(
      `contact/getExportColumns?$skip=0&$top=100`,
      {},
      {
        headers: {
          Authorization: "Bearer " + getToken(),
        },
      }
    );
    var res = convertToApiResponse(response);
    return res;
  } catch (error) {
    let err = convertToApiError(error);
    return err;
  }
};
export const getContactExportCsvAPI = async (payload) => {
  try {
    const response = await axiosInstance.post(
      `contact/contactExportCSV`,
      payload,
      {
        headers: {
          Authorization: "Bearer " + getToken(),
        },
      }
    );
    var res = convertToApiResponse(response);
    return res;
  } catch (error) {
    let err = convertToApiError(error);
    return err;
  }
};

export const getAllContactExportCsvAPI = async (payload) => {
  try {
    const response = await axiosInstance.post(
      `/contact/allContactExportCSV`,
      payload,
      {
        headers: {
          Authorization: "Bearer " + getToken(),
        },
      }
    );
    var res = convertToApiResponse(response);
    return res;
  } catch (error) {
    let err = convertToApiError(error);
    return err;
  }
};
// export const importContactGetAPI = async () => {
//   try {
//     const response = await axiosInstance.get("/contact/getFiles");
//     var res = convertToApiResponse(response);
//     return res;
//   } catch (error) {
//     let err = convertToApiError(error);
//     return err;
//   }
// };

export const updateExportColumnPostAPI = async (payload) => {
  try {
    const response = await axiosInstance.patch(
      "/contact/updateExportColumns",
      payload,
      {
        headers: {
          Authorization: "Bearer " + getToken(),
        },
      }
    );
    var res = convertToApiResponse(response);
    return res;
  } catch (error) {
    let err = convertToApiError(error);
    return err;
  }
};

export const createViewPostAPI = async (payload) => {
  try {
    const response = await axiosInstance.post("/query/view", payload, {
      headers: {
        Authorization: "Bearer " + getToken(),
      },
    });
    let res = convertToApiResponse(response);
    return res;
  } catch (error) {
    let err = convertToApiError(error);
    return err;
  }
};

export const createViewPatchAPIwithID = async (id, payload) => {
  try {
    const response = await axiosInstance.put(
      `/query/view/upsertFilter/${id}`,
      payload,
      {
        headers: {
          Authorization: "Bearer " + getToken(),
        },
      }
    );
    let res = convertToApiResponse(response);
    return res;
  } catch (error) {
    let err = convertToApiError(error);
    return err;
  }
};

export const getAllViewsAPI = async (payload) => {
  try {
    const response = await axiosInstance.post(`/query/getAllViews`, payload, {
      headers: {
        Authorization: "Bearer " + getToken(),
      },
    });
    var res = convertToApiResponse(response);
    return res;
  } catch (error) {
    let err = convertToApiError(error);
    return err;
  }
};

export const emsConnUpdate = async (payload) => {
  try {
    let response = await axiosInstance.put(`/account/emsConnUpdate`, payload, {
      headers: {
        Authorization: "Bearer " + getToken(),
      },
    });
    let res = convertToApiResponse(response);
    return res;
  } catch (err) {
    let error = convertToApiError(err);
    return error;
  }
};

export const postAddGroupInContactPageAPI = async (payload) => {
  try {
    const response = await axiosInstance.post(`/group/addToGroup`, payload, {
      headers: {
        Authorization: "Bearer " + getToken(),
      },
    });
    var res = convertToApiResponse(response);
    return res;
  } catch (error) {
    let err = convertToApiError(error);
    return err;
  }
};

export const postRemoveToGroupInContactPageAPI = async (payload) => {
  try {
    const response = await axiosInstance.post(
      `/group/removeFromGroup`,
      payload,
      {
        headers: {
          Authorization: "Bearer " + getToken(),
        },
      }
    );
    var res = convertToApiResponse(response);
    return res;
  } catch (error) {
    let err = convertToApiError(error);
    return err;
  }
};

export const postDeleteFromAllGroupInContactPageAPI = async (payload) => {
  try {
    const response = await axiosInstance.post(`/contact/deleteContacts`, payload, {
      headers: {
        Authorization: "Bearer " + getToken(),
      },
    });
    var res = convertToApiResponse(response);
    return res;
  } catch (error) {
    let err = convertToApiError(error);
    return err;
  }
};

export const updateViewNamePatchAPI = async (id, payload) => {
  try {
    const response = await axiosInstance.patch(
      `query/updateViewName/${id}`,
      payload,
      {
        headers: {
          Authorization: "Bearer " + getToken(),
        },
      }
    );
    var res = convertToApiResponse(response);
    return res;
  } catch (error) {
    let err = convertToApiError(error);
    return err;
  }
};

export const deleteViewAPI = async (payload) => {
  try {
    const response = await axiosInstance.post(`/query/deleteView`, payload, {
      headers: {
        Authorization: "Bearer " + getToken(),
      },
    });
    var res = convertToApiResponse(response);
    return res;
  } catch (error) {
    let err = convertToApiError(error);
    return err;
  }
};
export const postMergeGroupPostAPI = async (payload) => {
  try {
    const response = await axiosInstance.post(`/group/mergeGroups`, payload, {
      headers: {
        Authorization: "Bearer " + getToken(),
      },
    });
    var res = convertToApiResponse(response);
    return res;
  } catch (error) {
    let err = convertToApiError(error);
    return err;
  }
};

export const postSplitGroupPostAPI = async (payload) => {
  try {
    const response = await axiosInstance.post(`/group/splitGroup`, payload, {
      headers: {
        Authorization: "Bearer " + getToken(),
      },
    });
    var res = convertToApiResponse(response);
    return res;
  } catch (error) {
    let err = convertToApiError(error);
    return err;
  }
};

export const postWarmupSplitGroupPostAPI = async (payload) => {
  try {
    const response = await axiosInstance.post(`/group/warmUpGroup`, payload, {
      headers: {
        Authorization: "Bearer " + getToken(),
      },
    });
    var res = convertToApiResponse(response);
    return res;
  } catch (error) {
    let err = convertToApiError(error);
    return err;
  }
};

export const getGroupByIdPostAPI = async (id, payload) => {
  try {
    const response = await axiosInstance.get(`/group/${id}`,{
      params:payload,
      headers: {
        Authorization: "Bearer " + getToken(),
      },
    });
    var res = convertToApiResponse(response);
    return res;
  } catch (error) {
    let err = convertToApiError(error);
    return err;
  }
};

export const getViewByIdPostAPI = async (id, payload) => {
  try {
    const response = await axiosInstance.post(
      `/query/getViewById/${id}`,
      payload,
      {
        headers: {
          Authorization: "Bearer " + getToken(),
        },
      }
    );
    var res = convertToApiResponse(response);
    return res;
  } catch (error) {
    let err = convertToApiError(error);
    return err;
  }
};

export const emsConnAddPostAPI = async (payload) => {
  try {
    const response = await axiosInstance.post("/account/emsConnAdd", payload, {
      headers: {
        Authorization: "Bearer " + getToken(),
      },
    });
    var res = convertToApiResponse(response);
    return res;
  } catch (error) {
    let err = convertToApiError(error);
    return error;
  }
};

export const deleteEMSConn = async (id, payload) => {
  try {
    const response = await axiosInstance.delete(
      `/account/deleteEMSConn/${id}`,
      payload,
      {
        headers: {
          Authorization: "Bearer " + getToken(),
        },
      }
    );
    var res = convertToApiResponse(response);
    return res;
  } catch (error) {
    let err = convertToApiError(error);
    return err;
  }
};

export const getEMSAPIType = async (payload) => {
  try {
    const response = await axiosInstance.get("/account/getEMSAPIType", {
      headers: {
        Authorization: "Bearer " + getToken(),
      },
    });
    var res = convertToApiResponse(response);
    return res;
  } catch (error) {
    let err = convertToApiError(error);
    return err;
  }
};


export const updateIsActiveEMS = async (payload) => {
  try {
    const response = await axiosInstance.patch(
      "account/updateIsActive",
      payload,
      {
        headers: {
          Authorization: "Bearer " + getToken(),
        },
      }
    );
    var res = convertToApiResponse(response);
    return res;
  } catch (error) {
    let err = convertToApiError(error);
    return err;
  }
};

export const getAllEMSById = async (id) => {
  try {
    const response = await axiosInstance.get(`/account/getAllEMSConnById/${id}`, {
      headers: {
        Authorization: "Bearer " + getToken(),
      },
    });
    var res = convertToApiResponse(response);
    return res;
  } catch (error) {
    let err = convertToApiError(error);
    return err;
  }
};

export const DashboardStatsGetAPI = async (date) => {
  try {
    const response = await axiosInstance.get(`/dashboard/?month=${date}`, {
      headers: {
        Authorization: "Bearer " + getToken(),
      },
    });
    var res = convertToApiResponse(response);
    return res;
  } catch (error) {
    let err = convertToApiError(error);
    return err;
  }
};

export const testConnectionGetAPI = async (id) => {
  try {
    const response = await axiosInstance.get(`/account/testConnection/${id}`, {
      headers: {
        Authorization: "Bearer " + getToken(),
      },
    });
    var res = convertToApiResponse(response);
    return res;
  } catch (error) {
    let err = convertToApiError(error);
    return err;
  }
};

export const importCustomContactPostAPI = async (payload) => {
  try {
    const response = await axiosInstance.post("/contact/importCustomContacts", payload, {
      headers: {
        Authorization: "Bearer " + getToken(),
      },
    });
    var res = convertToApiResponse(response);
    return res;
  } catch (error) {
    let err = convertToApiError(error);
    return error;
  }
};

export const getCustomFileDataGetAPI = async (id) => {
  try {
    const response = await axiosInstance.get(`/contact/getCustomFileData/${id}`, {
      headers: {
        Authorization: "Bearer " + getToken(),
      },
    });
    var res = convertToApiResponse(response);
    return res;
  } catch (error) {
    let err = convertToApiError(error);
    return err;
  }
};


export const updateContactAPI = async (Id, payload) => {
  try {
    const response = await axiosInstance.put(`/contact/updateContact/${Id}`, payload, {
      headers: {
        Authorization: "Bearer " + getToken(),
      },
    });
    var res = convertToApiResponse(response);
    return res;
  } catch (error) {
    let err = convertToApiError(error);
    return err;
  }
};

export const filterCountPostAPI = async (payload) => {
  try {
    const response = await axiosInstance.post(`/contact/getFilterCount`, payload, {
      headers: {
        Authorization: "Bearer " + getToken(),
      },
    });
    var res = convertToApiResponse(response);
    return res;
  } catch (error) {
    let err = convertToApiError(error);
    return err;
  }
};

export const exportColumnPostAPI = async (payload) => {
  try {
    const response = await axiosInstance.post("/contact/getExportColumns?$skip=0&$top=100",{}, {
      headers: {
        Authorization: "Bearer " + getToken(),
      },
    });
    var res = convertToApiResponse(response);
    return res;
  } catch (error) {
    let err = convertToApiError(error);
    return error;
  }
};

export const emsConnectionPostAPI = async (payload) => {
  try {
    const response = await axiosInstance.post("/account/getAllEMSConn",payload, {
      headers: {
        Authorization: "Bearer " + getToken(),
      },
    });
    var res = convertToApiResponse(response);
    return res;
  } catch (error) {
    let err = convertToApiError(error);
    return error;
  }
};

export const backupGetAPI = async () => {
  try {
    const response = await axiosInstance.get("/backup/list", {
      headers: {
        Authorization: "Bearer " + getToken(),
      },
    });
    var res = convertToApiResponse(response);
    return res;
  } catch (error) {
    let err = convertToApiError(error);
    return err;
  }
};

export const activityGetAPI = async (params) => {
  try {
    const response = await axiosInstance.get(`/backup/activitylist?$skip=${params?.$skip}&$top=${params?.$top}`, {
      headers: {
        Authorization: "Bearer " + getToken(),
      },
    });
    var res = convertToApiResponse(response);
    return res;
  } catch (error) {
    let err = convertToApiError(error);
    return err;
  }
};

export const restoreGetAPI = async (payload,bool) => {
  console.log("Bool",bool)
  try {
    const response = await axiosInstance.get(`/backup/restore/${payload}?isTodaybackup =${bool}`, {
      headers: {
        Authorization: "Bearer " + getToken(),
      },
    });
    var res = convertToApiResponse(response);
    return res;
  } catch (error) {
    let err = convertToApiError(error);
    return err;
  }
};

export const backupListDeleteAPI = async (id) => {
  try {
    const response = await axiosInstance.delete(`backup/list/delete/${id}`, {
      headers: {
        Authorization: "Bearer " + getToken(),
      },
    });
    var res = convertToApiResponse(response);
    return res;
  } catch (error) {
    let err = convertToApiError(error);
    return err;
  }
};

export const backupActivityDeleteAPI = async (id) => {
  try {
    const response = await axiosInstance.delete(`backup/activitylist/delete/${id}`, {
      headers: {
        Authorization: "Bearer " + getToken(),
      },
    });
    var res = convertToApiResponse(response);
    return res;
  } catch (error) {
    let err = convertToApiError(error);
    return err;
  }
};
