import {  MdKeyboardArrowRight } from "react-icons/md"
import "./AddSingleSubscriber.css"
import MatchColumnsNavbarComponent from "../../components/matchColumnsNavbarComponent/MatchColumnsNavbarComponent"
import { MyContext } from "../../ContextAPIStore/CreateContext"
import { useCallback, useContext } from "react"

const AddSingleSubscriber = ()=>{
    const constConfigData = useContext(MyContext)
    return <div className="add-single">
    <div className="add-single-route">
          <p>{constConfigData?.Contacts}</p>
          <MdKeyboardArrowRight style={{margin:"0px 10px"}} />
          <p>{constConfigData?.Add_New}</p>
      </div>
      <div className="add-single-heading">
          <h1>{constConfigData?.Add_New}{constConfigData?.Contacts}</h1>
      </div>
    <MatchColumnsNavbarComponent />
    <div className="add-single-content">
    {constConfigData?.Add_Single}
    </div>

    </div>
}

export default AddSingleSubscriber