import { useState } from "react";
import "./AddGroupModal.css";
import { IoClose } from "react-icons/io5";
import { createGroupPostAPI } from "../../../../../../services/servicesAPI";
import { toast } from "react-toastify";
import CrossSVG from "../../../../../../asset/svg/CrossSVG";

const AddGroupModal = ({
  headingText,
  onclose,
  handleCloseAddGroupModalCreate
}) => {
  const [inputValue, setInputValue] = useState("");
  const [errorMessage, setErrorMessage] = useState(false);
  const [dropdownFlag, setDropdownFlag] = useState(false);


  const handleCreateGroupAPI= async ()=>{
     let response = await createGroupPostAPI({name:inputValue});

     if(response.success){
      toast.success(response.message)
      handleCloseAddGroupModalCreate()
     }else{
      toast.error(response.message)
     }

  }


  const handleAddGroupSubmitAPI = ()=>{
    if(inputValue.length > 2){
      handleCreateGroupAPI()
    }else{
      setTimeout(()=>{
        setErrorMessage("")
      },3000)
      setErrorMessage("Group Name must be greater than 3 characeter.")

    }
  }

  return (
    <div className="addgroupmodal">
      <div className="addgroupmodal-content">
        <div

          className="addgroupmodal-content-close"
          onClick={() => {
            setDropdownFlag(false);
            onclose();
          }}
        >
          <div >
          <CrossSVG />
          </div>
        </div>
        <div className="addgroupmodal-content-content">
          <div className="addgroupmodal-content-content-1">
            <h3>{headingText}</h3>
          </div>
          <div className="addgroupmodal-content-content-2">

            <div className="addgroupmodal-content-content-2-2">
              <input
                value={inputValue}
                onChange={(e) => {
                  setInputValue(e.target.value)
                }}
                type="text"
                placeholder="Enter group name"
              />
            </div>

            {errorMessage ? (
            <div style={{position:"absolute",bottom:"-15px"}}>
              <p
                style={{ fontSize: "0.8em", color: "red", textAlign: "center" }}
              >
                Group Name must be greater than 3 characeter.
              </p>
            </div>
          ) : (
            <></>
          )}
          </div>
          
          <div className="addgroupmodal-content-content-3">
            <button
            onClick={() => {
              onclose()
            }}
             style={{ color: "#404040", backgroundColor: "#E5E7EB" }}>
              Cancel
            </button>
            <button
              onClick={() => {
                handleAddGroupSubmitAPI()
              }}
              style={{ backgroundColor: "#2081E2", color: "#ffffff" }}
            >
              Create
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddGroupModal;
