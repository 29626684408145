import React from "react";

function ProhibitedSVG() {
  return (
    <div>
      <svg
        width="16"
        height="17"
        viewBox="0 0 16 17"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle cx="8" cy="8.5" r="6.25" stroke="#404040" stroke-width="1.5" />
        <path
          d="M4 12.5L12 4.5"
          stroke="#404040"
          stroke-width="1.5"
          stroke-linecap="round"
        />
      </svg>
    </div>
  );
}

export default ProhibitedSVG;
