import React from "react";
import { useLocation } from "react-router-dom";

function Contact(props) {
  const location = useLocation();

  // Check the current pathname
  const currentPath = location.pathname;

  // Use currentPath to determine if it matches a specific route
  const isContactsActive = currentPath.includes("/contacts");

  const strokeColor = isContactsActive ? "#2081E3" : "#262625";

  return (
    <svg
      width="18"
      height="17"
      viewBox="0 0 18 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M11.9 4.5C11.9 6.10163 10.6016 7.4 9 7.4C7.39837 7.4 6.1 6.10163 6.1 4.5C6.1 2.89837 7.39837 1.6 9 1.6C10.6016 1.6 11.9 2.89837 11.9 4.5ZM13.5 4.5C13.5 6.98528 11.4853 9 9 9C6.51472 9 4.5 6.98528 4.5 4.5C4.5 2.01472 6.51472 0 9 0C11.4853 0 13.5 2.01472 13.5 4.5ZM9 9H6C2.74927 9 0.102333 11.5852 0.00289667 14.8118C0.000970268 14.8743 0 14.937 0 15V16.4118H1.6H16.4H18V15C18 14.937 17.999 14.8743 17.9971 14.8118C17.8977 11.5852 15.2507 9 12 9H9ZM1.60395 14.8118C1.70256 12.469 3.63301 10.6 6 10.6H12C14.367 10.6 16.2974 12.469 16.396 14.8118H1.60395Z"
        fill={isContactsActive ? "#2081E3" : "#262626"}
      />
    </svg>
  );
}

export default Contact;
