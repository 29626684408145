import { updatedContactsData } from "../../../asset/documentfiles/Documents"
import { handleIsCheckedOddOrEven } from "../../../utils/Utils"
import "./UpdatedContacts.css"
const UpdatedContacts = ()=>{
    return <div className="updated-contacts">
        <div className="updated-contacts-heading">
            <h3>These are contacts that no longer want to be part of the database and opted out.</h3>
        </div>
        {/* <div className="updated-contacts-content">
        <div className="updated-contacts-content-heading">
            <p>Email</p>
        </div>
        <div className="updated-contacts-content-item">
        
        {
            updatedContactsData.map((item,index)=>{
                return <p style={{backgroundColor: `${ handleIsCheckedOddOrEven(index)? "white": "rgba(250, 250, 250, 1)"}`}}>{item?.name}</p>
            })
        }
        </div>
        <div className="updated-contacts-content-footer">
            <p>Showing 1 to 5 Result</p>
            <button>Show more</button>
        </div>
        </div> */}
    </div>
}

export default UpdatedContacts