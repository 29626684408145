import React, { useEffect, useState } from "react";
import { useLocation, useSearchParams } from "react-router-dom";
import FilterParent from "../../../../../components/filterNewComp/filterParent";
import { getViewByIdPostAPI } from "../../../../../services/servicesAPI";
import { type } from "@testing-library/user-event/dist/type";
import {
  returnDateFromTimestamp,
  returnTimeFromTimestamp,
} from "../../../../../utils/Utils";
import { chooseFilterGETApi } from "../../../../../services/servicesAPI";
import { groupGetAPI } from "../../../../../services/servicesAPI";

function SeeViews() {
  const location = useLocation();
  const id = location.state.id;
  const [groups, setGroups] = useState([]);
  const [data, setData] = useState([]);
  const [dataForMap, setDataForMap] = useState([]);
  let [formatedData, setFormatedData] = useState([]);
  const [filterOptions, setFilterOptions] = useState(null);

  const getViewByIDPostFunction = async () => {
    try {
      let res = await getViewByIdPostAPI(id, payload);
      console.log("res getViewbyId", res?.data?.view?.filters);
      if (res?.data?.view?.filters) {
        setData(res?.data);
        setDataForMap(res?.data?.view?.filters);
        let temp = formateDataFun(res?.data?.view?.filters);
        setFormatedData(temp);
        // console.log("data all view api ", res.data.view.filters);
      } else {
        // console.log("Data from API is undefined or null");
      }
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    const loadFilterOptions = async () => {
      try {
        const response = await chooseFilterGETApi();
        console.log("RESPONSE=====>", response.data.data.result);
        console.log("first useeffect");
        setFilterOptions(response.data.data.result);
      } catch (error) {}
    };
    const getGroups = async () => {
      const res = await groupGetAPI(
        { page: 1, limit: 25 },
        { sortBy: "createdAt", order: "DESC" }
      );
      setGroups(res?.data?.data?.result);
    };
    loadFilterOptions();
    getGroups();
    // getViewByIDPostFunction();
  }, []);
  useEffect(() => {
    if (filterOptions !== null) {
      getViewByIDPostFunction();
    }
  }, [filterOptions]);

  console.log("chooseFilterGroupapi", groups);
  console.log("formatedDATA_of_firlst_LIne", formatedData);

  const [payload, setPayload] = useState({
    isExpanded: false,
  });

  const createPayload = () => {
    setPayload({
      isExpanded: false,
    });
  };

  useEffect(() => {
    createPayload();
  }, []);

  /* const formateDataFun = (data) => {
    dataForMap.map((singleData, index) => {
      console.log("____________________________________________________");
      let temp = [...formatedData];
      singleData.map((value, innerIndex) => {
        console.log(value);
        if (value?.args[0] == "groups") {
            temp[index]?.rulesSelected[innerIndex]?.args[0][0]?.key = value?.args[0]
            temp[index]?.rulesSelected[innerIndex]?.args[3][0]?.key = value?.args[1]
        } else if (value?.args[0] == "fields") {
            temp[index]?.rulesSelected[innerIndex]?.args[0][0]?.key = value?.args[0]
            temp[index]?.rulesSelected[innerIndex]?.args[1][0]?.key = value?.args[1]
            temp[index]?.rulesSelected[innerIndex]?.args[3][0]?.key = value?.args[2]
        }
      });
    });
  }; */

  console.log("setFilterOptions", filterOptions);

  const formateDataFun = (data) => {
    console.log(
      "===================================>filterOptions",
      filterOptions
    );

    let operators;

    return data.map((singleData, index) => {
      return {
        index,
        options: filterOptions,
        rulesSelected: singleData.map((value) => {
          const args = [];

          if (value?.args[0] === "groups") {
            args.push([
              {
                key: "groups",
                value: "groups",
                isOptionOperator: filterOptions[1]?.isOptionOperator,
                placeholder: filterOptions[1]?.placeholder,
                options: filterOptions[1]?.options,
              },
            ]);
            args.push([]);
            args.push([]);
            args.push(value.args[1].map((group) => group));
            operators = filterOptions[1]?.options;
          } else if (value?.args[0] === "fields") {
            args.push([
              {
                key: "fields",
                value: "fields",
                isOptionOperator: filterOptions[0]?.isOptionOperator,
                placeholder: filterOptions[0]?.placeholder,
                options: filterOptions[0]?.options,
              },
            ]);
            args.push([
              {
                key: value.args[1],
                value: value.args[1],
                isOptionOperator: filterOptions[0]?.isOptionOperator,
                placeholder: filterOptions[0]?.placeholder,
                options: filterOptions[0]?.options[0]?.options,
              },
            ]);
            args.push([]);
            args.push(value.args[2]);
            operators = filterOptions[0]?.options[0]?.options;
          }

          const selectedOperator = operators.find(
            (operator) => operator.key === value?.operator
          );

          return {
            args,
            operator: [
              {
                key: value.operator,
                value: value.operator,
                hasInput: selectedOperator?.hasInput,
                hasMultiValue: selectedOperator?.hasMultiValue,
                isOptionOperator: selectedOperator?.isOptionOperator,
              },
            ],
          };
        }),
      };
    });
  };

  useEffect(() => {
    console.log("formatedData", formatedData);
  }, [formatedData, setFormatedData]);

  console.log("===formateedData", formatedData);

  return (
    <div>
      <div
        style={{
          display: "flex",
          margin: "1.2rem 0rem",
          paddingTop: "0.5rem",
          fontSize: "0.8em",
        }}
      >
        <p>Contact</p>
        <span style={{ margin: "0 0.3rem" }}> {">"} </span>
        <p>views</p>
        <span style={{ margin: "0 0.3rem" }}> {">"} </span>
        <p>{data?.view?.name}</p>
      </div>
      <h1>{data?.view?.name}</h1>
      <br></br>
      <p style={{ color: "gray" }}>
        Created{" "}
        <abbr id="group-abbr">
          {returnDateFromTimestamp(data?.view?.createdAt)}{" "}
          <span className="group-abbr-span">
            {returnTimeFromTimestamp(data?.view?.createdAt)}
          </span>{" "}
        </abbr>
      </p>
      <br></br>
      <FilterParent formatedData={formatedData} />
    </div>
  );
}

export default SeeViews;
