import React from "react";

function ProfileSVG() {

  return (
    <div>
        <svg width="16" height="14" viewBox="0 0 16 14" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fill-rule="evenodd" clip-rule="evenodd" d="M9.99999 4.19961C9.99999 5.30418 9.10456 6.19961 7.99999 6.19961C6.89542 6.19961 5.99999 5.30418 5.99999 4.19961C5.99999 3.09504 6.89542 2.19961 7.99999 2.19961C9.10456 2.19961 9.99999 3.09504 9.99999 4.19961ZM8.07573 7.79883C10.029 7.75851 11.6 6.16252 11.6 4.19961C11.6 2.21138 9.98821 0.599609 7.99999 0.599609C6.01176 0.599609 4.39999 2.21138 4.39999 4.19961C4.39999 6.16252 5.97099 7.75851 7.92424 7.79883H6.7294C4.00907 7.79883 1.71661 9.63075 1.01837 12.1282C0.876073 12.6372 0.799988 13.1738 0.799988 13.7282H2.39999H13.6H15.2C15.2 13.1738 15.1239 12.6372 14.9816 12.1282C14.2834 9.63075 11.9909 7.79883 9.27057 7.79883H8.07573ZM6.7294 9.39883H9.27057C11.0965 9.39883 12.6584 10.5292 13.2947 12.1282H2.70525C3.3416 10.5292 4.9035 9.39883 6.7294 9.39883Z" fill="#404040"/>
</svg>
    </div>
  );
}

export default ProfileSVG;