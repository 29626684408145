import { useState } from "react";
import "./AddSegmentModal.css";
import { IoSendSharp } from "react-icons/io5";
import cross from "../../asset/images/CrossBlack.png";

const AddSegmentModal = (props) => {
  const [inputValue, setInputValue] = useState("");
  const [errorMessage, setErrorMessage] = useState(false);

  const onclose = () => {
    props.setSaveModalFlag(false);
  };

  const inputHandle = (value) => {
    setInputValue(value);
    setErrorMessage(false);
    props.setFilterName(value)
  };

  const handleAddSegment = () => {
    
    if (!inputValue) {
      setErrorMessage(true);
    }else{
        props.setCreateFilter(true)
        props.createFilterAPI()
    }
  };

  return (
    <div className="addsegmentmodal">
      <div className="addsegmentmodal-content">
        <div
          className="addsegmentmodal-content-close"
          onClick={() => {
            onclose();
          }}
        >
          <div style={{background:"rgba(64, 64, 64, 1)",borderRadius:"50%", padding:"10px 10px 7px 10px"}}>
            <img  src={cross} ></img>
          </div>
        </div>
        <div className="addsegmentmodal-content-content">
          <div className="addsegmentmodal-content-content-1">
            <h3>{props.headingText}</h3>
          </div>
          <div className="addsegmentmodal-content-content-2">
            {/* <p>Are you sure you want to delete 1 group?</p> */}
            <input
              type="text"
              onChange={(e) => {
                inputHandle(e.target.value);
              }}
              placeholder="Enter View Name"
            />
            {errorMessage && (
              <p style={{ color: "red" }}>Please enter view name</p>
            )}
          </div>

          <div className="addsegmentmodal-content-content-3">
            <button
              onClick={() => {
                props.setSaveModalFlag(false);
              }}
              style={{ color: "#404040", backgroundColor: "#E5E7EB" }}
            >
              Cancel
            </button>
            <button
              onClick={() => {
                handleAddSegment();
              }}
              style={{ backgroundColor: "#2081E2", color: "#ffffff" }}
            >
              Create
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddSegmentModal;
