import React from "react";

function ContactMailSVG({style}) {
  return (
    <div style={style}>
    
    <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
<rect width="26" height="26" rx="4" fill="#2081E2"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M8.3046 8.30518H17.6945C18.3401 8.30518 18.8683 8.83336 18.8683 9.47892V16.5214C18.8683 17.1669 18.3401 17.6951 17.6945 17.6951H8.3046C7.65904 17.6951 7.13086 17.1669 7.13086 16.5214V9.47892C7.13086 8.83336 7.65904 8.30518 8.3046 8.30518Z" stroke="white" stroke-width="1.3" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M18.8683 9.47754L12.9996 13.5856L7.13086 9.47754" stroke="white" stroke-width="1.3" stroke-linecap="round" stroke-linejoin="round"/>
</svg>
    </div>
  );
}

export default ContactMailSVG;
