import { useContext, useEffect, useRef, useState } from "react";
import "./ImportUploadMD.css";
import { MdOutlineFileUpload } from "react-icons/md";
import { IoClose } from "react-icons/io5";
import { useNavigate } from "react-router-dom";
import MatchColumnsNavbarComponent from "../../components/matchColumnsNavbarComponent/MatchColumnsNavbarComponent";
import {  getPresignedUrlApi, importContactsAPI, uploadCsvFilePutAPI } from "../../services/servicesAPI";
import { toast } from "react-toastify";
import BreadCrumbsComponent from "../../components/breadCrumbsComponent/BreadCrumbsComponent";
import { MyContext } from "../../ContextAPIStore/CreateContext";
import { TiDocument } from "react-icons/ti";
import LoaderComponent from "../../components/loaderComponent/LoaderComponent";
import ProgressBarComponent from "../../components/progressBarComponent/ProgressBarComponent";






const ImportUploadMD = () => {


  const navigate = useNavigate();
  const constConfigData = useContext(MyContext)
  const [file, setFile] = useState("");
  const [chooseTypeFileFlag,setChooseTypeFileFlag] = useState(false)
  const [chooseItemValue,setChooseItemValue] = useState("")
  const [fileId,setFileId] = useState("")
  const [loader,setLoader] = useState(false)
  const [dragFileHoverFlag,setDragFileHoverFlag] = useState(false)



  const [loaderProgressBar,setLoaderProgressBar] = useState(0)




  const fileInputCSVRef = useRef(null);
  const selectAFileRef = useRef(null)

  const handleFileUpload = () => {
    fileInputCSVRef.current.click();
  };

  const handleNavigate = (fileDetails) => {
    console.log("navigate========>",fileDetails)
    // navigate("/contacts/import/status", { state: { data: {fileId:fileDetails,fromPage:"UPLOAD_MD"}} } );
    navigate("/contacts/history")

    
  };
const getPresignedURL = async (fileName)=>{
  const  response = await getPresignedUrlApi({fileName:fileName,fileType:chooseItemValue});

  if(response.success){

    return {url:response?.data?.data?.url,id:response?.data?.data?.id}
  }else{
    toast.error(response.message)
  }


}

  const uploadCsvFilePut = async (fileObject,url)=>{

    let response = await uploadCsvFilePutAPI(fileObject,url,setLoaderProgressBar)
        if(response.success){

        toast.success("File uploaded sucessfully")
        setFile(fileObject);

        
        const reader = new FileReader();
      }else{
        toast.error(response.message)
      }

  }

  const handleCsvData = async (e) => {
    
    let file = e.target.files[0]
    let urlData = await getPresignedURL(file.name)
    setFileId(urlData?.id)
    if(urlData?.url){
      uploadCsvFilePut(file,urlData?.url)
    }
  };

  const handleChooseItem = (value)=>{
    setFile("")
    setLoaderProgressBar(0)
    setChooseItemValue(value)
    setChooseTypeFileFlag(false)
  }


  const handleImportContacts = async (fileId)=>{
    setLoader(true)
    const response = await importContactsAPI(fileId)
    console.log("Response",response)
    if(response.success){
      toast.success(response.message)
      handleNavigate(response?.data?.data)
    }else{
      toast.error(response.message)
    }
    setLoader(false)
  }

  const handleDropFile = async (e)=>{
    e.preventDefault();
    if(chooseItemValue === ""){
      toast.error("Please Select a file type")
      selectAFileRef.current.focus()
      setDragFileHoverFlag(false)
    }else{
      const droppedFile = e.dataTransfer.files[0];
      if(droppedFile.type === "text/csv"){
    let urlData= await getPresignedURL(droppedFile.name)
    setFileId(urlData?.id)
    if(urlData?.url){
      uploadCsvFilePut(droppedFile,urlData?.url)
    }

      }else{
        toast.error("Only CSV file are allowed")

      }


      
    }
  }
  const handleDragOver = (e) => {
    e.preventDefault();
    setDragFileHoverFlag(true)
  };
  const handleDragLeave = () => {
    setDragFileHoverFlag(false);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      let target = event.target;
      let isInsideDropdown = target.closest('.uploadmd-dropdown-hide') 
      if (!isInsideDropdown) {
        setChooseTypeFileFlag(false);
      }
    };
  
    document.addEventListener('click', handleClickOutside);
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, []); 



  return (
    <div className="import-md" style={{fontSize:"1.2em", marginTop:"4vh"}}>
      {
      loader && <LoaderComponent />
      }
      
        <BreadCrumbsComponent routeData={[{ key:"Contacts",value:"/contacts"},{key:"Add new",}]}  />

      <div className="import-md-heading">
        <h1>Add new contacts</h1>
      </div>
      
      <MatchColumnsNavbarComponent />
      
      <div className="import-md-content">
        <div className="uploadmd">
          {/* <div className="uploadmd-1">
            <div className="uploadmd-heading">
              <h1>Import disclaimer</h1>
            </div>
            <div className="uploadmd-desc">
              <p>
                We’ll automatically clean duplicate instances of email addresses
                from the list.
              </p>
              <p>
                Importing does not send any confirmation emails to your list,
                because we trust you’ve already received permission.
              </p>
              <p>
                Make sure everyone on your list actually signed up for it and
                granted you permission to email them.
              </p>
            </div>
          </div>
          <div
            style={{
              height: "1px",
              backgroundColor: "#eaeaea",
              margin: "5vh 0px",
            }}
          /> */}
          <div className="uploadmd-2">
            <h1>Upload file</h1>
            <div className="uploadmd-dropdown-hide" >
            <p>Choose file type</p>
            <input  ref={selectAFileRef} onClick={()=>{setChooseTypeFileFlag(!chooseTypeFileFlag)}} placeholder="Select file type" value={chooseItemValue} type="text" />
            {
              chooseTypeFileFlag && <div style={{boxShadow:"0px 0px 13px 5px #e4dfdf",width:"100%",position:"absolute",zIndex:1,backgroundColor:"#FAFAFA",borderRadius:"4px",border:"1px solid #EBEDF0"}}>
                <ul style={{listStyle:"none", padding:"0px"}}>
                  <li onClick={()=>{handleChooseItem("MD")}} style={{padding:"1.5vh 1vw",fontSize:"0.8em"}}>MD</li>
                  <li onClick={()=>{handleChooseItem("PDL")}} style={{padding:"1.5vh 1vw",fontSize:"0.8em"}}>PDL</li>
                </ul>
              </div>
            }
            </div>
                  {!file ? 
                  (
                      loaderProgressBar ?
                      <div className="uploadmd-2-1-progress-bar">

                      
                      <ProgressBarComponent value={loaderProgressBar} />
                      </div>
                       :
                    <div className="uploadmd-2-1"
                    style={{
                      border:`dashed 1px ${dragFileHoverFlag?"rgba(32, 129, 226, 1)":"rgb(221, 221, 221)"}`,
                      backgroundColor:dragFileHoverFlag?"rgba(32, 129, 226, 0.1)":"rgba(250, 250, 250, 1)"
                    }}
                    onDrop={(e)=>{handleDropFile(e)}}
                    onDragLeave={handleDragLeave}
                    onDragOver={handleDragOver}
                    onClick={() => {
                      if(chooseItemValue === ""){
                        toast.error("Please Select a file type")
                        selectAFileRef.current.focus()
                      }else{
                        handleFileUpload();
                      }
                    }}
                    >
                    <div className="uploadmd-2-1-1">
                    <div className="uploadmd-2-1-1-upload-icon">
                    <div className="uploadmd-2-1-1-upload-icon-ct">
                    <div  style={{ backgroundColor: "#e8e8e8" }} >
                      <input
                        value={file}
                        onChange={(e) => {
                          
                          handleCsvData(e);
                        }}
                        ref={fileInputCSVRef}
                        accept=".csv"
                        type="file"
                        id="csvFile"
                      />
                      <MdOutlineFileUpload size={20} />
                    </div>
                    <h3>Drop a csv file</h3>
                    <p>or select if from computer</p>
                    </div>
                    </div>
                    </div>
                    </div>
                  ) 
                  : (
                    <div className="uploadmd-2-1-2"
                    >
                    <div className="uploadmd-2-1-1">
                    <div className="uploadmd-2-1-1-upload-icon">
                    <div className="uploadmd-2-1-1-upload-icon-ct-2">
                    <div style={{padding:"0.6em",borderRadius:"4px",display:"flex",justifyContent:"center",alignItems:"center",backgroundColor:"#2081E3"}}>
                      <TiDocument color="#ffffff" size={20} />
                    </div>
                      <div
                        style={{
                          display: "flex",
                          width: "100%",
                          justifyContent: "space-between",
                          alignItems: "center",
                          marginTop: "3vh"
                        }}
                      >
                        <p style={{ fontSize: "1em", color: "#2081E3" }}>
                        {
                          file?.name
                        }
                        </p>
                        <div style={{marginLeft:"10px",display:"flex",justifyContent:"center",alignItems:"center"}}>
                          <IoClose
                          size={20}
                          color="#2081E3"
                            onClick={() => {
                              setLoaderProgressBar(0)
                              setFile("");
                            }}
                          />
                        </div>
                      </div>
                      </div>
                      </div>
                      </div>
                      </div>
                  )}
            <div className="uploadmd-2-2">
              <button
              style={{backgroundColor:file?"#2081E3":"grey"}}
                disabled={!file?true:false}
                onClick={() => {
                  handleImportContacts(fileId)
                }}
              >
                {constConfigData?.import_contacts} 
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ImportUploadMD;
