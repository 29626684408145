import { useState } from "react";
import "./MergeModal.css"
import { IoClose} from "react-icons/io5";
import CrossSVG from "../../asset/svg/CrossSVG";

const MergeModal = ({headingText,onSuccess,onClose,descText,saveText = "Save",descText2,placeholder})=>{
    const [inputTextValue,setInputTextValue] = useState("")
    const [inputCheckboxValue,setInputCheckboxValue] = useState(false)

    const handleInputValue = (value)=>{
        // console.log("text",value)
        setInputTextValue(value)
    }

    const handleCheckBox = (value)=>{
        console.log("valu",value)
        setInputCheckboxValue(value)
    }

    const handleSuccess = ()=>{
        onClose();
        onSuccess(inputCheckboxValue,inputTextValue)

    }



    return <div className="mergemodal">
        <div className="mergemodal-content">
            <div className="mergemodal-content-close" >
                <div onClick={()=>{onClose()}} >
          <CrossSVG />
          </div>
            </div>
            <div className="mergemodal-content-content">
                <div className="mergemodal-content-content-1">
                    <h3>{headingText}</h3>
                </div>
                <div className="mergemodal-content-content-2">
                    <p>{descText}</p>
                    <input value={inputTextValue} onChange={(e)=>{handleInputValue(e.target.value)}} placeholder={placeholder} type="text" />
                    <div className="mergemodal-content-content-2-div">
                        <input checked={inputCheckboxValue} onClick={(e)=>{handleCheckBox(!inputCheckboxValue)}} style={{marginRight:"1vw"}} type="checkbox" name="" id="" />
                        <p>{descText2}</p>
                    </div>
                </div>
                <div className="mergemodal-content-content-3">
                    <button onClick={()=>{onClose()}} style={{color:"#404040",backgroundColor:"#E5E7EB"}}>Cancel</button>
                    <button onClick={()=>{handleSuccess()}} style={{backgroundColor:"#2081E3",color:"#ffffff"}}>{saveText}</button>
                </div>
            </div>
        

        </div>
        
        
    </div>
}

export default MergeModal