import { MdKeyboardArrowRight } from "react-icons/md"
import "./EditActivitiesDetails.css"
import {useLocation, useNavigate} from "react-router-dom"
import { MyContext } from "../../../../ContextAPIStore/CreateContext"
import { useContext } from "react"
import BreadCrumbsComponent from "../../../../components/breadCrumbsComponent/BreadCrumbsComponent"
import { returnDateFromTimestamp } from "../../../../utils/Utils"
const EditActivitiesDetails = ()=>{
  const navigate = useNavigate()
  const constConfigData = useContext(MyContext)

  const location = useLocation();
  const { state } = location;
  const res = state?.message

  const handleNavigate = (path)=>{
    navigate(path)
  }

  



    return <div className="edit-activity">
     <div style={{ height: "15px" }}></div>
      <div className="edit-activity-route">
        {/* <p onClick={()=>{handleNavigate("/accounts")}} style={{ color: "#404040E5",cursor:"pointer" }}>Accounts</p>
        <MdKeyboardArrowRight style={{ margin: "0px 10px" }} />
        <p onClick={()=>{handleNavigate("/accounts")}} style={{ color: "#404040E5",cursor:"pointer" }}>Auto activities </p>
        <MdKeyboardArrowRight style={{ margin: "0px 10px" }} />
        <p style={{ color: "#262626" }}>edit </p> */}
        <BreadCrumbsComponent routeData={[{ key:"Accounts",value:"../"},{key:"Auto Activities",value:"./"},{key:"First Label",}]}  />
      </div>
      <div style={{ height: "25px" }}></div>
      <div className="edit-activity-content">
        <div className="edit-activity-content-heading">
          <h1>{constConfigData?.Activity_Details}</h1>
        </div>


        <div className="edit-activity-content-content">


          <div className="edit-activity-content-content-row">
            <div className="edit-activity-content-content-row-left">
              <p>Label</p>
            </div>
            <div className="edit-activity-content-content-row-right">
              <p>{res?.label}</p>
            </div>
          </div>

          <div className="edit-activity-content-content-row">
            <div className="edit-activity-content-content-row-left">
              <p>Type</p>
            </div>
            <div className="edit-activity-content-content-row-right">
              <p>{res?.type}</p>
            </div>
          </div>

          <div className="edit-activity-content-content-row">
            <div className="edit-activity-content-content-row-left">
              <p>Date</p>
            </div>
            <div className="edit-activity-content-content-row-right">
              <p>{returnDateFromTimestamp(res?.date)}</p>
            </div>
          </div>

          <div className="edit-activity-content-content-row">
            <div className="edit-activity-content-content-row-left">
              <p>Activity Name</p>
            </div>
            <div className="edit-activity-content-content-row-right">
              <p>{res?.activityName}</p>
            </div>
          </div>

          <div className="edit-activity-content-content-row">
            <div className="edit-activity-content-content-row-left">
              <p>Task</p>
            </div>
            <div className="edit-activity-content-content-row-right">
              <p>{res?.task}</p>
            </div>
          </div>
          <div className="edit-activity-content-content-row">
            <div className="edit-activity-content-content-row-left">
              <p>Note</p>
            </div>
            <div className="edit-activity-content-content-row-right">
              <p>{res?.note}</p>
            </div>
          </div>
          <div className="edit-activity-content-content-row">
            <div className="edit-activity-content-content-row-left">
              <p>Contact Id</p>
            </div>
            <div className="edit-activity-content-content-row-right">
              <p>{res?.contactId }</p>
            </div>
          </div>
          <div className="edit-activity-content-content-row">
            <div className="edit-activity-content-content-row-left">
              <p>Status</p>
            </div>
            <div className="edit-activity-content-content-row-right">
              <p>{res?.status ? res?.status : "null"}</p>
            </div>
          </div>



        </div>


      </div>
    </div>
}

export default EditActivitiesDetails