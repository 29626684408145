import CrossSVG from "../../asset/svg/CrossSVG";
import "./GeneralConfirmModal.css"
import { IoClose, IoSendSharp } from "react-icons/io5";

const GeneralConfirmModal = ({handleDeleteSegment,headingText,onSuccess,onclose,descText,deleteText = "Delete"})=>{
    return <div className="generalconfirmmodal">
        <div className="generalconfirmmodal-content">
            <div className="generalconfirmmodal-content-clos" onClick={()=>{onclose()}}>
                <div >
          
          <CrossSVG />
          </div>
            </div>
            <div className="generalconfirmmodal-content-content">
                <div className="generalconfirmmodal-content-content-1">
                    <h3>{headingText}</h3>
                </div>
                <div className="generalconfirmmodal-content-content-2">
                    <p>{descText}</p>
                </div>
                <div className="generalconfirmmodal-content-content-3">
                    <button onClick={()=>{onclose()}} style={{color:"#404040",backgroundColor:"#E5E7EB"}}>Cancel</button>
                    <button onClick={()=>{onSuccess();onclose();}} style={{backgroundColor:"#EF4444",color:"#ffffff"}}>{deleteText}</button>
                </div>
            </div>
        

        </div>
        
        
    </div>
}

export default GeneralConfirmModal