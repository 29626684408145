import { NavLink } from "react-router-dom"
import "./SpecificContactNavbar.css"
const SpecificContactNavbar = ()=>{
    return <div className="specific-contact-navabar">
        <ul>
            <li><NavLink>Contact profile</NavLink></li>
        </ul>
    </div>
}

export default SpecificContactNavbar