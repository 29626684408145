import { Navigate } from "react-router-dom";

const AuthRoute = ({ children }) => {
  // let userDetails =  JSON.parse(localStorage.getItem("userDetails"));
  let sessionStorageToken = JSON.parse(sessionStorage.getItem("token"))
  let localStorageToken = JSON.parse(localStorage.getItem("token"))

  if (localStorageToken?.accessToken || sessionStorageToken?.accessToken){
    return <Navigate to="/dashboard" />
  } else {
    return children;
  }
};

export default AuthRoute;