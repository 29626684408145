import { useEffect, useRef, useState } from "react";
import "./AddModal.css";
import {
  groupGetAPI,
  postAddGroupInContactPageAPI,
} from "../../services/servicesAPI";
import { toast } from "react-toastify";
import { IoClose } from "react-icons/io5";
import SmallLoaderComponent from "../../components/smallLoaderComponent/SmallLoaderComponent";

const AddModal = ({ selectedIds, headingText, onClose,onSuccess }) => {
  console.log("selectedIds=========>", selectedIds);
  const [inputValue, setInputValue] = useState("");
  const [errorMessage, setErrorMessage] = useState(false);
  const [dropdownFlag, setDropdownFlag] = useState(false);
  const [groupData, setGroupData] = useState([]);
  const [selectedGroupId, setSelectedGroupId] = useState([]);
  const [refreshPage, setRefreshPage] = useState(false);
  const dropDownRefs = useRef(null);
  const [pagingData, setPagingData] = useState({ page: 1, limit: 1000 });
  const [smallLoaderFlag,setSmallLoaderFlag] = useState(false)
  const [filterData, setFilteredData] = useState({
    sortBy: "createdAt",
    order: "DESC",
  });

  useEffect(() => {
    fetchAllGroupAPI(pagingData, filterData);
  }, []);
  useEffect(() => {
    const handleClickOutside = (event) => {
      let target = event.target;
      let isInsideDropdown = target.closest(".add-modal-content-content-2");
      if (!isInsideDropdown) {
        setDropdownFlag(false);
      }
    };

    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  const fetchAllGroupAPI = async (pagingData, filterData) => {
    setSmallLoaderFlag(true)
    const response = await groupGetAPI(pagingData, filterData);
    // console.log("response==============>",response?.data?.data?.result)
    if (response.success) {
      toast.success(response.message);
      let temp = response?.data?.data?.result.map((item) => {
        item.isCheck = false;
        return item;
      });
      setGroupData(temp);
    } else {
      toast.error(response.message);
    }
    setSmallLoaderFlag(false)
  };

  const saveButtonPostApi = async () => {
    let onlyGroupIds = selectedGroupId.map((item) => {
      return item._id;
    });

    let temp = {
      selectAll: false,
      groupIds: onlyGroupIds,
      contactIds: selectedIds,
    };

    const response = await postAddGroupInContactPageAPI(temp);

    console.log("response=====>", response);

    if (response.success) {
      onSuccess()
      toast.success(response.message);

    } else {
      toast.error(response.message);
    }
  };

  const handleSaveButton = () => {
    if (selectedGroupId.length > 0) {
      // console.log("selected ID",selectedGroupId)

      saveButtonPostApi();
      onClose();
    } else {
      setTimeout(() => {
        setErrorMessage(false);
      }, 500);

      setErrorMessage(true);
    }
  };

  const handleInput = (value) => {
    setInputValue(value);
    if (value.length > 0) {
      setDropdownFlag(true);
    } else {
      setDropdownFlag(false);
    }
  };

  const handleCheckIdIsOrNot = (value) => {
    for (let i = 0; i < selectedGroupId.length; i++) {
      if (selectedGroupId[i]._id === value._id) {
        return { boolValue: true, index: i };
      }
    }
    return { boolValue: false, index: -1 };
  };

  const handleGroupIdSelected = (value, index) => {
    let itemCheck = handleCheckIdIsOrNot(value);
    if (itemCheck.boolValue) {
      selectedGroupId.splice(itemCheck.index, 1);
      groupData[index].isCheck = false;
    } else {
      groupData[index].isCheck = true;

      selectedGroupId.push(value);
    }
    setRefreshPage(!refreshPage);
  };

  const removeGroupId = (item, index) => {
    selectedGroupId.splice(index, 1);
    item.isCheck = false;
    setRefreshPage(!refreshPage);
  };
  const handleScroll = async () => {
    const dropdown = dropDownRefs.current;
    console.log("dropdown======>",dropdown)
    // if (!dropdown) return;
    const { scrollTop, clientHeight, scrollHeight } = dropdown;
    if (scrollTop + clientHeight >= scrollHeight - 30) {
      console.log("scrollTop",scrollTop)
      console.log("clientHeight",clientHeight)
      console.log("scrollHeight==>",scrollHeight)
      let temp = {

        page: pagingData.page + 1,
        limit: pagingData.limit,
      };
      console.log("temp=====>",temp)
      // const response = await groupGetAPI(temp, filterData);
      // if (response.success) {
      //   toast.success(response.message);
      //   let temp = response?.data?.data?.result.map((item) => {
      //     item.isCheck = false;
      //     return item;
      //   });
      //   setGroupData([...groupData, temp]);
      // } else {
      //   toast.error(response.message);
      // }
    }
  };




  // useEffect(() => {
  //   const dropdown = dropDownRefs.current;
    
  //   if (!dropdown) return;
  //   dropdown.addEventListener("scroll", handleScroll);
  //   return () => dropdown.removeEventListener("scroll", handleScroll);
  // }, []);

  const handleScrollDiv = async ()=>{
    let temp = {
      page: pagingData.page + 1,
      limit: pagingData.limit,
    };
    const response = await groupGetAPI(temp, filterData);
    if(response.success){
      console.log("response=======>",response)

    }else{
      toast.error(response.message)

    }

  }

  return (
    <div className="add-modal">
      <div className="add-modal-content">
        <div
          className="add-modal-content-close"
          onClick={() => {
            onClose();
          }}
        >
          <div>
            <p>x</p>
          </div>
        </div>
        <div className="add-modal-content-content">
          <div className="add-modal-content-content-1">
            <h3>{headingText}</h3>
          </div>
          <div className="add-modal-content-content-2">
            {selectedGroupId.map((item) => {
              return (
                <span>
                  {item?.name}{" "}
                  <IoClose
                    onClick={() => {
                      removeGroupId(item);
                    }}
                    style={{ marginLeft: "1vw", cursor: "pointer" }}
                  />{" "}
                </span>
              );
            })}

            <input
              onFocus={() => {
                setDropdownFlag(true);
              }}
              value={inputValue}
              onChange={(e) => {
                handleInput(e.target.value);
              }}
              type="text"
              placeholder="Select group"
            />
            {dropdownFlag && (
              <div
                ref={dropDownRefs}
                className="add-modal-content-content-2-dp"
              >
                {
                  smallLoaderFlag && <SmallLoaderComponent />
                }
                <ul>
                  {groupData.map((item, index) => {
                    return (
                      <li
                        onClick={() => {
                          handleGroupIdSelected(item, index);
                        }}
                      >
                        <input
                          checked={item?.isCheck}
                          style={{ marginRight: "0.7vw" }}
                          type="checkbox"
                          name=""
                          id=""
                        />
                        {item?.name}
                      </li>
                    );
                  })}
                </ul>
              </div>
              
            )}
            {errorMessage ? (
              <div
                style={{ position: "absolute", bottom: "-15px", left: "0px" }}
              >
                <p
                  style={{
                    fontSize: "0.8em",
                    color: "red",
                    textAlign: "center",
                  }}
                >
                  you are not selected any group.
                </p>
              </div>
            ) : (
              <></>
            )}
          </div>

          <div className="add-modal-content-content-3">
            <button
              onClick={() => {
                onClose();
              }}
              style={{
                color: "#404040",
                backgroundColor: "#E5E7EB",
                fontWeight: "500",
                fontSize: "1.1em",
              }}
            >
              Cancel
            </button>
            <button
              onClick={() => {
                handleSaveButton();
              }}
              style={{
                backgroundColor: "#2081E3",
                color: "#ffffff",
                fontWeight: "600",
                fontSize: "1.1em",
              }}
            >
              Save
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddModal;
