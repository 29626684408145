import { GoChevronDown } from "react-icons/go";
import "./MatchColumns.css";
import { useLocation, useNavigate } from "react-router-dom";
import { useContext, useEffect, useState } from "react";
import {
  belongstoDropDownTotalList,
  groupDataList,
} from "../../asset/documentfiles/Documents";
import { MyContext } from "../../ContextAPIStore/CreateContext";
import { IoClose, IoAddCircleOutline } from "react-icons/io5";
import {
  createGroupPostAPI,
  exportColumnGetAPI,
  groupGetAPI,
} from "../../services/servicesAPI";
import LoaderComponent from "../../components/loaderComponent/LoaderComponent";
import { toast } from "react-toastify";
import SmallLoaderComponent from "../../components/smallLoaderComponent/SmallLoaderComponent";
import { importCustomContactPostAPI } from "../../services/servicesAPI";
// import { IoAddCircleOutline } from "react-icons/io5";

const MatchColumns = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const constConfigData = useContext(MyContext);

  // console.log("location match-columns data=====>", location.state?.data?.file);

  const [csvData, setCSVData] = useState([]);
  const [belongsToFlag, setBelongsToFlag] = useState([]);
  const [radioButtonItem, setRadioButtonItem] = useState({ id: 2 });
  const [chooseInputValue, setChooseInputValue] = useState("");
  const [chooseInputValueFlag, setChooseInputValueFlag] = useState(false);
  const [selectedChooseValueData, setSelectedChooseValueData] = useState([]);
  const [groupData, setGroupData] = useState([]);
  const [groupDataInitial, setGroupDataInitial] = useState([]);
  const [refreshPage, setRefreshPage] = useState(false);
  const [belongstoDropdownList, setBelongsToDropdownList] = useState([]);
  const [belongstoDropdownInitialList, setBelongsToDropdownInitialList] =
    useState([]);
  let [
    belongstoDropdownListItemIsVisible,
    setBelongsToDropdownListItemIsVisible,
  ] = useState([]);
  const [
    belongstoDropdownListItemIsVisibleWithValue,
    setBelongstoDropdownListItemIsVisible,
  ] = useState([]);
  const [inputValue, setInputValue] = useState("");
  const [columnFieldInputValue, setColumnFieldInputValue] = useState([]);
  const [loader, setLoader] = useState(false);
  const [apiTableFirstRecord, setApiTableFirstRecord] = useState({});
  const [smallLoaderFlag, setSmallLoaderFlag] = useState(false);

  const [filterSortingPayloadGroup, setFilterSortingPayloadGroup] = useState({
    sortBy: "createdAt",
    order: "DESC",
  });

  useEffect(() => {
    setCSVData(location.state?.data?.file);
    // setGroupData(groupDataList)
    // setBelongsToDropdownInitialList(belongstoDropDownTotalList)
    // setBelongsToDropdownList(belongstoDropDownTotalList)
    getGroupAPI();
    exportColumnGet();
  }, []);

  useEffect(() => {}, [refreshPage]);

  const getGroupAPI = async (inputValue) => {
    setSmallLoaderFlag(true);
    const response = await groupGetAPI(
      { page: 1, limit: 100 },
      filterSortingPayloadGroup
    );
    if (response.success) {
      let tempInitial = response?.data?.data?.result.map((item, index) => {
        item.id = index + 1;
        if (item?.name === inputValue) {
          item.isChecked = true;
          setSelectedChooseValueData([...selectedChooseValueData, item]);
        } else {
          item.isChecked = false;
        }
        return item;
      });
      let tempFinal = response?.data?.data?.result.map((item, index) => {
        item.id = index + 1;
        if (item?.name === inputValue) {
          item.isChecked = true;
          setSelectedChooseValueData([...selectedChooseValueData, item]);
        } else {
          item.isChecked = false;
        }
        return item;
      });
      setGroupData(tempFinal);
      setGroupDataInitial(tempInitial);
    } else {
      console.log("error error========>", response);
    }
    setSmallLoaderFlag(false);
  };

  const handleCreateGroupAPI = async (inputValue) => {
    let response = await createGroupPostAPI(
      { name: inputValue },
      filterSortingPayloadGroup
    );
    if (response.success) {
      await getGroupAPI(inputValue);
      setChooseInputValueFlag(false);
    } else {
      toast.error(response.message);
      console.log("response error", response);
    }
    setChooseInputValue("");
  };

  const exportColumnGet = async () => {
    let response = await exportColumnGetAPI();
    console.log("response?.data?.result?", response);
    if (response.success) {
      setApiTableFirstRecord(response?.data?.result[0]);

      let tempInitial = response?.data?.result?.map((item, index) => {
        item.id = index + 1;
        item.isVisible = true;
        // item.isNewField = true
        return item;
      });

      let tempFinal = response?.data?.result?.map((item, index) => {
        item.isVisible = true;
        item.id = index + 1;
        // item.isNewField = true
        return item;
      });

      setBelongsToDropdownInitialList(tempInitial);
      setBelongsToDropdownList(tempFinal);
    } else {
      console.log("error===>", response.error);
    }
  };

  const handleRadioButtonItem = (value) => {
    let temp = {};
    temp.id = value;
    setRadioButtonItem(temp);
  };

  const handleChooseInputChange = (value) => {
    setChooseInputValue(value);
    if (value.length > 0) {
      let temp = groupDataInitial.filter((item) => {
        return item?.name.toUpperCase().includes(value.toUpperCase());
      });
      setGroupData(temp);
      setChooseInputValueFlag(true);
    } else {
      setChooseInputValueFlag(false);
    }
  };

  const handleSelectedChooseValueItem = (value, index) => {
    if (groupData[index].isChecked) {
      groupData[index].isChecked = false;
      handleClearSelectedData(value);
      setChooseInputValue("");
      setChooseInputValueFlag(false);
    } else {
      groupData[index].isChecked = true;
      setSelectedChooseValueData([...selectedChooseValueData, value]);
      setChooseInputValue("");
      setChooseInputValueFlag(false);
    }

    setRefreshPage(!refreshPage);
  };

  const handleClearSelectedData = (value) => {
    let temp2 = groupData.map((item) => {
      if (value?.id === item?.id) {
        item.isChecked = false;
      }
      return item;
    });
    setGroupData(temp2);

    let temp = selectedChooseValueData.filter((item) => {
      return item?.id !== value?.id;
    });
    setSelectedChooseValueData(temp);
  };

  const checkBelongsItemIsPresentOrNotInFilter = (index, belongsItem) => {
    for (
      let i = 0;
      i < belongstoDropdownListItemIsVisibleWithValue.length;
      i++
    ) {
      if (belongstoDropdownListItemIsVisibleWithValue[i].indexValue === index) {
        belongstoDropdownListItemIsVisibleWithValue[i].id = belongsItem.id;
        belongstoDropdownListItemIsVisibleWithValue[i].indexValue = index;
        belongstoDropdownListItemIsVisibleWithValue[i].columnName =
          belongsItem.columnName;
        belongstoDropdownListItemIsVisibleWithValue[i].tableColumnName =
          belongsItem.tableColumnName;

        return true;
      }
    }

    return false;
  };

  const handleBelongsTosetValue = (index, belongsItem) => {
    console.log("belongsItem======>", belongsItem);

    let tempIsVisible = checkBelongsItemIsPresentOrNotInFilter(
      index,
      belongsItem
    );

    if (tempIsVisible) {
      let tempArray1 = belongstoDropdownListItemIsVisibleWithValue.map(
        (item) => {
          return item.id;
        }
      );
      belongstoDropdownListItemIsVisible = tempArray1;

      function removeItemsById(originalArray, itemsToRemove) {
        return originalArray.filter((item) => !itemsToRemove.includes(item.id));
      }
      let tempArray = removeItemsById(belongstoDropdownInitialList, tempArray1);

      setBelongsToDropdownList(tempArray);
    } else {
      let tempObj = {
        indexValue: index,
        id: belongsItem.id,
        columnName: belongsItem.columnName,
        tableColumnName: belongsItem.tableColumnName,
        isNewField: true,
      };
      // belongsItem.indexValue = index
      belongstoDropdownListItemIsVisibleWithValue.push(tempObj);

      let tempArray1 = belongstoDropdownListItemIsVisibleWithValue.map(
        (item) => {
          return item.id;
        }
      );
      belongstoDropdownListItemIsVisible = tempArray1;

      function removeItemsById(originalArray, itemsToRemove) {
        return originalArray.filter((item) => !itemsToRemove.includes(item.id));
      }

      let tempArray = removeItemsById(
        belongstoDropdownInitialList,
        belongstoDropdownListItemIsVisible
      );

      setBelongsToDropdownList(tempArray);

      // let tempArray = belongstoDropdownInitialList.map((item)=>{

      //   belongstoDropdownListItemIsVisibleWithValue.map((innerItem)=>{
      //     if(innerItem.id === item.id){
      //       item.isDisabled = true
      //     }
      //   })
      //   return item
      // })
    }
    // belongsItem[index]
    // if(belongsItem[index]?.isNewField){
    //   belongsItem[index].isNewField = true
    // }
    belongsToFlag[index].selectValue = belongsItem.columnName;
    belongsToFlag[index].tableColumnName = belongsItem.tableColumnName;
    belongsToFlag[index].isVisible = false;
    console.log("hey belongs to flag", belongsToFlag);
    setInputValue("");
    setRefreshPage(!refreshPage);
  };

  const handleSearchInputValue = (value) => {
    setInputValue(value);
    let tempArray = belongstoDropdownInitialList.filter((item) => {
      if (item.columnName.toUpperCase().includes(value.toUpperCase())) {
        return item;
      }
    });

    let tempArray1 = belongstoDropdownListItemIsVisibleWithValue.map((item) => {
      return item.id;
    });

    function removeItemsById(originalArray, itemsToRemove) {
      return originalArray.filter((item) => !itemsToRemove.includes(item.id));
    }
    let tempArray2 = removeItemsById(tempArray, tempArray1);
    setBelongsToDropdownList(tempArray2);
  };

  const handleCheckColumnField = (index) => {
    for (let i = 0; i < columnFieldInputValue.length; i++) {
      if (index === columnFieldInputValue[i].indexColumnField) {
        return true;
      }
    }
    return false;
  };

  const handleColumnFieldInputValue = (index, columnName) => {
    // console.log("indexVa;ue",index)

    let checkBool = handleCheckColumnField(index);
    if (checkBool) {
      // console.log("inside if")
      columnFieldInputValue[index] = columnName;
    } else {
      // console.log("inside else")
      let obj = {
        indexColumnField: index,
        key: columnName,
        value: null,
      };
      setColumnFieldInputValue([...columnFieldInputValue, obj]);
    }
  };
  console.log("ColumnFieldInputValue", columnFieldInputValue);
  const handleInputValueField = (index, inputValue) => {
    // console.log("index====",index,"inputValue",inputValue)
    // console.log("columnFieldInputValue",columnFieldInputValue)
    columnFieldInputValue.map((item, innerIndex) => {
      if (item.indexColumnField === index) {
        columnFieldInputValue[innerIndex].value = inputValue;
      }
    });

    setRefreshPage(!refreshPage);
  };

  const handleCheck = (item) => {
    for (let i = 0; i < columnFieldInputValue.length; i++) {
      if (columnFieldInputValue[i].indexColumnField === item.id) {
        return { boolValue: true, value: columnFieldInputValue[i].value };
      }
    }
    return { boolValue: false };
  };

  /* const handleFinishReportButton = () => {
    console.log(
      "belongsToFlag.splice(0,belongstoDropdownListItemIsVisibleWithValue?.length)",
      belongsToFlag.slice(0, location?.state?.data?.file.length)
    );

    belongsToFlag.slice(0, location?.state?.data?.file.length).map((item) => {
      let temp = handleCheck(item);
      if (temp.boolValue) {
        item.isNewField = true;
        item.tableColumnName = temp.value;
      }
    });
    let finalTemp = belongsToFlag.slice(0, location?.state?.data?.file.length);

    // console.log("belongs to final ",finalTemp)

    let outputArray = finalTemp.filter((item) => {
      return item.selectValue !== "Skip this columns";
    });
    console.log("outputArray", outputArray);

    // let final1 = outputArray.map((item)=>{
    //   let obj = {
    //     csv_columnName: location?.state?.data?.file[item.id].keyName,
    //     belongsToTableColumnName:item.tableColumnName,
    //     table_name:apiTableFirstRecord.tableName,
    //     isNewColumn:item.isNewField
    //   };

    //   return obj
    // })

    let finalPayload = outputArray.map((item) => {
      console.log(item);
      let obj = {
        groupIds: selectedChooseValueData.map((e) => {
          return e?._id;
        }),
        columns: [
          {
            CSVColumnName: location?.state?.data?.file[item.id].keyName,
            contactColumnName: item.tableColumnName || columnFieldInputValue,
            isNewField: false,
          },
        ],
        overrideWithEmptydata: radioButtonItem?.id == 1 ? true : false,
        csvFileId: location?.state?.data?.fileId,
      };

      return obj;
    });

    console.log("final Payload to finish report", finalPayload);
  }; */

  const importCustomContactPostAPIFun = async (payload) =>{
    setLoader(true);
    const res = await importCustomContactPostAPI(payload)
    if (res.success) {
      toast.success(res.message)
      navigate(`/contacts/import/status/${res?.data?.data?._id}`)
      console.log("sendIIIDDD",res?.data?.data)
      setLoader(false);
    } else {
      console.log(res)
      if(res?.response?.data?.error?.length) {
        toast.error(res?.response?.data?.error[0])
      } else {
        toast.error(res?.response?.data?.message)
      }
      setLoader(false);
    }
  }

  

  const handleFinishReportButton = () => {
    console.log(
        "belongsToFlag.splice(0,belongstoDropdownListItemIsVisibleWithValue?.length)",
        belongsToFlag.slice(0, location?.state?.data?.file.length)
    );

    belongsToFlag.slice(0, location?.state?.data?.file.length).map((item) => {
        let temp = handleCheck(item);
        if (temp.boolValue) {
            item.isNewField = true;
            item.tableColumnName = temp.value;
        }
    });
    
    let finalTemp = belongsToFlag.slice(0, location?.state?.data?.file.length);
    let outputArray = finalTemp.filter((item) => {
        return item.selectValue !== "Skip this columns";
    });
    console.log("outputArray", outputArray);

    // Create an object to accumulate the columns
    let finalPayload = {
        groupIds: selectedChooseValueData.map((e) => e?._id),
        columns: [],
        overrideWithEmptydata: radioButtonItem?.id === 1,
        csvFileId: location?.state?.data?.fileId,
    };

    outputArray.forEach((item) => {
        console.log(item);
        let columnObj = {
            CSVColumnName: location?.state?.data?.file[item.id].keyName,
            contactColumnName: item.tableColumnName || columnFieldInputValue,
            isNewField: item.isNewField || false,
        };
        finalPayload.columns.push(columnObj);
    });

    console.log("final Payload to finish report", finalPayload);
    importCustomContactPostAPIFun(finalPayload)
};

console.log("csvFileId",location)

  return (
    <div className="match-columns" style={{ fontSize: "1.2em" }}>
      <h1>{constConfigData?.Import_Setting}</h1>
      {loader && <LoaderComponent />}

      <div className="match-columns-content">
        <div className="match-columns-content-1">
          <div className="match-columns-content-1-heading">
            <h3>{constConfigData?.Add_to_group}</h3>
            <p>You can add new subscribers to one or multiple groups</p>
          </div>
          <div className="match-columns-content-1-input">
            {/* {
              selectedChooseValueData .length > 0 &&<div className="match-columns-content-1-input-select">
                {
                  selectedChooseValueData.map((item,index)=>{
                    return (
                      <span >
                        {item?.name}
                        <IoClose
                          onClick={() => {
                            handleClearSelectedData(item);
                          }}
                          style={{
                            marginLeft: "5px",
                            cursor: "pointer",
                            color: "#404040",
                          }}
                        />
                      </span>
                    );
                  })
                }
              </div>
            } */}
            <div>
              <p style={{ fontSize: "0.8em" }}>{constConfigData?.Choose}</p>
              <div className="group-search-input">
                {selectedChooseValueData.length > 0 && (
                  <div className="match-columns-content-1-input-select">
                    {selectedChooseValueData.map((item, index) => {
                      return (
                        <span>
                          {item?.name}
                          <IoClose
                            onClick={() => {
                              handleClearSelectedData(item);
                            }}
                            style={{
                              marginLeft: "5px",
                              cursor: "pointer",
                              color: "#404040",
                            }}
                          />
                        </span>
                      );
                    })}
                  </div>
                )}
                <input
                  value={chooseInputValue}
                  onChange={(e) => {
                    handleChooseInputChange(e.target.value);
                  }}
                  placeholder="Add an existing group or create a new one"
                  type="text"
                />
              </div>
            </div>
            {chooseInputValueFlag && (
              <div className="choose-value-dropdown">
                <ul>
                  {smallLoaderFlag && <SmallLoaderComponent />}
                  {groupData.length > 0 ? (
                    groupData.map((item, index) => {
                      // console.log("inside drop down===>",item);
                      return (
                        <li
                          onClick={() => {
                            handleSelectedChooseValueItem(item, index);
                          }}
                        >
                          <input
                            checked={item?.isChecked}
                            style={{ cursor: "pointer", marginRight: "1vw" }}
                            type="checkbox"
                          />
                          {item?.name}
                        </li>
                      );
                    })
                  ) : (
                    <li
                      onClick={() => {
                        handleCreateGroupAPI(chooseInputValue);
                      }}
                      style={{
                        display: "flex",
                        alignItems: "center",
                        padding: "10px",
                      }}
                    >
                      <IoAddCircleOutline style={{ marginRight: "5px" }} />
                      <span>
                        {constConfigData?.Add_a_new_group} "{chooseInputValue}"
                      </span>
                    </li>
                  )}
                </ul>
              </div>
            )}
          </div>
        </div>
        <div
          style={{
            height: "1px",
            backgroundColor: "#D1D5DB",
            margin: "5vh 0px",
          }}
        />

        <div className="match-columns-content-2">
          <h3>{constConfigData?.Match_Fields}</h3>
          <div className="match-columns-content-2-content">
            {csvData.map((item, index) => {
              // console.log("csvData=======>")
              belongsToFlag.push({
                id: index,
                isVisible: false,
                selectValue: "Skip this columns",
              });
              // belongsToFlag.push({id:index,isVisible:false,selectValue:"A new text field"})
              // belongsToFlag.push({id:index,isVisible:false,selectValue:"A new number field"})
              // belongsToFlag.push({id:index,isVisible:false,selectValue:"A new date field"})
              // console.log("item data====>",index, item.data)
              return (
                <div className="match-columns-content-2-content-row">
                  <div className="match-columns-content-2-content-row-1">
                    <div className="match-columns-content-2-content-row-1-1">
                      <p>{item.keyName}</p>
                    </div>
                    <div className="match-columns-content-2-content-row-1-2">
                      <p>Belongs to</p>
                    </div>
                  </div>
                  <div className="match-columns-content-2-content-row-2">
                    <div className="match-columns-content-2-content-row-2-1">
                      {item.data.slice(0, 3).map((item, index) => {
                        // belongsToFlag.push({id:index,isVisible:false})

                        return <input type="text" value={item} />;
                      })}
                    </div>
                    <div className="match-columns-content-2-content-row-2-2">
                      <div
                        style={{
                          cursor: "pointer",
                          padding: "10px 12px",
                          border: "1px solid #ECEDF0",
                          color: "rgb(68, 68, 68)",
                          borderRadius: "4px",
                        }}
                        onClick={() => {
                          belongsToFlag[index].isVisible =
                            !belongsToFlag[index].isVisible;
                          setRefreshPage(!refreshPage);
                        }}
                      >
                        <p>{belongsToFlag[index].selectValue}</p>
                        <GoChevronDown />
                      </div>
                      {/* <li><input type="text" value={inputValue} onChange={(e)=>{handleSearchInputValue(e.target.value)}} placeholder="Search" /></li> */}
                      {belongsToFlag[index].isVisible && (
                        <div className="outer-belongstolist">
                          <div>
                            <ul className="outer-belongstolist-ul">
                              <li>
                                <input
                                  type="text"
                                  value={inputValue}
                                  onChange={(e) => {
                                    handleSearchInputValue(e.target.value);
                                  }}
                                  placeholder="Search"
                                />
                              </li>
                            </ul>
                          </div>
                          <div className="belongstolist">
                            <ul>
                              <li
                                onClick={() => {
                                  handleBelongsTosetValue(index, {
                                    id: index,
                                    isVisible: false,
                                    selectValue: "Skip this columns",
                                    columnName: "Skip this columns",
                                  });
                                }}
                              >
                                Skip to the columns
                              </li>
                              {belongstoDropdownList.map((belongsItem) => {
                                return (
                                  <li
                                    onClick={() => {
                                      belongsToFlag[index].isNewField = false;
                                      handleBelongsTosetValue(
                                        index,
                                        belongsItem
                                      );
                                    }}
                                  >
                                    {belongsItem?.columnName}
                                  </li>
                                );
                              })}
                              <li
                                onClick={() => {
                                  belongsToFlag[index].isNewField = true;
                                  handleColumnFieldInputValue(index, "A");
                                  handleBelongsTosetValue(index, {
                                    id: index,
                                    isVisible: false,
                                    selectValue: "A new text field",
                                    columnName: "A new text field",
                                  });
                                }}
                              >
                                A new text field
                              </li>
                              {/* <li
                                onClick={() => {
                                  belongsToFlag[index].isNewField = true;
                                  handleColumnFieldInputValue(index, "B");
                                  handleBelongsTosetValue(index, {
                                    id: index,
                                    isVisible: false,
                                    selectValue: "A new number field",
                                    columnName: "A new number field",
                                  });
                                }}
                              >
                                A new number field
                              </li> */}
                              {/* <li
                                onClick={() => {
                                  belongsToFlag[index].isNewField = true;
                                  handleColumnFieldInputValue(index, "C");
                                  handleBelongsTosetValue(index, {
                                    id: index,
                                    isVisible: false,
                                    selectValue: "A new date field",
                                    columnName: "A new date field",
                                  });
                                }}
                              >
                                A new date field
                              </li> */}
                            </ul>
                          </div>
                        </div>
                      )}
                      {belongsToFlag[index].isNewField && (
                        <div
                          style={{
                            marginTop: "2vh",
                            cursor: "pointer",
                            padding: "10px 12px",
                            border: "1px solid #ECEDF0",
                            color: "rgb(68, 68, 68)",
                            borderRadius: "4px",
                          }}
                        >
                          <input
                            onChange={(e) => {
                              handleInputValueField(index, e.target.value);
                            }}
                            style={{ width: "100%", borderRadius: "4px" }}
                            type="text"
                            placeholder="Name this field..."
                          />
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>

        <div
          style={{
            height: "1px",
            backgroundColor: "#D1D5DB",
            margin: "5vh 0px",
          }}
        />

        <div className="match-columns-content-3">
          <h3>Do you want to update subscriber fields with empty data?</h3>
          <div className="match-columns-content-3-content">
            <div
              onClick={() => {
                handleRadioButtonItem(1);
              }}
              style={{
                backgroundColor:
                  radioButtonItem.id === 1 ? "#E9F3FD" : "#ffffff",
                border: `1px solid ${
                  radioButtonItem.id === 1 ? "#2081E2" : "#D1D5DB"
                }`,
              }}
              className="match-columns-content-3-content-1"
            >
              <input
                checked={radioButtonItem.id === 1 ? true : false}
                style={{ marginRight: "10px" }}
                type="radio"
              />
              <label htmlFor="">
                Yes, update my fields with empty data (this can override any
                fields with information)
              </label>
            </div>
            <div
              onClick={() => {
                handleRadioButtonItem(2);
              }}
              style={{
                backgroundColor:
                  radioButtonItem.id === 2 ? "#E9F3FD" : "#ffffff",
                border: `1px solid ${
                  radioButtonItem.id === 2 ? "#2081E2" : "#D1D5DB"
                }`,
              }}
              className="match-columns-content-3-content-1"
            >
              <input
                checked={radioButtonItem.id === 2 ? true : false}
                style={{ marginRight: "10px" }}
                type="radio"
              />
              <label htmlFor="">No, update only filled fields</label>
            </div>
          </div>
        </div>

        <div
          style={{
            height: "1px",
            backgroundColor: "#D1D5DB",
            margin: "5vh 0px",
          }}
        />

        <div className="match-columns-content-4">
          <button
            onClick={() => {
              navigate("/contacts/import/", {
                state: { fromScreen: "ALL_SUBSCRIBER" },
              });
            }}
          >
            {constConfigData?.Cancel}
          </button>
          <button
            onClick={() => {
              handleFinishReportButton();
              // console.log("location?.state?.data?.fileId",location?.state?.data?.fileId)
              // navigate("/contacts/import/status",{state:{data:{belongsToFlag:belongsToFlag,selectedTableHeadingName:belongstoDropdownListItemIsVisibleWithValue,fileId:location?.state?.data?.fileId}}})
              // console.log("belongs to Flag",belongsToFlag.splice(0,belongstoDropdownListItemIsVisibleWithValue?.length))
              // console.log("tableHeading",belongstoDropdownListItemIsVisibleWithValue)
              // console.log("final=======>",belongstoDropdownListItemIsVisibleWithValue);
            }}
            style={{ backgroundColor: "#2081E3", color: "#ffffff" }}
          >
            {constConfigData?.Finish_Report}
          </button>
        </div>
      </div>
    </div>
  );
};

export default MatchColumns;
