import React from "react";

function ToggleOnSVG({height = 26,width = 50}) {
  return (
    <div style={{display:"flex",justifyContent:"center",alignItems:"center"}}>
      <svg
        width={width}
        height={height}
        viewBox="0 0 50 26"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect width="50" height="26" rx="13" fill="#2081E2" />
        <rect x="27" y="4" width="18" height="18" rx="9" fill="white" />
      </svg>
    </div>
  );
}

export default ToggleOnSVG;
