import { useContext, useEffect, useState } from "react";
import "./GroupComponent.css";
import { GoChevronDown } from "react-icons/go";
import RenameSVG from "../../../../../../asset/svg/RenameSVG";
import DeleteSVG from "../../../../../../asset/svg/DeleteSVG";
import RenameInputModal from "../../../../../../modal/renameInputModal/RenameInputModal";
import GeneralConfirmModal from "../../../../../../modal/generalConfirmModal/GeneralConfirmModal";
import { MyContext } from "../../../../../../ContextAPIStore/CreateContext";
import { returnDateFromTimestamp, returnTimeFromTimestamp } from "../../../../../../utils/Utils";
import SplitSVG from "../../../../../../asset/svg/SplitSVG";
import WarmupSplitSVG from "../../../../../../asset/svg/WarmupSplitSVG";
import SplitModal from "../splitModal/SplitModal";
import WarmupModal from "../warmupModal/WarmupModal";
import { useNavigate } from "react-router-dom";
const GroupComponent = ({
  index,
  item,
  setDeleteGroupFlag,
  deleteGroupFlag,
  setDeleteGroupItem,
  deleteGroupItem,
  handleUpdateGroupAPI,
  handleDeleteGroupAPI,
  handleSplitGroupAPI,
  handleWarmupSplitGroupAPI,
  refreshPage
}) => {

  const navigate = useNavigate()

  const [isCheckedFlag, setIsCheckedFlag] = useState(false);
  const [moreDropdownFlag, setMoreDropdownFlag] = useState(false);
  const [renameModalFlag,setRenameModalFlag] = useState(false)
  const [delteModalFlag,setDeleteModalFlag] = useState(false)
  const [splitModalFlag,setSplitModalFlag] = useState(false)
  const [warmupModalFlag,setWarmupModalFlag] = useState(false)
  const constConfigData = useContext(MyContext)
  useEffect(()=>{
    setIsCheckedFlag(false)

  },[refreshPage])
  useEffect(() => {
    setIsCheckedFlag(item?.isChecked);
  }, [item?.isChecked]);

  const handleToggleChecked = (index, itemId) => {
    let ind = deleteGroupItem.indexOf(itemId);
    if (ind == -1) {
      setDeleteGroupItem([...deleteGroupItem, itemId]);
    } else {
      let temp = [...deleteGroupItem];
      temp.splice(ind, 1);
      setDeleteGroupItem(temp);
    }
    setIsCheckedFlag(!isCheckedFlag);
    setDeleteGroupFlag(!deleteGroupFlag);
  };

  const handleUpdateSave = (inputValue)=>{
    handleUpdateGroupAPI(item,inputValue);
  }

  const handleDeleteGroupByIdSave = ()=>{
    handleDeleteGroupAPI(item);
  }

  const handleSplitGroupModalSave = (inputValue)=>{
    handleSplitGroupAPI(item,inputValue)

  }
  const handleWarmupSplitGroupModalSave = ()=>{
    handleWarmupSplitGroupAPI(item)
  }


  useEffect(() => {
    const handleClickOutside = (event) => {
      let target = event.target;
      let isInsideDropdown = target.closest('.groupcomponent-right-1-2')
      if (!isInsideDropdown) {
        setMoreDropdownFlag(false);
      }
    };
  
    document.addEventListener('click', handleClickOutside);
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, []); 

  const handleNavigate = (valueId)=>{
    navigate(`/contacts?type=group&id=${valueId}`)
  }
  return (
    <div className="groupcomponent">
        {
            renameModalFlag && <RenameInputModal handleSave={handleUpdateSave} headingText="Rename the group" placeholder={item?.name} onClose={()=>{setRenameModalFlag(false)}}  />
        }
        {
          delteModalFlag && <GeneralConfirmModal onSuccess={handleDeleteGroupByIdSave} headingText={`Please confirm`} descText={`Are you sure you want to delete group: ${item?.name}`} onclose={()=>{setDeleteModalFlag(false)}} />
        }
        {
          splitModalFlag && <SplitModal item={item} onSuccess={handleSplitGroupModalSave} descText={"Groups count after the split"} headingText={`Split group ${item?.name}`} onClose={()=>{setSplitModalFlag(false)}} />
        }
        {
          warmupModalFlag && <WarmupModal onSuccess={handleWarmupSplitGroupModalSave} onclose={()=>{setWarmupModalFlag(false)}} headingText={"Warmup Split"} descText={`Do you want to apply warmup split to group: ${item?.name}`} />
        }
      <div className="groupcomponent-left">
        <div className="groupcomponent-left-1">
          <input
            checked={isCheckedFlag}
            onChange={() => {
              handleToggleChecked(index, item?._id);
            }}
            style={{ marginRight: "10px" ,border:'2px solid #D1D5DB',height:'18px',width:'18px'}}
            type="checkbox"
          />
        </div>
        <div className="groupcomponent-left-2">
          <h3>{item?.name}</h3>
          <p>Created <abbr id="group-abbr">{returnDateFromTimestamp(item?.createdAt)} <span className="group-abbr-span">{returnTimeFromTimestamp(item?.createdAt)}</span> </abbr></p>
          <button onClick={()=>{handleNavigate(`${item?._id}`)}}>{constConfigData?.View_Group}</button>
        </div>
      </div>
      <div className="groupcomponent-right">
        <div
          style={{ borderLeft: "2px solid #E5E7EB" }}
          className="groupcomponent-right-1"
        >
          <div className="groupcomponent-right-1-1">
            <p>{constConfigData?.Contacts}</p>
          </div>
          <div style={{ position: "relative" }}>
            <div className="groupcomponent-right-1-2">
              <p style={{fontSize:'1em',fontWeight:'500'}}>{item?.contactCount}</p>
              <div
                onClick={() => {
                  setMoreDropdownFlag(!moreDropdownFlag);
                }}
              >
                <button>{constConfigData?.More}</button>
                <GoChevronDown />
              </div>
            </div>
            {moreDropdownFlag && (
              <div className="more-dropdown-div">
                <ul>
                  <li onClick={()=>{ setMoreDropdownFlag(false) ; setRenameModalFlag(true)}}>
                    <RenameSVG />{" "}
                    <span style={{ marginLeft: "10px",fontWeight:'400',color:'#404040' }}>{constConfigData?.Rename}</span>
                  </li>
                  {
                    item?.contactCount>0  && <li onClick={()=>{setMoreDropdownFlag(false);setSplitModalFlag(true)}}>
                    <SplitSVG/>{" "}
                    <span style={{ marginLeft: "10px",fontWeight:'400',color:'#404040' }}>{constConfigData?.Split}</span>{" "}
                  </li>
                  }
                  {
                    item?.contactCount>0 && <li onClick={()=>{ setMoreDropdownFlag(false) ; setWarmupModalFlag(true)}} >
                    <WarmupSplitSVG/>{" "}
                    <span style={{ marginLeft: "10px",fontWeight:'400',color:'#404040' }}>{constConfigData?.Warmup_split}</span>{" "}
                  </li>
                  }
                   <p style={{border:'1px solid #E5E7EB'}}></p>
                  <li onClick={()=>{ setMoreDropdownFlag(false) ; setDeleteModalFlag(true)}}>
                    <DeleteSVG />{" "}
                    <span style={{ marginLeft: "10px",fontWeight:'400',color:'#404040' }}>{constConfigData?.Delete}</span>{" "}
                  </li>
                </ul>
              </div>
            )}
          </div>
        </div>
        <div
          style={{ borderLeft: "2px solid #2081E2" }}
          className="groupcomponent-right-1"
        >
          <div className="groupcomponent-right-1-1">
            <p>
              Emails
            </p>
          </div>
          <div className="groupcomponent-right-1-2">
                <p>
                  {item?.emailCount}
                </p>
                
            </div>
        </div>
        <div
          style={{ borderLeft: "2px solid #FD8C04" }}
          className="groupcomponent-right-1"
        >
          <div className="groupcomponent-right-1-1">
            <p>
              Addresses
            </p>
          </div>
          <div className="groupcomponent-right-1-2">
                <p>{item?.addressCount}</p>
            </div>
        </div>
      </div>
    </div>
  );
};

export default GroupComponent;
