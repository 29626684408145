import { useState } from "react";
import "./ResetPassword.css";
import { FiEye, FiEyeOff } from "react-icons/fi";
import { ResetPasswordSchema } from "../../utils/ValidationSchema";
import { Formik } from "formik";
import { useNavigate, useParams } from "react-router-dom";
import loginBanner2 from "../../asset/images/loginBanner2.png";
import LoaderComponent from "../../components/loaderComponent/LoaderComponent";
import MainLogo from "../../asset/svg/MainLogo";
import { toast } from "react-toastify";
import { resetPasswordAPI } from "../../services/servicesAPI";

const ResetPassword = () => {
  const [showPasswordFlag, setShowPasswordFlag] = useState(false);
  const [showConfirmPasswordFlag, setShowConfirmPasswordFlag] = useState(false);
  const [loader, setLoader] = useState(false);
  const {token} = useParams();

  const navigate = useNavigate();

  const handleResetPasswordSubmit = async (values) => {
    try {
      if(!token) {
        toast.error('Invalid Reset Password Link');
        return;
      }
      if(!values.newPassword && !values.confirmPassword) {
        toast.error('Password and confirm password required filled');
        return;
      }
      setLoader(true);
      const req = {
        newPassword: values.newPassword,
        confirmPassword: values.confirmPassword
      }
      const res = await resetPasswordAPI(req, token);
      if(res?.statusCode === 200) {
        toast.success(res?.message || "Password changed successfully");
        navigate("/login");
        setLoader(false);
      } else {
        toast.error(res?.message || "Unable to change the password")
        setLoader(false);
      }
    } catch (error) {
      toast.error("Something went wrong");
      setLoader(false);
    }
  };

  return (
    <div className="login">
      {loader && <LoaderComponent />}
      <div className="login-content">
        <div className="login-content-logo">
          <MainLogo />
        </div>
        <div className="login-content-form">
          <div className="">
            <div style={{ height: "35px" }}></div>
            <div className="reset-password">
              <h1>Reset Password</h1>
              <p>Please enter your current password to reset your password.</p>

              <Formik
                initialValues={{
                  newPassword: "",
                  confirmPassword: "",
                }}
                validationSchema={ResetPasswordSchema}
                onSubmit={(values) => {
                  handleResetPasswordSubmit(values);
                }}
              >
                {({ handleSubmit, errors, touched, handleChange, values }) => {
                  return (
                    <div className="reset-password-content">
                      <div className="reset-password-content-1">
                        <div className="reset-password-content-row">
                          <label htmlFor="">Enter new password</label>
                          <div className="reset-password-content-row-input">
                            <input
                              id="newPassword"
                              name="newPassword"
                              value={values.newPassword}
                              onChange={handleChange}
                              placeholder="Current Password"
                              type={showPasswordFlag ? "text" : "password"}
                            />
                            {showPasswordFlag ? (
                              <div
                                onClick={() => {
                                  setShowPasswordFlag(!showPasswordFlag);
                                }}
                                className="reset-password-content-row-icon"
                              >
                                <FiEyeOff size={20} />
                              </div>
                            ) : (
                              <div
                                onClick={() => {
                                  setShowPasswordFlag(!showPasswordFlag);
                                }}
                                className="reset-password-content-row-icon"
                              >
                                <FiEye size={20} />
                              </div>
                            )}
                            {errors.newPassword && touched.newPassword ? (
                              <p
                                style={{
                                  color: "red",
                                  position: "absolute",
                                  bottom: "-20px",
                                }}
                              >
                                {errors.newPassword}
                              </p>
                            ) : (
                              <></>
                            )}
                          </div>
                        </div>
                        <div className="reset-password-content-row">
                          <label htmlFor="">Confirm new password</label>
                          <div className="reset-password-content-row-input">
                            <input
                              id="confirmPassword"
                              name="confirmPassword"
                              value={values.confirmPassword}
                              onChange={handleChange}
                              placeholder="Current Password"
                              type={
                                showConfirmPasswordFlag ? "text" : "password"
                              }
                            />
                            {showConfirmPasswordFlag ? (
                              <div
                                onClick={() => {
                                  setShowConfirmPasswordFlag(
                                    !showConfirmPasswordFlag
                                  );
                                }}
                                className="reset-password-content-row-icon"
                              >
                                <FiEyeOff size={20} />
                              </div>
                            ) : (
                              <div
                                onClick={() => {
                                  setShowConfirmPasswordFlag(
                                    !showConfirmPasswordFlag
                                  );
                                }}
                                className="reset-password-content-row-icon"
                              >
                                <FiEye size={20} />
                              </div>
                            )}
                            {errors.confirmPassword &&
                            touched.confirmPassword ? (
                              <p
                                style={{
                                  color: "red",
                                  position: "absolute",
                                  bottom: "-20px",
                                }}
                              >
                                {errors.confirmPassword}
                              </p>
                            ) : (
                              <></>
                            )}
                          </div>
                        </div>
                      </div>
                      <div className="reset-password-content-2">
                        <button
                          type="button"
                          style={{ backgroundColor: "#2081E2" }}
                          onClick={() => {
                            handleSubmit(values);
                          }}
                        >
                          Reset Password
                        </button>
                      </div>
                    </div>
                  );
                }}
              </Formik>
            </div>
          </div>
        </div>
      </div>
      <div className="login-banner">
        <img
          src={loginBanner2}
          alt="LoginBanner_image"
          height="100%"
          width="100%"
        />
      </div>
    </div>
  );
};

export default ResetPassword;
