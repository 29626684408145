import { useState } from "react";
import "./RenameInputModal.css";
import CrossSVG from "../../asset/svg/CrossSVG";
const RenameInputModal = ({
  headingText,
  placeholder="",
  onClose,
  handleSave,
  value,
}) => {
  const [inputValue, setInputValue] = useState(value);
  const [errorMessage, setErrorMessage] = useState(false);

  const handleSaveButton = () => {
    if (inputValue.length > 2) {
      handleSave(inputValue);
      onClose();
    } else {
      setTimeout(() => {
        setErrorMessage(false);
      }, 500);

      setErrorMessage(true);
    }
  };

  return (
    <div className="rename-modal">
      <div className="rename-modal-content">
        <div
          className="rename-modal-content-close"
          onClick={() => {
            onClose();
          }}
        >
          <div>
            <CrossSVG />
          </div>
        </div>
        <div className="rename-modal-content-content">
          <div className="rename-modal-content-content-1">
            <h3>{headingText}</h3>
          </div>
          <div className="rename-modal-content-content-2">
            <input
              value={inputValue}
              onChange={(e) => {
                setInputValue(e.target.value);
              }}
              type="text"
              placeholder={placeholder}
            />
            {errorMessage ? (
              <div style={{ position: "absolute", bottom: "-15px" }}>
                <p
                  style={{
                    fontSize: "0.8em",
                    color: "red",
                    textAlign: "center",
                  }}
                >
                  Group Name must be greater than 2 character.
                </p>
              </div>
            ) : (
              <></>
            )}
          </div>
          <div className="rename-modal-content-content-3">
            <button
              onClick={() => {
                onClose();
              }}
              style={{ color: "#404040", backgroundColor: "#E5E7EB" ,fontWeight:'500',fontSize:'1.1em'}}
            >
              Cancel
            </button>
            <button
              onClick={() => {
                handleSaveButton();
              }}
              style={{ backgroundColor: "#2081E3", color: "#ffffff" ,fontWeight:'600',fontSize:'1.1em'}}
            >
              Save
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RenameInputModal;
