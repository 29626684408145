import { useContext, useEffect, useState } from "react";
import "./SubscriberNavbar.css";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import { MyContext } from "../../ContextAPIStore/CreateContext";

const SubscriberNavbar = (props) => {
  const ALL_SUBSCRIBER = "ALL_SUBSCRIBER";
  const SEGMENT = "SEGMENT";
  const GROUP = "GROUP";
  const HISTORY = "HISTORY";
  const location = useLocation()
  const navigate = useNavigate()
  const [subscribeNavTabItemFlag, setSubscribeNavTabItemFlag] = useState({
    key: "ALL_SUBSCRIBER",
    value: true,
  });

  const constConfigData = useContext(MyContext)


  useEffect(()=>{
    let obj1 = { key: location?.state?.fromScreen, value: true };
      setSubscribeNavTabItemFlag(obj1);


  },[])

    const handleChangeNavigation = (value) => {
    if(value === ALL_SUBSCRIBER){
        navigate("/contacts/all",{state:{fromScreen:ALL_SUBSCRIBER}})
    }else if(value === SEGMENT){
        navigate("/contacts/segment",{state:{fromScreen:SEGMENT}})
    }
    else if(value === GROUP){
        navigate("/contacts/group",{state:{fromScreen:GROUP}})
    }
    else if(value === HISTORY){
        navigate("/contacts/history",{state:{fromScreen:HISTORY}})
    }

    };
  return (
    <div>
      {
        (!location?.search ) && <div className="subscriber-nav-tab">
        <ul>
          {/* <li
            onClick={() => {
              handleChangeNavigation(ALL_SUBSCRIBER);
            }}
            style={{
              borderBottom: `2px solid ${
                subscribeNavTabItemFlag.key === ALL_SUBSCRIBER
                  ? "#2081E3"
                  : "#F3F4F6"
              }`,
              color:subscribeNavTabItemFlag.key === ALL_SUBSCRIBER  ? "#262626" : "#404040D9"
            }}
          >
            {constConfigData?.All_Contacts}
          </li> */}
          {/* <li
            onClick={() => {
              handleChangeNavigation(SEGMENT);
            }}
            style={{
              borderBottom: `2px solid ${
                subscribeNavTabItemFlag.key === SEGMENT ? "#2081E3" : "#F3F4F6"
              }`,
              color:subscribeNavTabItemFlag.key === SEGMENT ? "#262626" : "#404040D9"
            }}
          >
            {constConfigData?.Saved_Query}
          </li> */}
          {/* <li
            onClick={() => {
              handleChangeNavigation(GROUP);
            }}
            style={{
              borderBottom: `2px solid ${
                subscribeNavTabItemFlag.key === GROUP ? "#2081E3" : "#F3F4F6"
              }`,
              color: subscribeNavTabItemFlag.key === GROUP ? "#262626" : "#404040D9"
            }}
          >
            {constConfigData?.Groups}
          </li> */}
          {/* <li
            onClick={() => {
              handleChangeNavigation(HISTORY);
            }}
            style={{
              borderBottom: `2px solid ${
                subscribeNavTabItemFlag.key === HISTORY ? "#2081E3" : "#F3F4F6"
              }`,
              color: subscribeNavTabItemFlag.key === HISTORY ? "#262626" : "#404040D9"
            }}
          >
            {constConfigData?.History}
          </li> */}
          <li><NavLink  to="/contacts" end > {constConfigData?.All_Contacts}</NavLink></li>
        <li> <NavLink   to="/contacts/segment" end >{constConfigData?.Views}</NavLink> </li>
        <li><NavLink  to="/contacts/group" end > {constConfigData?.Groups}</NavLink></li>
        <li> <NavLink   to="/contacts/history" end >{constConfigData?.History}</NavLink> </li>
        </ul>
      </div>
      }
    </div>
  );
};

export default SubscriberNavbar;
