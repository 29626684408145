export const getToken = ()=>{
    let userDetails =  JSON.parse(localStorage.getItem("userDetails"));
    if(userDetails){

    }else{
        userDetails =  JSON.parse(sessionStorage.getItem("userDetails"));
    }
    return userDetails?.accessToken
}

export const getUserDetailsFromLocalStorage = ()=>{
    let userDetails =  JSON.parse(localStorage.getItem("userDetails"));
    if(userDetails){

    }else{
        userDetails = JSON.parse(sessionStorage.getItem("userDetails"));
    }
    return userDetails
}

