const DeleteWithRedOutlineSVG = () => {
  return (
    <div>
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect width="24" height="24" rx="4" fill="#E5E7EB" fill-opacity="0.5" />
        <path
          d="M5.5 8.89529H7.45961M18.5 8.89529H16.4959M9.15392 8.89529V6.9186C9.15392 6.35383 9.71869 5.78906 10.2835 5.78906H13.6721C14.2368 5.78906 14.8016 6.35383 14.8016 6.9186V8.89529M9.15392 8.89529H14.8016M9.15392 8.89529H7.45961M14.8016 8.89529H16.4959M7.45961 8.89529C7.45961 8.89529 7.45961 15.7383 7.45961 16.5197C7.45961 17.3011 7.9679 18.214 8.87153 18.214C9.77516 18.214 12.919 18.214 14.8016 18.214C15.3664 18.214 16.4959 17.8751 16.4959 16.5197C16.4959 15.1642 16.4959 10.872 16.4959 8.89529"
          stroke="#EF4444"
          stroke-width="1.4"
          stroke-linecap="round"
        />
      </svg>
    </div>
  );
};
export default DeleteWithRedOutlineSVG;
