
import { Navigate, useLocation } from "react-router-dom";

const PrivateRoute = ({ children }) => {

  const location = useLocation()

  // let userDetails =  JSON.parse(localStorage.getItem("userDetails"));
  let sessionStorageToken = JSON.parse(sessionStorage.getItem("userDetails"))
  let localStorageToken = JSON.parse(localStorage.getItem("userDetails"))

  if (sessionStorageToken?.accessToken || localStorageToken?.accessToken ){
    if(location.pathname === "/"){
     return <Navigate to="/dashboard" />
    }else{
      return children;

    }

    
  } else {
    return <Navigate to="/login" />;
  }
};

export default PrivateRoute;
