import { MdKeyboardArrowRight } from "react-icons/md";
import "./ImportStatus.css";
import SuccessfullChecked from "../../asset/svg/SuccessfullChecked";
// import { LiaEdit } from "react-icons/lia";
import { useContext, useEffect, useState } from "react";
import { dummyDataImportStatusList } from "../../asset/documentfiles/Documents";
import DataComponent from "./dataComponent/DataComponent";
import { Outlet, useLocation, useParams } from "react-router-dom";
import { MyContext } from "../../ContextAPIStore/CreateContext";
import BreadCrumbsComponent from "../../components/breadCrumbsComponent/BreadCrumbsComponent";
import { getFileDetailsAPI } from "../../services/servicesAPI";
import { toast } from "react-toastify";
import { returnDateAndTimeFromTimestamp } from "../../utils/Utils";
import ImportStatusNavbar from "./importStatusNavbar/ImportStatusNavbar";
import UpdatedContacts from "./updatedContacts/UpdatedContacts";
import LoaderComponent from "../../components/loaderComponent/LoaderComponent";
import IbuttonSVG from "../../asset/svg/IbuttonSVG";

const ImportStatus = () => {
  const constConfigData  = useContext(MyContext)
  const location = useLocation();
  const [dataList, setDataList] = useState([]);
  const [resultData,setResultData] = useState();
  const [loader,setLoader] = useState(false)
  const {id} = useParams();

  useEffect(() => {
    // debugger
    if(id) {
      getFileDetails(id)
      setDataList(dummyDataImportStatusList);
    }
  }, [id]);

  const getFileDetails = async (fileId)=>{
    setLoader(true)

    const response = await getFileDetailsAPI(fileId)

    if(response.success){
      setResultData(response?.data?.data)
    }else{
      toast.error(response.message)
    }
    setLoader(false)
  }




  return (
    <div className="import-status" style={{fontSize:"1.1em", marginTop:"8vh"}}>
      {
        loader && <LoaderComponent />

      }
      <div className="import-status-route">
        {/* <p>{constConfigData?.Contacts}</p>
        <MdKeyboardArrowRight style={{ margin: "0px 10px" }} />
        <p>{constConfigData?.Add_New}</p>
        <MdKeyboardArrowRight style={{ margin: "0px 10px" }} />
        <p>{constConfigData?.Import_Status}</p> */}
        <BreadCrumbsComponent routeData={[{ key:"Contacts",value:"/contacts"},{key:"Add new",value:"/contacts/import/"},{key:"Import status",}]} />
      </div>
      <div className="import-status-heading">
        <h1>{constConfigData?.Import_Result}</h1>
      </div>
      {/* <MatchColumnsNavbarComponent /> */}
      {
        resultData ? <div className="import-status-content">
        <div className="import-status-content-1">
          <div style={{display:"flex",alignItems:"center",justifyContent:"space-between"}}>
          <div style={{display:"flex",alignItems:"center",justifyContent:"space-between"}}>
          <h3 >{resultData?.name}</h3>
            <div className="history-component-left-2-icon"  style={{marginLeft:"1vw",display:"flex",alignItems:"center",position:"relative"}}>
            <IbuttonSVG />
            <p className="history-component-left-2-icon-abbr" >{resultData?._id}</p>
            </div>
            </div>



          <p style={{color:"##404040",fontSize:"0.9em"}}>{returnDateAndTimeFromTimestamp(resultData?.updatedAt)}</p>

          </div>
          <div style={{backgroundColor:"#E9F3FD"}} className="import-status-content-1-error">
              <SuccessfullChecked style={{color:"#2081E2"}} />
              <p style={{color:"#2081E2"}}>Import process is completed now.</p>
          </div>
          {/* {
            location?.state?.data?.fromPage?<div style={{backgroundColor:"#E9F3FD"}} className="import-status-content-1-error">
              <SuccessfullChecked style={{color:"#2081E2"}} />
              <p style={{color:"#2081E2"}}>Import process in progressing.</p>
               </div>
            :<div style={{backgroundColor:"rgb(221, 243, 221)"}} className="import-status-content-1-error">
              <SuccessfullChecked style={{color:"rgb(64, 226, 64)"}} />
              <p style={{color:"rgb(64, 226, 64)"}}>Import process is completed now.</p>
          </div>
          } */}
          
          
        </div>
        <div className="import-status-content-2">
          <div className="import-status-content-2-1">
            <p>{constConfigData?.New_contacts_added}</p>
            <h2>{resultData?.newContacts}</h2>
          </div>
          <div
            style={{
              borderRight: "1px solid #E5E7EB",
              borderLeft: "1px solid #E5E7EB",
            }}
            className="import-status-content-2-1"
          >
            <p>{constConfigData?.Contacts_Updated}</p>
            <h2>{resultData?.existingContacts}</h2>
          </div>
          <div className="import-status-content-2-1">
            <p>
              {constConfigData?.Contacts_not_added}
              
            </p>
            <h2>{resultData?.failedContacts}</h2>
          </div>
        </div>



        <div className="import-status-content-3">
          <h1>
            {constConfigData?.Results}
          </h1>
          <ImportStatusNavbar  id={id} badEmails={resultData.badEmails} />
          <Outlet />
          {/* <UpdatedContacts /> */}


          {/* <div className="import-status-content-3-nav">
            <ul>
              <li>
                Invalid emails
                <div>1</div>
              </li>
            </ul>
          </div> */}
          {/* <div className="import-status-content-3-error-heading">
            <h3>
              We couldn’t recognise these email addresses, so they couldn’t be
              added to your list. Correct them below.
            </h3>
          </div> */}

          {/* <div className="import-status-content-3-record">
            <div className="import-status-content-3-record-heading">
              <p>Email</p>
            </div>
            <div className="import-status-content-3-record-data">
              
              {dataList.map((item, index) => {
                return (
                  <DataComponent 
                    handleUpdateData={handleUpdateData}
                    item={item}
                    index={index}
                  />
                );
              })}
            </div>
          </div> */}

          {/* <div className="import-status-content-3-footer">
            <p>{constConfigData?.Showing} 1 to 1 {constConfigData?.Results}</p>
            <button>Show more</button>
          </div> */}
        </div>
      </div> : <div className="import-status-content">
        <div className="import-status-content-1">
          <div style={{display:"flex",alignItems:"center",justifyContent:"center"}}>
            <div style={{display:"flex",alignItems:"center",justifyContent:"center"}}>
              <h3 style={{textAlign: "center"}}>No Data Found</h3>
            </div>
        </div>
      </div>
      </div>
      }
    </div>
  );
};

export default ImportStatus;
