import { useState } from "react";
import "./AddSegmentModal.css";
import { IoSendSharp } from "react-icons/io5";
import CrossSVG from "../../../../../../asset/svg/CrossSVG";

const AddSegmentModal = ({
  headingText,
  segmentFinalData,
  setSegmentFinalData,
  onclose,
  onSuccess,
  filterSortingPayloadGroup,
  getAllviewsFunction,
}) => {
  const [inputValue, setInputValue] = useState("");
  const [errorMessage, setErrorMessage] = useState(false);

  const handleAddSegment = async () => {
    if (inputValue.length > 3) {
        let res =await onSuccess({"name":inputValue})
        if(res.success){
            onclose();
            getAllviewsFunction(filterSortingPayloadGroup)
        }
    } else {
      setErrorMessage(true);
    }
  };

  return (
    <div className="addsegmentmodal">
      <div className="addsegmentmodal-content">
        <div
          className="addgroupmodal-content-close"
          onClick={() => {
            onclose();
          }}
        >
          <div>
            <CrossSVG />
          </div>
        </div>
        <div className="addsegmentmodal-content-content">
          <div className="addsegmentmodal-content-content-1">
            <h3>{headingText}</h3>
          </div>
          <div className="addsegmentmodal-content-content-2">
            {/* <p>Are you sure you want to delete 1 group?</p> */}
            <input
              value={inputValue}
              onChange={(e) => {
                setErrorMessage(false);
                setInputValue(e.target.value);
              }}
              type="text"
              placeholder="Enter view name"
            />
          </div>
          {errorMessage ? (
            <div>
              <p
                style={{ fontSize: "0.6em", color: "red", textAlign: "center" }}
              >
                View Name must be greater than 3 characeter.
              </p>
            </div>
          ) : (
            <></>
          )}
          <div className="addsegmentmodal-content-content-3">
            <button
              onClick={() => {
                onclose();
              }}
              style={{ color: "#404040", backgroundColor: "#E5E7EB" }}
            >
              Cancel
            </button>
            <button
              onClick={() => {
                handleAddSegment();
              }}
              style={{ backgroundColor: "#2081E2", color: "#ffffff" }}
            >
              Create
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddSegmentModal;
