import React from "react";

function BackupSVG() {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="24" height="24" rx="4" fill="#E5E7EB" fill-opacity="0.5" />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M13.0294 4.04281C13.2823 4.33519 13.2504 4.77725 12.958 5.03018L12.0669 5.8011C13.526 5.81463 14.954 6.27167 16.1562 7.12154C17.5847 8.13137 18.5967 9.62675 19.003 11.3283L18.3697 11.4795L19.003 11.3283C19.4094 13.0298 19.1824 14.8211 18.3644 16.3674C17.5464 17.9138 16.1934 19.1094 14.5582 19.7309C12.9229 20.3525 11.1173 20.3575 9.47869 19.7449L9.47862 19.7449C7.84008 19.1321 6.48054 17.944 5.65411 16.4022C4.82767 14.8603 4.59085 13.0703 4.98793 11.3666C5.20493 10.4355 5.60315 9.56492 6.15194 8.80078C6.37745 8.48677 6.81482 8.41503 7.12883 8.64055C7.44284 8.86606 7.51458 9.30343 7.28907 9.61744C6.84696 10.233 6.52619 10.9343 6.35139 11.6843L6.35139 11.6843C6.03152 13.0568 6.22229 14.4987 6.88803 15.7408L6.2724 16.0708L6.88804 15.7408C7.55375 16.9828 8.64893 17.9399 9.96901 18.4336C11.289 18.9271 12.7434 18.923 14.0607 18.4223C15.378 17.9216 16.4679 16.9584 17.1269 15.7128C17.7858 14.4671 17.9687 13.0242 17.6413 11.6535C17.314 10.2828 16.4988 9.07821 15.3481 8.26473L15.72 7.73866L15.3481 8.26473C14.3699 7.57327 13.2063 7.2046 12.0186 7.20082L12.795 8.01892C13.0611 8.29933 13.0495 8.74239 12.7691 9.00853C12.4887 9.27466 12.0457 9.26308 11.7795 8.98267L10.4953 7.62953C10.3569 7.49599 10.2189 7.35972 10.1107 7.22797C10.0029 7.09671 9.86527 6.90109 9.81732 6.64509C9.76308 6.35548 9.84238 6.10373 9.9571 5.90794C10.0592 5.73372 10.203 5.58 10.3357 5.44943C10.3463 5.43898 10.3573 5.42886 10.3686 5.41911L12.042 3.9714C12.3344 3.71847 12.7765 3.75044 13.0294 4.04281ZM12 15.0008C13.1046 15.0008 14 14.1054 14 13.0008C14 11.8962 13.1046 11.0008 12 11.0008C10.8954 11.0008 10 11.8962 10 13.0008C10 14.1054 10.8954 15.0008 12 15.0008Z"
        fill="#1F1F1F"
      />
    </svg>
  );
}

export default BackupSVG;
