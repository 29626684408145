import React from 'react'

const WarmupSplitSVG = () => {
  return (
    <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fill-rule="evenodd" clip-rule="evenodd" d="M14.4595 5.95923C14.7134 5.70539 14.7134 5.29383 14.4595 5.03999L12.4595 3.03999C12.2057 2.78615 11.7941 2.78615 11.5403 3.03999C11.2864 3.29383 11.2864 3.70539 11.5403 3.95923L12.4322 4.85117H12.0286C11.6696 4.85117 11.3786 5.14219 11.3786 5.50117C11.3786 5.86016 11.6696 6.15117 12.0286 6.15117H12.4291L11.5403 7.03999C11.2864 7.29383 11.2864 7.70539 11.5403 7.95923C11.7941 8.21307 12.2057 8.21307 12.4595 7.95923L14.4595 5.95923ZM4.45952 13.04C4.71336 13.2938 4.71336 13.7054 4.45952 13.9592C4.20568 14.2131 3.79412 14.2131 3.54028 13.9592L1.54028 11.9592C1.28644 11.7054 1.28644 11.2938 1.54028 11.04L3.54028 9.03999C3.79412 8.78615 4.20568 8.78615 4.45952 9.03999C4.71336 9.29383 4.71336 9.70539 4.45952 9.95923L3.56707 10.8517L4.04378 10.8518C4.40277 10.8519 4.69372 11.143 4.69363 11.5019C4.69355 11.8609 4.40247 12.1519 4.04348 12.1518L3.57122 12.1517L4.45952 13.04ZM11.5001 10.8535C11.859 10.8536 12.15 11.1447 12.1499 11.5037C12.1498 11.8627 11.8587 12.1536 11.4998 12.1535L10.5056 12.1533C10.1466 12.1532 9.85565 11.8621 9.85573 11.5031C9.85582 11.1442 10.1469 10.8532 10.5059 10.8533L11.5001 10.8535ZM8.269 10.8528C8.62799 10.8529 8.91893 11.1439 8.91885 11.5029C8.91877 11.8619 8.62769 12.1529 8.2687 12.1528L6.28036 12.1523C5.92138 12.1522 5.63043 11.8611 5.63051 11.5022C5.6306 11.1432 5.92168 10.8522 6.28066 10.8523L8.269 10.8528ZM9.72138 4.85117C10.0804 4.85117 10.3714 5.14219 10.3714 5.50117C10.3714 5.86016 10.0804 6.15117 9.72138 6.15117H7.77847C7.41949 6.15117 7.12847 5.86016 7.12847 5.50117C7.12847 5.14219 7.41949 4.85117 7.77847 4.85117L9.72138 4.85117ZM5.47126 4.85117C5.83025 4.85117 6.12126 5.14219 6.12126 5.50117C6.12126 5.86016 5.83025 6.15117 5.47126 6.15117H4.49981C4.14082 6.15117 3.84981 5.86016 3.84981 5.50117C3.84981 5.14219 4.14082 4.85117 4.49981 4.85117H5.47126Z" fill="#404040"/>
</svg>

  )
}

export default WarmupSplitSVG
