import "./Subscriber.css";
import { MdDashboardCustomize, MdOutlinePersonOutline } from "react-icons/md";
import Subscrib from "./subscriberPages/Subscribe";
import {
  Outlet,
  RouterProvider,
  createBrowserRouter,
  useLocation,
} from "react-router-dom";
import { useState } from "react";
import Dashboard from "../dashboard/Dashboard";
import { IoSettingsOutline } from "react-icons/io5";
import Accounts from "../accounts/Account";
import ConstantConfig from "../setting/ConstantConfig";
import HomeSideNavbar from "../../components/homeSideNavbar/HomeSideNavbar";

const Subscribe = () => {
  const DASHBOARD = "DASHBOARD";
  const SUBSCRIBER = "SUBSCRIBER";
  const ACCOUNTS = "ACCOUNTS";
  const CONSTANT_CONFIG = "CONSTANT_CONFIG";
  const [rootNavTabItemFlag, setRootNavTabItemFlag] = useState({
    key: "DASHBOARD",
    value: true,
  });
  let location = useLocation();
  console.log("props=========>", location);

  const handleChangeNav = (value) => {
    let obj1 = { key: value, value: true };
    setRootNavTabItemFlag(obj1);
  };

  return (
    <div >
      {/* <div className="dashboard-container-left">
        <div className="dashboard-container-left-logo">
          <p>MailerLite Logo</p>
        </div>
        <div 
        onClick={()=>{handleChangeNav(DASHBOARD)}}
        className="dashboard-container-left-item"
        style={{ borderRight: `2px solid ${
          rootNavTabItemFlag.key === DASHBOARD
            ? "#4DC36B"
            : "#F3F4F6"
        }` }}
        >
          <MdDashboardCustomize />
          <p> Dashboard</p>
        </div>
        <div
        onClick={()=>{handleChangeNav(SUBSCRIBER)}}
          className="dashboard-container-left-item"
          style={{ borderRight: `2px solid ${
            rootNavTabItemFlag.key === SUBSCRIBER
              ? "#4DC36B"
              : "#F3F4F6"
          }` }}
        >
          <MdOutlinePersonOutline />
          <p>Subscriber</p>
        </div>
        <div
        onClick={()=>{handleChangeNav(ACCOUNTS)}}
          className="dashboard-container-left-item"
          style={{ borderRight: `2px solid ${
            rootNavTabItemFlag.key === ACCOUNTS
              ? "#4DC36B"
              : "#F3F4F6"
          }` }}
        >
          <IoSettingsOutline />
          <p>Accounts</p>
        </div>
        <div
        onClick={()=>{handleChangeNav(CONSTANT_CONFIG)}}
          className="dashboard-container-left-item"
          style={{ borderRight: `2px solid ${
            rootNavTabItemFlag.key === CONSTANT_CONFIG
              ? "#4DC36B"
              : "#F3F4F6"
          }` }}
        >
          <IoSettingsOutline />
          <p>Constant Config</p>
        </div>
        <div className="dashboard-left-spacer"></div>
        <div className="dashboard-left-spacer"></div>
        <div style={{ margin: "0px 0px", padding: "15px 40px" }}>
          <div style={{ border: "1px solid #ECEDF0", padding: "10px" }}>
            <div style={{ margin: "2px 0px" }}>
              <p style={{ fontSize: "12px", color: "#404146" }}>Free trial</p>
            </div>
            <div style={{ margin: "2px 0px" }}>
              <p style={{ fontSize: "12px", color: "#404146" }}>Progress Bar</p>
            </div>
            <div
              style={{
                margin: "2px 0px",
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              <p style={{ fontSize: "12px", color: "#404146" }}>30 days left</p>
              <div
                style={{
                  marginLeft: "2px",
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <GoArrowRight />
              </div>
            </div>
          </div>
        </div>

      </div> */}

      {/* <div className="dashboard-container-right"> */}
      {/* <Subscrib /> */}

        <Outlet></Outlet>

        {
          // (()=>{
          //   if(rootNavTabItemFlag.key === DASHBOARD ){
          //     return <Dashboard />
          //   }else if(rootNavTabItemFlag.key === SUBSCRIBER){
          //     return <Subscrib />
          //   }else if(rootNavTabItemFlag.key === ACCOUNTS){
          //     return <Accounts />
          //   }else if(rootNavTabItemFlag.key === CONSTANT_CONFIG){
          //     return <ConstantConfig />
          //   }
          //   })()
        }
      {/* </div> */}
    </div>
  );
};

export default Subscribe;
