import { useContext, useEffect, useState } from "react";
import "./Segment.css";
import { IoSearchOutline } from "react-icons/io5";
import { GoChevronDown } from "react-icons/go";
import {
  HiOutlineBarsArrowDown,
  HiOutlineBarsArrowUp,
  HiOutlineDocumentDuplicate,
} from "react-icons/hi2";
import SegmentComponent from "./components/SegmentComponent";
import { segmentDataList } from "../../../../../asset/documentfiles/Documents";
import AddSegmentModal from "./addSegmentModal/AddSegmentModal";
import GeneralConfirmModal from "../../../../../modal/generalConfirmModal/GeneralConfirmModal";
import SubscriberNavbar from "../../../../../components/subscriberNavbar/SubscriberNavbar";
import { MyContext } from "../../../../../ContextAPIStore/CreateContext";
import { getAllViewsAPI } from "../../../../../services/servicesAPI";
import { deleteViewAPI } from "../../../../../services/servicesAPI";
import LoaderComponent from "../../../../../components/loaderComponent/LoaderComponent";
import { createViewPostAPI } from "../../../../../services/servicesAPI";
import { toast } from "react-toastify";

const Segment = (props) => {
  const [segmentInitialData, setSegmentInitialData] = useState([]);
  const [segmentFinalData, setSegmentFinalData] = useState([]);
  const [selectAllFlag, setSelectAllFlag] = useState(false);
  const [inputSearchSegment, setInputSearchSegment] = useState("");
  const [addsegmentmodalFlag, setAddSegmentModalFlag] = useState(false);
  const [deleteSegmentFlag, setDeleteSegmentFlag] = useState(false);
  const [deleteSegmentItem, setDeleteSegmentItem] = useState([]);
  const [deleteModalFlag, setDeleteModalFlag] = useState(false);
  // console.log("delteSegmentItem",deleteSegmentItem)
  const [dateCreatedDropdownFlag, setDateCreatedDropdownFlag] = useState(false);
  const constConfigData = useContext(MyContext);
  const [pagging, setPagging] = useState({});
  const [loaderBool, setLoaderBool] = useState(false);
  const [filterDataValue, setFilterDataValue] = useState("Date created");
  const [pagingData, setPagingData] = useState({ page: 1, limit: 25 });
  const [reverseSortingFlag, setReverseSortingFlag] = useState(false);
  const [prevNextButton, setPrevNextButton] = useState({});
  const [totalCount, setTotalCount] = useState();
  const [refreshPage, setRefreshPage] = useState(false);
  const [filterSortingPayloadGroup, setFilterSortingPayloadGroup] = useState({
    $skip: 0,
    $top: 25,
    sortBy: "createdAt",
    order: "DESC",
  });
  const [searchBool, setSearchBool] = useState()
  const createPayloadForAllfun = (data) => {};

  console.log("deleteSegmentItem",deleteSegmentItem)

  useEffect(() => {
    const handleClickOutside = (event) => {
      let target = event.target;
      let isInsideDropdown = target.closest(".segment-content-1-2-2");
      console.log("outside", isInsideDropdown);
      if (!isInsideDropdown) {
        console.log("inside", isInsideDropdown);
        setDateCreatedDropdownFlag(false);
      }
    };

    document.addEventListener("click", handleClickOutside);

    // Cleanup function to remove event listener when component unmounts
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    setSegmentInitialData(segmentDataList);
    // setSegmentFinalData(segmentDataList);
  }, []);
  useEffect(() => {}, [segmentFinalData, deleteSegmentItem]);

  const getAllviewsFunction = async (payload) => {
    console.log("payload_justFor_check",payload)
    try {
      setLoaderBool(true);
      const res = await getAllViewsAPI(payload);
      setPagging(res?.data);
      setTimeout(() => {
        setLoaderBool(false);
        setSegmentFinalData(res?.data?.result);
      },1500);
      console.log("res_of_pagination", res);
      setTotalCount(res?.data?.count)
      let obj = {};
      obj.prev = res?.data?.hasPrevPage;
      obj.next = res?.data?.hasNextPage;
      setPrevNextButton(obj);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    getAllviewsFunction(filterSortingPayloadGroup);
  }, [refreshPage]);

  useEffect(() => {
    getAllviewsFunction(filterSortingPayloadGroup)
  },[])

  const handleSearchSegment = () => {
    setSearchBool(true)
    if (inputSearchSegment) {
      let payload = filterSortingPayloadGroup;
      getAllviewsFunction({
        $skip: 0,
        $top: 3,
        sortBy: "name",
        order: "ASC",
        viewName: inputSearchSegment,
      });
    }else{
      getAllviewsFunction(filterSortingPayloadGroup)
    }
  };

  const handleSelectAllChange = (e) => {
    if(e.target.checked){
      setSelectAllFlag(false);
    }else{
      setSelectAllFlag(true);
    }
    // console.log("segmentFinalData",segmentFinalData)
    // console.log("segmentInitialData",segmentInitialData)
    // console.log("selectAllFlag",selectAllFlag)
    if (e.target.checked) {
      let res = segmentFinalData.map((e) => e._id);
      setDeleteSegmentItem(res);
      // let temp = segmentFinalData.map((item) => {
      //   item.isChecked = true;

      //   return item;
      // });
      // setSegmentFinalData(temp);
      // setDeleteSegmentFlag(true);
      // setSelectAllFlag(true);
      // let tempIndex = segmentFinalData.map((item) => {
      //   return item.id;
      // });
      // setDeleteSegmentItem(tempIndex);
    } else {
      setDeleteSegmentItem([])
      // console.log("inside else ",segmentInitialData);
      // let temp = segmentFinalData.map((item) => {
      //   item.isChecked = false;
      //   return item;
      // });
      // setSegmentFinalData(temp);
      // setDeleteSegmentFlag(false);
      // setSelectAllFlag(false);
      // setDeleteSegmentItem([]);
    }

  };

  const deleteViewApiFunction = async (payload) => {
    try {
      const res = await deleteViewAPI({
        "viewsId": deleteSegmentItem
      });
      if (res.success) {
        toast.success(res.message);
        setDeleteSegmentItem([])
      } else {
        toast.error(res.message);
      }
      getAllviewsFunction(filterSortingPayloadGroup);
    } catch (err) {
      console.log(err);
    }
  };

  const handleDeleteSegment = () => {
    deleteViewApiFunction()
  };

  const handleFilterGroupData = (listItem) => {
    setFilterDataValue(listItem.key);
    if (listItem.value === 1) {
      filterSortingPayloadGroup.sortBy = "createdAt";
      getAllviewsFunction(filterSortingPayloadGroup);
    } else if (listItem.value === 2) {
      filterSortingPayloadGroup.sortBy = "name";
      getAllviewsFunction(filterSortingPayloadGroup);
    } else {
      filterSortingPayloadGroup.sortBy = "contacts";
      getAllviewsFunction(filterSortingPayloadGroup);
    }
  };

  const handleReverseData = async () => {
    if (reverseSortingFlag) {
      filterSortingPayloadGroup.order = "ASC";
    } else {
      filterSortingPayloadGroup.order = "DESC";
    }
    // filterSortingPayloadGroup.sortBy = "name";
    await getAllviewsFunction(filterSortingPayloadGroup);
    setReverseSortingFlag(!reverseSortingFlag);
  };

  const handlePagingApiData = async (value) => {
    console.log("value======>", value);
    if (value === 1) {
      if (pagingData.page >= 2) {
        let page = pagingData.page - 1;
        let limit = pagingData.limit;
        let payload = { page: page, limit: limit };
        filterSortingPayloadGroup.$skip=pagging?.pagingCounter-limit-1
        filterSortingPayloadGroup.$top=limit
        setPagingData(payload);
        await getAllviewsFunction(filterSortingPayloadGroup);
      }
    } else {
      if (totalCount > 10 * pagingData.page) {
        let page = pagingData.page + 1;
        let limit = pagingData.limit;
        let payload = { page: page, limit: limit };
        filterSortingPayloadGroup.$skip=limit*pagging?.page
        filterSortingPayloadGroup.$top=limit
        setPagingData(payload);
        await getAllviewsFunction(filterSortingPayloadGroup);
      }
    }
  };

  const handleCreateView = async (payload) =>{
     try{
      let res = await createViewPostAPI(payload);
      if (res.success) {
        toast.success(res.message);
        getAllviewsFunction(filterSortingPayloadGroup)
      } else {
        toast.error(res.message);
      }
      setRefreshPage(!refreshPage)
      return true;
     }catch{

     }
     
  }

  return (
    <div className="segment">
      {loaderBool && <LoaderComponent />}
      <div style={{ height: "35px" }}></div>

    <div className="segment-heading">
        <div>
          <h2 style={{ fontWeight: "600",fontSize: "2.4em",
              letterSpacing: "0.8px", }}>{constConfigData?.Contacts}</h2>
        </div>
        <div className="segment-heading-right">
          <div

            onClick={() => {
              setAddSegmentModalFlag(true);
            }}
            className="segment-heading-right-2"
          >
            <p
              style={{
                fontWeight: "550",
                fontSize: "14px",
                padding: "0.13rem 0.5rem",
                color: "white",
              }}
            >
              {constConfigData?.Create_view}
            </p>
          </div>
        </div>
      </div>
      <div style={{ height: "30px" }}></div>
      <SubscriberNavbar />
      <div style={{ height: "35px" }}></div>

      <div className="segment-sorting">
        {deleteModalFlag && (
          <GeneralConfirmModal
            descText={
              "This will delete the selected contacts from your account (from all segments)."
            }
            headingText={"Delete Saved query"}
            onSuccess={handleDeleteSegment}
            onclose={() => {
              setDeleteModalFlag(false);
            }}
          />
        )}
        {addsegmentmodalFlag && (
          <AddSegmentModal
            headingText={constConfigData?.Create_view}
            segmentFinalData={segmentFinalData}
            setSegmentFinalData={setSegmentFinalData}
            handleCreateView={handleCreateView}
            onclose={() => {
              setAddSegmentModalFlag(false);
            }}
            onSuccess={createViewPostAPI}
            filterSortingPayloadGroup={filterSortingPayloadGroup}
            getAllviewsFunction = {getAllviewsFunction}
          />
        )}
      </div>
      {(segmentFinalData?.length > 0 || searchBool) ? (
        <div className="segment-content">
          <div className="segment-content-1">
            <div className="segment-content-1-1">
              { (segmentFinalData != 0) &&
                <>
                  <input
                checked={
                  deleteSegmentItem.length === segmentFinalData.length
                    ? true
                    : false
                }
                onClick={(e) => {
                  handleSelectAllChange(e);
                }}
                style={{ marginRight: "8px" }}
                type="checkbox"
              />
              <p>{constConfigData?.Select_All}</p>
                </>
              }
              {deleteSegmentItem.length > 0 && (
                <div style={{ marginLeft: "10px" }}>
                  <button
                    onClick={() => {
                      setDeleteModalFlag(true);
                    }}
                    style={{
                      backgroundColor: "#E5E7EB",
                      padding: "10px 15px",
                      border: "none",
                      borderRadius: "4px",
                      fontSize: "0.9em",
                      fontWeight: "600",
                      color: "#393A3B",
                      cursor: "pointer",
                    }}
                  >
                    {constConfigData?.Delete} ({deleteSegmentItem.length})
                  </button>
                </div>
              )}
            </div>

            <div className="segment-content-1-2">
              <div className="segment-content-1-2-1">
                <input
                  type="text"
                  placeholder="Search view"
                  value={inputSearchSegment}
                  onChange={(e) => {
                    setInputSearchSegment(e.target.value);
                  }}
                />
                <div
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    handleSearchSegment();
                  }}
                >
                  <IoSearchOutline size={18} />
                </div>
              </div>
              <div className="segment-content-1-2-2">
                <div
                  onClick={() => {
                    setDateCreatedDropdownFlag(!dateCreatedDropdownFlag);
                  }}
                  style={{
                    cursor: "pointer",
                    width: "100%",
                    height: "100%",
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <p style={{ color: "#404040" }}>{filterDataValue}</p>
                  <div>
                    <GoChevronDown />
                  </div>
                </div>
                {dateCreatedDropdownFlag && (
                  <div className="segment-content-1-2-2-dropdown">
                    <ul>
                      <li
                        onClick={() => {
                          handleFilterGroupData({
                            key: "Date Created",
                            value: 1,
                          });
                          setDateCreatedDropdownFlag(false);
                        }}
                      >
                        {constConfigData?.Date_Created}
                      </li>
                      <li
                        onClick={() => {
                          handleFilterGroupData({ key: "View Name", value: 2 });
                          setDateCreatedDropdownFlag(false);
                        }}
                      >
                        {constConfigData?.View_name}
                      </li>
                      <li
                        onClick={() => {
                          handleFilterGroupData({ key: "Contacts", value: 3 });
                          setDateCreatedDropdownFlag(false);
                        }}
                      >
                        {constConfigData?.Contacts}
                      </li>
                      {/* <li
                        onClick={() => {
                          setDateCreatedDropdownFlag(false);
                        }}
                      >
                        {constConfigData?.Open_Rate}
                      </li>
                      <li
                        onClick={() => {
                          setDateCreatedDropdownFlag(false);
                        }}
                      >
                        {constConfigData?.Click_Rate}
                      </li> */}
                    </ul>
                  </div>
                )}
              </div>

              <div
                onClick={() => {
                  handleReverseData();
                }}
                className="segment-content-1-2-3"
              >
                {reverseSortingFlag ? (
                  <HiOutlineBarsArrowUp size={18} />
                ) : (
                  <HiOutlineBarsArrowDown size={18} />
                )}
              </div>
            </div>
          </div>
          <div>
          {
              segmentFinalData == 0 && <p style={{textAlign:"center",padding:"1rem",color:"gray"}}>View not found.</p>
            }
            {segmentFinalData.map((item, index) => {
              return (
                <SegmentComponent
                  getAllviewsFunction={getAllviewsFunction}
                  filterSortingPayloadGroup = {filterSortingPayloadGroup}
                  deleteSegmentItem={deleteSegmentItem}
                  setDeleteSegmentItem={setDeleteSegmentItem}
                  deleteSegmentFlag={deleteSegmentFlag}
                  setDeleteSegmentFlag={setDeleteSegmentFlag}
                  selectAllFlag = {selectAllFlag}
                  setSelectAllFlag={setSelectAllFlag}
                  segmentFinalData={segmentFinalData}
                  key={index}
                  index={index}
                  item={item}
                />
              );
            })}

            {/* <SegmentComponent /> */}
          </div>
          <div className="segment-content-3">
            <div className="segment-content-3-1">
              <p>
                {constConfigData?.Showing} <span>{pagging?.pagingCounter}</span>{" "}
                to{" "}
                <span>
                  {pagingData.page * pagingData.limit > totalCount
                    ? totalCount
                    : pagingData.page * pagingData.limit}
                </span>{" "}
                of <span>{pagging?.count}</span> {constConfigData?.results}
              </p>
            </div>
            <div className="group-content-3-2">
              <button
                disabled={prevNextButton?.prev ? false : true}
                onClick={() => {
                  handlePagingApiData(1);
                }}
                style={{
                  backgroundColor: prevNextButton?.prev ? "#E5E7EB" : "#F2F3F5",
                  color: prevNextButton?.prev ? "#404040" : "rgb(159,159,159)",
                  cursor: prevNextButton?.prev ? "pointer" : "",
                  padding: "12px 22px",
                  fontSize: "1.2em",
                }}
              >
                {constConfigData?.Previous}
              </button>
              <button
                disabled={prevNextButton?.next ? false : true}
                onClick={() => {
                  handlePagingApiData(2);
                }}
                style={{
                  backgroundColor: prevNextButton?.next ? "#E5E7EB" : "#F2F3F5",
                  color: prevNextButton?.next ? "#404040" : "rgb(159,159,159)",
                  cursor: prevNextButton?.next ? "pointer" : "",
                  padding: "12px 22px",
                  fontSize: "1.2em",
                }}
              >
                {constConfigData?.Next}
              </button>
            </div>
          </div>
        </div>
      ) : (
        <div className="segment-no-data">
          <p
            style={{
              color: "rgba(38, 38, 38, 1),",
              fontSize: "1.5em",
              fontWeight: "500",
            }}
          >
            {constConfigData?.You_dont_have_any_saved_query}
          </p>
          <p style={{ color: "grey", padding: "0.5rem" }}>
            saved query are dynamic lists of your subscribers based on their
            attributes and behaviors.
          </p>
          <button
            onClick={() => {
              setAddSegmentModalFlag(true);
            }}
          >
            <p
              style={{
                fontWeight: "550",
                fontSize: "14px",
                padding: "0.13rem 0.5rem",
                color: "white",
              }}
            >
              {constConfigData?.Create_saved_query}
            </p>
          </button>
        </div>
      )}
    </div>
  );
};

export default Segment;
