import { Outlet } from "react-router-dom";
import HomeSideNavbar from "../../components/homeSideNavbar/HomeSideNavbar";
import SubscriberHeader from "../../components/subscriberHeader/SubscriberHeader";

const Home = () => {
  return (
    <>
    <div className="dashboard-container">
    
      <HomeSideNavbar></HomeSideNavbar>

      <div className="dashboard-container-right">
      <SubscriberHeader />
      <Outlet></Outlet>

      </div>
      
      
      </div>
    </>
  );
};

export default Home;
