const SendSVG = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_766_19457)">
        <path
          d="M21.8 5H2.19995V19H21.8V5ZM19.7218 13.1594H8.47808V15.9594L4.27808 12.4594L8.47808 8.95937V11.7594H18.3218V7.55938H19.7218V13.1594Z"
          fill="#404040"
        />
      </g>
      <defs>
        <clipPath id="clip0_766_19457">
          <rect
            x="2.19995"
            y="5"
            width="19.6"
            height="14"
            rx="2"
            fill="white"
          />
        </clipPath>
      </defs>
    </svg>
  );
};
export default SendSVG;
