// export const data = [
//   {
//     id:1,
//     categoryName: "Active",
//     subscriber: "Active@oodles.io",
//     sent: 0,
//     open: 0,
//     clicks: 0,
//     subscribed: "2024-03-06 08:52:24",
//     location: "Delhi",
//   },
//   {
//     id:2,
//     categoryName: "Unsubcribed",
//     subscriber: "Unsubcribed@oodles.io",
//     sent: 0,
//     open: 0,
//     clicks: 0,
//     subscribed: "2024-03-06 08:52:24",
//     location: "Delhi",
//   },
//   {
//     id:1,
//     categoryName: "Active",
//     subscriber: "Active@oodles.io",
//     sent: 0,
//     open: 0,
//     clicks: 0,
//     subscribed: "2024-03-06 08:52:24",
//     location: "Delhi",
//   },
//   {
//     id:2,
//     categoryName: "Unsubcribed",
//     subscriber: "Unsubcribed@oodles.io",
//     sent: 0,
//     open: 0,
//     clicks: 0,
//     subscribed: "2024-03-06 08:52:24",
//     location: "Delhi",
//   },
//   {
//     id:3,
//     categoryName: "Bounced",
//     subscriber: "Bounced@oodles.io",
//     sent: 0,
//     open: 0,
//     clicks: 0,
//     subscribed: "2024-03-06 08:52:24",
//     location: "Delhi",
//   },

//   {
//     id:4,
//     categoryName: "Spam complaints",
//     subscriber: "Spam complaints@oodles.io",
//     sent: 0,
//     open: 0,
//     clicks: 0,
//     subscribed: "2024-03-06 08:52:24",
//     location: "Delhi",
//   },
//   {
//     id:5,
//     categoryName: "Uncofirmed",
//     subscriber: "Uncofirmed@oodles.io",
//     sent: 0,
//     open: 0,
//     clicks: 0,
//     subscribed: "2024-03-06 08:52:24",
//     location: "Delhi",
//   },
// ];

export const toogleColumnsItemList = [
  {
    key: "First Name",
    value: false,
  },
  {
    key: "Last Name",
    value: false,
  },
  {
    key: "Address",
    value: false,
  },
];

export const searchFilterList = [
  {
    id: 1,
    categoryName: "Email",
    chooseAnOperatorStatus: true,
    chooseAnOperator: [
      {
        chooseAnOperatorKey: "Equals",
        chooseAnOperatorInputStatus: true,
      },
      {
        chooseAnOperatorKey: "Does not Equal",
        chooseAnOperatorInputStatus: true,
      },
      {
        chooseAnOperatorKey: "Contains",
        chooseAnOperatorInputStatus: true,
      },
      {
        chooseAnOperatorKey: "Does not contain",
        chooseAnOperatorInputStatus: false,
      },
    ],
    // inputFieldStatus:true,
  },
  {
    id: 2,
    categoryName: "Group",
    chooseAnOperatorStatus: true,
    chooseAnOperator: [
      {
        chooseAnOperatorKey: "Belongs to",
        chooseAnOperatorInputStatus: true,
      },
      {
        chooseAnOperatorKey: "Does not belong to",
        chooseAnOperatorInputStatus: true,
      },
      {
        chooseAnOperatorKey: "Belongs to any",
        chooseAnOperatorInputStatus: true,
      },
      {
        chooseAnOperatorKey: "Does not belong to any",
        chooseAnOperatorInputStatus: true,
      },
      {
        chooseAnOperatorKey: "No group",
        chooseAnOperatorInputStatus: false,
      },
    ],
    // inputFieldStatus:true,
  },
  {
    id: 3,
    categoryName: "Fields",
    chooseAnOperatorStatus: true,
    chooseAnOperator: [
      {
        chooseAnOperatorKey: "City",
        // chooseAnOperatorChooseValueStatus,
        chooseAnOperatorChooseValueStatus: true,
        chooseAnOperatorChooseValue: [
          {
            chooseAnOperatorChooseValueKey: "Equals",
            chooseAnOperatorChooseValueKeyInputStatus: true,
          },
          {
            chooseAnOperatorChooseValueKey: "Does not equals",
            chooseAnOperatorChooseValueKeyInputStatus: true,
          },
          {
            chooseAnOperatorChooseValueKey: "Contains",
            chooseAnOperatorChooseValueKeyInputStatus: true,
          },
          {
            chooseAnOperatorChooseValueKey: "Does not contain",
            chooseAnOperatorChooseValueKeyInputStatus: true,
          },
          {
            chooseAnOperatorChooseValueKey: "Is set",
            chooseAnOperatorChooseValueKeyInputStatus: false,
          },
          {
            chooseAnOperatorChooseValueKey: "Is not set",
            chooseAnOperatorChooseValueKeyInputStatus: false,
          },
        ],
      },
      {
        chooseAnOperatorKey: "Company",
        // chooseAnOperatorChooseValueStatus,
        chooseAnOperatorChooseValueStatus: true,
        chooseAnOperatorChooseValue: [
          {
            chooseAnOperatorChooseValueKey: "Equals",
            chooseAnOperatorChooseValueKeyInputStatus: true,
          },
          {
            chooseAnOperatorChooseValueKey: "Does not equals",
            chooseAnOperatorChooseValueKeyInputStatus: true,
          },
          {
            chooseAnOperatorChooseValueKey: "Contains",
            chooseAnOperatorChooseValueKeyInputStatus: true,
          },
          {
            chooseAnOperatorChooseValueKey: "Does not contain",
            chooseAnOperatorChooseValueKeyInputStatus: true,
          },
          {
            chooseAnOperatorChooseValueKey: "Is set",
            chooseAnOperatorChooseValueKeyInputStatus: false,
          },
          {
            chooseAnOperatorChooseValueKey: "Is not set",
            chooseAnOperatorChooseValueKeyInputStatus: false,
          },
        ],
      },
      {
        chooseAnOperatorKey: "Country",
        // chooseAnOperatorChooseValueStatus,
        chooseAnOperatorChooseValueStatus: true,
        chooseAnOperatorChooseValue: [
          {
            chooseAnOperatorChooseValueKey: "Equals",
            chooseAnOperatorChooseValueKeyInputStatus: true,
          },
          {
            chooseAnOperatorChooseValueKey: "Does not equals",
            chooseAnOperatorChooseValueKeyInputStatus: true,
          },
          {
            chooseAnOperatorChooseValueKey: "Contains",
            chooseAnOperatorChooseValueKeyInputStatus: true,
          },
          {
            chooseAnOperatorChooseValueKey: "Does not contain",
            chooseAnOperatorChooseValueKeyInputStatus: true,
          },
          {
            chooseAnOperatorChooseValueKey: "Is set",
            chooseAnOperatorChooseValueKeyInputStatus: false,
          },
          {
            chooseAnOperatorChooseValueKey: "Is not set",
            chooseAnOperatorChooseValueKeyInputStatus: false,
          },
        ],
      },
      {
        chooseAnOperatorKey: "Last name",
        // chooseAnOperatorChooseValueStatus,
        chooseAnOperatorChooseValueStatus: true,
        chooseAnOperatorChooseValue: [
          {
            chooseAnOperatorChooseValueKey: "Equals",
            chooseAnOperatorChooseValueKeyInputStatus: true,
          },
          {
            chooseAnOperatorChooseValueKey: "Does not equals",
            chooseAnOperatorChooseValueKeyInputStatus: true,
          },
          {
            chooseAnOperatorChooseValueKey: "Contains",
            chooseAnOperatorChooseValueKeyInputStatus: true,
          },
          {
            chooseAnOperatorChooseValueKey: "Does not contain",
            chooseAnOperatorChooseValueKeyInputStatus: true,
          },
          {
            chooseAnOperatorChooseValueKey: "Is set",
            chooseAnOperatorChooseValueKeyInputStatus: false,
          },
          {
            chooseAnOperatorChooseValueKey: "Is not set",
            chooseAnOperatorChooseValueKeyInputStatus: false,
          },
        ],
      },
      {
        chooseAnOperatorKey: "Name",
        // chooseAnOperatorChooseValueStatus,
        chooseAnOperatorChooseValueStatus: true,
        chooseAnOperatorChooseValue: [
          {
            chooseAnOperatorChooseValueKey: "Equals",
            chooseAnOperatorChooseValueKeyInputStatus: true,
          },
          {
            chooseAnOperatorChooseValueKey: "Does not equals",
            chooseAnOperatorChooseValueKeyInputStatus: true,
          },
          {
            chooseAnOperatorChooseValueKey: "Contains",
            chooseAnOperatorChooseValueKeyInputStatus: true,
          },
          {
            chooseAnOperatorChooseValueKey: "Does not contain",
            chooseAnOperatorChooseValueKeyInputStatus: true,
          },
          {
            chooseAnOperatorChooseValueKey: "Is set",
            chooseAnOperatorChooseValueKeyInputStatus: false,
          },
          {
            chooseAnOperatorChooseValueKey: "Is not set",
            chooseAnOperatorChooseValueKeyInputStatus: false,
          },
        ],
      },
      {
        chooseAnOperatorKey: "Phone",
        // chooseAnOperatorChooseValueStatus,
        chooseAnOperatorChooseValueStatus: true,
        chooseAnOperatorChooseValue: [
          {
            chooseAnOperatorChooseValueKey: "Equals",
            chooseAnOperatorChooseValueKeyInputStatus: true,
          },
          {
            chooseAnOperatorChooseValueKey: "Does not equals",
            chooseAnOperatorChooseValueKeyInputStatus: true,
          },
          {
            chooseAnOperatorChooseValueKey: "Contains",
            chooseAnOperatorChooseValueKeyInputStatus: true,
          },
          {
            chooseAnOperatorChooseValueKey: "Does not contain",
            chooseAnOperatorChooseValueKeyInputStatus: true,
          },
          {
            chooseAnOperatorChooseValueKey: "Is set",
            chooseAnOperatorChooseValueKeyInputStatus: false,
          },
          {
            chooseAnOperatorChooseValueKey: "Is not set",
            chooseAnOperatorChooseValueKeyInputStatus: false,
          },
        ],
      },
      {
        chooseAnOperatorKey: "State",
        // chooseAnOperatorChooseValueStatus,
        chooseAnOperatorChooseValueStatus: true,
        chooseAnOperatorChooseValue: [
          {
            chooseAnOperatorChooseValueKey: "Equals",
            chooseAnOperatorChooseValueKeyInputStatus: true,
          },
          {
            chooseAnOperatorChooseValueKey: "Does not equals",
            chooseAnOperatorChooseValueKeyInputStatus: true,
          },
          {
            chooseAnOperatorChooseValueKey: "Contains",
            chooseAnOperatorChooseValueKeyInputStatus: true,
          },
          {
            chooseAnOperatorChooseValueKey: "Does not contain",
            chooseAnOperatorChooseValueKeyInputStatus: true,
          },
          {
            chooseAnOperatorChooseValueKey: "Is set",
            chooseAnOperatorChooseValueKeyInputStatus: false,
          },
          {
            chooseAnOperatorChooseValueKey: "Is not set",
            chooseAnOperatorChooseValueKeyInputStatus: false,
          },
        ],
      },
      {
        chooseAnOperatorKey: "Zip",
        // chooseAnOperatorChooseValueStatus,
        chooseAnOperatorChooseValueStatus: true,
        chooseAnOperatorChooseValue: [
          {
            chooseAnOperatorChooseValueKey: "Equals",
            chooseAnOperatorChooseValueKeyInputStatus: true,
          },
          {
            chooseAnOperatorChooseValueKey: "Does not equals",
            chooseAnOperatorChooseValueKeyInputStatus: true,
          },
          {
            chooseAnOperatorChooseValueKey: "Contains",
            chooseAnOperatorChooseValueKeyInputStatus: true,
          },
          {
            chooseAnOperatorChooseValueKey: "Does not contain",
            chooseAnOperatorChooseValueKeyInputStatus: true,
          },
          {
            chooseAnOperatorChooseValueKey: "Is set",
            chooseAnOperatorChooseValueKeyInputStatus: false,
          },
          {
            chooseAnOperatorChooseValueKey: "Is not set",
            chooseAnOperatorChooseValueKeyInputStatus: false,
          },
        ],
      },
    ],
  },
  {
    id: 4,
    categoryName: "Signup Forces",
    chooseAnOperatorStatus: true,
    chooseAnOperator: [
      {
        chooseAnOperatorKey: "Is",
        chooseAnOperatorChooseValueStatus: true,
        chooseAnOperatorChooseValue: [
          {
            chooseAnOperatorChooseValueKey: "Added manually",
          },
          {
            chooseAnOperatorChooseValueKey: "Imported from file",
          },
          {
            chooseAnOperatorChooseValueKey: "Added using Webform",
          },
          {
            chooseAnOperatorChooseValueKey: "Added using API",
          },
          {
            chooseAnOperatorChooseValueKey: "Added using the Subscriber app",
          },
        ],
      },
      {
        chooseAnOperatorKey: "Is Not",
        chooseAnOperatorChooseValueStatus: true,
        chooseAnOperatorChooseValue: [
          {
            chooseAnOperatorChooseValueKey: "Added manually",
          },
          {
            chooseAnOperatorChooseValueKey: "Imported from file",
          },
          {
            chooseAnOperatorChooseValueKey: "Added using Webform",
          },
          {
            chooseAnOperatorChooseValueKey: "Added using API",
          },
          {
            chooseAnOperatorChooseValueKey: "Added using the Subscriber app",
          },
        ],
      },
    ],
  },
  {
    id: 5,
    categoryName: "Location",
    chooseAnOperatorStatus: true,
    chooseAnOperator: [
      {
        chooseAnOperatorKey: "Is",
        chooseAnOperatorChooseValueStatus: true,
        chooseAnOperatorChooseValue: [
          {
            chooseAnOperatorChooseValueKey: "Afganistan",
          },
          {
            chooseAnOperatorChooseValueKey: "India",
          },
        ],
      },
      {
        chooseAnOperatorKey: "Is Not",
        chooseAnOperatorChooseValueStatus: true,
        chooseAnOperatorChooseValue: [
          {
            chooseAnOperatorChooseValueKey: "Afganistan",
          },
          {
            chooseAnOperatorChooseValueKey: "India",
          },
        ],
      },
      {
        chooseAnOperatorKey: "Set",
        chooseAnOperatorChooseValueStatus: false,
      },
      {
        chooseAnOperatorKey: "Is Set Not",
        chooseAnOperatorChooseValueStatus: false,
      },
    ],
  },
  {
    id: 6,
    categoryName: "Signup Date",
    chooseAnOperatorStatus: true,
    chooseAnOperator: [
      {
        chooseAnOperatorKey: "Is before",
        chooseAnOperatorCalenderStatus: true,
      },
      {
        chooseAnOperatorKey: "Is after",
        chooseAnOperatorCalenderStatus: true,
      },
      {
        chooseAnOperatorKey: "Is in the last",
        chooseAnOperatorCalenderStatus: false,
        chooseAnOperatorInputDaysStatus: true,
        chooseAnOperatorChooseValueStatus: true,
        chooseAnOperatorChooseValue: [
          {
            chooseAnOperatorChooseValueKey: "Minutes",
          },
          {
            chooseAnOperatorChooseValueKey: "Hours",
          },
          {
            chooseAnOperatorChooseValueKey: "Days",
          },
        ],
      },
      {
        chooseAnOperatorKey: "Is not in the last",
        chooseAnOperatorCalenderStatus: false,
        chooseAnOperatorInputDaysStatus: true,
        chooseAnOperatorChooseValueStatus: true,
        chooseAnOperatorChooseValue: [
          {
            chooseAnOperatorChooseValueKey: "Minutes",
          },
          {
            chooseAnOperatorChooseValueKey: "Hours",
          },
          {
            chooseAnOperatorChooseValueKey: "Days",
          },
        ],
      },
    ],
  },
  {
    id: 7,
    categoryName: "Time zone",
    chooseAnOperatorStatus: true,
    chooseAnOperator: [
      {
        chooseAnOperatorKey: "Is",
        chooseAnOperatorChooseValueStatus: true,
        chooseAnOperatorChooseValue: [
          {
            chooseAnOperatorChooseValueKey: "Africa/Abidjan(+00:00)",
          },
          {
            chooseAnOperatorChooseValueKey: "Africa/Accra(+00:00)",
          },
        ],
      },
      {
        chooseAnOperatorKey: "Is not",
        chooseAnOperatorChooseValueStatus: true,
        chooseAnOperatorChooseValue: [
          {
            chooseAnOperatorChooseValueKey: "Africa/Abidjan(+00:00)",
          },
          {
            chooseAnOperatorChooseValueKey: "Africa/Accra(+00:00)",
          },
        ],
      },
      {
        chooseAnOperatorKey: "Is set",
        chooseAnOperatorChooseValueStatus: false,
      },
      {
        chooseAnOperatorKey: "Is not set",
        chooseAnOperatorChooseValueStatus: false,
      },
    ],
  },
  {
    id: 8,
    categoryName: "Campagins",
    chooseAnOperatorStatus: true,
    chooseAnOperator: [
      {
        chooseAnOperatorKey: "Was sent",
        chooseAnOperatorChooseValueStatus: true,
        chooseAnOperatorChooseValue: [
          {
            chooseAnOperatorChooseValueKey: "Any campagins",
          },
        ],
      },
      {
        chooseAnOperatorKey: "Was not sent",
        chooseAnOperatorChooseValueStatus: true,
        chooseAnOperatorChooseValue: [
          {
            chooseAnOperatorChooseValueKey: "Any campagins",
          },
        ],
      },
      {
        chooseAnOperatorKey: "Was opened",
        chooseAnOperatorChooseValueStatus: true,
        chooseAnOperatorChooseValue: [
          {
            chooseAnOperatorChooseValueKey: "Any campagins",
          },
        ],
      },
      {
        chooseAnOperatorKey: "Was not opened",
        chooseAnOperatorChooseValueStatus: true,
        chooseAnOperatorChooseValue: [
          {
            chooseAnOperatorChooseValueKey: "Any campagins",
          },
        ],
      },
      {
        chooseAnOperatorKey: "Was clicked",
        chooseAnOperatorChooseValueStatus: true,
        chooseAnOperatorChooseValue: [
          {
            chooseAnOperatorChooseValueKey: "Any campagins",
          },
        ],
      },
      {
        chooseAnOperatorKey: "Was not clicked",
        chooseAnOperatorChooseValueStatus: true,
        chooseAnOperatorChooseValue: [
          {
            chooseAnOperatorChooseValueKey: "Any campagins",
          },
        ],
      },
    ],
  },
  {
    id: 9,
    categoryName: "Automation workflow",
    chooseAnOperatorStatus: true,
    chooseAnOperator: [
      {
        chooseAnOperatorKey: "Active in",
        chooseAnOperatorChooseValueStatus: true,
        chooseAnOperatorChooseValue: [
          {
            chooseAnOperatorChooseValueKey: "Any automation",
          },
        ],
      },
      {
        chooseAnOperatorKey: "Not active in",
        chooseAnOperatorChooseValueStatus: true,
        chooseAnOperatorChooseValue: [
          {
            chooseAnOperatorChooseValueKey: "Any automation",
          },
        ],
      },
      {
        chooseAnOperatorKey: "Has completed",
        chooseAnOperatorChooseValueStatus: true,
        chooseAnOperatorChooseValue: [
          {
            chooseAnOperatorChooseValueKey: "Any automation",
          },
        ],
      },
      {
        chooseAnOperatorKey: "Has not completed",
        chooseAnOperatorChooseValueStatus: true,
        chooseAnOperatorChooseValue: [
          {
            chooseAnOperatorChooseValueKey: "Any automation",
          },
        ],
      },
      {
        chooseAnOperatorKey: "Never active in",
        chooseAnOperatorChooseValueStatus: true,
        chooseAnOperatorChooseValue: [
          {
            chooseAnOperatorChooseValueKey: "Any automation",
          },
        ],
      },
    ],
  },
  {
    id: 10,
    categoryName: "Time inactive",
    textStatus: true,
    textStatusValue: "Have not opened or clicked any email in the last days",
    textStatusInputStatus: true,
  },
  {
    id: 11,
    categoryName: "Stats",
    chooseAnOperatorStatus: true,
    chooseAnOperator: [
      {
        chooseAnOperatorKey: "Emails sent",
        chooseAnOperatorChooseValueStatus: true,
        chooseAnOperatorChooseValue: [
          {
            chooseAnOperatorChooseValueKey: "Equals",
            chooseAnOperatorChooseValueKeyInputStatus: true,
          },
          {
            chooseAnOperatorChooseValueKey: "Is less than",
            chooseAnOperatorChooseValueKeyInputStatus: true,
          },
          {
            chooseAnOperatorChooseValueKey: "Is greater than",
            chooseAnOperatorChooseValueKeyInputStatus: true,
          },
        ],
      },
      {
        chooseAnOperatorKey: "Emails opened",
        chooseAnOperatorChooseValueStatus: true,
        chooseAnOperatorChooseValue: [
          {
            chooseAnOperatorChooseValueKey: "Equals",
            chooseAnOperatorChooseValueKeyInputStatus: true,
          },
          {
            chooseAnOperatorChooseValueKey: "Is less than",
            chooseAnOperatorChooseValueKeyInputStatus: true,
          },
          {
            chooseAnOperatorChooseValueKey: "Is greater than",
            chooseAnOperatorChooseValueKeyInputStatus: true,
          },
        ],
      },
      {
        chooseAnOperatorKey: "Emails clicked",
        chooseAnOperatorChooseValueStatus: true,
        chooseAnOperatorChooseValue: [
          {
            chooseAnOperatorChooseValueKey: "Equals",
            chooseAnOperatorChooseValueKeyInputStatus: true,
          },
          {
            chooseAnOperatorChooseValueKey: "Is less than",
            chooseAnOperatorChooseValueKeyInputStatus: true,
          },
          {
            chooseAnOperatorChooseValueKey: "Is greater than",
            chooseAnOperatorChooseValueKeyInputStatus: true,
          },
        ],
      },
      {
        chooseAnOperatorKey: "Opened rate",
        chooseAnOperatorChooseValueStatus: true,
        chooseAnOperatorChooseValue: [
          {
            chooseAnOperatorChooseValueKey: "Equals",
            chooseAnOperatorChooseValueKeyInputStatusWithPercentage: true,
          },
          {
            chooseAnOperatorChooseValueKey: "Is less than",
            chooseAnOperatorChooseValueKeyInputStatusWithPercentage: true,
          },
          {
            chooseAnOperatorChooseValueKey: "Is greater than",
            chooseAnOperatorChooseValueKeyInputStatusWithPercentage: true,
          },
        ],
      },
      {
        chooseAnOperatorKey: "Clicked rate",
        chooseAnOperatorChooseValueStatus: true,
        chooseAnOperatorChooseValue: [
          {
            chooseAnOperatorChooseValueKey: "Equals",
            chooseAnOperatorChooseValueKeyInputStatusWithPercentage: true,
          },
          {
            chooseAnOperatorChooseValueKey: "Is less than",
            chooseAnOperatorChooseValueKeyInputStatusWithPercentage: true,
          },
          {
            chooseAnOperatorChooseValueKey: "Is greater than",
            chooseAnOperatorChooseValueKeyInputStatusWithPercentage: true,
          },
        ],
      },
    ],
  },
  {
    id: 12,
    categoryName: "Used Subscribers",
    textStatus: true,
    textStatusValue:
      "The filter shows the amount of unique used subscribers in current billing period.",
  },
];

export const activeList = [
  {
    id: 1,
    activeListKey: "All",
    count: 4,
  },
  {
    id: 2,
    activeListKey: "No emails",
    count: 2,
  },
  {
    id: 3,
    activeListKey: "Good emails",
    count: 1,
  },
  {
    id: 4,
    activeListKey: "Unsubscribed",
    count: 1,
  },
  {
    id: 5,
    activeListKey: "Bounced",
    count: 1,
  },
  {
    id: 6,
    activeListKey: "Spam complaints",
    count: 9,
  },
  {
    id: 7,
    activeListKey: "Unprocessed emails",
    count: 9,
  },
];

export const actionList = [
  { 
    id:1 ,
    iconName: "IoAddCircleOutline",
    isDisabled: true,
    actionListKey: "Add to group",
  },
  {
    id:2,
    iconName: "MdOutlineRemoveCircleOutline",
    isDisabled: true,
    actionListKey: "Remove from group",
  },
  // {
  //   iconName: "PiProhibitInsetLight",
  //   isDisabled: true,
  //   actionListKey: "Move to subscribed",
  // },
  {
    id:3,
    iconName: "MdDeleteOutline",
    isDisabled: true,
    actionListKey: "Delete",
  },
  {
    id:4,
    iconName: "MdOutlineFileDownload",
    isDisabled: false,
    actionListKey: "Export CSV",
  },
  // {
  //   iconName: "MdOutlineFileDownload",
  //   isDisabled: false,
  //   actionListKey: "Export CSV for excel",
  // },
];

export const segmentDataList = [
  {
    id: 1,
    isChecked: false,
    segmentName: "Segment1",
    createdAt: "2024-03-15 10:18:07",
    subscribersCount: 1,
    openRatePer: 3,
    clickRatePer: 2,
  },
  {
    id: 2,
    isChecked: false,
    segmentName: "Segment2",
    createdAt: "3 Hour ago",
    subscribersCount: 1,
    openRatePer: 2,
    clickRatePer: 8,
  },
  {
    id: 3,
    isChecked: false,
    segmentName: "Segment3",
    createdAt: "1 Hour ago",
    subscribersCount: 10,
    openRatePer: 2,
    clickRatePer: 9,
  },
];

export const groupDataList = [
  {
    id: 1,
    isChecked: false,
    groupName: "Group1",
    createdAt: "2024-03-15 10:18:07",
    subscribersCount: 1,
    openRatePer: 3,
    clickRatePer: 2,
  },
  {
    id: 2,
    isChecked: false,
    groupName: "Group2",
    createdAt: "3 Hour ago",
    subscribersCount: 1,
    openRatePer: 2,
    clickRatePer: 8,
  },
  {
    id: 3,
    isChecked: false,
    groupName: "Group3",
    createdAt: "1 Hour ago",
    subscribersCount: 10,
    openRatePer: 2,
    clickRatePer: 9,
  },
];

export const data = [
  {
    id: 5,
    fName: "Suraj",
    lName: "Singh",
    company: "oodles",
    country: "India",
    city: "Gurgoan",
    phone: "+91 1234567890",
    state: "Delhi",
    zip: "110001",
    groups: "GroupA",
    categoryName: "Uncofirmed",
  },
  {
    id: 4,
    fName: "Suraj",
    
    lName: "Singh",
    company: "oodles",
    country: "India",
    city: "Gurgoan",
    phone: "+91 1234567890",
    state: "Delhi",
    zip: "110001",
    groups: "GroupA",
    categoryName: "Spam complaints",
  },
  {
    id: 2,
    fName: "Suraj",
    lName: "Singh",
    company: "oodles",
    country: "India",
    city: "Gurgoan",
    phone: "+91 1234567890",
    state: "Delhi",
    zip: "110001",
    groups: "GroupA",
    categoryName: "Unsubcribed",
  },
  {
    id: 1,
    fName: "Suraj",
    lName: "Singh",
    company: "oodles",
    country: "India",
    city: "Gurgoan",
    phone: "+91 1234567890",
    state: "Delhi",
    zip: "110001",
    groups: "GroupA",
    categoryName: "Active",
  },
  {
    id: 2,
    fName: "Suraj",
    lName: "Singh",
    company: "oodles",
    country: "India",
    city: "Gurgoan",
    phone: "+91 1234567890",
    state: "Delhi",
    zip: "110001",
    groups: "GroupA",
    categoryName: "Unsubcribed",
  },
  {
    id: 1,
    fName: "Suraj",
    lName: "Singh",
    company: "oodles",
    country: "India",
    city: "Gurgoan",
    phone: "+91 1234567890",
    state: "Delhi",
    zip: "110001",
    groups: "GroupA",
    categoryName: "Active",
  },
  {
    id: 3,
    fName: "Suraj",
    lName: "Singh",
    company: "oodles",
    country: "India",
    city: "Gurgoan",
    phone: "+91 1234567890",
    state: "Delhi",
    zip: "110001",
    groups: "GroupA",
    categoryName: "Bounced",
  },
  {
    id: 1,
    fName: "Suraj",
    lName: "Singh",
    company: "oodles",
    country: "India",
    city: "Gurgoan",
    phone: "+91 1234567890",
    state: "Delhi",
    zip: "110001",
    groups: "GroupA",
    categoryName: "Active",
  },
  {
    id: 1,
    fName: "Suraj",
    lName: "Singh",
    company: "oodles",
    country: "India",
    city: "Gurgoan",
    phone: "+91 1234567890",
    state: "Delhi",
    zip: "110001",
    groups: "GroupA",
    categoryName: "Active",
  },
];

export const dummyDataImportStatusList = [
  {
      id:1,
      value:false,
      name:"Suraj"
  },
  {
      id:2,
      value:false,
      name:"Hari"
  },

]

export const belongstoDropDownTotalList = [
  // {
  //   id:-1,
  //   belongstoDropDownListValue:"Skip this columns",
  //   isVisible:true,
    
  // },
  {
    id:1,
    belongstoDropDownListValue:"First Name",
    isVisible:true,
  },
  {
    id:2,
    belongstoDropDownListValue:"Last Name",
    isVisible:true,
  },
  {
    id:3,
    belongstoDropDownListValue:"Company",
    isVisible:true,
  },
  {
    id:4,
    belongstoDropDownListValue:"Country",
    isVisible:true,
  },
  {
    id:5,
    belongstoDropDownListValue:"City",
    isVisible:true,
  },
  {
    id:6,
    belongstoDropDownListValue:"Phone",
    isVisible:true,
  },
  {
    id:7,
    belongstoDropDownListValue:"State",
    isVisible:true,
  },
  {
    id:8,
    belongstoDropDownListValue:"Zip",
    isVisible:true,
  },
  {
    id:9,
    belongstoDropDownListValue:"Group",
    isVisible:true,
  },
]

export const tempFilterList = [
  {
      "_id": "660baed45dc281253c27a993",
      "key": "groups",
      "value": "Groups",
      "operatorOptions": [
          {
              "key": "belongsTo",
              "value": "Belongs to",
              "hasMultiValue": false
          },
          {
              "key": "doesNotBelongsTo",
              "value": "Does not belongs to",
              "hasMultiValue": false
          },
          {
              "key": "belongsToAny",
              "value": "Belongs to any",
              "hasMultiValue": true
          },
          {
              "key": "doesNotBelongsToAny",
              "value": "Does not belongs to any",
              "hasMultiValue": true
          },
          {
              "key": "noGroups",
              "value": "No groups",
              "hasMultiValue": false
          }
      ],
      "isActive": true
  },
  {
      "_id": "660baed45dc281253c27a994",
      "key": "fields",
      "value": "Fields",
      "options": [
          {
              "key": "city",
              "value": "City",
              "operatorOptions": [
                  {
                      "key": "equals",
                      "value": "Equals"
                  },
                  {
                      "key": "doesNotequals",
                      "value": "Does not equals"
                  },
                  {
                      "key": "contains",
                      "value": "Contains"
                  },
                  {
                      "key": "doesNotContains",
                      "value": "Does not contains"
                  },
                  {
                      "key": "isSet",
                      "value": "Is set"
                  },
                  {
                      "key": "isNotSet",
                      "value": "Is not set"
                  }
              ]
          },
          {
              "key": "company",
              "value": "Company",
              "operatorOptions": [
                  {
                      "key": "equals",
                      "value": "Equals"
                  },
                  {
                      "key": "doesNotequals",
                      "value": "Does not equals"
                  },
                  {
                      "key": "contains",
                      "value": "Contains"
                  },
                  {
                      "key": "doesNotContains",
                      "value": "Does not contains"
                  },
                  {
                      "key": "isSet",
                      "value": "Is set"
                  },
                  {
                      "key": "isNotSet",
                      "value": "Is not set"
                  }
              ]
          },
          {
              "key": "country",
              "value": "Country",
              "operatorOptions": [
                  {
                      "key": "equals",
                      "value": "Equals"
                  },
                  {
                      "key": "doesNotequals",
                      "value": "Does not equals"
                  },
                  {
                      "key": "contains",
                      "value": "Contains"
                  },
                  {
                      "key": "doesNotContains",
                      "value": "Does not contains"
                  },
                  {
                      "key": "isSet",
                      "value": "Is set"
                  },
                  {
                      "key": "isNotSet",
                      "value": "Is not set"
                  }
              ]
          },
          {
              "key": "lastName",
              "value": "Last name",
              "operatorOptions": [
                  {
                      "key": "equals",
                      "value": "Equals"
                  },
                  {
                      "key": "doesNotequals",
                      "value": "Does not equals"
                  },
                  {
                      "key": "contains",
                      "value": "Contains"
                  },
                  {
                      "key": "doesNotContains",
                      "value": "Does not contains"
                  },
                  {
                      "key": "isSet",
                      "value": "Is set"
                  },
                  {
                      "key": "isNotSet",
                      "value": "Is not set"
                  }
              ]
          },
          {
              "key": "phone",
              "value": "Phone",
              "operatorOptions": [
                  {
                      "key": "equals",
                      "value": "Equals"
                  },
                  {
                      "key": "doesNotequals",
                      "value": "Does not equals"
                  },
                  {
                      "key": "contains",
                      "value": "Contains"
                  },
                  {
                      "key": "doesNotContains",
                      "value": "Does not contains"
                  },
                  {
                      "key": "isSet",
                      "value": "Is set"
                  },
                  {
                      "key": "isNotSet",
                      "value": "Is not set"
                  }
              ]
          },
          {
              "key": "state",
              "value": "State",
              "operatorOptions": [
                  {
                      "key": "equals",
                      "value": "Equals"
                  },
                  {
                      "key": "doesNotequals",
                      "value": "Does not equals"
                  },
                  {
                      "key": "contains",
                      "value": "Contains"
                  },
                  {
                      "key": "doesNotContains",
                      "value": "Does not contains"
                  },
                  {
                      "key": "isSet",
                      "value": "Is set"
                  },
                  {
                      "key": "isNotSet",
                      "value": "Is not set"
                  }
              ]
          },
          {
              "key": "zip",
              "value": "Zip",
              "operatorOptions": [
                  {
                      "key": "equals",
                      "value": "Equals"
                  },
                  {
                      "key": "doesNotequals",
                      "value": "Does not equals"
                  },
                  {
                      "key": "contains",
                      "value": "Contains"
                  },
                  {
                      "key": "doesNotContains",
                      "value": "Does not contains"
                  },
                  {
                      "key": "isSet",
                      "value": "Is set"
                  },
                  {
                      "key": "isNotSet",
                      "value": "Is not set"
                  }
              ]
          }
      ],
      "isActive": true
  }
]




export const accountsData = [
  {
    label:"First Label",
    type:"Mailer Lite1",
    username:"suraj@test.com",
    lastActivity:"03/02/2024",
    isActive:false,
    connection:"Bad",
  },
  {
    label:"Second Label",
    type:"Mailer Lite2",
    username:"suraj@test.com",
    lastActivity:"03/02/2024",
    isActive:false,
    connection:"Green",
  },
  {
    label:"First Label",
    type:"Mailer Lite3",
    username:"suraj@test.com",
    lastActivity:"03/02/2024",
    isActive:true,
    connection:"Unknown",
  },
  {
    label:"First Label",
    type:"Mailer Lite4",
    username:"suraj@test.com",
    lastActivity:"03/02/2024",
    isActive:false,
    connection:"Green",
  },
  {
    label:"First Label",
    type:"Mailer Lite5",
    username:"suraj@test.com",
    lastActivity:"03/02/2024",
    isActive:true,
    connection:"Green",
  },
  {
    label:"First Label",
    type:"Mailer Lite6",
    username:"suraj@test.com",
    lastActivity:"03/02/2024",
    isActive:true,
    connection:"Green",
  },
  {
    label:"First Label",
    type:"Mailer Lite7",
    username:"suraj@test.com",
    lastActivity:"03/02/2024",
    isActive:true,
    connection:"Unknown",
  },
  {
    label:"First Label",
    type:"Mailer Lite8",
    username:"suraj@test.com",
    lastActivity:"03/02/2024",
    isActive:true,
    connection:"Green",
  },
  {
    label:"First Label",
    type:"Mailer Lite9",
    username:"suraj@test.com",
    lastActivity:"03/02/2024",
    isActive:true,
    connection:"Bad",
  },
  
];

export const accountAutoActivitiesData = [
  {
    label:"First Label 1",
    type:"MailerLite1",
    date:"03/03/24",
    activityName:"Activity Name",
    task:"Task1",
    note:"Note1",
    contactId:"contactId1",
    status:"Status1"
  },
  {
    label:"First Label 2",
    type:"MailerLite2",
    date:"03/03/24",
    activityName:"Activity Name",
    task:"Task2",
    note:"Note2",
    contactId:"contactId2",
    status:"Status2"
  },
  {
    label:"First Label 3",
    type:"MailerLite3",
    date:"03/03/24",
    activityName:"Activity Name",
    task:"Task3",
    note:"Note3",
    contactId:"contactId3",
    status:"Status3"
  },
  {
    label:"First Label 4",
    type:"MailerLite4",
    date:"03/03/24",
    activityName:"Activity Name",
    task:"Task4",
    note:"Note4",
    contactId:"contactId4",
    status:"Status4"
  },
  {
    label:"First Label 5",
    type:"MailerLite5",
    date:"03/03/24",
    activityName:"Activity Name",
    task:"Task5",
    note:"Note5",
    contactId:"contactId5",
    status:"Status5"
  },
  {
    label:"First Label 6",
    type:"MailerLite6",
    date:"03/03/24",
    activityName:"Activity Name",
    task:"Task6",
    note:"Note6",
    contactId:"contactId6",
    status:"Status6"
  },
  {
    label:"First Label 7",
    type:"MailerLite7",
    date:"03/03/24",
    activityName:"Activity Name",
    task:"Task7",
    note:"Note7",
    contactId:"contactId7",
    status:"Status7"
  },
  {
    label:"First Label 8",
    type:"MailerLite8",
    date:"03/03/24",
    activityName:"Activity Name",
    task:"Task8",
    note:"Note8",
    contactId:"contactId8",
    status:"Status8"
  },
]

export const contactColumnData = [
  {
    name: "First name",
    tableName: "Lorem ipsum dolor",
    type: "Lorem ipsum",
    canExport: "Yes",
    createDate: "04/11/2024",
  },
  {
    name: "Last name",
    tableName: "Lorem ipsum dolor",
    type: "Lorem ipsum",
    canExport: "Yes",
    createDate: "04/11/2024",
  },
  {
    name: "Email",
    tableName: "Lorem ipsum dolor",
    type: "Lorem ipsum",
    canExport: "Yes",
    createDate: "04/11/2024",
  },
  {
    name: "Phone",
    tableName: "Lorem ipsum dolor",
    type: "Lorem ipsum",
    canExport: "Yes",
    createDate: "04/11/2024",
  },
  {
    name: "LinkedIn",
    tableName: "Lorem ipsum dolor",
    type: "Lorem ipsum",
    canExport: "Yes",
    createDate: "04/11/2024",
  },
  {
    name: "HBCU short",
    tableName: "Lorem ipsum dolor",
    type: "Lorem ipsum",
    canExport: "Yes",
    createDate: "04/11/2024",
  },
  {
    name: "Address",
    tableName: "Lorem ipsum dolor",
    type: "Lorem ipsum",
    canExport: "Yes",
    createDate: "04/11/2024",
  },
  {
    name: "City",
    tableName: "Lorem ipsum dolor",
    type: "Lorem ipsum",
    canExport: "Yes",
    createDate: "04/11/2024",
  },
  {
    name: "State",
    tableName: "Lorem ipsum dolor",
    type: "Lorem ipsum",
    canExport: "Yes",
    createDate: "04/11/2024",
  },
  {
    name: "Zip",
    tableName: "Lorem ipsum dolor",
    type: "Lorem ipsum",
    canExport: "Yes",
    createDate: "04/11/2024",
  },
  {
    name: "Active",
    tableName: "Lorem ipsum dolor",
    type: "Lorem ipsum",
    canExport: "Yes",
    createDate: "04/11/2024",
  },
  {
    name: "Lorem idm",
    tableName: "Lorem ipsum dolor",
    type: "Lorem ipsum",
    canExport: "Yes",
    createDate: "04/11/2024",
  },
  {
    name: "korem ipsum",
    tableName: "Lorem ipsum dolor",
    type: "Lorem ipsum",
    canExport: "No",
    createDate: "04/11/2024",
  },
  {
    name: "Oorem ipsum",
    tableName: "Lorem ipsum dolor",
    type: "Lorem ipsum",
    canExport: "Yes",
    createDate: "04/11/2024",
  },
  {
    name: "porem ipsum",
    tableName: "Lorem ipsum dolor",
    type: "Lorem ipsum",
    canExport: "No",
    createDate: "04/11/2024",
  },
  {
    name: "yorem ipsum",
    tableName: "Lorem ipsum dolor",
    type: "Lorem ipsum",
    canExport: "Yes",
    createDate: "04/11/2024",
  },
  {
    name: "oporem ipsum",
    tableName: "Lorem ipsum dolor",
    type: "Lorem ipsum",
    canExport: "No",
    createDate: "04/11/2024",
  },
  {
    name: "lorem ipsum",
    tableName: "Lorem ipsum dolor",
    type: "Lorem ipsum",
    canExport: "Yes",
    createDate: "04/11/2024",
  },
  {
    name: "aorem ipsum",
    tableName: "Lorem ipsum dolor",
    type: "Lorem ipsum",
    canExport: "No",
    createDate: "04/11/2024",
  },
  {
    name: "dorem ipsum",
    tableName: "Lorem ipsum dolor",
    type: "Lorem ipsum",
    canExport: "Yes",
    createDate: "04/11/2024",
  },
  {
    name: "corem ipsum",
    tableName: "Lorem ipsum dolor",
    type: "Lorem ipsum",
    canExport: "No",
    createDate: "04/11/2024",
  },
  {
    name: "yorem ipsum",
    tableName: "Lorem ipsum dolor",
    type: "Lorem ipsum",
    canExport: "Yes",
    createDate: "04/11/2024",
  },
  {
    name: "morem ipsum",
    tableName: "Lorem ipsum dolor",
    type: "Lorem ipsum",
    canExport: "No",
    createDate: "04/11/2024",
  },
  {
    name: "ndnorem ipsum",
    tableName: "Lorem ipsum dolor",
    type: "morem ipsum",
    canExport: "Yes",
    createDate: "04/11/2024",
  },
  {
    name: "borem ipsum",
    tableName: "Lorem ipsum dolor",
    type: "Lorem ipsum",
    canExport: "No",
    createDate: "04/11/2024",
  },
];

export const updatedContactsData = [
  {
    name:"email1@gmail.com"
  },
  {
    name:"email1@gmail.com"
  },
  {
    name:"email1@gmail.com"
  },
  {
    name:"email1@gmail.com"
  },
  {
    name:"email1@gmail.com"
  }

]



export const filterData = [
  {
      "options": [
          {
              "key": "fields",
              "value": "Fields",
              "options": [
                  {
                      "key": "city",
                      "value": "City",
                      "placeholder": "Choose a operator",
                      "options": [
                          {
                              "key": "equals",
                              "value": "Equals",
                              "hasInput": true,
                              "hasMultiValue": false,
                              "isOptionOperator": true
                          },
                          {
                              "key": "doesNotequals",
                              "value": "Does not equals",
                              "hasInput": true,
                              "hasMultiValue": false,
                              "isOptionOperator": true
                          },
                          {
                              "key": "contains",
                              "value": "Contains",
                              "hasInput": true,
                              "hasMultiValue": false,
                              "isOptionOperator": true
                          },
                          {
                              "key": "doesNotContains",
                              "value": "Does not contains",
                              "hasInput": true,
                              "hasMultiValue": false,
                              "isOptionOperator": true
                          },
                          {
                              "key": "isSet",
                              "value": "Is set",
                              "hasInput": false,
                              "hasMultiValue": false,
                              "isOptionOperator": true
                          },
                          {
                              "key": "isNotSet",
                              "value": "Is not set",
                              "hasInput": false,
                              "hasMultiValue": false,
                              "isOptionOperator": true
                          }
                      ]
                  },
                  {
                      "key": "company",
                      "value": "Company",
                      "placeholder": "Choose a operator",
                      "options": [
                          {
                              "key": "equals",
                              "value": "Equals",
                              "hasInput": true,
                              "hasMultiValue": false,
                              "isOptionOperator": true
                          },
                          {
                              "key": "doesNotequals",
                              "value": "Does not equals",
                              "hasInput": true,
                              "hasMultiValue": false,
                              "isOptionOperator": true
                          },
                          {
                              "key": "contains",
                              "value": "Contains",
                              "hasInput": true,
                              "hasMultiValue": false,
                              "isOptionOperator": true
                          },
                          {
                              "key": "doesNotContains",
                              "value": "Does not contains",
                              "hasInput": true,
                              "hasMultiValue": false,
                              "isOptionOperator": true
                          },
                          {
                              "key": "isSet",
                              "value": "Is set",
                              "hasInput": false,
                              "hasMultiValue": false,
                              "isOptionOperator": true
                          },
                          {
                              "key": "isNotSet",
                              "value": "Is not set",
                              "hasInput": false,
                              "hasMultiValue": false,
                              "isOptionOperator": true
                          }
                      ]
                  },
                  {
                      "key": "country",
                      "value": "Country",
                      "placeholder": "Choose a operator",
                      "options": [
                          {
                              "key": "equals",
                              "value": "Equals",
                              "hasInput": true,
                              "hasMultiValue": false,
                              "isOptionOperator": true
                          },
                          {
                              "key": "doesNotequals",
                              "value": "Does not equals",
                              "hasInput": true,
                              "hasMultiValue": false,
                              "isOptionOperator": true
                          },
                          {
                              "key": "contains",
                              "value": "Contains",
                              "hasInput": true,
                              "hasMultiValue": false,
                              "isOptionOperator": true
                          },
                          {
                              "key": "doesNotContains",
                              "value": "Does not contains",
                              "hasInput": true,
                              "hasMultiValue": false,
                              "isOptionOperator": true
                          },
                          {
                              "key": "isSet",
                              "value": "Is set",
                              "hasInput": false,
                              "hasMultiValue": false,
                              "isOptionOperator": true
                          },
                          {
                              "key": "isNotSet",
                              "value": "Is not set",
                              "hasInput": false,
                              "hasMultiValue": false,
                              "isOptionOperator": true
                          }
                      ]
                  },
                  {
                      "key": "lastName",
                      "value": "Last name",
                      "placeholder": "Choose a operator",
                      "options": [
                          {
                              "key": "equals",
                              "value": "Equals",
                              "hasInput": true,
                              "hasMultiValue": false,
                              "isOptionOperator": true
                          },
                          {
                              "key": "doesNotequals",
                              "value": "Does not equals",
                              "hasInput": true,
                              "hasMultiValue": false,
                              "isOptionOperator": true
                          },
                          {
                              "key": "contains",
                              "value": "Contains",
                              "hasInput": true,
                              "hasMultiValue": false,
                              "isOptionOperator": true
                          },
                          {
                              "key": "doesNotContains",
                              "value": "Does not contains",
                              "hasInput": true,
                              "hasMultiValue": false,
                              "isOptionOperator": true
                          },
                          {
                              "key": "isSet",
                              "value": "Is set",
                              "hasInput": false,
                              "hasMultiValue": false,
                              "isOptionOperator": true
                          },
                          {
                              "key": "isNotSet",
                              "value": "Is not set",
                              "hasInput": false,
                              "hasMultiValue": false,
                              "isOptionOperator": true
                          }
                      ]
                  },
                  {
                      "key": "firstName",
                      "value": "First name",
                      "placeholder": "Choose a operator",
                      "options": [
                          {
                              "key": "equals",
                              "value": "Equals",
                              "hasInput": true,
                              "hasMultiValue": false,
                              "isOptionOperator": true
                          },
                          {
                              "key": "doesNotequals",
                              "value": "Does not equals",
                              "hasInput": true,
                              "hasMultiValue": false,
                              "isOptionOperator": true
                          },
                          {
                              "key": "contains",
                              "value": "Contains",
                              "hasInput": true,
                              "hasMultiValue": false,
                              "isOptionOperator": true
                          },
                          {
                              "key": "doesNotContains",
                              "value": "Does not contains",
                              "hasInput": true,
                              "hasMultiValue": false,
                              "isOptionOperator": true
                          },
                          {
                              "key": "isSet",
                              "value": "Is set",
                              "hasInput": false,
                              "hasMultiValue": false,
                              "isOptionOperator": true
                          },
                          {
                              "key": "isNotSet",
                              "value": "Is not set",
                              "hasInput": false,
                              "hasMultiValue": false,
                              "isOptionOperator": true
                          }
                      ]
                  },
                  {
                      "key": "phone",
                      "value": "Phone",
                      "placeholder": "Choose a operator",
                      "options": [
                          {
                              "key": "equals",
                              "value": "Equals",
                              "hasInput": true,
                              "hasMultiValue": false,
                              "isOptionOperator": true
                          },
                          {
                              "key": "doesNotequals",
                              "value": "Does not equals",
                              "hasInput": true,
                              "hasMultiValue": false,
                              "isOptionOperator": true
                          },
                          {
                              "key": "contains",
                              "value": "Contains",
                              "hasInput": true,
                              "hasMultiValue": false,
                              "isOptionOperator": true
                          },
                          {
                              "key": "doesNotContains",
                              "value": "Does not contains",
                              "hasInput": true,
                              "hasMultiValue": false,
                              "isOptionOperator": true
                          },
                          {
                              "key": "isSet",
                              "value": "Is set",
                              "hasInput": false,
                              "hasMultiValue": false,
                              "isOptionOperator": true
                          },
                          {
                              "key": "isNotSet",
                              "value": "Is not set",
                              "hasInput": false,
                              "hasMultiValue": false,
                              "isOptionOperator": true
                          }
                      ]
                  },
                  {
                      "key": "state",
                      "value": "State",
                      "placeholder": "Choose a operator",
                      "options": [
                          {
                              "key": "equals",
                              "value": "Equals",
                              "hasInput": true,
                              "hasMultiValue": false,
                              "isOptionOperator": true
                          },
                          {
                              "key": "doesNotequals",
                              "value": "Does not equals",
                              "hasInput": true,
                              "hasMultiValue": false,
                              "isOptionOperator": true
                          },
                          {
                              "key": "contains",
                              "value": "Contains",
                              "hasInput": true,
                              "hasMultiValue": false,
                              "isOptionOperator": true
                          },
                          {
                              "key": "doesNotContains",
                              "value": "Does not contains",
                              "hasInput": true,
                              "hasMultiValue": false,
                              "isOptionOperator": true
                          },
                          {
                              "key": "isSet",
                              "value": "Is set",
                              "hasInput": false,
                              "hasMultiValue": false,
                              "isOptionOperator": true
                          },
                          {
                              "key": "isNotSet",
                              "value": "Is not set",
                              "hasInput": false,
                              "hasMultiValue": false,
                              "isOptionOperator": true
                          }
                      ]
                  },
                  {
                      "key": "zip",
                      "value": "Zip",
                      "placeholder": "Choose a operator",
                      "options": [
                          {
                              "key": "equals",
                              "value": "Equals",
                              "hasInput": true,
                              "hasMultiValue": false,
                              "isOptionOperator": true
                          },
                          {
                              "key": "doesNotequals",
                              "value": "Does not equals",
                              "hasInput": true,
                              "hasMultiValue": false,
                              "isOptionOperator": true
                          },
                          {
                              "key": "contains",
                              "value": "Contains",
                              "hasInput": true,
                              "hasMultiValue": false,
                              "isOptionOperator": true
                          },
                          {
                              "key": "doesNotContains",
                              "value": "Does not contains",
                              "hasInput": true,
                              "hasMultiValue": false,
                              "isOptionOperator": true
                          },
                          {
                              "key": "isSet",
                              "value": "Is set",
                              "hasInput": false,
                              "hasMultiValue": false,
                              "isOptionOperator": true
                          },
                          {
                              "key": "isNotSet",
                              "value": "Is not set",
                              "hasInput": false,
                              "hasMultiValue": false,
                              "isOptionOperator": true
                          }
                      ]
                  }
              ],
              "isOptionOperator": false,
              "placeholder": "Choose a field"
          },
          {
              "key": "groups",
              "value": "Groups",
              "options": [
                  {
                      "key": "belongsTo",
                      "value": "Belongs to",
                      "hasMultiValue": true,
                      "hasInput": true,
                      "isOptionOperator": true
                  },
                  {
                      "key": "doesNotBelongsTo",
                      "value": "Does not belongs to",
                      "hasMultiValue": true,
                      "hasInput": true,
                      "isOptionOperator": true
                  },
                  {
                      "key": "belongsToAny",
                      "value": "Belongs to any",
                      "hasMultiValue": true,
                      "hasInput": true,
                      "isOptionOperator": true
                  },
                  {
                      "key": "doesNotBelongsToAny",
                      "value": "Does not belongs to any",
                      "hasMultiValue": true,
                      "hasInput": true,
                      "isOptionOperator": true
                  },
                  {
                      "key": "noGroups",
                      "value": "No groups",
                      "hasMultiValue": false,
                      "hasInput": false,
                      "isOptionOperator": true
                  }
              ],
              "isOptionOperator": true,
              "placeholder": "Choose a operator"
          }
      ],
      "index": 0,
      "rulesSelected": [
          {
              "operator": [
                  {
                      "key": "doesNotequals",
                      "value": "Does not equals",
                      "hasInput": true,
                      "hasMultiValue": false,
                      "isOptionOperator": true
                  }
              ],
              "args": [
                  [
                      {
                          "key": "fields",
                          "value": "Fields",
                          "options": [
                              {
                                  "key": "city",
                                  "value": "City",
                                  "placeholder": "Choose a operator",
                                  "options": [
                                      {
                                          "key": "equals",
                                          "value": "Equals",
                                          "hasInput": true,
                                          "hasMultiValue": false,
                                          "isOptionOperator": true
                                      },
                                      {
                                          "key": "doesNotequals",
                                          "value": "Does not equals",
                                          "hasInput": true,
                                          "hasMultiValue": false,
                                          "isOptionOperator": true
                                      },
                                      {
                                          "key": "contains",
                                          "value": "Contains",
                                          "hasInput": true,
                                          "hasMultiValue": false,
                                          "isOptionOperator": true
                                      },
                                      {
                                          "key": "doesNotContains",
                                          "value": "Does not contains",
                                          "hasInput": true,
                                          "hasMultiValue": false,
                                          "isOptionOperator": true
                                      },
                                      {
                                          "key": "isSet",
                                          "value": "Is set",
                                          "hasInput": false,
                                          "hasMultiValue": false,
                                          "isOptionOperator": true
                                      },
                                      {
                                          "key": "isNotSet",
                                          "value": "Is not set",
                                          "hasInput": false,
                                          "hasMultiValue": false,
                                          "isOptionOperator": true
                                      }
                                  ]
                              },
                              {
                                  "key": "company",
                                  "value": "Company",
                                  "placeholder": "Choose a operator",
                                  "options": [
                                      {
                                          "key": "equals",
                                          "value": "Equals",
                                          "hasInput": true,
                                          "hasMultiValue": false,
                                          "isOptionOperator": true
                                      },
                                      {
                                          "key": "doesNotequals",
                                          "value": "Does not equals",
                                          "hasInput": true,
                                          "hasMultiValue": false,
                                          "isOptionOperator": true
                                      },
                                      {
                                          "key": "contains",
                                          "value": "Contains",
                                          "hasInput": true,
                                          "hasMultiValue": false,
                                          "isOptionOperator": true
                                      },
                                      {
                                          "key": "doesNotContains",
                                          "value": "Does not contains",
                                          "hasInput": true,
                                          "hasMultiValue": false,
                                          "isOptionOperator": true
                                      },
                                      {
                                          "key": "isSet",
                                          "value": "Is set",
                                          "hasInput": false,
                                          "hasMultiValue": false,
                                          "isOptionOperator": true
                                      },
                                      {
                                          "key": "isNotSet",
                                          "value": "Is not set",
                                          "hasInput": false,
                                          "hasMultiValue": false,
                                          "isOptionOperator": true
                                      }
                                  ]
                              },
                              {
                                  "key": "country",
                                  "value": "Country",
                                  "placeholder": "Choose a operator",
                                  "options": [
                                      {
                                          "key": "equals",
                                          "value": "Equals",
                                          "hasInput": true,
                                          "hasMultiValue": false,
                                          "isOptionOperator": true
                                      },
                                      {
                                          "key": "doesNotequals",
                                          "value": "Does not equals",
                                          "hasInput": true,
                                          "hasMultiValue": false,
                                          "isOptionOperator": true
                                      },
                                      {
                                          "key": "contains",
                                          "value": "Contains",
                                          "hasInput": true,
                                          "hasMultiValue": false,
                                          "isOptionOperator": true
                                      },
                                      {
                                          "key": "doesNotContains",
                                          "value": "Does not contains",
                                          "hasInput": true,
                                          "hasMultiValue": false,
                                          "isOptionOperator": true
                                      },
                                      {
                                          "key": "isSet",
                                          "value": "Is set",
                                          "hasInput": false,
                                          "hasMultiValue": false,
                                          "isOptionOperator": true
                                      },
                                      {
                                          "key": "isNotSet",
                                          "value": "Is not set",
                                          "hasInput": false,
                                          "hasMultiValue": false,
                                          "isOptionOperator": true
                                      }
                                  ]
                              },
                              {
                                  "key": "lastName",
                                  "value": "Last name",
                                  "placeholder": "Choose a operator",
                                  "options": [
                                      {
                                          "key": "equals",
                                          "value": "Equals",
                                          "hasInput": true,
                                          "hasMultiValue": false,
                                          "isOptionOperator": true
                                      },
                                      {
                                          "key": "doesNotequals",
                                          "value": "Does not equals",
                                          "hasInput": true,
                                          "hasMultiValue": false,
                                          "isOptionOperator": true
                                      },
                                      {
                                          "key": "contains",
                                          "value": "Contains",
                                          "hasInput": true,
                                          "hasMultiValue": false,
                                          "isOptionOperator": true
                                      },
                                      {
                                          "key": "doesNotContains",
                                          "value": "Does not contains",
                                          "hasInput": true,
                                          "hasMultiValue": false,
                                          "isOptionOperator": true
                                      },
                                      {
                                          "key": "isSet",
                                          "value": "Is set",
                                          "hasInput": false,
                                          "hasMultiValue": false,
                                          "isOptionOperator": true
                                      },
                                      {
                                          "key": "isNotSet",
                                          "value": "Is not set",
                                          "hasInput": false,
                                          "hasMultiValue": false,
                                          "isOptionOperator": true
                                      }
                                  ]
                              },
                              {
                                  "key": "firstName",
                                  "value": "First name",
                                  "placeholder": "Choose a operator",
                                  "options": [
                                      {
                                          "key": "equals",
                                          "value": "Equals",
                                          "hasInput": true,
                                          "hasMultiValue": false,
                                          "isOptionOperator": true
                                      },
                                      {
                                          "key": "doesNotequals",
                                          "value": "Does not equals",
                                          "hasInput": true,
                                          "hasMultiValue": false,
                                          "isOptionOperator": true
                                      },
                                      {
                                          "key": "contains",
                                          "value": "Contains",
                                          "hasInput": true,
                                          "hasMultiValue": false,
                                          "isOptionOperator": true
                                      },
                                      {
                                          "key": "doesNotContains",
                                          "value": "Does not contains",
                                          "hasInput": true,
                                          "hasMultiValue": false,
                                          "isOptionOperator": true
                                      },
                                      {
                                          "key": "isSet",
                                          "value": "Is set",
                                          "hasInput": false,
                                          "hasMultiValue": false,
                                          "isOptionOperator": true
                                      },
                                      {
                                          "key": "isNotSet",
                                          "value": "Is not set",
                                          "hasInput": false,
                                          "hasMultiValue": false,
                                          "isOptionOperator": true
                                      }
                                  ]
                              },
                              {
                                  "key": "phone",
                                  "value": "Phone",
                                  "placeholder": "Choose a operator",
                                  "options": [
                                      {
                                          "key": "equals",
                                          "value": "Equals",
                                          "hasInput": true,
                                          "hasMultiValue": false,
                                          "isOptionOperator": true
                                      },
                                      {
                                          "key": "doesNotequals",
                                          "value": "Does not equals",
                                          "hasInput": true,
                                          "hasMultiValue": false,
                                          "isOptionOperator": true
                                      },
                                      {
                                          "key": "contains",
                                          "value": "Contains",
                                          "hasInput": true,
                                          "hasMultiValue": false,
                                          "isOptionOperator": true
                                      },
                                      {
                                          "key": "doesNotContains",
                                          "value": "Does not contains",
                                          "hasInput": true,
                                          "hasMultiValue": false,
                                          "isOptionOperator": true
                                      },
                                      {
                                          "key": "isSet",
                                          "value": "Is set",
                                          "hasInput": false,
                                          "hasMultiValue": false,
                                          "isOptionOperator": true
                                      },
                                      {
                                          "key": "isNotSet",
                                          "value": "Is not set",
                                          "hasInput": false,
                                          "hasMultiValue": false,
                                          "isOptionOperator": true
                                      }
                                  ]
                              },
                              {
                                  "key": "state",
                                  "value": "State",
                                  "placeholder": "Choose a operator",
                                  "options": [
                                      {
                                          "key": "equals",
                                          "value": "Equals",
                                          "hasInput": true,
                                          "hasMultiValue": false,
                                          "isOptionOperator": true
                                      },
                                      {
                                          "key": "doesNotequals",
                                          "value": "Does not equals",
                                          "hasInput": true,
                                          "hasMultiValue": false,
                                          "isOptionOperator": true
                                      },
                                      {
                                          "key": "contains",
                                          "value": "Contains",
                                          "hasInput": true,
                                          "hasMultiValue": false,
                                          "isOptionOperator": true
                                      },
                                      {
                                          "key": "doesNotContains",
                                          "value": "Does not contains",
                                          "hasInput": true,
                                          "hasMultiValue": false,
                                          "isOptionOperator": true
                                      },
                                      {
                                          "key": "isSet",
                                          "value": "Is set",
                                          "hasInput": false,
                                          "hasMultiValue": false,
                                          "isOptionOperator": true
                                      },
                                      {
                                          "key": "isNotSet",
                                          "value": "Is not set",
                                          "hasInput": false,
                                          "hasMultiValue": false,
                                          "isOptionOperator": true
                                      }
                                  ]
                              },
                              {
                                  "key": "zip",
                                  "value": "Zip",
                                  "placeholder": "Choose a operator",
                                  "options": [
                                      {
                                          "key": "equals",
                                          "value": "Equals",
                                          "hasInput": true,
                                          "hasMultiValue": false,
                                          "isOptionOperator": true
                                      },
                                      {
                                          "key": "doesNotequals",
                                          "value": "Does not equals",
                                          "hasInput": true,
                                          "hasMultiValue": false,
                                          "isOptionOperator": true
                                      },
                                      {
                                          "key": "contains",
                                          "value": "Contains",
                                          "hasInput": true,
                                          "hasMultiValue": false,
                                          "isOptionOperator": true
                                      },
                                      {
                                          "key": "doesNotContains",
                                          "value": "Does not contains",
                                          "hasInput": true,
                                          "hasMultiValue": false,
                                          "isOptionOperator": true
                                      },
                                      {
                                          "key": "isSet",
                                          "value": "Is set",
                                          "hasInput": false,
                                          "hasMultiValue": false,
                                          "isOptionOperator": true
                                      },
                                      {
                                          "key": "isNotSet",
                                          "value": "Is not set",
                                          "hasInput": false,
                                          "hasMultiValue": false,
                                          "isOptionOperator": true
                                      }
                                  ]
                              }
                          ],
                          "isOptionOperator": false,
                          "placeholder": "Choose a field"
                      }
                  ],
                  [
                      {
                          "key": "city",
                          "value": "City",
                          "placeholder": "Choose a operator",
                          "options": [
                              {
                                  "key": "equals",
                                  "value": "Equals",
                                  "hasInput": true,
                                  "hasMultiValue": false,
                                  "isOptionOperator": true
                              },
                              {
                                  "key": "doesNotequals",
                                  "value": "Does not equals",
                                  "hasInput": true,
                                  "hasMultiValue": false,
                                  "isOptionOperator": true
                              },
                              {
                                  "key": "contains",
                                  "value": "Contains",
                                  "hasInput": true,
                                  "hasMultiValue": false,
                                  "isOptionOperator": true
                              },
                              {
                                  "key": "doesNotContains",
                                  "value": "Does not contains",
                                  "hasInput": true,
                                  "hasMultiValue": false,
                                  "isOptionOperator": true
                              },
                              {
                                  "key": "isSet",
                                  "value": "Is set",
                                  "hasInput": false,
                                  "hasMultiValue": false,
                                  "isOptionOperator": true
                              },
                              {
                                  "key": "isNotSet",
                                  "value": "Is not set",
                                  "hasInput": false,
                                  "hasMultiValue": false,
                                  "isOptionOperator": true
                              }
                          ]
                      }
                  ],
                  [],
                  "aditya"
              ],
              "orStatus": ""
          },
          {
              "operator": [
                  {
                      "key": "doesNotBelongsTo",
                      "value": "Does not belongs to",
                      "hasMultiValue": true,
                      "hasInput": true,
                      "isOptionOperator": true
                  }
              ],
              "args": [
                  [
                      {
                          "key": "groups",
                          "value": "Groups",
                          "options": [
                              {
                                  "key": "belongsTo",
                                  "value": "Belongs to",
                                  "hasMultiValue": true,
                                  "hasInput": true,
                                  "isOptionOperator": true
                              },
                              {
                                  "key": "doesNotBelongsTo",
                                  "value": "Does not belongs to",
                                  "hasMultiValue": true,
                                  "hasInput": true,
                                  "isOptionOperator": true
                              },
                              {
                                  "key": "belongsToAny",
                                  "value": "Belongs to any",
                                  "hasMultiValue": true,
                                  "hasInput": true,
                                  "isOptionOperator": true
                              },
                              {
                                  "key": "doesNotBelongsToAny",
                                  "value": "Does not belongs to any",
                                  "hasMultiValue": true,
                                  "hasInput": true,
                                  "isOptionOperator": true
                              },
                              {
                                  "key": "noGroups",
                                  "value": "No groups",
                                  "hasMultiValue": false,
                                  "hasInput": false,
                                  "isOptionOperator": true
                              }
                          ],
                          "isOptionOperator": true,
                          "placeholder": "Choose a operator"
                      }
                  ],
                  [],
                  [],
                  [
                      "group4",
                      "group2"
                  ]
              ],
              "orStatus": ""
          }
      ]
  },
  {
      "options": [
          {
              "key": "fields",
              "value": "Fields",
              "options": [
                  {
                      "key": "city",
                      "value": "City",
                      "placeholder": "Choose a operator",
                      "options": [
                          {
                              "key": "equals",
                              "value": "Equals",
                              "hasInput": true,
                              "hasMultiValue": false,
                              "isOptionOperator": true
                          },
                          {
                              "key": "doesNotequals",
                              "value": "Does not equals",
                              "hasInput": true,
                              "hasMultiValue": false,
                              "isOptionOperator": true
                          },
                          {
                              "key": "contains",
                              "value": "Contains",
                              "hasInput": true,
                              "hasMultiValue": false,
                              "isOptionOperator": true
                          },
                          {
                              "key": "doesNotContains",
                              "value": "Does not contains",
                              "hasInput": true,
                              "hasMultiValue": false,
                              "isOptionOperator": true
                          },
                          {
                              "key": "isSet",
                              "value": "Is set",
                              "hasInput": false,
                              "hasMultiValue": false,
                              "isOptionOperator": true
                          },
                          {
                              "key": "isNotSet",
                              "value": "Is not set",
                              "hasInput": false,
                              "hasMultiValue": false,
                              "isOptionOperator": true
                          }
                      ]
                  },
                  {
                      "key": "company",
                      "value": "Company",
                      "placeholder": "Choose a operator",
                      "options": [
                          {
                              "key": "equals",
                              "value": "Equals",
                              "hasInput": true,
                              "hasMultiValue": false,
                              "isOptionOperator": true
                          },
                          {
                              "key": "doesNotequals",
                              "value": "Does not equals",
                              "hasInput": true,
                              "hasMultiValue": false,
                              "isOptionOperator": true
                          },
                          {
                              "key": "contains",
                              "value": "Contains",
                              "hasInput": true,
                              "hasMultiValue": false,
                              "isOptionOperator": true
                          },
                          {
                              "key": "doesNotContains",
                              "value": "Does not contains",
                              "hasInput": true,
                              "hasMultiValue": false,
                              "isOptionOperator": true
                          },
                          {
                              "key": "isSet",
                              "value": "Is set",
                              "hasInput": false,
                              "hasMultiValue": false,
                              "isOptionOperator": true
                          },
                          {
                              "key": "isNotSet",
                              "value": "Is not set",
                              "hasInput": false,
                              "hasMultiValue": false,
                              "isOptionOperator": true
                          }
                      ]
                  },
                  {
                      "key": "country",
                      "value": "Country",
                      "placeholder": "Choose a operator",
                      "options": [
                          {
                              "key": "equals",
                              "value": "Equals",
                              "hasInput": true,
                              "hasMultiValue": false,
                              "isOptionOperator": true
                          },
                          {
                              "key": "doesNotequals",
                              "value": "Does not equals",
                              "hasInput": true,
                              "hasMultiValue": false,
                              "isOptionOperator": true
                          },
                          {
                              "key": "contains",
                              "value": "Contains",
                              "hasInput": true,
                              "hasMultiValue": false,
                              "isOptionOperator": true
                          },
                          {
                              "key": "doesNotContains",
                              "value": "Does not contains",
                              "hasInput": true,
                              "hasMultiValue": false,
                              "isOptionOperator": true
                          },
                          {
                              "key": "isSet",
                              "value": "Is set",
                              "hasInput": false,
                              "hasMultiValue": false,
                              "isOptionOperator": true
                          },
                          {
                              "key": "isNotSet",
                              "value": "Is not set",
                              "hasInput": false,
                              "hasMultiValue": false,
                              "isOptionOperator": true
                          }
                      ]
                  },
                  {
                      "key": "lastName",
                      "value": "Last name",
                      "placeholder": "Choose a operator",
                      "options": [
                          {
                              "key": "equals",
                              "value": "Equals",
                              "hasInput": true,
                              "hasMultiValue": false,
                              "isOptionOperator": true
                          },
                          {
                              "key": "doesNotequals",
                              "value": "Does not equals",
                              "hasInput": true,
                              "hasMultiValue": false,
                              "isOptionOperator": true
                          },
                          {
                              "key": "contains",
                              "value": "Contains",
                              "hasInput": true,
                              "hasMultiValue": false,
                              "isOptionOperator": true
                          },
                          {
                              "key": "doesNotContains",
                              "value": "Does not contains",
                              "hasInput": true,
                              "hasMultiValue": false,
                              "isOptionOperator": true
                          },
                          {
                              "key": "isSet",
                              "value": "Is set",
                              "hasInput": false,
                              "hasMultiValue": false,
                              "isOptionOperator": true
                          },
                          {
                              "key": "isNotSet",
                              "value": "Is not set",
                              "hasInput": false,
                              "hasMultiValue": false,
                              "isOptionOperator": true
                          }
                      ]
                  },
                  {
                      "key": "firstName",
                      "value": "First name",
                      "placeholder": "Choose a operator",
                      "options": [
                          {
                              "key": "equals",
                              "value": "Equals",
                              "hasInput": true,
                              "hasMultiValue": false,
                              "isOptionOperator": true
                          },
                          {
                              "key": "doesNotequals",
                              "value": "Does not equals",
                              "hasInput": true,
                              "hasMultiValue": false,
                              "isOptionOperator": true
                          },
                          {
                              "key": "contains",
                              "value": "Contains",
                              "hasInput": true,
                              "hasMultiValue": false,
                              "isOptionOperator": true
                          },
                          {
                              "key": "doesNotContains",
                              "value": "Does not contains",
                              "hasInput": true,
                              "hasMultiValue": false,
                              "isOptionOperator": true
                          },
                          {
                              "key": "isSet",
                              "value": "Is set",
                              "hasInput": false,
                              "hasMultiValue": false,
                              "isOptionOperator": true
                          },
                          {
                              "key": "isNotSet",
                              "value": "Is not set",
                              "hasInput": false,
                              "hasMultiValue": false,
                              "isOptionOperator": true
                          }
                      ]
                  },
                  {
                      "key": "phone",
                      "value": "Phone",
                      "placeholder": "Choose a operator",
                      "options": [
                          {
                              "key": "equals",
                              "value": "Equals",
                              "hasInput": true,
                              "hasMultiValue": false,
                              "isOptionOperator": true
                          },
                          {
                              "key": "doesNotequals",
                              "value": "Does not equals",
                              "hasInput": true,
                              "hasMultiValue": false,
                              "isOptionOperator": true
                          },
                          {
                              "key": "contains",
                              "value": "Contains",
                              "hasInput": true,
                              "hasMultiValue": false,
                              "isOptionOperator": true
                          },
                          {
                              "key": "doesNotContains",
                              "value": "Does not contains",
                              "hasInput": true,
                              "hasMultiValue": false,
                              "isOptionOperator": true
                          },
                          {
                              "key": "isSet",
                              "value": "Is set",
                              "hasInput": false,
                              "hasMultiValue": false,
                              "isOptionOperator": true
                          },
                          {
                              "key": "isNotSet",
                              "value": "Is not set",
                              "hasInput": false,
                              "hasMultiValue": false,
                              "isOptionOperator": true
                          }
                      ]
                  },
                  {
                      "key": "state",
                      "value": "State",
                      "placeholder": "Choose a operator",
                      "options": [
                          {
                              "key": "equals",
                              "value": "Equals",
                              "hasInput": true,
                              "hasMultiValue": false,
                              "isOptionOperator": true
                          },
                          {
                              "key": "doesNotequals",
                              "value": "Does not equals",
                              "hasInput": true,
                              "hasMultiValue": false,
                              "isOptionOperator": true
                          },
                          {
                              "key": "contains",
                              "value": "Contains",
                              "hasInput": true,
                              "hasMultiValue": false,
                              "isOptionOperator": true
                          },
                          {
                              "key": "doesNotContains",
                              "value": "Does not contains",
                              "hasInput": true,
                              "hasMultiValue": false,
                              "isOptionOperator": true
                          },
                          {
                              "key": "isSet",
                              "value": "Is set",
                              "hasInput": false,
                              "hasMultiValue": false,
                              "isOptionOperator": true
                          },
                          {
                              "key": "isNotSet",
                              "value": "Is not set",
                              "hasInput": false,
                              "hasMultiValue": false,
                              "isOptionOperator": true
                          }
                      ]
                  },
                  {
                      "key": "zip",
                      "value": "Zip",
                      "placeholder": "Choose a operator",
                      "options": [
                          {
                              "key": "equals",
                              "value": "Equals",
                              "hasInput": true,
                              "hasMultiValue": false,
                              "isOptionOperator": true
                          },
                          {
                              "key": "doesNotequals",
                              "value": "Does not equals",
                              "hasInput": true,
                              "hasMultiValue": false,
                              "isOptionOperator": true
                          },
                          {
                              "key": "contains",
                              "value": "Contains",
                              "hasInput": true,
                              "hasMultiValue": false,
                              "isOptionOperator": true
                          },
                          {
                              "key": "doesNotContains",
                              "value": "Does not contains",
                              "hasInput": true,
                              "hasMultiValue": false,
                              "isOptionOperator": true
                          },
                          {
                              "key": "isSet",
                              "value": "Is set",
                              "hasInput": false,
                              "hasMultiValue": false,
                              "isOptionOperator": true
                          },
                          {
                              "key": "isNotSet",
                              "value": "Is not set",
                              "hasInput": false,
                              "hasMultiValue": false,
                              "isOptionOperator": true
                          }
                      ]
                  }
              ],
              "isOptionOperator": false,
              "placeholder": "Choose a field"
          },
          {
              "key": "groups",
              "value": "Groups",
              "options": [
                  {
                      "key": "belongsTo",
                      "value": "Belongs to",
                      "hasMultiValue": true,
                      "hasInput": true,
                      "isOptionOperator": true
                  },
                  {
                      "key": "doesNotBelongsTo",
                      "value": "Does not belongs to",
                      "hasMultiValue": true,
                      "hasInput": true,
                      "isOptionOperator": true
                  },
                  {
                      "key": "belongsToAny",
                      "value": "Belongs to any",
                      "hasMultiValue": true,
                      "hasInput": true,
                      "isOptionOperator": true
                  },
                  {
                      "key": "doesNotBelongsToAny",
                      "value": "Does not belongs to any",
                      "hasMultiValue": true,
                      "hasInput": true,
                      "isOptionOperator": true
                  },
                  {
                      "key": "noGroups",
                      "value": "No groups",
                      "hasMultiValue": false,
                      "hasInput": false,
                      "isOptionOperator": true
                  }
              ],
              "isOptionOperator": true,
              "placeholder": "Choose a operator"
          }
      ],
      "index": 1,
      "rulesSelected": [
          {
              "operator": [
                  {
                      "key": "equals",
                      "value": "Equals",
                      "hasInput": true,
                      "hasMultiValue": false,
                      "isOptionOperator": true
                  }
              ],
              "args": [
                  [
                      {
                          "key": "fields",
                          "value": "Fields",
                          "options": [
                              {
                                  "key": "city",
                                  "value": "City",
                                  "placeholder": "Choose a operator",
                                  "options": [
                                      {
                                          "key": "equals",
                                          "value": "Equals",
                                          "hasInput": true,
                                          "hasMultiValue": false,
                                          "isOptionOperator": true
                                      },
                                      {
                                          "key": "doesNotequals",
                                          "value": "Does not equals",
                                          "hasInput": true,
                                          "hasMultiValue": false,
                                          "isOptionOperator": true
                                      },
                                      {
                                          "key": "contains",
                                          "value": "Contains",
                                          "hasInput": true,
                                          "hasMultiValue": false,
                                          "isOptionOperator": true
                                      },
                                      {
                                          "key": "doesNotContains",
                                          "value": "Does not contains",
                                          "hasInput": true,
                                          "hasMultiValue": false,
                                          "isOptionOperator": true
                                      },
                                      {
                                          "key": "isSet",
                                          "value": "Is set",
                                          "hasInput": false,
                                          "hasMultiValue": false,
                                          "isOptionOperator": true
                                      },
                                      {
                                          "key": "isNotSet",
                                          "value": "Is not set",
                                          "hasInput": false,
                                          "hasMultiValue": false,
                                          "isOptionOperator": true
                                      }
                                  ]
                              },
                              {
                                  "key": "company",
                                  "value": "Company",
                                  "placeholder": "Choose a operator",
                                  "options": [
                                      {
                                          "key": "equals",
                                          "value": "Equals",
                                          "hasInput": true,
                                          "hasMultiValue": false,
                                          "isOptionOperator": true
                                      },
                                      {
                                          "key": "doesNotequals",
                                          "value": "Does not equals",
                                          "hasInput": true,
                                          "hasMultiValue": false,
                                          "isOptionOperator": true
                                      },
                                      {
                                          "key": "contains",
                                          "value": "Contains",
                                          "hasInput": true,
                                          "hasMultiValue": false,
                                          "isOptionOperator": true
                                      },
                                      {
                                          "key": "doesNotContains",
                                          "value": "Does not contains",
                                          "hasInput": true,
                                          "hasMultiValue": false,
                                          "isOptionOperator": true
                                      },
                                      {
                                          "key": "isSet",
                                          "value": "Is set",
                                          "hasInput": false,
                                          "hasMultiValue": false,
                                          "isOptionOperator": true
                                      },
                                      {
                                          "key": "isNotSet",
                                          "value": "Is not set",
                                          "hasInput": false,
                                          "hasMultiValue": false,
                                          "isOptionOperator": true
                                      }
                                  ]
                              },
                              {
                                  "key": "country",
                                  "value": "Country",
                                  "placeholder": "Choose a operator",
                                  "options": [
                                      {
                                          "key": "equals",
                                          "value": "Equals",
                                          "hasInput": true,
                                          "hasMultiValue": false,
                                          "isOptionOperator": true
                                      },
                                      {
                                          "key": "doesNotequals",
                                          "value": "Does not equals",
                                          "hasInput": true,
                                          "hasMultiValue": false,
                                          "isOptionOperator": true
                                      },
                                      {
                                          "key": "contains",
                                          "value": "Contains",
                                          "hasInput": true,
                                          "hasMultiValue": false,
                                          "isOptionOperator": true
                                      },
                                      {
                                          "key": "doesNotContains",
                                          "value": "Does not contains",
                                          "hasInput": true,
                                          "hasMultiValue": false,
                                          "isOptionOperator": true
                                      },
                                      {
                                          "key": "isSet",
                                          "value": "Is set",
                                          "hasInput": false,
                                          "hasMultiValue": false,
                                          "isOptionOperator": true
                                      },
                                      {
                                          "key": "isNotSet",
                                          "value": "Is not set",
                                          "hasInput": false,
                                          "hasMultiValue": false,
                                          "isOptionOperator": true
                                      }
                                  ]
                              },
                              {
                                  "key": "lastName",
                                  "value": "Last name",
                                  "placeholder": "Choose a operator",
                                  "options": [
                                      {
                                          "key": "equals",
                                          "value": "Equals",
                                          "hasInput": true,
                                          "hasMultiValue": false,
                                          "isOptionOperator": true
                                      },
                                      {
                                          "key": "doesNotequals",
                                          "value": "Does not equals",
                                          "hasInput": true,
                                          "hasMultiValue": false,
                                          "isOptionOperator": true
                                      },
                                      {
                                          "key": "contains",
                                          "value": "Contains",
                                          "hasInput": true,
                                          "hasMultiValue": false,
                                          "isOptionOperator": true
                                      },
                                      {
                                          "key": "doesNotContains",
                                          "value": "Does not contains",
                                          "hasInput": true,
                                          "hasMultiValue": false,
                                          "isOptionOperator": true
                                      },
                                      {
                                          "key": "isSet",
                                          "value": "Is set",
                                          "hasInput": false,
                                          "hasMultiValue": false,
                                          "isOptionOperator": true
                                      },
                                      {
                                          "key": "isNotSet",
                                          "value": "Is not set",
                                          "hasInput": false,
                                          "hasMultiValue": false,
                                          "isOptionOperator": true
                                      }
                                  ]
                              },
                              {
                                  "key": "firstName",
                                  "value": "First name",
                                  "placeholder": "Choose a operator",
                                  "options": [
                                      {
                                          "key": "equals",
                                          "value": "Equals",
                                          "hasInput": true,
                                          "hasMultiValue": false,
                                          "isOptionOperator": true
                                      },
                                      {
                                          "key": "doesNotequals",
                                          "value": "Does not equals",
                                          "hasInput": true,
                                          "hasMultiValue": false,
                                          "isOptionOperator": true
                                      },
                                      {
                                          "key": "contains",
                                          "value": "Contains",
                                          "hasInput": true,
                                          "hasMultiValue": false,
                                          "isOptionOperator": true
                                      },
                                      {
                                          "key": "doesNotContains",
                                          "value": "Does not contains",
                                          "hasInput": true,
                                          "hasMultiValue": false,
                                          "isOptionOperator": true
                                      },
                                      {
                                          "key": "isSet",
                                          "value": "Is set",
                                          "hasInput": false,
                                          "hasMultiValue": false,
                                          "isOptionOperator": true
                                      },
                                      {
                                          "key": "isNotSet",
                                          "value": "Is not set",
                                          "hasInput": false,
                                          "hasMultiValue": false,
                                          "isOptionOperator": true
                                      }
                                  ]
                              },
                              {
                                  "key": "phone",
                                  "value": "Phone",
                                  "placeholder": "Choose a operator",
                                  "options": [
                                      {
                                          "key": "equals",
                                          "value": "Equals",
                                          "hasInput": true,
                                          "hasMultiValue": false,
                                          "isOptionOperator": true
                                      },
                                      {
                                          "key": "doesNotequals",
                                          "value": "Does not equals",
                                          "hasInput": true,
                                          "hasMultiValue": false,
                                          "isOptionOperator": true
                                      },
                                      {
                                          "key": "contains",
                                          "value": "Contains",
                                          "hasInput": true,
                                          "hasMultiValue": false,
                                          "isOptionOperator": true
                                      },
                                      {
                                          "key": "doesNotContains",
                                          "value": "Does not contains",
                                          "hasInput": true,
                                          "hasMultiValue": false,
                                          "isOptionOperator": true
                                      },
                                      {
                                          "key": "isSet",
                                          "value": "Is set",
                                          "hasInput": false,
                                          "hasMultiValue": false,
                                          "isOptionOperator": true
                                      },
                                      {
                                          "key": "isNotSet",
                                          "value": "Is not set",
                                          "hasInput": false,
                                          "hasMultiValue": false,
                                          "isOptionOperator": true
                                      }
                                  ]
                              },
                              {
                                  "key": "state",
                                  "value": "State",
                                  "placeholder": "Choose a operator",
                                  "options": [
                                      {
                                          "key": "equals",
                                          "value": "Equals",
                                          "hasInput": true,
                                          "hasMultiValue": false,
                                          "isOptionOperator": true
                                      },
                                      {
                                          "key": "doesNotequals",
                                          "value": "Does not equals",
                                          "hasInput": true,
                                          "hasMultiValue": false,
                                          "isOptionOperator": true
                                      },
                                      {
                                          "key": "contains",
                                          "value": "Contains",
                                          "hasInput": true,
                                          "hasMultiValue": false,
                                          "isOptionOperator": true
                                      },
                                      {
                                          "key": "doesNotContains",
                                          "value": "Does not contains",
                                          "hasInput": true,
                                          "hasMultiValue": false,
                                          "isOptionOperator": true
                                      },
                                      {
                                          "key": "isSet",
                                          "value": "Is set",
                                          "hasInput": false,
                                          "hasMultiValue": false,
                                          "isOptionOperator": true
                                      },
                                      {
                                          "key": "isNotSet",
                                          "value": "Is not set",
                                          "hasInput": false,
                                          "hasMultiValue": false,
                                          "isOptionOperator": true
                                      }
                                  ]
                              },
                              {
                                  "key": "zip",
                                  "value": "Zip",
                                  "placeholder": "Choose a operator",
                                  "options": [
                                      {
                                          "key": "equals",
                                          "value": "Equals",
                                          "hasInput": true,
                                          "hasMultiValue": false,
                                          "isOptionOperator": true
                                      },
                                      {
                                          "key": "doesNotequals",
                                          "value": "Does not equals",
                                          "hasInput": true,
                                          "hasMultiValue": false,
                                          "isOptionOperator": true
                                      },
                                      {
                                          "key": "contains",
                                          "value": "Contains",
                                          "hasInput": true,
                                          "hasMultiValue": false,
                                          "isOptionOperator": true
                                      },
                                      {
                                          "key": "doesNotContains",
                                          "value": "Does not contains",
                                          "hasInput": true,
                                          "hasMultiValue": false,
                                          "isOptionOperator": true
                                      },
                                      {
                                          "key": "isSet",
                                          "value": "Is set",
                                          "hasInput": false,
                                          "hasMultiValue": false,
                                          "isOptionOperator": true
                                      },
                                      {
                                          "key": "isNotSet",
                                          "value": "Is not set",
                                          "hasInput": false,
                                          "hasMultiValue": false,
                                          "isOptionOperator": true
                                      }
                                  ]
                              }
                          ],
                          "isOptionOperator": false,
                          "placeholder": "Choose a field"
                      }
                  ],
                  [
                      {
                          "key": "company",
                          "value": "Company",
                          "placeholder": "Choose a operator",
                          "options": [
                              {
                                  "key": "equals",
                                  "value": "Equals",
                                  "hasInput": true,
                                  "hasMultiValue": false,
                                  "isOptionOperator": true
                              },
                              {
                                  "key": "doesNotequals",
                                  "value": "Does not equals",
                                  "hasInput": true,
                                  "hasMultiValue": false,
                                  "isOptionOperator": true
                              },
                              {
                                  "key": "contains",
                                  "value": "Contains",
                                  "hasInput": true,
                                  "hasMultiValue": false,
                                  "isOptionOperator": true
                              },
                              {
                                  "key": "doesNotContains",
                                  "value": "Does not contains",
                                  "hasInput": true,
                                  "hasMultiValue": false,
                                  "isOptionOperator": true
                              },
                              {
                                  "key": "isSet",
                                  "value": "Is set",
                                  "hasInput": false,
                                  "hasMultiValue": false,
                                  "isOptionOperator": true
                              },
                              {
                                  "key": "isNotSet",
                                  "value": "Is not set",
                                  "hasInput": false,
                                  "hasMultiValue": false,
                                  "isOptionOperator": true
                              }
                          ]
                      }
                  ],
                  [],
                  "TCS"
              ],
              "orStatus": ""
          }
      ]
  }
]


