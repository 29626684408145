import CrossSVG from "../../../../../../asset/svg/CrossSVG";
import "./WarmupModal.css"
import { IoClose, IoSendSharp } from "react-icons/io5";

const WarmupModal = ({headingText,onSuccess,onclose,descText,yesText = "Yes"})=>{

    const handleSave = ()=>{
        onSuccess();
        onclose();
    }


    return <div className="warmupmodal">
        <div className="warmupmodal-content">
            <div className="warmupmodal-content-close" onClick={()=>{onclose()}}>
                <div >
          <CrossSVG />
          </div>
            </div>
            <div className="warmupmodal-content-content">
                <div className="warmupmodal-content-content-1">
                    <h3>{headingText}</h3>
                </div>
                <div className="warmupmodal-content-content-2">
                    <p>{descText}</p>
                </div>
                <div className="warmupmodal-content-content-3">
                    <button onClick={()=>{onclose()}} style={{color:"#404040",backgroundColor:"#E5E7EB"}}>No</button>
                    <button onClick={()=>{handleSave()}} style={{backgroundColor:"#2081E3",color:"#ffffff"}}>{yesText}</button>
                </div>
            </div>
        

        </div>
        
        
    </div>
}

export default WarmupModal