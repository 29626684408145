
import "./HistoryComponent.css"
import { FaCircleCheck } from "react-icons/fa6";
import { GoClock } from "react-icons/go";
import ProfileSVG from "../../../../../../asset/svg/ProfileSVG";
import ISVG from "../../../../../../asset/svg/ISVG";
import { useState } from "react";
import GeneralConfirmModal from "../../../../../../modal/generalConfirmModal/GeneralConfirmModal";
import { returnDateFromTimestamp, returnEstTimeFromTimestamp, returnTimeFromTimestamp } from "../../../../../../utils/Utils";
import { useNavigate } from "react-router-dom";
import IbuttonSVG from "../../../../../../asset/svg/IbuttonSVG";
import FileProgressSVG from "../../../../../../asset/svg/FileProgressSVG";
import FileFailedSVG from "../../../../../../asset/svg/FileFailedSVG";



const HistoryComponent = ({item})=>{
    const navigate = useNavigate()
    const[hovered,setHovered]=useState(false)
    const [undoModalFlag,setUndoModalFlag] = useState(false)
    const timeStyle={
        display:hovered ? "inline": 'none'
    }
    const handleMouseEnter =()=>{
        setHovered(true)
    }
    const handleMouseLeave =()=>{
        setHovered(false)
    }




    const handleGetFileDetails = async (fileId)=>{
        navigate(`/contacts/import/status/${fileId}`,{state:{data:{fileId:fileId}}})
    
      }


    return <div className="history-component">
        {
            undoModalFlag && <GeneralConfirmModal onSuccess={()=>{setUndoModalFlag(false)}} onclose={()=>{setUndoModalFlag(false)}} deleteText="Undo" headingText={"Are you sure you want to undo this import?"} descText={"This will remove 1 contact(s)"} />
        }
        <div className="history-component-left">
        <div className="history-component-left-1">
            
        
        {
            item?.status === "FILE_PROCESSED"?<FaCircleCheck size={20} color="rgb(32,129,226)" style={{marginRight:"30px"}}/>
            :
            (item?.status === "FILE_PROCESSING" || item?.status === "UPLOADED") ?
            <div style={{marginRight:"30px"}}>
            <FileProgressSVG />
            </div>
            :
            <div style={{marginRight:"30px"}}>
            <FileFailedSVG />
            </div>

        }
        
        </div>
        <div className="history-component-left-2">
            <div>
            <h3 onClick={()=>{handleGetFileDetails(item?._id)}} style={{fontSize:'1.125em',fontWeight:'600'}}>{item?.name} </h3>
            <div className="history-component-left-2-icon"  style={{marginLeft:"1vw",display:"flex",alignItems:"center",position:"relative"}}>
            <IbuttonSVG />
            <p className="history-component-left-2-icon-abbr" >{item?._id}</p>
            </div>
            </div>
            
            <div>
                <GoClock style={{marginRight:"10px"}} size={17} color="black" />
               <p >Imported<abbr style={{borderBottom:'1px dashed rgb(84,84,84)'}}onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave} >{returnDateFromTimestamp(item?.createdAt)}<span style={timeStyle } >{" "}{returnEstTimeFromTimestamp(item?.createdAt)}</span></abbr> </p>
            </div>
            
            {/* <button >View segment</button> */}
        </div>
    </div>
    <div className="history-component-right">
        <div style={{borderLeft:"2px solid #E5E7EB"}} className="history-component-right-1">
            <div className="history-component-right-1-1">
                <ProfileSVG  />
                <p style={{fontWeight:'300',fontSize:'1em'}}>Contacts</p>
            </div>
            <div className="history-component-right-1-2">
                <p style={{fontWeight:'500',fontSize:'1em'}}>{item?.totalContacts}</p>

                
                <div onClick={()=>{
                    handleGetFileDetails(item?._id)

                }} style={{position:"relative",cursor:"pointer"}}>
                <button style={{cursor:"pointer",fontWeight:'500',fontSize:'1em' ,padding:'0.1em 1em',color:'rgb(64,64,64)'}} >View</button>
                
                </div>
                
            </div>
        </div>
        <div style={{borderLeft:"2px solid #2081E2"}} className="history-component-right-1">
            <div className="history-component-right-1-1">
                <ISVG />
                <p style={{fontWeight:'300',fontSize:'1em'}}>Contacts added</p>
            </div>
            <div className="history-component-right-1-2">
                <p style={{fontWeight:'500',fontSize:'1em'}}>{item?.newContacts}</p>
                
                
            </div>
        </div>
        
    </div>
    </div>
}

export default HistoryComponent