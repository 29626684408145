const RenameSVG = () => {
  return (
    <div>
      <svg
        width="14"
        height="15"
        viewBox="0 0 14 15"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M6.36744 2.76512H3.86264C2.86062 2.76512 2.35961 2.76512 1.97689 2.96012C1.64024 3.13166 1.36654 3.40536 1.19501 3.74201C1 4.12473 1 4.62574 1 5.62775V10.6374C1 11.6394 1 12.1404 1.19501 12.5231C1.36654 12.8598 1.64024 13.1335 1.97689 13.305C2.35961 13.5 2.86062 13.5 3.86263 13.5H8.87225C9.87426 13.5 10.3753 13.5 10.758 13.305C11.0946 13.1335 11.3683 12.8598 11.5399 12.5231C11.7349 12.1404 11.7349 11.6394 11.7349 10.6374V8.13256M4.57828 9.92171H5.57695C5.86869 9.92171 6.01455 9.92171 6.15183 9.88875C6.27353 9.85953 6.38988 9.81134 6.4966 9.74594C6.61697 9.67218 6.72011 9.56903 6.9264 9.36274L12.6295 3.65969C13.1235 3.16563 13.1235 2.3646 12.6295 1.87054C12.1354 1.37649 11.3344 1.37648 10.8403 1.87054L5.13724 7.5736C4.93095 7.77989 4.82781 7.88303 4.75404 8.0034C4.68865 8.11012 4.64045 8.22647 4.61123 8.34817C4.57828 8.48545 4.57828 8.63132 4.57828 8.92306V9.92171Z"
          stroke="#404040"
          stroke-width="1.3"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
    </div>
  );
};
export default RenameSVG;
