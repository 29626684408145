import { GoChevronDown } from "react-icons/go";
import { handleIsCheckedOddOrEven } from "../../../utils/Utils";
import "./BadFormat.css";
import { dummyDataImportStatusList } from "../../../asset/documentfiles/Documents";
import DataComponent from "../dataComponent/DataComponent";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
const BadFormat = () => {
  const location = useLocation();
  const { badEmails } = location.state || {};
  const [dataList, setDataList] = useState([]);

  useEffect(() => {
    setDataList(badEmails);
  }, [badEmails]);

  const handleUpdateData = (index, value) => {
    // console.log("index========>",index)
    // console.log("value=======>",value);
    dataList[index].name = value;
  };

  return (
    <div className="bad-format">
      {dataList.length ? (
        <div className="bad-format-content">
          <div className="bad-format-content-heading">
            <p>Email</p>
          </div>
          <div className="bad-format-content-item">
            {dataList.map((item, index) => {
              return (
                <p style={{backgroundColor: `${ handleIsCheckedOddOrEven(index)? "white": "rgba(250, 250, 250, 1)"}`}} key={index}>{item}</p>
                // <DataComponent
                //   styles={
                //     handleIsCheckedOddOrEven(index)
                //       ? "white"
                //       : "rgba(250, 250, 250, 1)"
                //   }
                //   handleUpdateData={handleUpdateData}
                //   item={item}
                //   index={index}
                // />
              );
            })}
          </div>
          {/* <div className="bad-format-content-footer">
            <p>Showing 1 to {dataList.length} Result</p>
            <button>Show more</button>
          </div> */}
        </div>
      ) : (
        <div className="bad-format-heading">
          <h3>
            These are contacts that bad format that were corrected or attempted
            to be corrected.
          </h3>
          {/* <button>Actions <GoChevronDown style={{marginLeft:"10px"}} /></button> */}
        </div>
      )}
    </div>
  );
};

export default BadFormat;
