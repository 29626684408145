import React from "react";

function EditSVG() {
  return (
    <div>
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect width="24" height="24" rx="4" fill="#E5E7EB" />
        <path
          d="M13.5568 8.05687L15.9431 10.4432M6 18V15.2159L14.983 6.2329C15.1322 6.08377 15.3345 6 15.5454 6C15.7563 6 15.9586 6.08377 16.1078 6.2329L17.7671 7.89221C17.9162 8.04138 18 8.24367 18 8.45459C18 8.66552 17.9162 8.86781 17.7671 9.01697L8.78408 18H6Z"
          stroke="#262626"
          stroke-width="1.4"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
    </div>
  );
}

export default EditSVG;
