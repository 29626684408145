
import "./SplitModal.css";
import CrossSVG from "../../../../../../asset/svg/CrossSVG";
import { useState } from "react";
import { toast } from "react-toastify";

const SplitModal = ({headingText,onClose,descText,onSuccess,item={}}) => {

  const [splitInputValue,setSplitInputValue] = useState(null)

  // const handleCheckNumber

  const handleSave = ()=>{
    if(item?.contactCount >= splitInputValue){
      onSuccess(splitInputValue);
      onClose();
    }else{
      toast.error("Split count should be less than the contacts.")

    }
  }

  
  return (
    <div className="splitmodal">
      <div className="splitmodal-content">
        <div className="splitmodal-content-close">
          <div onClick={()=>{onClose()}} >
          <CrossSVG />
          </div>
        </div>
        <div className="splitmodal-content-content">
          <div className="splitmodal-content-content-1">
            <h3>{headingText}</h3>
          </div>
          <div className="splitmodal-content-content-2">
            <p>{descText}</p>
            
           

            <div className="splitmodal-content-content-2-2">
              <input
              type="number"
                value={splitInputValue}
                onChange={(e)=>{
                  setSplitInputValue(e.target.value)
                }}
                placeholder="0"
              />
            </div>

            
          </div>
          
          <div className="splitmodal-content-content-3">
            <button
            onClick={()=>{onClose()}}
             style={{ color: "#404040", backgroundColor: "#E5E7EB" }}>
              Cancel
            </button>
            <button
            onClick={()=>{handleSave()}}
              style={{ backgroundColor: "#2081E2", color: "#ffffff" }}
            >
              Save
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SplitModal;
