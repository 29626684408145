import { MdKeyboardArrowRight } from "react-icons/md"
import "./CopyPasteExcel.css"
import MatchColumnsNavbarComponent from "../../components/matchColumnsNavbarComponent/MatchColumnsNavbarComponent"
const CopyPasteExcel = ()=>{
    return <div className="copy-paste">
    <div className="copy-paste-route">
          <p>Contacts</p>
          <MdKeyboardArrowRight style={{margin:"0px 10px"}} />
          <p>Add new</p>
      </div>
      <div className="copy-paste-heading">
          <h1>Add new contacts</h1>
      </div>
    <MatchColumnsNavbarComponent />
    <div className="copy-paste-content">
        copy paste excel
    </div>

    </div>
}

export default CopyPasteExcel