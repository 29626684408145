import {
  BreadcrumbComponent,
  BreadcrumbItemDirective,
  BreadcrumbItemsDirective,
} from "@syncfusion/ej2-react-navigations";
import { useState } from "react";
const BreadCrumbsComponent = (props) => {
  const [breadCrumbsItem, setBreadCrumbsItem] = useState(props?.routeData);

//   const breadCrumbsComponentStyle = {
//     fontSize:'5px'

//   }

  return (
    <BreadcrumbComponent  separatorTemplate={">"} enableNavigation={true}>
      <BreadcrumbItemsDirective>
        {breadCrumbsItem.map((item) => {
          return (
            <BreadcrumbItemDirective
              text={item.key}
              url={item.value}
            ></BreadcrumbItemDirective>
          );
        })}
      </BreadcrumbItemsDirective>
    </BreadcrumbComponent>
  );
};

export default BreadCrumbsComponent;
