import { useContext, useEffect, useState } from "react";
import "./HomeSideNavbar.css";
import Account from "../../asset/svg/Account";
import Contact from "../../asset/svg/Contact";
import Dashboard from "../../asset/svg/Dashboard";
import Setting from "../../asset/svg/Setting";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import mySVG from "./image/logo.jpg";
import HomeSideBarLogo from "../../asset/images/HomeSideBarLogo.png";

import { MyContext } from "../../ContextAPIStore/CreateContext";
import { IoSettingsOutline } from "react-icons/io5";
import MainLogo from "../../asset/svg/MainLogo";
import SideBarSVG from "../../asset/svg/SideBarSVG";

const HomeSideNavbar = () => {
  // const DASHBOARD = "DASHBOARD";
  // const SUBSCRIBER = "SUBSCRIBER";
  // const ACCOUNTS = "ACCOUNTS";
  // const CONSTANT_CONFIG = "CONSTANT_CONFIG";
  // const navigate = useNavigate();
  // const location = useLocation();

  // const [rootNavTabItemFlag, setRootNavTabItemFlag] = useState({
  //   key: "SUBSCRIBER",
  //   value: true,
  // });
  const constConfigData = useContext(MyContext);
  //   const [svgColor, setSvgColor] = useState('#262626');
  //   const handleNavLinkClick = () => {
  //     setSvgColor('#2081E3');
  // };
  // useEffect(() => {
  //   let obj1 = { key: (location?.state?.fromScreen === SUBSCRIBER || location?.state?.fromScreen === "ALL_SUBSCRIBER")?SUBSCRIBER:location?.state?.fromScreen , value: true };
  //   setRootNavTabItemFlag(obj1);
  // }, []);

  // const handleChangeNav = (value) => {
  //   let obj1 = { key: value, value: true };
  //   setRootNavTabItemFlag(obj1);
  //   if (value === DASHBOARD) {
  //     navigate("/dashboard", { state: { fromScreen: DASHBOARD } });
  //   } else if (value === SUBSCRIBER) {
  //     navigate("/contacts/all", { state: { fromScreen: "ALL_SUBSCRIBER" } });
  //   } else if (value === ACCOUNTS) {
  //     navigate("/accounts", { state: { fromScreen: ACCOUNTS } });
  //   } else if (value === CONSTANT_CONFIG) {
  //     navigate("/constant-config", { state: { fromScreen: CONSTANT_CONFIG } });
  //   }
  // };

  return (
    <div className="homesidenavbar">
      <div className="homesidenavbar-logo">
        {/* <div className="logo-same">{<img className="logo-same-img" src={HomeSideBarLogo} alt="My SVG" />}</div> */}
        <div className="logo-same">
          <SideBarSVG />{" "}
        </div>
      </div>

      <div className="homesidenavbar-item">
        <NavLink id="homesidenavbar-item-link" to="/dashboard">
          <Dashboard />
          {/* <IoSettingsOutline size={18} /> */}
          <p>{constConfigData?.Dashboard}</p>
        </NavLink>
      </div>
      <div  className="homesidenavbar-item">
        <NavLink id="homesidenavbar-item-link" to="/contacts">
          <Contact />
          <p>{constConfigData?.Contacts}</p>
        </NavLink>
      </div>
      <div className="homesidenavbar-item">
        <NavLink id="homesidenavbar-item-link" to="/accounts/">
          <Account />
          <p>{constConfigData?.Accounts}</p>
        </NavLink>
      </div>
      <div className="homesidenavbar-item">
        <NavLink id="homesidenavbar-item-link" to="/constant-config">
          <Setting />
          <p>{constConfigData?.Setting}</p>
        </NavLink>
      </div>
    </div>
  );
};

export default HomeSideNavbar;
