import React from "react";

function FilterListSVG() {
  return (
    <div>
      <svg
        width="14"
        height="15"
        viewBox="0 0 14 15"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M5.42042 12.3333H8.66667V10.5H5.42042V12.3333ZM1 3V4.79557H13V3H1ZM3 8.49999H11V6.83332H3V8.49999Z"
          fill="#2081E2"
        />
      </svg>
    </div>
  );
}

export default FilterListSVG;
