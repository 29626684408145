import { GoChevronDown } from "react-icons/go";
import "./SubscriberHeader.css";
import { FaRegBell } from "react-icons/fa6";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { logoutHandle } from "../../redux/features/AuthSlice";
import { logoutAPI, notificationGetAPI } from "../../services/servicesAPI";

import LogoutSVG from "../../asset/svg/LogoutSVG";
import ChangePasswordSVG from "../../asset/svg/ChangePasswordSVG";
import { toast } from "react-toastify";
import LoaderComponent from "../loaderComponent/LoaderComponent";
import { returnDateAndTimeFromTimestamp } from "../../utils/Utils";
import SmallLoaderComponent from "../smallLoaderComponent/SmallLoaderComponent";

const SubscriberHeader = () => {
  const [showLogoutModalFlag, setShowLogoutModalFlag] = useState(false);
  const [localStorageData, setLocalStorageData] = useState();
  const [notification, setNotification] = useState(false);
  const [notificationData, setNotificationData] = useState([]);
  const [pagingAllData, setPagingAllData] = useState({});
  const [loader, setLoader] = useState(false);
  const [pagingData, setPagingData] = useState({ page: 1, limit: 6, skip: 0 });

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const getAllNotificationAPI = async (payload) => {
    console.log(notification);
    setLoader(true);
    let response = await notificationGetAPI(payload);
    console.log(response);
    console.log("notification=========>", response?.data?.result);
    if (response.success) {
      // toast.success(response.message)
      setNotificationData(response?.data?.result);
      let temp = {
        hasNextPage: response?.data?.hasNextPage,
        hasPrevPage: response?.data?.hasPrevPage,
        count: response?.data?.count,
      };
      setPagingAllData(temp);
      setLoader(false)
    } else {
      toast.error(response.message);
    }
  };

  useEffect(() => {
    console.log(notification);
    if (notification == true) {
      getAllNotificationAPI(pagingData);
    }
  }, [notification]);

  

  const handleLogout = async () => {
    let userDetails = JSON.parse(localStorage.getItem("userDetails"));

    if (userDetails) {
    } else {
      userDetails = JSON.parse(sessionStorage.getItem("userDetails"));
    }

    let response = await logoutAPI(userDetails?.accessToken);

    if (response.success) {
      toast.success(response.message);
      setShowLogoutModalFlag(false);
      dispatch(logoutHandle());
      localStorage.removeItem("userDetails");
      navigate("/login");
    } else {
      toast.error(response.message);
      setTimeout(() => {}, 500);
    }
  };

  const handleNavigate = () => {
    // navigate("/change-password")
    navigate("/constant-config/my-profile");
  };

  const getLocalStorageData = () => {
    let userDetailData = JSON.parse(localStorage.getItem("userDetails"));
    // console.log("================>",userDetailData)
    let payload = {};
    if (userDetailData) {
      payload = {
        email: userDetailData?.email,
        firstName: userDetailData?.firstName,
        lastName: userDetailData?.lastName,
      };
    } else {
      userDetailData = JSON.parse(sessionStorage.getItem("userDetails"));
      payload = {
        email: userDetailData?.email,
        firstName: userDetailData?.firstName,
        lastName: userDetailData?.lastName,
      };
    }

    // console.log("payload=========>",payload)
    setLocalStorageData(payload);
  };

  const handleNavigateNotification = (pathRedirectTo,isExternalRedirect)=>{
    console.log("path reditect to====>",pathRedirectTo)
    if(isExternalRedirect){
      window.open(`${pathRedirectTo}`, '_blank');
    }else{
      navigate(`/${pathRedirectTo}`)
    }
    
  }

  useEffect(() => {
    getLocalStorageData();
  }, []);

  useEffect(() => {
    const handleClickOutside = (event) => {
      let target = event.target;
      let isInsideDropdown = target.closest(".logout-dropdown") ||
      target.closest(".flex-row");
      if (!isInsideDropdown) {
        setShowLogoutModalFlag(false);
        setNotification(false)
      }
    };

    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);
  
  let baseURL = process.env.REACT_APP_BASE_URL;

  return (
    <div className="subcriberheader">
      <div className="subcriberheader-right">
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-end",
            width: "64%",
          }}
        >
          <div
            onClick={() => {
              setNotification(!notification);
            }}
            className="flex-row"
          >
            <div className="red-dot"></div>
            <div className="bell">
              <FaRegBell size={15} />
            </div>
          </div>

          <div className="main-notification">
          {notification && (
            <span className="notification-box">
              <div className="notification-box-content">
                <div>
                  <div className="notification-top">
                    <div style={{fontWeight:"600",color:"gray"}}>
                      Notifications
                    </div>
                    <div >
                      <p onClick={()=>{navigate("/constant-config/notifications");}}>View all</p>
                    </div>
                  </div>
                <div className="overflow">
                {loader && <SmallLoaderComponent />}
                {notificationData.map((item, index) => {
                        return (
                          <div 
                            onClick={() => {
                              handleNavigateNotification(
                                item?.redirectTo,
                                item?.isExternalRedirect
                              );
                            }}
                            className="notifictaion-items"
                            key={index}
                          >
                            <p className="item-one">{item.message}</p>{" "}
                            <p className="item-two">
                              {returnDateAndTimeFromTimestamp(item.createdAt)}
                            </p>
                            
                          </div>
                        );
                      })}
                </div>
                </div>
              </div>
            </span>
          )}
          </div>
        </div>
        <div style={{ display: "flex", alignItems: "center" }}>
          <div className="subcriberheader-right-content">
            <p id="subcriberheader-right-content-1">
              {localStorageData?.firstName} {localStorageData?.lastName}{" "}
            </p>
            <p id="subcriberheader-right-content-2">
              {localStorageData?.email}
            </p>
          </div>
          <div className="flex-row-1">
            <p>{localStorageData?.firstName[0]}</p>
          </div>
          <div
            className="logout-dropdown"
            onClick={() => {
              setShowLogoutModalFlag(!showLogoutModalFlag);
            }}
            style={{ position: "relative" }}
          >
            <GoChevronDown style={{ cursor: "pointer" }} />
            {showLogoutModalFlag && (
              <div className="header-logout">
                <ul style={{ padding: "0px", listStyle: "none" }}>
                  <li
                    onClick={() => {
                      handleNavigate();
                    }}
                  >
                    <ChangePasswordSVG style={{ marginRight: "5px" }} />{" "}
                    <p>Change password</p>
                  </li>
                  <li
                    onClick={() => {
                      handleLogout();
                    }}
                  >
                    <LogoutSVG style={{ marginRight: "5px" }} /> <p>Logout</p>
                  </li>
                </ul>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default SubscriberHeader;
