import "./App.css";
import Login from "./pages/authPages/login/Login";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import PrivateRoute from "./pages/PrivateRoute";
import Dashboard from "./pages/dashboard/Dashboard";
import Accounts from "./pages/accounts/Account";
import ConstantConfig from "./pages/setting/ConstantConfig";
import Home from "./pages/home/home";
import Subscribe from "./pages/subscriber/Subscriber";
import Segment from "./pages/subscriber/subscriberPages/pages/segment/Segment";
import Group from "./pages/subscriber/subscriberPages/pages/group/Group";
import History from "./pages/subscriber/subscriberPages/pages/history/History";
import Subsribe from "./pages/subscriber/subscriberPages/Subscribe";
import ImportUploadCSV from "./pages/importUploadCSV/ImportUploadCSV";
import ImportUploadMD from "./pages/importUploadMD/ImportUploadMD"
import CopyPasteExcel from "./pages/copyPasteExcel/CopyPasteExcel";
import AddSingleSubscriber from "./pages/addSingleSubscriber/AddSIngleSubcriber";
import ImportMailChamp from "./pages/importMailchamp/ImportMailChamp";
import MatchColumns from "./pages/matchColumns/MatchColumns";
import ImportStatus from "./pages/importStatus/ImportStatus";
import AuthRoute from "./pages/authPages/AuthRoute";
import ChangePassword from "./pages/changePassword/ChangePassword";
// import AddNewAccounts from "./pages/addNewAccounts/AddNewAccounts";
// import EditAccounts from "./pages/editAccounts/EditAccounts";
import MyProfile from "./pages/setting/myProfile/MyProfile";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import AddNewAccounts from "./pages/accounts/addNewAccounts/AddNewAccounts";
import EditAccounts from "./pages/accounts/editAccounts/EditAccounts";
import EmsConnection from "./pages/accounts/emsConnection/EmsConnection";
import AutoActivities from "./pages/accounts/autoActivities/AutoActivities";

// sync fusion starts from here
import "../node_modules/@syncfusion/ej2-base/styles/material.css";
// import "../node_modules/@syncfusion/ej2-buttons/styles/material.css";
// import "../node_modules/@syncfusion/ej2-calendars/styles/material.css";
// import "../node_modules/@syncfusion/ej2-dropdowns/styles/material.css";
// import "../node_modules/@syncfusion/ej2-inputs/styles/material.css";
import "../node_modules/@syncfusion/ej2-navigations/styles/material.css";
// import "../node_modules/@syncfusion/ej2-popups/styles/material.css";
// import "../node_modules/@syncfusion/ej2-splitbuttons/styles/material.css";
// import "../node_modules/@syncfusion/ej2-notifications/styles/material.css";
import "../node_modules/@syncfusion/ej2-react-grids/styles/material.css";
import { MyContext } from "./ContextAPIStore/CreateContext";
import { constantConfigGETApi } from "./services/servicesAPI";
import { useEffect, useState } from "react";
import Notifications from "./pages/setting/notifications/Notifications";
import EditActivitiesDetails from "./pages/accounts/autoActivities/editActivitiesDetails/EditActivitiesDetails";
import ContactColumns from "./pages/setting/contactColumns/ContactColumns";
import UpdatedContacts from "./pages/importStatus/updatedContacts/UpdatedContacts";
import UnchangedContacts from "./pages/importStatus/unchangedContacts/UnchangedContacts";
import Unsubscribed from "./pages/importStatus/unsubscribed/Unsubscribed";
import BadFormat from "./pages/importStatus/badFormat/BadFormat";
import SpecificContact from "./pages/specificContact/SpecificContact";
import ContactProfile from "./pages/specificContact/contactProfile/ContactProfile";
import SeeViews from "./pages/subscriber/subscriberPages/pages/segment/SeeViews";
import Backup from "./pages/setting/backup/Backup";
import ResetPassword from "./pages/resetPassword/ResetPassword";

function App() {
  const [constConfigData, setConstConfigData] = useState();

  const constantConfigGET = async () => {
    let response = await constantConfigGETApi();
    setConstConfigData(response.data?.GLOBAL_TEXTS);
  };

  useEffect(() => {
    constantConfigGET();
  }, []);

  const router = createBrowserRouter([
    {
      path: "/login",
      element: (
        <AuthRoute>
          <Login />
        </AuthRoute>
      ),
    },
    {
      path: "/reset-password/:token",
      element: (
        <AuthRoute>
          <ResetPassword />
        </AuthRoute>
      ),
    },
    {
      path: "",
      element: (
        <PrivateRoute>
          <Home />{" "}
        </PrivateRoute>
      ),
      children: [
        {
          path: "dashboard",
          element: <Dashboard />,
        },
        {
          path: "contacts",
          element: (
            <PrivateRoute>
              <Subscribe />
            </PrivateRoute>
          ),
          children: [
            {
              path: "",
              children: [
                {
                  path: "",
                  element: <Subsribe />,
                },
                {
                  path: "profile/:id",
                  element: <SpecificContact />,
                  children:[
                    {
                      path:"",
                      element:<ContactProfile />
                    }
                  ]
                },
                {
                  path: "import/",
                  children: [
                    {
                      path: "",
                      element: <ImportUploadCSV />,
                    },
                    {
                      path: "md-pdl",
                      element: <ImportUploadMD />,
                    },
                    {
                      path: "copy-paste",
                      element: <CopyPasteExcel />,
                    },
                    {
                      path: "add-single",
                      element: <AddSingleSubscriber />,
                    },
                    {
                      path: "mail-champ",
                      element: <ImportMailChamp />,
                    },
                    {
                      path: "match-columns",
                      element: <MatchColumns />,
                    },
                    {
                      path: "status/:id",
                      element: <ImportStatus />,
                      children:[
                        {
                          path:"",
                          element:<UpdatedContacts />
                        },
                        // {
                        //   path:"unchanged-contacts",
                        //   element:<UnchangedContacts />
                        // },
                        // {
                        //   path:"unsubscribed",
                        //   element:<Unsubscribed />
                        // },
                        {
                          path:"bad-format",
                          element:<BadFormat />
                        }
                      ]
                    },
                  ],
                },
              ],
            },
            {
              path: "segment",
              // element: <Segment />,
              children: [
                {
                  path: "",
                  element: <Segment />,
                },
                {
                  path: ":id",
                  children: [
                    {
                      path: "",
                      element: <SeeViews />,
                    },
                  ],
                },
              ],
            },
            {
              path: "group",
              element: <Group />,
            },
            {
              path: "history",
              element: <History />,
            },
          ],
        },
        {
          path: "accounts/",
          // element: (
          //   <PrivateRoute>
          //     <></>
          //   </PrivateRoute>
          // ),
          children: [
            {
              path: "",
              element: <EmsConnection />,
            },
            {
              path: "auto-activities/",
              // element: <AutoActivities />,
              children:[
                {
                  path: "",
                  element: (
                    <PrivateRoute>
                      <AutoActivities />
                    </PrivateRoute>
                  ),
                },
                {
                  path: "edit-activity",
                  element: (
                    <PrivateRoute>
                      <EditActivitiesDetails />
                    </PrivateRoute>
                  ),
                },
              ]
            },
            {
              path: "add-new-account",
              element: (
                <PrivateRoute>
                  <AddNewAccounts />
                </PrivateRoute>
              ),
            },
            {
              path: "edit-account",
              element: (
                <PrivateRoute>
                  <EditAccounts />
                </PrivateRoute>
              ),
            },
            
          ],
        },
        {
          path: "constant-config",
          element: (
            <PrivateRoute>
              <ConstantConfig />
            </PrivateRoute>
          ),
          children: [
            {
              path: "",
              element: <ContactColumns/>,
            },
            {
              path: "my-profile",
              element: <MyProfile />,
            },
            {
              path: "notifications",
              element: <Notifications/>,
            },
            {
              path:"backup",
              element:<Backup/>
            }
          ],
        },
        {
          path: "change-password",
          element: (
            <PrivateRoute>
              <ChangePassword />
            </PrivateRoute>
          ),
        },
        // {
        //   path: "segment",
        //   element: (
        //     <PrivateRoute>
        //       <Segment />
        //     </PrivateRoute>
        //   ),
        // },
      ],
    },

    // {

    //   path: "/home/dashboard",
    //   element: (
    //     <Login />
    //   ),
    // },
  ]);
  return (
    <>
      <MyContext.Provider value={ constConfigData }>
        <RouterProvider router={router} />
        <ToastContainer position="top-right" />
      </MyContext.Provider>
    </>
  );
}

export default App;
