import React, { useState } from "react";
import Syncfuse from "../../../components/syncfusion/Syncfuse";

function ContactColumns() {
  const [selectedRow, setSelectedRow] = useState([]);
  return (
    <div>
      <Syncfuse selectedRow={selectedRow} setSelectedRow={setSelectedRow} />
    </div>
  );
}

export default ContactColumns;
