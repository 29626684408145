import { useEffect, useState } from "react";
import Select from "react-dropdown-select";
import "./filter.css";
import SmallLoaderComponent from "../../components/smallLoaderComponent/SmallLoaderComponent";

const FilterElement = ({
  options,
  placeholder = "Choose a filter",
  onChange,
  value,
  firstChoose,
  groups,
  rules,
  index,
  childIndex,
  ruleRow,
  specialRule,
  groupLoader,
  setFilterBtnFlg2,
}) => {
  const [groupModal, setGroupModal] = useState(false);
  const [firstOpt, setFirstOpt] = useState();
  const [hasInput, setHasinput] = useState([]);
  const [rule, setRule] = useState([]);
  const [selectedOptions, setSelectedOptions] = useState(value);

  useEffect(() => {
    if (rules) {
      setRule(rules);
    }
  }, [rule]);

  // console.log("<========rules_ji========>", rules);
  // console.log("value================>",value)

  const handleClick = () => {
    setGroupModal(!groupModal);
  };

  useEffect(() => {}, [hasInput]);

  if (rules?.args[0][0]?.key) {
    sessionStorage.setItem(
      `local${(index, childIndex)}`,
      rules?.args[0][0]?.key
    );
  }

  useEffect(() => {
    const handleClickOutside = (event) => {
      let target = event.target;
      let isInsideDropdown = target.closest(".filter-4-option");
      // console.log("outside",isInsideDropdown)
      if (!isInsideDropdown) {
        // console.log("inside",isInsideDropdown)
        setGroupModal(false);
      }
    };

    document.addEventListener("click", handleClickOutside);

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    onChange(selectedOptions);
  }, [selectedOptions]);

  const handleOptionChange = (option, isChecked) => {
    console.log("isChecked", isChecked);
    const isSelected = selectedOptions.some(
      (item) => item._id === option._id && item.name === option.name
    );

    let updatedOptions;

    if (isChecked) {
      // Add the option to selectedOptions
      updatedOptions = isSelected
        ? selectedOptions
        : [...selectedOptions, option];
    } else {
      // Remove the option from selectedOptions
      updatedOptions = selectedOptions.filter(
        (item) => !(item._id === option._id && item.name === option.name)
      );
    }

    setSelectedOptions(updatedOptions);
    onChange(updatedOptions);
    setFilterBtnFlg2(true);
  };

  return options?.length ? (
    <Select
      style={{
        border: "1px solid #e2e2e2",
        padding: "0px 15px",
        borderRadius: "4px",
        backgroundColor: "white",
        minWidth: "12rem",
      }}
      multi={false}
      values={value}
      options={options}
      labelField="value"
      valueField="key"
      placeholder={placeholder}
      onChange={(values) => {
        onChange(values);
        setHasinput(values);
      }}
      onDropdownClose={()=>{setFilterBtnFlg2(true)}}
      /* onClearAll={()=>{
        onChange([])
      }} */
    />
  ) : (
    <div
      className="filter-4-option"
      style={{ position: "absolute", paddingTop: "1px", paddingLeft: "10px" }}
    >
      <div>
        {firstChoose == "groups" || specialRule?.args[0][0]?.key == "groups" ? (
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              paddingLeft: "8px",
              overflow: "scroll",
            }}
            className="dropDownBtn"
            onClick={handleClick}
          >
            {value?.length > 0 && Array.isArray(value)
              ? value?.map((e) => {
                console.log("Value of group",value)
                  return (
                    <p
                      style={{
                        marginRight: "5px",
                        backgroundColor: "rgb(228 228 228)",
                        padding: "4px 10px",
                        color: "gray",
                        borderRadius: "2px",
                        whiteSpace: "nowrap",
                      }}
                    >
                      {e.name}
                    </p>
                  );
                })
              : "Select group"}
          </div>
        ) : (
          <input
            value={value}
            onChange={(event) => {
              onChange(event.target.value);
              setFilterBtnFlg2(true);
            }}
            placeholder={"Type here"}
            style={{
              padding: "9px 15px",
              border: "1px solid #e2e2e2",
              borderRadius: "4px",
            }}
          />
        )}
      </div>
      {groupModal && (
        <div className="dropdown">
          {groupLoader ? (
            <div className="loader">
              <SmallLoaderComponent />
            </div>
          ) : (
            <div>
              {groups.map((e) => {
                return (
                  <div
                    className="check"
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      padding: "0.5rem",
                    }}
                  >
                    {}
                    <label key={e} />
                    <input
                      style={{ height: "1.1rem" }}
                      type="checkbox"
                      value={e.name}
                      checked={value.some(
                        (item) => item._id === e._id && item.name === e.name
                      )}
                      onChange={(event) =>
                        handleOptionChange(e, event.target.checked)
                      }
                    ></input>
                    <p style={{ paddingLeft: "0.5rem", color: "gray" }}>
                      {e.name}
                    </p>
                  </div>
                );
              })}
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default FilterElement;
