import "./ContactProfileEditModal.css";
import { IoMdClose } from "react-icons/io";
import { FaPlus } from "react-icons/fa6";
import { GoChevronDown, GoPlus } from "react-icons/go";
import MinusSVG from "../../../../asset/svg/MinusSVG";
import { useEffect, useState } from "react";
import { IoClose } from "react-icons/io5";
import {
  groupGetAPI,
  updateContactAPI,
} from "../../../../services/servicesAPI";
import { convertToReadableFormat } from "../../../../utils/Utils";
import { valCustomPlacement } from "@syncfusion/ej2-react-grids";
import { toast } from "react-toastify";

const ContactProfileEditModal = ({
  onClose,
  editContactProfileDetails,
  setEditProfileModal,
}) => {
  let avoidKey = [
    "_id",
    "phone",
    "companyWebsite",
    "companyLinkedInURL",
    "companyFounded",
    "companyStreetAddress",
    "companyCity",
    "companyState",
    "companyCountry",
    "companyName",
    "email",
    "city",
    "state",
    "streetAddress",
    "zipCode",
  ];

  console.log("editContactProfileDetails", editContactProfileDetails);

  const [groupDropdownFlag, setGroupDropdownFlag] = useState(false);
  const [groupDropdownList, setGroupDropdownList] = useState([]);
  const [selectedGroupData, setSelectedGroupData] = useState([]);
  const [editDetails, setEditDetails] = useState([]);
  const [editEmailDetails, setEditEmailDetails] = useState([]);
  const [editPhoneDetails, setEditPhoneDetails] = useState([]);
  const [editAddressDetails, setEditAddressDetails] = useState([]);
  const [editOtherDetails, setEditOtherDetails] = useState([]);
  const [refreshPage, setRefreshPage] = useState(false);
  const [payloadForEdit, setPayloadForEdit] = useState({});

  useEffect(() => {
    const entries = Object.entries(editContactProfileDetails);
    console.log("entries", entries);
    let temp = entries.filter((item) => {
      if (avoidKey.includes(item[0])) {
        return false;
      } else {
        return true;
      }
    });
    console.log("temp====", temp);
    temp.map((item) => {
      if (item[0] === "contactEmailData") {
        console.log("inside 0", item[1]);
        setEditEmailDetails(item[1]);
      } else if (item[0] === "phoneData") {
        setEditPhoneDetails(item[1]);
      } else if (item[0] === "addressData") {
        console.log("editAddressDEtai===================>");
        setEditAddressDetails(item[1]);
      } else {
        // setEditOtherDetails([...editOtherDetails,item])
        editOtherDetails.push(item);
      }
    });

    // setEditDetails(editContactProfileDetails)

    groupGetDropdownAPI();
  }, []);

  const groupGetDropdownAPI = async () => {
    const response = await groupGetAPI(
      { page: 1, limit: 25 },
      {
        sortBy: "createdAt",
        order: "DESC",
        // "textSearch":"ramtest-(1)-1714970701973-(1)-1714976506204"
      }
    );
    if (response.success) {
      console.log("response=========>", response?.data?.data?.result);
      let temp = response?.data?.data?.result.map((item) => {
        item.isChecked = false;
        return item;
      });
      setGroupDropdownList(temp);
    } else {
      console.log("error=========>", response);
    }
  };
  const checkSelectedGroupPresentOrNot = (value) => {
    for (let i = 0; i < selectedGroupData.length; i++) {
      if (selectedGroupData[i]._id === value._id) {
        selectedGroupData.splice(i, 1);
        return true;
      }
    }
    return false;
  };

  const handleClearSelectedData = (value) => {
    for (let i = 0; i < selectedGroupData.length; i++) {
      if (selectedGroupData[i]._id === value._id) {
        value.isChecked = false;
        selectedGroupData.splice(i, 1);
        return;
      }
    }
  };

  const handleSelectedGroupData = (value) => {
    console.log("value======>", value);
    let temp = checkSelectedGroupPresentOrNot(value);
    console.log("temp======>", temp);

    if (temp) {
      value.isChecked = false;
    } else {
      value.isChecked = true;
      setSelectedGroupData([...selectedGroupData, value]);
    }

    setGroupDropdownFlag(false);
  };

  const handleAddSnippet = (data, payload) => {
    console.log("data======>", payload);
    console.log("data======>", data);
    data.push(payload);
    setRefreshPage(!refreshPage);
  };

  const handleClearSnippet = (data, item, index) => {
    const updatedContacts = data.map((contact, i) =>
      i === index ? { ...contact, isDeleted: true } : contact
    );
    if (item?.email) {
      setEditEmailDetails(updatedContacts);
    }
    if (item?.phone) {
      setEditPhoneDetails(updatedContacts);
    }
    if (item?.streetAddress) {
      setEditAddressDetails(updatedContacts);
    }
    console.log("UpdatedContacts", updatedContacts);

    // console.log("editEmail",editEmailDetails)
    // console.log("data",data)
    // data.splice(index, 1);
    console.log("data", data);
    // console.log("editEmail",editEmailDetails)
    console.log("clicked index=====", index);
    console.log("clicked data", item);
    setRefreshPage(!refreshPage);
  };

  const handleEmailTypeChange = (value, index) => {
    console.log(value, index);
    const updatedEmailDetails = [...editEmailDetails];
    updatedEmailDetails[index].type = value;
    setEditEmailDetails(updatedEmailDetails);
  };
  const handleEmailChange = (value, index) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const isValidEmail = emailRegex.test(value);
    const updatedEmailDetails = [...editEmailDetails];
    updatedEmailDetails[index].email = value;
    // updatedEmailDetails[index]._id=""
    // updatedEmailDetails[index].isValid = isValidEmail;
    setEditEmailDetails(updatedEmailDetails);
  };

  const handleOtherChange = (value, index) => {
    console.log(editOtherDetails);
    const otherDetails = [...editOtherDetails];
    otherDetails[index][1] = value;
    setEditOtherDetails(otherDetails);
  };

  const handleEditPhoneDetails = (value, index) => {
    console.log(value);
    console.log(editEmailDetails);
    const updatePhoneDetails = [...editPhoneDetails];
    updatePhoneDetails[index] = { phone: value };
    setEditPhoneDetails(updatePhoneDetails);
  };
  console.log("editPhoneDetails", editPhoneDetails);

  const handleEditAddressDetails = (value, index, key) => {
    const updateAddressDetails = [...editAddressDetails];
    updateAddressDetails[index][key] = value;
    setEditAddressDetails(updateAddressDetails);
  };

  useEffect(() => {
    const createPaylodForEditContact = () => {
      let payloadObj = {
        contactData: editOtherDetails.reduce((acc, [key, value]) => {
          if (
            key === "isActive" ||
            key === "HBCUName" ||
            key === "HBCUShort" ||
            key === "HBCUSpirit" ||
            key === "addrIds" ||
            key === "emailIds"
          ) {
          } else {
            acc[key] = value;
          }

          return acc;
        }, {}),
        emails: [
          {
            email: "testupdate@yopmail.com",
          },
        ],
        // selectedGroup:selectedGroupData,
        emails: editEmailDetails.map(({ isValid, ...rest }) => rest),
        addresses: editAddressDetails.map(({contactId, ...rest}) => rest),
        phoneNumbers: editPhoneDetails.map(({ type, ...rest }) => rest),
      };
      setPayloadForEdit(payloadObj);
    };
    createPaylodForEditContact();
  }, [
    editAddressDetails,
    editEmailDetails,
    editPhoneDetails,
    selectedGroupData,
    editOtherDetails,
  ]);

  console.log("FinalPayload", payloadForEdit);

  const handleSave = async () => {
    const response = await updateContactAPI(
      editContactProfileDetails?._id,
      payloadForEdit
    );
    if (response.success) {
      toast.success(response.message);
      setEditProfileModal(false);
    } else {
      toast.error(response.message);
    }
  };

  let resEmail = editAddressDetails.map(({ isValid, ...rest }) => rest);
  console.log("RESSSSS", resEmail);

  console.log("SelectedGroup", selectedGroupData);
  console.log("EditOtherDetails", editEmailDetails);
  console.log("EditAddressDetails", editAddressDetails);

  return (
    <div className="cpe-modal">
      <div style={{ width: "70%" }}>
        <div className="cpe-modal-close">
          <div
            onClick={() => {
              onClose();
            }}
          >
            <IoMdClose size={16} />
          </div>
        </div>
        <div className="cpe-modal-content">
          <div className="cpe-modal-content-heading">
            <h1>Contact details</h1>
          </div>
          <div className="cpe-modal-content-email">
            <div className="cpe-modal-content-email-1">
              <h1>Emails</h1>
              <div
                onClick={() => {
                  handleAddSnippet(editEmailDetails, { email: "", type: "" });
                }}
                style={{ cursor: "pointer" }}
              >
                <FaPlus size={10} />
              </div>
            </div>

            <div className="cpe-modal-content-email-row">
              {editEmailDetails.map((item, index) => {
                if (item?.isDeleted == true) {
                } else {
                  return (
                    <div className="cpe-modal-content-email-row-1">
                      <div className="cpe-modal-content-email-row-1-1">
                        <div className="cpe-modal-content-email-row-1-1-left">
                          <p>Email type</p>
                        </div>
                        <div className="cpe-modal-content-email-row-1-1-right">
                          <p>Email address</p>
                        </div>
                      </div>
                      <div className="cpe-modal-content-email-row-1-2">
                        <div className="cpe-modal-content-email-row-1-2-left">
                          <input
                            onChange={(e) => {
                              handleEmailTypeChange(e.target.value, index);
                            }}
                            value={item.type}
                            placeholder="Email type"
                            type="text"
                          />
                        </div>
                        <div className="cpe-modal-content-email-row-1-2-right">
                          <input
                            onChange={(e) => {
                              handleEmailChange(e.target.value, index);
                            }}
                            type="text"
                            value={item.email}
                            placeholder="email@test.com"
                          />
                          <div
                            onClick={() => {
                              handleClearSnippet(editEmailDetails, item, index);
                            }}
                            style={{ cursor: "pointer" }}
                          >
                            <MinusSVG color={"#EF4444"} />
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                }
              })}
              {/* <div className="cpe-modal-content-email-row-1">
                        <div className="cpe-modal-content-email-row-1-1">
                            <div className="cpe-modal-content-email-row-1-1-left">
                               <p>Email type</p>
                            </div>
                            <div className="cpe-modal-content-email-row-1-1-right">
                                <p>Email address</p>
                            </div>
                        </div>
                        <div className="cpe-modal-content-email-row-1-2">
                            <div className="cpe-modal-content-email-row-1-2-left">
                                <input placeholder="Primary" type="text" />
                            </div>
                            <div className="cpe-modal-content-email-row-1-2-right">
                            <input type="text" placeholder="email1@gmail.com" />
                            <div >
                                <MinusSVG color={"#EF4444"} /> 
                            </div>
                            
                            </div>
                        </div>
                    </div> */}
            </div>
          </div>

          <div className="cpe-modal-content-email">
            <div className="cpe-modal-content-email-1">
              <h1>Phone</h1>
              <div
                onClick={() => {
                  handleAddSnippet(editPhoneDetails, { phone: "", type: "" });
                }}
                style={{ cursor: "pointer" }}
              >
                <FaPlus size={10} />
              </div>
            </div>

            <div className="cpe-modal-content-phone-row">
              {editPhoneDetails.map((item, index) => {
                if (item?.isDeleted == true) {
                } else {
                  return (
                    <div className="cpe-modal-content-phone-row-item">
                      <div className="cpe-modal-content-phone-row-item-1">
                        <p>Phone</p>
                      </div>
                      <div className="cpe-modal-content-phone-row-item-2">
                        <input
                          onChange={(e) => {
                            handleEditPhoneDetails(e.target.value, index);
                          }}
                          value={item.phone}
                          type="text"
                          placeholder="(919) 995-1234"
                        />
                        <div
                          onClick={() => {
                            handleClearSnippet(editPhoneDetails, item, index);
                          }}
                          style={{ cursor: "pointer" }}
                        >
                          <MinusSVG color={"#EF4444"} />
                        </div>
                      </div>
                    </div>
                  );
                }
              })}
            </div>
          </div>
          <div className="cpe-modal-content-email">
            <div className="cpe-modal-content-email-1">
              <h1>Address</h1>
              <div
                onClick={() => {
                  handleAddSnippet(editAddressDetails, {
                    streetAddress: "",
                    city: "",
                    state: "",
                    country: "",
                    zipCode: "",
                  });
                }}
                style={{ cursor: "pointer" }}
              >
                <FaPlus size={10} />
              </div>
            </div>
            <div className="cpe-modal-content-address-row">
              {editAddressDetails.map((item, index) => {
                if (item?.isDeleted == true) {
                } else {
                  return (
                    <div style={{ margin: "5vh 0px" }}>
                      <div className="cpe-modal-content-address-row-item-heading">
                        <div
                          onClick={() => {
                            handleClearSnippet(editAddressDetails, item, index);
                          }}
                          style={{ cursor: "pointer" }}
                        >
                          <MinusSVG color={"#EF4444"} />
                        </div>
                        <p>Address {index + 1}</p>
                        <div
                          style={{
                            backgroundColor: "white",
                            marginLeft: "10px",
                          }}
                        >
                          <GoChevronDown />
                        </div>
                      </div>
                      <div className="cpe-modal-content-address-row-item-content">
                        <div className="cpe-modal-content-phone-row-item">
                          <div className="cpe-modal-content-phone-row-item-1">
                            <p>Street</p>
                          </div>
                          <div className="cpe-modal-content-phone-row-item-2">
                            <input
                              onChange={(e) => {
                                handleEditAddressDetails(
                                  e.target.value,
                                  index,
                                  "streetAddress"
                                );
                              }}
                              value={item?.streetAddress}
                              type="text"
                              placeholder="Enter street"
                            />
                            {/* <div >
                                <MinusSVG color={"#EF4444"} /> 
                            </div> */}
                          </div>
                        </div>
                        <div className="cpe-modal-content-phone-row-item">
                          <div className="cpe-modal-content-phone-row-item-1">
                            <p>City</p>
                          </div>
                          <div className="cpe-modal-content-phone-row-item-2">
                            <input
                              onChange={(e) => {
                                handleEditAddressDetails(
                                  e.target.value,
                                  index,
                                  "city"
                                );
                              }}
                              value={item?.city}
                              type="text"
                              placeholder="Enter city"
                            />
                            {/* <div >
                                <MinusSVG color={"#EF4444"} /> 
                            </div> */}
                          </div>
                        </div>
                        <div className="cpe-modal-content-phone-row-item">
                          <div className="cpe-modal-content-phone-row-item-1">
                            <p>State</p>
                          </div>
                          <div className="cpe-modal-content-phone-row-item-2">
                            <input
                              onChange={(e) => {
                                handleEditAddressDetails(
                                  e.target.value,
                                  index,
                                  "state"
                                );
                              }}
                              value={item?.state}
                              type="text"
                              placeholder="Enter state"
                            />
                            {/* <div >
                                <MinusSVG color={"#EF4444"} /> 
                            </div> */}
                          </div>
                        </div>
                        <div className="cpe-modal-content-phone-row-item">
                          <div className="cpe-modal-content-phone-row-item-1">
                            <p>Zip</p>
                          </div>
                          <div className="cpe-modal-content-phone-row-item-2">
                            <input
                              onChange={(e) => {
                                handleEditAddressDetails(
                                  e.target.value,
                                  index,
                                  "zipCode"
                                );
                              }}
                              value={item?.zipCode}
                              type="text"
                              placeholder="Enter zip code"
                            />
                            {/* <div >
                                <MinusSVG color={"#EF4444"} /> 
                            </div> */}
                          </div>
                        </div>
                        <div className="cpe-modal-content-phone-row-item">
                          <div className="cpe-modal-content-phone-row-item-1">
                            <p>Country</p>
                          </div>
                          <div className="cpe-modal-content-phone-row-item-2">
                            <input
                              onChange={(e) => {
                                handleEditAddressDetails(
                                  e.target.value,
                                  index,
                                  "country"
                                );
                              }}
                              value={item?.country}
                              type="text"
                              placeholder="Enter zip code"
                            />
                            {/* <div >
                                <MinusSVG color={"#EF4444"} /> 
                            </div> */}
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                }
              })}
            </div>
          </div>

          <div className="cpe-modal-content-other">
            <div className="cpe-modal-content-other-heading">
              <h1>Other details</h1>
            </div>
            <div
              style={{ width: "100%" }}
              className="cpe-modal-content-other-item"
            >
              <div className="cpe-modal-content-other-item-label">
                <p>Select Group</p>
              </div>
              <div
                style={{ position: "relative" }}
                className="cpe-modal-content-other-item-input"
              >
                {/* <input  type="text" /> */}
                <div
                  onClick={() => {
                    setGroupDropdownFlag(!groupDropdownFlag);
                  }}
                  className="cpe-modal-content-other-item-input-div"
                >
                  {selectedGroupData.map((item) => {
                    return (
                      <span>
                        {item.name}
                        <IoClose
                          onClick={() => {
                            handleClearSelectedData(item);
                          }}
                          style={{
                            marginLeft: "5px",
                            cursor: "pointer",
                            color: "#404040",
                          }}
                        />{" "}
                      </span>
                    );
                  })}
                  <p style={{ color: "gray" }}>Select Group</p>
                </div>
                {groupDropdownFlag && (
                  <div className="cpe-modal-content-other-item-input-dp">
                    <ul>
                      {groupDropdownList.map((item) => {
                        return (
                          <li
                            onClick={() => {
                              handleSelectedGroupData(item);
                            }}
                          >
                            {" "}
                            <input
                              checked={item.isChecked}
                              type="checkbox"
                              name=""
                              id=""
                            />{" "}
                            {item?.name}
                          </li>
                        );
                      })}
                    </ul>
                  </div>
                )}
              </div>
            </div>
            <div className="cpe-modal-content-other-content">
              {editOtherDetails.map((item, index) => {
                return (
                  <div className="cpe-modal-content-other-item">
                    <div className="cpe-modal-content-other-item-label">
                      <p>{convertToReadableFormat(item[0])}</p>
                    </div>
                    <div className="cpe-modal-content-other-item-input">
                      <input
                        onChange={(e) => {
                          handleOtherChange(e.target.value, index);
                        }}
                        value={item[1]}
                        type="text"
                      />
                    </div>
                  </div>
                );
              })}
            </div>
          </div>

          <div className="cpe-modal-content-footer">
            <button
              onClick={() => {
                onClose();
              }}
            >
              Cancel
            </button>
            <button
              onClick={() => {
                handleSave();
              }}
              style={{ backgroundColor: "#2081E2", color: "#ffffff" }}
            >
              Submit
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
export default ContactProfileEditModal;
