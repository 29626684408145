import { GoChevronDown } from "react-icons/go";
import BreadCrumbsComponent from "../../components/breadCrumbsComponent/BreadCrumbsComponent";
import "./SpecificContact.css";
import { Outlet } from "react-router";
import SpecificContactNavbar from "./specificContactNavbar/SpecificContactNavbar";
import { useEffect, useState } from "react";
import DeleteSVG from "../../asset/svg/DeleteSVG";
import ProhibitedSVG from "../../asset/svg/ProhibitedSVG";
import { toast } from "react-toastify";
import LoaderComponent from "../../components/loaderComponent/LoaderComponent";
import GeneralConfirmModal from "../../modal/generalConfirmModal/GeneralConfirmModal";
import { useLocation } from "react-router-dom";
const SpecificContact = () => {
  const location = useLocation();

  const [actionFlag, setActionFlag] = useState(false);
  const [profileDetails, setProfileDetails] = useState({});
  const [loader, setLoader] = useState(false);
  const [deleteModalFlag, setDeleteModalFlag] = useState(false);
  const [unsubscribedModalFlag, setUnsubscribedModalFlag] = useState(false);

  useEffect(() => {
    console.log(
      "location inside specific Contact========>",
      location.state?.data?.contactProfileDetails
    );
    setProfileDetails(location.state?.data?.contactProfileDetails);
    // getContactProfileById()
  }, []);
  console.log(
    "profileDetails",
    profileDetails,
    location.state?.data?.contactProfileDetails
  );

  

  // Define your function to be called on click
  // function handleClick() {
  //     // Your code here
  //     console.log("Window clicked!");
  //     if(actionFlag){
  //         setActionFlag(false)

  //     }

  // }

  // Add an event listener to the window for the 'click' event
  // window.addEventListener('click', handleClick,true);

  return (
    <div className="sp-contact">
      {loader && <LoaderComponent />}
      {unsubscribedModalFlag && (
        <GeneralConfirmModal
          onSuccess={() => {}}
          descText={
            "Do you really want to change contact status to “Unsubscribed”?"
          }
          headingText={"Please confirm"}
          onclose={() => {
            setUnsubscribedModalFlag(false);
          }}
        />
      )}
      {deleteModalFlag && (
        <GeneralConfirmModal
          onSuccess={() => {}}
          descText={"Are you sure you want to delete contact: devon grant?"}
          headingText={"Please confirm"}
          onclose={() => {
            setDeleteModalFlag(false);
          }}
        />
      )}
      <div style={{ height: "4vh" }}></div>
      <div className="sp-contact-breadcrumb">
        <BreadCrumbsComponent
          routeData={[
            { key: "Contacts", value: "/contacts" },
            {
              key: `${location.state?.data?.contactProfileDetails?.firstName} ${location.state?.data?.contactProfileDetails?.lastName}`,
            },
          ]}
        />
      </div>
      <div className="sp-contact-heading">
        <h1>
          {profileDetails.firstName} {profileDetails.lastName}{" "}
        </h1>
        <div style={{ position: "relative" }}>
          <div
            onClick={() => {
              setActionFlag(!actionFlag);
            }}
            className="sp-contact-heading-btn"
          >
            <button>Actions</button>
            <GoChevronDown style={{ marginLeft: "0.6vw" }} />
          </div>
          {actionFlag && (
            <div className="sp-contact-dropdown">
              <ul>
                <li
                  onClick={() => {
                    setActionFlag(false);
                    setUnsubscribedModalFlag(true);
                  }}
                >
                  <div style={{ marginRight: "10px", alignContent: "center" }}>
                    <ProhibitedSVG />
                  </div>
                  Unsubscribe
                </li>
                <li
                  onClick={() => {
                    setActionFlag(false);
                    setDeleteModalFlag(true);
                  }}
                >
                  <div style={{ marginRight: "10px", alignContent: "center" }}>
                    <DeleteSVG />{" "}
                  </div>
                  Delete
                </li>
              </ul>
            </div>
          )}
        </div>
      </div>
      <div className="sp-contact-label">
        <p>Active</p>
      </div>
      <div>
        <SpecificContactNavbar />
      </div>

      <Outlet />
    </div>
  );
};

export default SpecificContact;
