const DeleteSVG = () => {
  return (
    <div>
      <svg
        width="16"
        height="15"
        viewBox="0 0 16 15"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M1.5 4.39627H3.45961M14.5 4.39627H12.4959M5.15392 4.39627V2.41958C5.15392 1.85481 5.71869 1.29004 6.28345 1.29004H9.67207C10.2368 1.29004 10.8016 1.85481 10.8016 2.41958V4.39627M5.15392 4.39627H10.8016M5.15392 4.39627H3.45961M10.8016 4.39627H12.4959M3.45961 4.39627C3.45961 4.39627 3.45961 11.2393 3.45961 12.0207C3.45961 12.802 3.9679 13.715 4.87153 13.715C5.77516 13.715 8.91904 13.715 10.8016 13.715C11.3664 13.715 12.4959 13.3761 12.4959 12.0207C12.4959 10.6652 12.4959 6.37296 12.4959 4.39627"
          stroke="#404040"
          stroke-width="1.3"
          stroke-linecap="round"
        />
      </svg>
    </div>
  );
};
export default DeleteSVG;
