import { useContext, useEffect, useState } from "react";
import "./AccountsNavbar.css";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import { MyContext } from "../../../ContextAPIStore/CreateContext";
const AccountsNavbar = () => {
  const constConfigData = useContext(MyContext);
  // const EMS_CONNECTIONS = "EMS_CONNECTIONS";
  // const AUTO_ACTIVITIES = "AUTO_ACTIVITIES";
  // const navigate = useNavigate();
  // const location = useLocation();
  // console.log("location=====>", location?.state);

  // const [accountNavbarFlag, setAccountNavbarFlag] = useState({
  //   key: EMS_CONNECTIONS,
  //   value: true,
  // });

  // useEffect(() => {
  //   let obj = {
  //     key:
  //       location.state?.fromScreen === EMS_CONNECTIONS ||
  //       location.state?.fromScreen === "ACCOUNTS"
  //         ? EMS_CONNECTIONS
  //         : location.state?.fromScreen,
  //     value: true,
  //   };
  //   setAccountNavbarFlag(obj);
  // }, []);

  
  return (
    <div className="account-navbar">
      <ul>
        
        <li><NavLink id="accounts-nav" to="/accounts/" end > {constConfigData?.EMS_Connections}</NavLink></li>
        <li> <NavLink id="accounts-nav"  to="/accounts/auto-activities/" end >{constConfigData?.Auto_Activities}</NavLink> </li>
      </ul>
    </div>
  );
};

export default AccountsNavbar;
