import { useContext, useRef, useState } from "react";
import "./ImportUploadCSV.css";
import { MdOutlineFileUpload } from "react-icons/md";
import { IoClose } from "react-icons/io5";
import { useNavigate } from "react-router-dom";
import MatchColumnsNavbarComponent from "../../components/matchColumnsNavbarComponent/MatchColumnsNavbarComponent";
import { getPresignedUrlApi, importContactsAPI, uploadCsvFilePutAPI,getCustomFileDataGetAPI } from "../../services/servicesAPI";
import { toast } from "react-toastify";
import { MyContext } from "../../ContextAPIStore/CreateContext";
import BreadCrumbsComponent from "../../components/breadCrumbsComponent/BreadCrumbsComponent";
import { TiDocument } from "react-icons/ti";
import ProgressBarComponent from "../../components/progressBarComponent/ProgressBarComponent";
import LoaderComponent from "../../components/loaderComponent/LoaderComponent";

const ImportUploadCSV = () => {
  const constConfigData = useContext(MyContext)
  const navigate = useNavigate();
  const [file, setFile] = useState("");
  const [fileId,setFileId] = useState("")
  const [fileName,setFileName] = useState({})
  const [loaderProgressBar,setLoaderProgressBar] = useState(0)
  const [loader,setLoader] = useState(false)
  const [dragFileHoverFlag,setDragFileHoverFlag] = useState(false)

  const fileInputCSVRef = useRef(null);

  const handleFileUpload = () => {
    fileInputCSVRef.current.click();
  };

  const handleNavigate = (fileDetails,fileId) => {
    navigate("/contacts/import/match-columns", { state: { data: {file:file,fileId:fileId }} });
  };


const getPresignedURL = async (fileName)=>{

  const  response = await getPresignedUrlApi({fileName:fileName,fileType:"CUSTOM"});

  if(response.success){
    return {url:response?.data?.data?.url,id:response?.data?.data?.id}
  }else{
    toast.error(response.message)
  }


}




  const uploadCsvFilePut = async (fileObject,url)=>{

    let response = await uploadCsvFilePutAPI(fileObject,url,setLoaderProgressBar)
      if(response.success){
        toast.success("File uploaded sucessfully")

        const reader = new FileReader();

    reader.onload = (e) => {
      const csvData = e.target.result;
      const rows = csvData.split(/\r?\n/);
      const data = rows.map((row) => row.split(","));
      let tempHeading = [];
      data[0].map((item)=>{
        let temp = {};
        temp.keyName = item;
        temp.data = []
        tempHeading.push(temp)
      })
      let tempData = data.slice(1,data.length - 1);
      
      tempData.map((item1,index1)=>{
        item1.map((item2,index2)=>{
          let value = item2;
          tempHeading[index2]?.data.push(value)
        })
      })
      setFile(tempHeading);
    };
    reader.onerror = (event) => {
      console.log("File could not be read! Code " + event.target?.error?.code);
    };

    reader.readAsText(fileObject);


      }else{
        toast.error("Error during file upload")
      }

  }

  const handleCsvData = async (e) => {
    let file = e.target.files[0]
    let urlData= await getPresignedURL(file.name)
    setFileName(file)
    setFileId(urlData?.id)
    uploadCsvFilePut(file,urlData?.url)
  };
  

  const handleImportContacts = async (fileId)=>{
    console.log("fileid=====================>",fileId)
    setLoader(true)
    const response = await getCustomFileDataGetAPI(fileId)
    console.log("response button========>",response)
    if(response.success){
      // toast.success(response.message)
      handleNavigate(response?.data?.data,fileId)
    }else{
      toast.error(response.message)
    }
    setLoader(false)
  }

  const handleDropFile = async (e)=>{
    e.preventDefault();
    const droppedFile = e.dataTransfer.files[0];
    if(droppedFile.type ==="text/csv"){
      let urlData= await getPresignedURL(droppedFile.name)
    setFileName(droppedFile)
    setFileId(urlData?.id)
    uploadCsvFilePut(droppedFile,urlData?.url)
    }else{
      toast.error("Only CSV file are allowed")
    }

    
  }
  const handleDragOver = (e) => {
    e.preventDefault();
    setDragFileHoverFlag(true)
  };
  const handleDragLeave = () => {
    setDragFileHoverFlag(false);
  };

  return (
    <div className="import-csv" style={{fontSize:"1.2em", marginTop:"4vh"}}>
      {
      loader && <LoaderComponent />
      }
      <BreadCrumbsComponent routeData={[{ key:"Contacts",value:"/contacts"},{key:"Add new",}]}  />
      <div className="import-heading">
        <h1>{constConfigData?.Add_new_contacts}</h1>
      </div>
      <MatchColumnsNavbarComponent />
      <div className="import-content">
        <div className="uploadcsv">
          {/* <div className="uploadcsv-1">
            <div className="uploadcsv-heading">
              <h1>{constConfigData?.Import_Disclaimer}</h1>
            </div>
            <div className="uploadcsv-desc">
              <p>
                We’ll automatically clean duplicate instances of email addresses
                from the list.
              </p>
              <p>
                Importing does not send any confirmation emails to your list,
                because we trust you’ve already received permission.
              </p>
              <p>
                Make sure everyone on your list actually signed up for it and
                granted you permission to email them.
              </p>
            </div>
          </div> 
          <div
            style={{
              height: "1px",
              backgroundColor: "#eaeaea",
              margin: "5vh 0px",
            }}
          />*/}
          <div className="uploadcsv-2">
            <h1>{constConfigData?.Uploading_File}</h1>
            <p>CSV file with your contacts</p>
            
            {!file ? 
            (
              loaderProgressBar ?
                      <div className="uploadmd-2-1-progress-bar">
                      <ProgressBarComponent value={loaderProgressBar} />
                      </div>
                       :


                    <div className="uploadcsv-2-1"
                    style={{
                      border:`dashed 1px ${dragFileHoverFlag?"rgba(32, 129, 226, 1)":"rgb(221, 221, 221)"}`,
                      backgroundColor:dragFileHoverFlag?"rgba(32, 129, 226, 0.1)":"rgba(250, 250, 250, 1)"
                    }}
                    onDrop={(e)=>{handleDropFile(e)}}
                    onDragLeave={handleDragLeave}
                    onDragOver={handleDragOver}
                    onClick={() => {
                      handleFileUpload();
                    }}
                    >
                    <div className="uploadcsv-2-1-1">
                    <div className="uploadcsv-2-1-1-upload-icon">
                    <div className="uploadcsv-2-1-1-upload-icon-ct">
                    <div
                      
                      style={{ backgroundColor: "#e8e8e8" }}
                    >
                      <input
                      
                        value={file}
                        onChange={(e) => {
                          
                          handleCsvData(e);
                        }}
                        ref={fileInputCSVRef}
                        accept=".csv"
                        type="file"
                        id="csvFile"
                      />
                      <MdOutlineFileUpload size={20} />
                    </div>
                    <h3>Drop a csv file</h3>
                    <p>or select if from computer</p>

                    </div>
                    </div>
                    </div>
                    </div>

                  ) : (
                    <div className="uploadcsv-2-1-2"
                    >
                    <div className="uploadcsv-2-1-1">
                    <div className="uploadcsv-2-1-1-upload-icon">
                    <div className="uploadcsv-2-1-1-upload-icon-ct-2">
                    <div style={{padding:"0.6em",borderRadius:"4px",display:"flex",justifyContent:"center",alignItems:"center",backgroundColor:"#2081E3"}}>
                      <TiDocument color="#ffffff" size={20} />
                    </div>
                      <div
                        style={{
                          display: "flex",
                          width: "100%",
                          justifyContent: "space-between",
                          alignItems: "center",
                          marginTop: "3vh"
                        }}
                      >
                        <p style={{ fontSize: "1em", color: "#2081E3" }}>
                        {
                          fileName?.name
                        }
                        </p>
                        <div style={{cursor:"pointer",marginLeft:"10px",display:"flex",justifyContent:"center",alignItems:"center"}}>
                          <IoClose
                          size={20}
                          color="#2081E3"
                            onClick={() => {
                              setFile("");
                            }}
                          />
                        </div>
                      </div>
                      </div>
                      </div>
                      </div>
                      </div>
                  )}


            <div className="uploadcsv-2-2">
              <button
              style={{backgroundColor:file?"#2081E3":"gray"}}
                disabled={!file?true:false}
                onClick={() => {
                  handleImportContacts(fileId)
                }}
              >

                {constConfigData?.import_contacts} 
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ImportUploadCSV;
