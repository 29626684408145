import React from "react";
import { useLocation } from 'react-router-dom';

function Dashboard({ isActive }) {
  // const strokeColor = isActive ? '#2081E3' : '#262626';
  const location = useLocation();

  // Check the current pathname
  const currentPath = location.pathname;

  // Use currentPath to determine if it matches a specific route
  const isDashboardActive = currentPath.includes('/dashboard');

  const strokeColor = isDashboardActive ? '#2081E3' : '#262626';
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
      //  style={{"stroke": `${"#2081E3"}`}}
      style={{"stroke":strokeColor}}
        d="M18.5 10C18.5 14.6944 14.6944 18.5 10 18.5C5.30558 18.5 1.5 14.6944 1.5 10C1.5 5.30558 5.30558 1.5 10 1.5M18.5 10C18.5 5.30558 14.6944 1.5 10 1.5M18.5 10H10V1.5"
        stroke-width="1.7"
      />
    </svg>
  );
}

export default Dashboard;
