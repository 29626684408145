const SuccessfullChecked = ({style}) => {
  return (
    <svg
      width="16"
      height="17"
      viewBox="0 0 16 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M6.53875 3.43368C7.68237 3.1753 8.87886 3.29351 9.9498 3.77069C10.2777 3.9168 10.662 3.76942 10.8081 3.44151C10.9542 3.11361 10.8068 2.72934 10.4789 2.58323C9.1519 1.99196 7.66931 1.84548 6.25226 2.16564C4.8352 2.48581 3.55961 3.25546 2.61571 4.35982C1.67181 5.46418 1.11019 6.84407 1.0146 8.29369C0.919016 9.74331 1.29458 11.185 2.0853 12.4037C2.87601 13.6225 4.0395 14.553 5.40224 15.0564C6.76499 15.5599 8.25397 15.6094 9.64712 15.1974C11.0403 14.7855 12.263 13.9343 13.1328 12.7707C14.0027 11.6072 14.4731 10.1936 14.4739 8.74081L14.4739 8.74044V8.17435C14.4739 7.81537 14.1829 7.52435 13.8239 7.52435C13.4649 7.52435 13.1739 7.81537 13.1739 8.17435V8.74007C13.1732 9.91251 12.7936 11.0533 12.0916 11.9924C11.3896 12.9314 10.4029 13.6184 9.27853 13.9508C8.15421 14.2832 6.95255 14.2433 5.85276 13.837C4.75298 13.4307 3.81401 12.6797 3.17587 11.6962C2.53774 10.7126 2.23464 9.54912 2.31179 8.37923C2.38893 7.20933 2.84218 6.09571 3.60394 5.20446C4.36569 4.3132 5.39514 3.69206 6.53875 3.43368ZM14.8922 3.72188C15.1461 3.46804 15.1461 3.05648 14.8922 2.80264C14.6384 2.5488 14.2268 2.5488 13.973 2.80264L7.73696 9.03868L6.37049 7.67221C6.11665 7.41837 5.70509 7.41837 5.45125 7.67221C5.19741 7.92605 5.19741 8.33761 5.45125 8.59145L7.27734 10.4175C7.53118 10.6714 7.94273 10.6714 8.19658 10.4175L14.8922 3.72188Z"
        fill={style.color}
      />
    </svg>
  );
};

export default SuccessfullChecked;
