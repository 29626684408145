import { useEffect, useMemo, useState } from "react";
import ProgressBarComponent from "../../../components/progressBarComponent/ProgressBarComponent";
import "./ContactProfile.css";
import { IoMdAdd } from "react-icons/io";
import { getContactProfileBYIdAPI } from "../../../services/servicesAPI";
import { toast } from "react-toastify";
import { GoChevronDown, GoChevronRight } from "react-icons/go";
import LoaderComponent from "../../../components/loaderComponent/LoaderComponent";
import { useLocation, useNavigate } from "react-router-dom";
import ContactProfileEditModal from "./contactProfileEditModal/ContactProfileEditModal";
import { convertToReadableFormat } from "../../../utils/Utils";
import ContactMailSVG from "../../../asset/svg/ContactMailSVG";
import { capitalizeFirstLetter } from "@syncfusion/ej2-react-grids";
// import { object } from "yup";
// import { Navigate } from "react-router-dom";

const ContactProfile = () => {
  // const [profileDetails,setProfileDetails] = useState([])
  const location = useLocation();

  let avoidKey = [
    "_id",
    "phoneData",
    "companyWebsite",
    "companyLinkedInURL",
    "companyFounded",
    "companyStreetAddress",
    "companyCity",
    "companyState",
    "companyCountry",
    "companyName",
    "contactEmailData",
    "city",
    "state",
    "streetAddress",
    "zipCode",
  ];

  const navigate = useNavigate();
  const [profileDetailEmail, setProfileDetailEmail] = useState([]);
  const [profileDetailPhone, setProfileDetailPhone] = useState([]);
  const [profileDetailAddress, setProfileDetailAddress] = useState([]);
  const [profileDetailHCBU, setProfileDetailHBCU] = useState([]);
  const [profileDetailsName, setProfileDetailsName] = useState({});
  const [contactProfileDetails, setContactProfileDetails] = useState([]);
  const [editContactProfileDetails, setEditContactProfileDetails] = useState(
    []
  );
  const [loader, setLoader] = useState(true);
  const [toogleEmail, setToogleEmail] = useState(false);
  const [tooglePhone, setTooglePhone] = useState(false);
  const [toogleAddress, setToogleAddress] = useState(false);
  const [editProfileModal, setEditProfileModal] = useState(false);
  const [refreshPage, setRefreshPage] = useState(false);
  const [viewAll, setViewAll] = useState(false);
  const [arrState, setArrState] = useState([]);

  console.log(location.pathname.split("/")[3]);
  useEffect(() => {
    console.log("useEffect=============>", location.pathname.split("/")[3]);
    getContactProfileById(location.pathname.split("/")[3]);
  }, []);
  const getContactProfileById = async (id) => {
    setLoader(true);
    const response = await getContactProfileBYIdAPI(id);

    if (response.success) {
      // toast.success(response.message)
      console.log("response contact profile =========>", response?.data?.data);
      setEditContactProfileDetails(response?.data?.data);
      const entries = Object.entries(response?.data?.data);
      let temp = entries.filter((item) => {
        if (avoidKey.includes(item[0])) {
          return false;
        } else {
          return true;
        }
      });
      console.log("temp====>", temp.length);
      let filteredTemp = temp.map((item, index) => {
        if (typeof item[1] === "object") {
          item.push(true);
          item.push(index);
        } else {
          item.push(false);
        }
        return item;
      });

      console.log("temp========>", filteredTemp);

      setContactProfileDetails(filteredTemp);
      // console.log("entries======>",typeof(entries),entries)
      // setProfileDetails(response?.data?.data)

      // let temp = {
      //     firstName:response?.data?.data?.firstName,
      //     lastName:response?.data?.data?.lastName
      // }
      // setProfileDetailsName(temp)

      // setProfileDetailEmail(response?.data?.data?.contactEmailData)
      // setProfileDetailPhone(response?.data?.data?.phoneData)
      // setProfileDetailAddress(response?.data?.data?.addressData)
      // setProfileDetailHBCU(response?.data?.data?.hbcuSourceData)
    } else {
      toast.error(response.message);
    }
    setLoader(false);
  };

  const handleToogle = (index, value) => {
    contactProfileDetails[index][2] = !value[2];
    console.log("index========>", index);
    console.log("value========>", value);
    setRefreshPage(!refreshPage);
  };

  let obj = {
    email: "",
    phone: "",
    address: "",
    group: "",
    firstName: "",
    lastName: "",
    HBCUName: "",
    HBCUShort: "",
    HBCUSpirit: "",
  };
  useEffect(() => {
    let arr = [[], [], [], [], [], [], [], [], []];

    contactProfileDetails.map((item) => {
      if (item[0] == "email") {
        obj.email = item[1];
        arr[0].push(item[0]);
        arr[0].push(item[1]);
      }
      if (item[0] == "phone") {
        obj.phone = item[1];
        arr[1].push(item[0]);
        arr[1].push(item[1]);
      }
      if (item[0] == "addressData") {
        obj.address = item[1];
        arr[2].push(item[0]);
        arr[2].push(item[1]);
      }
      if (item[0] == "group") {
        obj.group = item[1];
        arr[3].push(item[0]);
        arr[3].push(item[1]);
      }
      if (item[0] == "firstName") {
        obj.firstName = item[1];
        arr[4].push(item[0]);
        arr[4].push(item[1]);
      }
      if (item[0] == "lastName") {
        obj.lastName = item[1];
        arr[5].push(item[0]);
        arr[5].push(item[1]);
      }
      if (item[0] == "HBCUName") {
        obj.HBCUName = item[1];
        arr[6].push(item[0]);
        arr[6].push(item[1]);
      }
      if (item[0] == "HBCUShort") {
        obj.HBCUShort = item[1];
        arr[7].push(item[0]);
        arr[7].push(item[1]);
      }
      if (item[0] == "HBCUSpirit") {
        obj.HBCUSpirit = item[1];
        arr[8].push(item[0]);
        arr[8].push(item[1]);
      }
    });
    setArrState(arr);
  }, [contactProfileDetails]);

  const handleToogle2 = (index, value) => {
    console.log(value);
    setViewAll(!viewAll);
    arrState[index][2] == true || arrState[index][2] == false
      ? (arrState[index][2] = !value[2])
      : arrState[index].push(true);
    console.log(arrState);
    setArrState(arrState);
  };
  // console.log("obj", arr);

  return (
    <div className="contact-profile">
      {loader && <LoaderComponent />}
      {editProfileModal && (
        <ContactProfileEditModal
          setEditProfileModal={setEditProfileModal}
          editContactProfileDetails={editContactProfileDetails}
          onClose={() => {
            setEditProfileModal(false);
          }}
        />
      )}
      <div className="contact-profile-1">
        <div className="contact-profile-1-1">
          <p>Email Sent </p>
          <h1>1</h1>
        </div>
        <div
          style={{
            borderLeft: "1px solid #E5E7EB",
            borderRight: "1px solid #E5E7EB",
          }}
          className="contact-profile-1-2"
        >
          <div className="contact-profile-1-2-1">
            <p>Sended</p>
            <h1>74%</h1>
          </div>
          <div className="contact-profile-1-2-2">
            <progress style={{ height: "3vh" }} id="file" value="74" max="100">
              {" "}
              32%{" "}
            </progress>
          </div>
        </div>
        <div className="contact-profile-1-2">
          <div className="contact-profile-1-2-1">
            <p>Clicked</p>
            <h1>32%</h1>
          </div>
          <div className="contact-profile-1-2-2">
            <progress style={{ height: "3vh" }} id="file" value="32" max="100">
              {" "}
              32%{" "}
            </progress>
          </div>
        </div>
      </div>

      <div className="contact-profile-2">
        <div className="contact-profile-2-heading">
          <h1>Contact details</h1>
          <button onClick={() => setEditProfileModal(true)}>Edit</button>
        </div>
        {arrState.map((item, index) => {
          if (!(item == "")) {
            console.log(item == "");
            return (
              <div style={{ display: "flex", flexDirection: "column" }}>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    padding: "1rem",
                    alignItems: "start",
                  }}
                >
                  <div
                    style={{
                      color: "#454545",
                      display: "flex",
                      flexDirection: "row",
                    }}
                  >
                    {capitalizeFirstLetter(item[0])}
                    <span
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "center",
                        alignItems: "start",
                      }}
                    >
                      {Array.isArray(item[1]) ? (
                        <>
                          <span
                            style={{
                              background: "#efefef",
                              padding: "1px 8px",
                              margin: "0px 5px",
                              borderRadius: "2px",
                            }}
                          >
                            {item[1].length}
                          </span>
                          {item[2] ? (
                            <GoChevronDown
                              style={{ cursor: "pointer" }}
                              onClick={() => {
                                handleToogle2(index, item);
                              }}
                              size={15}
                            />
                          ) : (
                            <GoChevronRight
                              style={{ cursor: "pointer" }}
                              onClick={() => {
                                handleToogle2(index, item);
                              }}
                              size={15}
                            />
                          )}
                        </>
                      ) : (
                        ""
                      )}
                    </span>
                  </div>
                  <div
                    style={{
                      width: "50%",
                      wordBreak: "break-word",
                      fontWeight: "",
                    }}
                  >
                    {Array.isArray(item[1]) ? (
                      <div>
                        {item[2] ? (
                          <div>
                            {item[1].map((e) => {
                              return (
                                <div style={{ marginBottom: "4px" }} key={e}>
                                  <div>
                                    {typeof e == "object" ? (
                                      <span>
                                        {e?.streetAddress}
                                        {" , "}
                                        {e?.addressLine2}
                                        {" , "}
                                        {e?.city}
                                        {" , "}
                                        {e?.state}
                                        {" , "}
                                        {e?.country}
                                      </span>
                                    ) : (
                                      e
                                    )}
                                  </div>
                                </div>
                              );
                            })}
                          </div>
                        ) : typeof item[1][1] == "object" ? (
                          <>
                            {item[1][0]?.streetAddress} 
                            {" , "}
                            {item[1][0]?.addressLine2}
                            {" , "}
                            {item[1][0]?.city}
                            {" , "}
                            {item[1][0]?.state}
                            {" , "}
                            {item[1][0]?.country}
                          </>
                        ) : (
                          typeof item[1][0] == "object" ? <>
                          {item[1][0]?.streetAddress} 
                          {" , "}
                          {item[1][0]?.addressLine2}
                          {" , "}
                          {item[1][0]?.city}
                          {" , "}
                          {item[1][0]?.state}
                          {" , "}
                          {item[1][0]?.country}
                        </> : item[1][0]
                        )}
                      </div>
                    ) : (
                      item[1]
                    )}
                  </div>
                </div>
                <div style={{ background: "#ededed", height: "1px" }}></div>
              </div>
            );
          }
        })}
        {/* {contactProfileDetails.map((item, index) => {
          return (
            <div className="contact-profile-2-item">
              <div className="contact-profile-2-item-1">
                <p>
                  {convertToReadableFormat(item[0])}
                  {typeof item[1] === "object" && (
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <span className="contact-profile-2-item-span">
                        {item[1]?.length}
                      </span>
                      {!item[2] ? (
                        <GoChevronDown
                          style={{ cursor: "pointer" }}
                          onClick={() => {
                            handleToogle(index, item);
                          }}
                          size={15}
                        />
                      ) : (
                        <GoChevronRight
                          style={{ cursor: "pointer" }}
                          onClick={() => {
                            handleToogle(index, item);
                          }}
                          size={15}
                        />
                      )}
                    </div>
                  )}
                </p>
              </div>
              <div className="contact-profile-2-item-2">
                
                {typeof item[1] === "object" ? (
                  !item[2] ? (
                    <div>
                      {item[1].map((item) => {
                        if (item?.streetAddress) {
                          return (
                            <p>
                              {item?.streetAddress +
                                " " +
                                item?.city +
                                " " +
                                item?.state +
                                " " +
                                item?.country +
                                " " +
                                item?.zipCode}
                            </p>
                          );
                        } else {
                          return <p>{item}</p>;
                        }
                      })}
                    </div>
                  ) : (
                    <div>
                      {item[1].slice(0, 1).map((item) => {
                        if (item?.streetAddress) {
                          return (
                            <p>
                              {item?.streetAddress +
                                " " +
                                item?.city +
                                " " +
                                item?.state +
                                " " +
                                item?.country +
                                " " +
                                item?.zipCode}
                            </p>
                          );
                        } else {
                        }
                      })}
                    </div>
                  )
                ) : (
                  <p>{item[1]}</p>
                )}
              </div>
            </div>
          );
        })} */}

        {/* <div className="contact-profile-2-item">
                <div className="contact-profile-2-item-1"><p>Email 
                    {
                       profileDetailEmail?.length > 1 && <div style={{display:"flex",alignItems:"center"}}>
                       <span className="contact-profile-2-item-span">{profileDetailEmail?.length}</span>
                       {
                        toogleEmail ? <GoChevronDown style={{cursor:"pointer"}}  onClick={()=>{setToogleEmail(false)}} size={15} /> : <GoChevronRight style={{cursor:"pointer"}} onClick={()=>{setToogleEmail(true)}} size={15} />
                       }
                       </div>
                    }

                    </p></div>
                <div className="contact-profile-2-item-2">
                    {
                        toogleEmail ? <div>
                            {
                                profileDetailEmail.map((item)=>{
                                    return <p>{item.email}</p>
        
                                })
                            }
                        </div>
                        :
                        
                         <p>{profileDetailEmail[0]?.email}</p>
                    }
                    
                </div>
            </div> */}
        {/* <div className="contact-profile-2-item">
                <div className="contact-profile-2-item-1"><p>Phone 
                    {
                       profileDetailEmail?.length > 1 && <div style={{display:"flex",alignItems:"center"}}>
                       <span className="contact-profile-2-item-span">{profileDetailEmail?.length}</span>
                       {
                        tooglePhone ? <GoChevronDown style={{cursor:"pointer"}} onClick={()=>{setTooglePhone(false)}} size={15} /> : <GoChevronRight style={{cursor:"pointer"}} onClick={()=>{setTooglePhone(true)}} size={15} />
                       }
                       </div>
                    }
                    
                    </p></div>
                <div className="contact-profile-2-item-2">
                {
                        tooglePhone ? <div>
                            {
                                profileDetailPhone.map((item)=>{
                                    return <p>{item.email}</p>
        
                                })
                            }
                        </div>
                        :
                        
                         <p>{profileDetailEmail[0]?.email}</p>
                    }
                </div>
            </div> */}
        {/* <div className="contact-profile-2-item">
                <div className="contact-profile-2-item-1"><p>Address 
                    {
                       profileDetailEmail?.length > 1 && <div style={{display:"flex",alignItems:"center"}}>
                       <span className="contact-profile-2-item-span">{profileDetailEmail?.length}</span>
                       {
                        toogleAddress ? <GoChevronDown style={{cursor:"pointer"}} onClick={()=>{setToogleAddress(false)}} size={15} /> : <GoChevronRight style={{cursor:"pointer"}} onClick={()=>{setToogleAddress(true)}} size={15} />
                       }
                       </div>
                    }
                    
                    </p></div>
                <div className="contact-profile-2-item-2">
                    {
                        toogleAddress ? <div>
                            {
                                profileDetailAddress.map((item)=>{
                                    return <p>{item.email}</p>
        
                                })
                            }
                        </div>
                        :
                        
                         <p>{profileDetailEmail[0]?.email}</p>
                    }
                </div>
            </div> */}

        {/* <div className="contact-profile-2-item">
          <div className="contact-profile-2-item-1">
            <p>Groups</p>
          </div>
          <div className="contact-profile-2-item-2">
            <span
              style={{
                borderRadius: "4px",
                backgroundColor: "#E5E7EB",
                padding: "0.5vh 0.8vw",
                fontSize: "0.75em",
              }}
            >
              7 chapter cities
            </span>
            <p>{profileDetails?.contactEmailData[0]?.email}</p>
          </div>
        </div> */}

        {/* <div className="contact-profile-2-item">
                <div className="contact-profile-2-item-1"><p>First name</p></div>
                <div className="contact-profile-2-item-2">
                    <p>{profileDetailsName?.firstName}</p>
                </div>
            </div> */}
        {/* <div className="contact-profile-2-item">
                <div className="contact-profile-2-item-1"><p>Last name</p></div>
                <div className="contact-profile-2-item-2">
                    <p>{profileDetailsName?.lastName}</p>
                </div>
            </div> */}
        {/* <div className="contact-profile-2-item">
                <div className="contact-profile-2-item-1"><p>HBCU name</p></div>
                <div className="contact-profile-2-item-2">
                    {
                        profileDetailHCBU.length > 0 && <p>{profileDetailHCBU[0]?.HBCUName}</p>
                    }
                </div>
            </div> */}
        {/* <div className="contact-profile-2-item">
                <div className="contact-profile-2-item-1"><p>HBCU short</p></div>
                <div className="contact-profile-2-item-2">
                    {
                        profileDetailHCBU.length > 0 && <p>{profileDetailHCBU[0]?.HBCUShort}</p>
                    }
                </div>
            </div> */}
        {/* <div className="contact-profile-2-item">
                <div className="contact-profile-2-item-1"><p>HBCU spirit</p></div>
                <div className="contact-profile-2-item-2">
                    {
                        profileDetailHCBU.length > 0 && <p>{profileDetailHCBU[0]?.HBCUSpirit}</p>
                    }
                </div>
            </div> */}
      </div>

      <div className="contact-profile-3">
        <div className="contact-profile-3-heading">
          <h1>Activity</h1>
        </div>
        <div className="contact-profile-3-date">
          <p>2024-01-01</p>
        </div>
        <div
          style={{ display: "flex", flexDirection: "row", paddingTop: "1rem" }}
        >
          <div style={{ paddingRight: "0.6rem" }} className="">
            <ContactMailSVG />
          </div>
          <div className="contact-profile-3-item-right">
            <h3>Auto Update - Was sent</h3>
            <p>12:31:59</p>
          </div>
        </div>
        <div className="contact-profile-3-item"></div>
        <div className="contact-profile-3-item">
          <div className="contact-profile-3-item-left">
            <div>
              <IoMdAdd size={18} />
            </div>
          </div>
          <div className="contact-profile-3-item-right">
            <h3>
              Add to group{" "}
              <span
                onClick={() => {
                  navigate("/contacts/group");
                }}
                style={{ color: "#2B87E5", cursor: "pointer" }}
              >
                Ohio
              </span>
            </h3>
            <p>12:31:59</p>
          </div>
        </div>
        <div className="contact-profile-3-item">
          <div className="contact-profile-3-item-left">
            <div>
              <IoMdAdd size={18} />
            </div>
          </div>
          <div className="contact-profile-3-item-right">
            <h3>
              Contact has been{" "}
              <span style={{ color: "#2B87E5", cursor: "pointer" }}>
                <span
                  onClick={() => {
                    navigate(
                      `/contacts/import/status/${
                        location.pathname.split("/")[3]
                      }`
                    );
                  }}
                >
                  Imported
                </span>
              </span>
            </h3>
            <p>12:31:59</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactProfile;
