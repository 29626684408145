import React from "react";

function MinusSVG({color}) {
  return (
    <svg
      width="16"
      height="17"
      viewBox="0 0 16 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M3.85156 8.33335C3.85156 7.97436 4.14258 7.68335 4.50156 7.68335H11.1682C11.5272 7.68335 11.8182 7.97436 11.8182 8.33335C11.8182 8.69233 11.5272 8.98335 11.1682 8.98335H4.50156C4.14258 8.98335 3.85156 8.69233 3.85156 8.33335Z"
        fill={color}
      />
    </svg>
  );
}

export default MinusSVG;
