import { NavLink, useNavigate } from "react-router-dom";
import "./ImportStatusNavbar.css";
const ImportStatusNavbar = ({id, badEmails}) => {
  const navigate = useNavigate();

  const redirectTo = () => {
    navigate(`/contacts/import/status/${id}/bad-format`, { state: { badEmails } });
  }

  return (
    <div className="import-status-navbar">
      <ul>
        {/* <li>
          <NavLink to={`/contacts/import/status/${id}`} end>
            Inactive <span>10</span>
          </NavLink>
        </li> */}
        {/* <li>
          <NavLink to="/contacts/import/status/unchanged-contacts" end>
            Unchanged contacts <span>7</span>
          </NavLink>
        </li>
        <li>
          <NavLink to="/contacts/import/status/unsubscribed" end>
            Unsubscribed <span>7</span>
          </NavLink>
        </li> */}
        <li>
          {/* <NavLink to={`/contacts/import/status/${id}/bad-format`} end>
            Bad Format <span>{badEmails?.length || 0}</span>
          </NavLink> */}
          <p onClick={() => redirectTo()}>
            Bad Format <span>{badEmails?.length || 0}</span>
          </p>
        </li>
      </ul>
    </div>
  );
};

export default ImportStatusNavbar;
