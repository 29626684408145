import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { Provider } from 'react-redux';
import { store } from './redux/store';
import { registerLicense } from '@syncfusion/ej2-base';
// import dotenv from "dotenv"
// dotenv.config()

registerLicense(process.env.REACT_APP_SYNC_FUSION_LICENSE_KEY);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    // <React.StrictMode>
        <Provider store={store}>
            <App />
        </Provider>
    // </React.StrictMode> 
);
reportWebVitals();
