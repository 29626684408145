
import { NavLink, useLocation, useNavigate } from "react-router-dom"
import "./MatchColumnsNavbarComponent.css"
import { useCallback, useContext, useEffect, useState } from "react"
import { MyContext } from "../../ContextAPIStore/CreateContext";

const MatchColumnsNavbarComponent = ()=>{
    const constConfigData = useContext(MyContext)

    // const IMPORT_CSV = "IMPORT_CSV"
    // const COPY_PASTE = "COPY_PASET"
    // const ADD_SINGLE = "ADD_SINGLE"
    // const MAIL_CHAMP = "MAIL_CHAMP"
    // const IMPORT_MD = "IMPORT_MD"
    // const [activeNavbarStatus,setActiveNavbarStatus] = useState({
    //     key:"IMPORT_CSV",
    //     value:true
    // })

    // const navigate = useNavigate()
    // const location = useLocation()

    // useEffect(()=>{
    //     let temp = {
    //         key: location?.state?.fromScreen === "ALL_SUBSCRIBER"  ?"IMPORT_CSV":location.state?.fromScreen ,
    //         value:true
    //     }
    //     setActiveNavbarStatus(temp)

    // },[])


    // const handleToggleNav = (value)=>{

    //     if(value === IMPORT_CSV){
    //         navigate("/contacts/all/import",{state:{fromScreen:IMPORT_CSV}})
    //     }else if(value === COPY_PASTE){
    //         navigate("/contacts/all/import/copy-paste",{state:{fromScreen:COPY_PASTE}})

    //     }
    //     else if(value === ADD_SINGLE){
    //         navigate("/contacts/all/import/add-single",{state:{fromScreen:ADD_SINGLE}})

    //     }
    //     else if(value === MAIL_CHAMP){
    //         navigate("/contacts/all/import/mail-champ",{state:{fromScreen:MAIL_CHAMP}})

    //     }else if(value === IMPORT_MD){
    //         navigate("/contacts/all/import/md-pdl",{state:{fromScreen:IMPORT_MD}})
    //     }

    // }
    return <div className="match-columns-navbar">
    <ul>
        {/* <li
        onClick={()=>{handleToggleNav(IMPORT_CSV)}}
        style={{
            borderBottom:`2px solid ${activeNavbarStatus.key === IMPORT_CSV ? "#2081E3":"#F3F4F6" }`
        }}
        > {constConfigData?.Import_from_csv}</li> */}
        
        
        <li><NavLink  to="/contacts/import/" end >{constConfigData?.Import_from_csv}</NavLink></li>
        <li> <NavLink   to="/contacts/import/md-pdl" end >Import from misc types</NavLink> </li>
    </ul>


</div>
}

export default MatchColumnsNavbarComponent