import React from "react";

function ActivitySVG() {
  return (
    <div  >
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<rect width="24" height="24" rx="4" fill="#E5E7EB" fill-opacity="0.5"/>
<path d="M4.5 12.75H7.5L10 7.25L14 17.75L16.5 12.75H19.5" stroke="black" stroke-width="1.4" stroke-linecap="round" stroke-linejoin="round"/>
</svg>
    </div>
  );
}

export default ActivitySVG;
